import { ReactElement, useContext, useEffect, useState } from 'react';

import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import DeleteIcon from 'components/icons/table/DeleteIcon';
import UnpinIcon from 'components/icons/table/UnpinIcon';
import PreviewIcon from 'components/icons/PreviewIcon';
import EditIcon from 'components/icons/table/EditIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import PreviewAttachmentIcon from 'components/icons/table/PreviewAttachmentIcon';
import AttachmentIcon from 'components/icons/AttachmentIcon';
import CopyIcon from 'components/icons/table/CopyIcon';

import styles from 'pages/entityList/EntityList.module.css';

import { useEditorChanged, useRefreshComponent, useModalForm } from 'hooks';
import modal from 'components/messages/modal';
import notification from 'components/messages/notification';
import ServerAPI from 'integration/ServerAPI';

import { downloadItem } from 'components/controls/FilesList/FileItem';
import { getObjectDataByTableRecord } from 'utils/FormatUtils';
import { RowToolType } from 'interfaces/TableView.dto';
import { RowToolActionType, TableRecord, ToolsIconType } from 'interfaces/Table';
import { JsObject } from 'interfaces/Object';
import { useWindowWidth } from '@react-hook/window-size';
import { tryParsePercentage } from '../utils/size';
import { RefreshID } from 'enums/refresh';
import useModalWithText from 'hooks/useModalWithText';

type GetToolIconMapType = JsObject<ReactElement>;

const toolIconMap: GetToolIconMapType = {
  [ToolsIconType.DELETE]: <DeleteIcon className={styles.icon} />,
  [ToolsIconType.UNPIN]: <UnpinIcon className={styles.icon} />,
  [ToolsIconType.PREVIEW]: <PreviewIcon className={styles.icon} />,
  [ToolsIconType.EDIT]: <EditIcon className={styles.icon} />,
  [ToolsIconType.ED]: <EditIcon className={styles.icon} />,
  [ToolsIconType.DOWNLOAD]: <DownloadIcon className={styles.icon} />,
  [ToolsIconType.HAS_ATTACHMENT]: <AttachmentIcon className={styles.icon} />,
  [ToolsIconType.PREVIEW_ATTACHMENT]: <PreviewAttachmentIcon className={styles.icon} />,
  [ToolsIconType.COPY]: <CopyIcon className={styles.icon} />,
} as const;

const iconByActionMap: GetToolIconMapType = {
  [RowToolActionType.DELETE]: toolIconMap[ToolsIconType.DELETE],
  [RowToolActionType.UNPIN]: toolIconMap[ToolsIconType.UNPIN],
  [RowToolActionType.PREVIEW]: toolIconMap[ToolsIconType.PREVIEW],
  [RowToolActionType.EDIT]: toolIconMap[ToolsIconType.EDIT],
  [RowToolActionType.DOWNLOAD]: toolIconMap[ToolsIconType.DOWNLOAD],
  [RowToolActionType.PREVIEW_ATTACHMENT]: toolIconMap[ToolsIconType.PREVIEW_ATTACHMENT],
  [RowToolActionType.COPY]: toolIconMap[ToolsIconType.COPY],
} as const;

const getToolIcon = ({ icon, action }: RowToolType, record?: TableRecord): ReactElement => {
  if (icon === 'hasAttachment' && record?.Files?.length === 0) {
    return <></>;
  } else {
    return (icon && toolIconMap[icon]) || iconByActionMap[action];
  }
};

export const useTableViewRowTools = ({ componentData, setResult, setPdf }: any) => {
  const { updateEditorChanged } = useEditorChanged();
  const { refreshComponent } = useRefreshComponent();
  const { showModalEdit, showModalReadOnly, showModalCopy } = useModalForm();
  const { form } = useContext(EntityEditorContext);
  const width = useWindowWidth();
  const { dataModal, getDataModal } = useModalWithText()

  const { refreshID, className, objectID, rowTools, simpleTable, isCardTable, fillCriteriaFrom } =
    componentData;

  const tableViewDeleteRow = async (record: any) => {
    await ServerAPI.deleteObject({
      id: record?.ID,
      className,
    });
    if (record?.VersionSeriesId) {
      refreshComponent(RefreshID.EDITOR_DETAILS_DATA)
    } else {
      refreshComponent(refreshID);
    }
  }

  const tableViewUnpinRow = (currentValues: any, record: TableRecord) => {
    updateEditorChanged(true);
    currentValues.splice(currentValues.indexOf(record.ID), 1);
    form?.setFieldsValue({ [fillCriteriaFrom]: currentValues });
    refreshComponent(refreshID);
  }

  useEffect(() => {
    if (dataModal.header !== '') {
      modal.showModal({ ...dataModal });
    }
  }, [dataModal])

  const tableViewDownloadRowTool = async (record: TableRecord) => {
    if (!record?.Files?.length) {
      notification.error('Не удалось обнаружить вложения');
      return;
    }

    const getFilesFetch: any = await ServerAPI.getFiles({
      property: JSON.stringify({
        className: record.ClassName,
        linkedId: record.ID,
      }),
    });

    const firstAttachment = getFilesFetch?.data?.[0];
    downloadItem(firstAttachment.params.Url, width, firstAttachment.params.fileName);
  };

  const tableViewFilePreviewTool = async (record: TableRecord) => {
    if (!record?.Files?.length) {
      notification.error('Не удалось обнаружить вложения');
      return;
    }

    const getFilesData = {
      property: JSON.stringify({
        className: record.ClassName,
        linkedId: record.ID,
      }),
    };

    const getFilesFetch: any = await ServerAPI.getFiles(getFilesData);

    const attachmentPreview = await ServerAPI.getFilesPreview({
      ...getFilesData,
      contentId: getFilesFetch?.data?.[0]?.id,
    });
    if (attachmentPreview.data.converted) {
      setPdf(attachmentPreview.data.file);
    } else {
      setResult(attachmentPreview.data.file);
    }
  };

  const getToolAction = (action: string, record?: TableRecord) => {
    if (!record || !record.id || !record.classType) return;

    switch (action) {
      case RowToolActionType.UNPIN: {
        const currentValues: any = fillCriteriaFrom && form?.getFieldValue(fillCriteriaFrom);
        if (!Array.isArray(currentValues) || !currentValues.length) return;
        return getDataModal(action, width, false, () => tableViewUnpinRow(currentValues, record))
      }
      case RowToolActionType.DELETE: {
        return getDataModal(action, width, false, () => tableViewDeleteRow(record))
      }
      case RowToolActionType.DOWNLOAD: {
        return tableViewDownloadRowTool(record);
      }
      case RowToolActionType.PREVIEW_ATTACHMENT: {
        return tableViewFilePreviewTool(record);
      }
      case RowToolActionType.EDIT: {
        return showModalEdit({
          objectId: record.id,
          objectClassName: record.classType,
          layoutParentId: objectID,
          parentClassName: record.parentClassType,
          // modalDetails: getObjectDataByTableRecord(record),
          refreshIDs: refreshID,
        });
      }
      case RowToolActionType.PREVIEW: {
        return showModalReadOnly({
          objectId: record.id,
          objectClassName: record.classType,
          layoutParentId: objectID,
          parentClassName: record.parentClassType,
          // modalDetails: getObjectDataByTableRecord(record),
          refreshIDs: refreshID,
        });
      }
      case RowToolActionType.COPY: {
        return showModalCopy({
          objectId: record.id,
          objectClassName: record.classType,
          layoutParentId: objectID,
          parentClassName: record.parentClassType,
          // modalDetails: getObjectDataByTableRecord(record),
          refreshIDs: refreshID,
        });
      }
    }
  };

  const getColumnsWithRowTools = (columns: any) => {
    if (simpleTable) return columns;

    if (rowTools && columns) {
      const finalRowTools: any = { left: [], right: [] };

      for (const rowTool of rowTools) {
        const dataIndex = rowTool.action === 'copy' ? 'duplicate' : rowTool.action;
        const rowToolPosition = rowTool.position || 'right';
        const rowToolWidth: string | boolean = tryParsePercentage(rowTool.width) || '50px';
        const rowToolToRender: any = {
          title: '',
          dataIndex,
          className: styles.rowBtn,
          width: rowToolWidth,
          key: 'action',
          fixed: 'right',
          render: (_: any, record: TableRecord) => (
            <div
              className={styles.rowBtn}
              onClick={() => {
                getToolAction(rowTool.action, record);
              }}
            >
              {getToolIcon(rowTool, record)}
            </div>
          ),
        };

        if (rowTool.fixed) rowToolToRender.fixed = rowToolPosition;
        finalRowTools[rowToolPosition].push(rowToolToRender);
      }

      return [...finalRowTools.left, ...columns, ...finalRowTools.right];
    }

    return columns;
  };

  return { getColumnsWithRowTools };
};
