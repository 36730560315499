import { ActionType, Action } from '../actionTypes';

export interface AppSettings {
  fields: string[];
}

const initialState = {
  fields: [],
};

export const checkedFilters = (
  state: AppSettings = initialState,
  action: Action<ActionType, any>
) => {
  switch (action.type) {
    case ActionType.IS_CHECKED_FILTERS:
      let fields;
      if (action.payload.checked) {
        fields = [...state.fields, action.payload];
      } else if (action.payload.reset) {
        fields = state.fields.map((item: any) => {
          return {
            fieldName: item.fieldName,
            checked: false,
            condition: item.condition,
          };
        });
      } else {
        fields = state.fields.map((item: any) => {
          if (item.fieldName === action.payload.fieldName) {
            return {
              fieldName: item.fieldName,
              checked: false,
              condition: item.condition,
            };
          } else {
            return item;
          }
        });
      }
      return {
        fields,
      };

    default:
      return state;
  }
};

export default checkedFilters;
