import React from 'react'
import styles from './TechSupBlock.module.css';
import MailIcon from '../../icons/MailIcon';
import PhoneFilledIcon from '../../icons/PhoneFilledIcon';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import AppBlock from 'components/AppBlock/AppBlock';

function Block(props: BaseComponentProps) {
  return (
    <AppBlock>
      <div className={styles.block}>
        <div className={styles.name}>{props?.value?.Name}</div>
        <div className={styles.text}>{props?.value?.OrganizationLink}</div>
        <div className={styles.splitter}></div>
        <div className={styles.row}>
          <div className={styles.icon}>
            <MailIcon />
          </div>
          <a className={styles.link} target='_blank' href={`mailto:${props?.value?.Email}`} rel="noreferrer">{props?.value?.Email}</a>
        </div>
        <div className={styles.row}>
          <div className={styles.icon}>
            <PhoneFilledIcon />
          </div>
          <a className={styles.link} href={`tel:${props?.value?.PhoneNumber}`}>{props?.value?.PhoneNumber}</a>
        </div>
      </div>
    </AppBlock>
  )
}

export default React.memo(Block)