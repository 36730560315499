import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#D4F0FF' />
    <path
      d='M6.25 16.5C6.94036 16.5 7.5 15.9404 7.5 15.25C7.5 14.5596 6.94036 14 6.25 14C5.55964 14 5 14.5596 5 15.25C5 15.9404 5.55964 16.5 6.25 16.5Z'
      fill='#AACFE4'
    />
    <path
      d='M23.75 16.5C24.4404 16.5 25 15.9404 25 15.25C25 14.5596 24.4404 14 23.75 14C23.0596 14 22.5 14.5596 22.5 15.25C22.5 15.9404 23.0596 16.5 23.75 16.5Z'
      fill='#2A6E92'
    />
    <path
      d='M23.1255 11.958C23.7234 11.6129 23.9282 10.8484 23.583 10.2505C23.2379 9.65263 22.4734 9.44779 21.8755 9.79297C21.2776 10.1381 21.0728 10.9026 21.418 11.5005C21.7631 12.0984 22.5276 12.3032 23.1255 11.958Z'
      fill='#2A6E92'
    />
    <path
      d='M20.458 8.3745C20.8032 7.77663 20.5984 7.01215 20.0005 6.66697C19.4026 6.32179 18.6381 6.52663 18.293 7.1245C17.9478 7.72237 18.1526 8.48685 18.7505 8.83203C19.3484 9.17721 20.1129 8.97237 20.458 8.3745Z'
      fill='#004D77'
    />
    <path
      d='M23.583 20.2534C23.9282 19.6555 23.7234 18.8911 23.1255 18.5459C22.5276 18.2007 21.7631 18.4055 21.418 19.0034C21.0728 19.6013 21.2776 20.3658 21.8755 20.7109C22.4734 21.0561 23.2379 20.8513 23.583 20.2534Z'
      fill='#558EAD'
    />
    <path
      d='M19.9966 23.833C20.5945 23.4879 20.7993 22.7234 20.4541 22.1255C20.1089 21.5276 19.3445 21.3228 18.7466 21.668C18.1487 22.0131 17.9439 22.7776 18.2891 23.3755C18.6342 23.9734 19.3987 24.1782 19.9966 23.833Z'
      fill='#558EAD'
    />
    <path
      d='M15 25.25C15.6904 25.25 16.25 24.6904 16.25 24C16.25 23.3096 15.6904 22.75 15 22.75C14.3096 22.75 13.75 23.3096 13.75 24C13.75 24.6904 14.3096 25.25 15 25.25Z'
      fill='#7FAFC9'
    />
    <path
      d='M15 7.75C15.6904 7.75 16.25 7.19036 16.25 6.5C16.25 5.80964 15.6904 5.25 15 5.25C14.3096 5.25 13.75 5.80964 13.75 6.5C13.75 7.19036 14.3096 7.75 15 7.75Z'
      fill='#004D77'
    />
    <path
      d='M11.708 23.3745C12.0532 22.7766 11.8484 22.0121 11.2505 21.667C10.6526 21.3218 9.88815 21.5266 9.54297 22.1245C9.19779 22.7224 9.40263 23.4869 10.0005 23.832C10.5984 24.1772 11.3629 23.9724 11.708 23.3745Z'
      fill='#7FAFC9'
    />
    <path
      d='M8.1255 20.7041C8.72337 20.3589 8.92821 19.5945 8.58303 18.9966C8.23785 18.3987 7.47337 18.1939 6.8755 18.5391C6.27763 18.8842 6.07279 19.6487 6.41797 20.2466C6.76315 20.8445 7.52763 21.0493 8.1255 20.7041Z'
      fill='#AACFE4'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.79442 0C9.34672 0 9.79442 0.44772 9.79442 1V4C9.79442 4.55228 9.34672 5 8.79442 5C8.24212 5 7.79442 4.55228 7.79442 4V1C7.79442 0.44772 8.24212 0 8.79442 0ZM8.79442 15C9.34672 15 9.79442 15.4477 9.79442 16V19C9.79442 19.5523 9.34672 20 8.79442 20C8.24212 20 7.79442 19.5523 7.79442 19V16C7.79442 15.4477 8.24212 15 8.79442 15ZM17.4546 5C17.7308 5.47829 17.5669 6.08988 17.0886 6.36602L14.4905 7.86602C14.0122 8.1422 13.4006 7.97829 13.1245 7.5C12.8484 7.02171 13.0122 6.41012 13.4905 6.13398L16.0886 4.63397C16.5669 4.35783 17.1785 4.52171 17.4546 5ZM4.46427 12.5C4.74041 12.9783 4.57653 13.5899 4.09824 13.866L1.50017 15.366C1.02187 15.6422 0.410281 15.4783 0.134141 15C-0.141999 14.5217 0.0218709 13.9101 0.500171 13.634L3.09824 12.134C3.57653 11.8578 4.18812 12.0217 4.46427 12.5ZM17.4546 15C17.1785 15.4783 16.5669 15.6422 16.0886 15.366L13.4905 13.866C13.0122 13.5899 12.8484 12.9783 13.1245 12.5C13.4006 12.0217 14.0122 11.8578 14.4905 12.134L17.0886 13.634C17.5669 13.9101 17.7308 14.5217 17.4546 15ZM4.46427 7.5C4.18812 7.97829 3.57653 8.1422 3.09824 7.86602L0.500171 6.36602C0.0218709 6.08988 -0.141999 5.47829 0.134141 5C0.410281 4.52171 1.02187 4.35783 1.50017 4.63397L4.09824 6.13398C4.57653 6.41012 4.74041 7.02171 4.46427 7.5Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
