import React from 'react';
import { Tooltip } from 'antd';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import styles from './SelectControl.module.css';
import stylesIcon from '../Checkbox/InputCheckbox.module.css'

export interface HintIconProps {
  hint?: string | null;
  cssClassTooltip?: string | null;
  cssClassIcon?: string | null;
}

export const parseHint = (hint?: string | null) => {
  if (!hint) return <div />;

  return hint.split('\\n').map((s: string, i) => <div key={i}>{s}</div>);
};

export const HintIcon: React.FC<HintIconProps> = ({ hint, cssClassTooltip, cssClassIcon }) => (
 
    <Tooltip
      color={'var(--color-light)'}
      title={parseHint(hint)}
      trigger='hover'
      overlayClassName={cssClassTooltip ? cssClassTooltip : styles.tooltip}
    >
      <InfoCircleOutlined className={stylesIcon.icon} style={cssClassTooltip ? {marginRight:5} : {}}/>
    </Tooltip>
 
);

export default HintIcon;
