import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd';

import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import ObjectUtils from 'utils/ObjectUtils';

import { ObjectData } from 'interfaces/Object';
import { AppState } from 'store/reducers';

type GetPropValue = (propName: string) => any;
type GetString = () => string;

type UseObjectDetails = (form?: FormInstance) => {
  details: ObjectData;
  inModal: boolean;
  getPropValue: GetPropValue;
  getChildrenParentID: GetString;
  getChildrenParentClassName: GetString;
};

export const useObjectDetails: UseObjectDetails = (form) => {
  const context = useContext(EntityEditorContext);
  const inModal: boolean = Boolean(context?.inModal);
  form = form || context?.form;

  const details: ObjectData =
    useSelector((state: AppState) => (inModal ? state.modal.modalDetails : state.editorDetails)) ||
    {};

  const getPropValue: GetPropValue = (propName) => {
    if (details.params?.hasOwnProperty(propName)) return details.params[propName];

    return form?.getFieldValue(propName);
  };

  const getChildrenParentID: GetString = () => ObjectUtils.getChildrenParentID(details, form);

  const getChildrenParentClassName: GetString = () =>
    ObjectUtils.getChildrenParentClassName(details, form);

  return { details, inModal, getPropValue, getChildrenParentID, getChildrenParentClassName };
};

export default useObjectDetails;
