import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 34 32' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.625 6.5625C5.10724 6.5625 4.6875 6.98224 4.6875 7.5C4.6875 8.01777 5.10724 8.4375 5.625 8.4375H13.125C13.6428 8.4375 14.0625 8.01777 14.0625 7.5C14.0625 6.98224 13.6428 6.5625 13.125 6.5625H5.625Z' />
    <path d='M4.6875 11.25C4.6875 10.7322 5.10724 10.3125 5.625 10.3125H13.125C13.6428 10.3125 14.0625 10.7322 14.0625 11.25C14.0625 11.7678 13.6428 12.1875 13.125 12.1875H5.625C5.10724 12.1875 4.6875 11.7678 4.6875 11.25Z' />
    <path d='M5.625 14.0625C5.10724 14.0625 4.6875 14.4822 4.6875 15C4.6875 15.5178 5.10724 15.9375 5.625 15.9375H16.875C17.3928 15.9375 17.8125 15.5178 17.8125 15C17.8125 14.4822 17.3928 14.0625 16.875 14.0625H5.625Z' />
    <path d='M4.6875 18.75C4.6875 18.2322 5.10724 17.8125 5.625 17.8125H16.875C17.3928 17.8125 17.8125 18.2322 17.8125 18.75C17.8125 19.2678 17.3928 19.6875 16.875 19.6875H5.625C5.10724 19.6875 4.6875 19.2678 4.6875 18.75Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.8125 0C1.2592 0 0 1.2592 0 2.8125V23.4375C0 24.9908 1.25919 26.25 2.8125 26.25H3.75V27.1875C3.75 28.7408 5.00919 30 6.5625 30H23.4375C24.9908 30 26.25 28.7408 26.25 27.1875V6.5625C26.25 5.0092 24.9908 3.75 23.4375 3.75H22.5V2.8125C22.5 1.2592 21.2408 0 19.6875 0H2.8125ZM20.625 2.8125C20.625 2.29473 20.2053 1.875 19.6875 1.875H2.8125C2.29474 1.875 1.875 2.29474 1.875 2.8125V23.4375C1.875 23.9553 2.29474 24.375 2.8125 24.375H19.6875C20.2053 24.375 20.625 23.9553 20.625 23.4375V2.8125ZM22.5 5.625H23.4375C23.9553 5.625 24.375 6.04474 24.375 6.5625V27.1875C24.375 27.7053 23.9553 28.125 23.4375 28.125H6.5625C6.04474 28.125 5.625 27.7053 5.625 27.1875V26.25H19.6875C21.2408 26.25 22.5 24.9908 22.5 23.4375V5.625Z'
    />
    <circle cx='26.4004' cy='24.5' r='7.5' fill='var(--color-additional-elements)'/>
    <rect x='26.0996' y='21.1992' width='1.2' height='6.6' rx='0.6' fill='white' />
    <rect
      x='23.4004'
      y='25.1016'
      width='1.2'
      height='6.6'
      rx='0.6'
      transform='rotate(-90 23.4004 25.1016)'
      fill='white'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 34 32' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.625 6.5625C5.10724 6.5625 4.6875 6.98224 4.6875 7.5C4.6875 8.01777 5.10724 8.4375 5.625 8.4375H13.125C13.6428 8.4375 14.0625 8.01777 14.0625 7.5C14.0625 6.98224 13.6428 6.5625 13.125 6.5625H5.625Z'
      fill='white'
    />
    <path
      d='M4.6875 11.25C4.6875 10.7322 5.10724 10.3125 5.625 10.3125H13.125C13.6428 10.3125 14.0625 10.7322 14.0625 11.25C14.0625 11.7678 13.6428 12.1875 13.125 12.1875H5.625C5.10724 12.1875 4.6875 11.7678 4.6875 11.25Z'
      fill='white'
    />
    <path
      d='M5.625 14.0625C5.10724 14.0625 4.6875 14.4822 4.6875 15C4.6875 15.5178 5.10724 15.9375 5.625 15.9375H16.875C17.3928 15.9375 17.8125 15.5178 17.8125 15C17.8125 14.4822 17.3928 14.0625 16.875 14.0625H5.625Z'
      fill='white'
    />
    <path
      d='M4.6875 18.75C4.6875 18.2322 5.10724 17.8125 5.625 17.8125H16.875C17.3928 17.8125 17.8125 18.2322 17.8125 18.75C17.8125 19.2678 17.3928 19.6875 16.875 19.6875H5.625C5.10724 19.6875 4.6875 19.2678 4.6875 18.75Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.8125 0C1.2592 0 0 1.2592 0 2.8125V23.4375C0 24.9908 1.25919 26.25 2.8125 26.25H3.75V27.1875C3.75 28.7408 5.00919 30 6.5625 30H23.4375C24.9908 30 26.25 28.7408 26.25 27.1875V6.5625C26.25 5.0092 24.9908 3.75 23.4375 3.75H22.5V2.8125C22.5 1.2592 21.2408 0 19.6875 0H2.8125ZM20.625 2.8125C20.625 2.29473 20.2053 1.875 19.6875 1.875H2.8125C2.29474 1.875 1.875 2.29474 1.875 2.8125V23.4375C1.875 23.9553 2.29474 24.375 2.8125 24.375H19.6875C20.2053 24.375 20.625 23.9553 20.625 23.4375V2.8125ZM22.5 5.625H23.4375C23.9553 5.625 24.375 6.04474 24.375 6.5625V27.1875C24.375 27.7053 23.9553 28.125 23.4375 28.125H6.5625C6.04474 28.125 5.625 27.7053 5.625 27.1875V26.25H19.6875C21.2408 26.25 22.5 24.9908 22.5 23.4375V5.625Z'
    />
    <circle cx='26.4004' cy='24.5' r='7.5' fill='white' />
    <rect x='26.0996' y='21.1992' width='1.2' height='6.6' rx='0.6' fill='var(--color-additional-elements)' />
    <rect
      x='23.4004'
      y='25.1016'
      width='1.2'
      height='6.6'
      rx='0.6'
      transform='rotate(-90 23.4004 25.1016)'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
