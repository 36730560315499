import React from 'react';
import styles from '../../index.module.css';
import cn from 'classnames';

const TableViewCounter = (props: any) => {
  const { count, selectedRows, tableId, simpleTable, isCardTable, readonly } = props;

  return (
    <>
      {count.openCount || count.closedCount ? (
        <h3
          className={cn(styles.text, styles.regCounter)}
        >{`Всего ${count.count} запросов, открытых ${count.openCount}, закрытых ${count.closedCount}`}</h3>
      ) : (
        <h3
          className={cn(styles.text, {
            [styles.regCounter]: !simpleTable && !isCardTable,
          })}
        >
          {selectedRows?.[tableId]?.length ? (
            <span className={styles.textCell}>
              Выбрано (без учета фильтров): {selectedRows?.[tableId]?.length}
            </span>
          ) : (
            ''
          )}
          {`Всего ${readonly && process.env.REACT_APP_TYPE !== 'irk' ? '0' : count.count} записей`}
        </h3>
      )}
    </>
  );
};

export default TableViewCounter;
