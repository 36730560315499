import { ActionType, Action } from '../actionTypes';

const initialState = {};

const meta = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_META_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

export default meta;
