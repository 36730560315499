import { Modal } from 'antd';
import styles from './FileModalPreview.module.css';
import { searchPlugin, Match } from '@react-pdf-viewer/search';
import React, { useEffect, useState } from 'react';
import ClosePreviewIcon from '../../icons/ClosePreviewIcon';
import { LocalizationMap, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import SearchSidebar from './SearchSidebar';
import { SearchIcon } from '@react-pdf-viewer/search';
import { MoreActionsPopover, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// @ts-ignore
import PDFJSWorker from '!!file-loader!pdfjs-dist/build/pdf.worker.js';
interface FileModalProps {
  isOpen: boolean;
  onClose: () => void;
  result: any;
  pdf: any;
  loading?: boolean;
}

const FileModalPreview: React.FC<FileModalProps> = (props) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin();

  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <Toolbar>
      {(toolbarSlot: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Open,
          Print,
          SwitchTheme,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = toolbarSlot;

        return (
          <div className='rpv-toolbar' role='toolbar' aria-orientation='horizontal'>
            <div className='rpv-toolbar__left'>
              <div className='rpv-core__display--hidden rpv-core__display--block-small'>
                <div className='rpv-toolbar__item'>
                  <GoToPreviousPage />
                </div>
              </div>
              <div className='rpv-toolbar__item'>
                <CurrentPageInput />{' '}
                <span className='rpv-toolbar__label'>
                  / <NumberOfPages />
                </span>
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-small'>
                <div className='rpv-toolbar__item'>
                  <GoToNextPage />
                </div>
              </div>
            </div>
            <div className='rpv-toolbar__center'>
              <div className='rpv-toolbar__item'>
                <ZoomOut />
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-small'>
                <div className='rpv-toolbar__item'>
                  <Zoom />
                </div>
              </div>
              <div className='rpv-toolbar__item'>
                <ZoomIn />
              </div>
            </div>
            <div className='rpv-toolbar__right'>
              <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
                <div className='rpv-toolbar__item'>
                  <SwitchTheme />
                </div>
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
                <div className='rpv-toolbar__item'>
                  <EnterFullScreen />
                </div>
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
                <div className='rpv-toolbar__item'>
                  <Open />
                </div>
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
                <div className='rpv-toolbar__item'>
                  <Download />
                </div>
              </div>
              <div className='rpv-core__display--hidden rpv-core__display--block-medium'>
                <div className='rpv-toolbar__item'>
                  <Print />
                </div>
              </div>
              <div className='rpv-toolbar__item'>
                <MoreActionsPopover toolbarSlot={toolbarSlot} />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) =>
      [
        {
          content: (
            <SearchSidebar
              searchPluginInstance={
                defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance
              }
            />
          ),
          icon: <SearchIcon />,
          title: 'Поиск',
        },
      ].concat(defaultTabs),
  });

  return (
    <Modal
      className={styles.modal}
      visible={props.isOpen}
      onCancel={props.onClose}
      footer={false}
      width={!props.pdf ? 'auto' : '80%'}
      closeIcon={<ClosePreviewIcon className={styles.icon} />}
    >
      {!props.pdf ? (
        <img style={{ width: '100%', height: '80vh', objectFit: 'contain' }} src={props.result} />
      ) : (
        <div
          style={{
            height: '80vh',
            width: '100%',
          }}
        >
          <Worker workerUrl={PDFJSWorker}>
            <Viewer
              defaultScale={SpecialZoomLevel.ActualSize}
              localization={ru_RU as unknown as LocalizationMap}
              plugins={[
                pageNavigationPluginInstance,
                defaultLayoutPluginInstance,
                searchPluginInstance,
              ]}
              fileUrl={props.pdf}
            />
          </Worker>
        </div>
      )}
    </Modal>
  );
};
export default FileModalPreview;
