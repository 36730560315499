import { notification as antdNotification } from 'antd';
import { isString } from 'lodash';

import NotificationSuccessIcon from 'components/icons/NotificationSuccessIcon';
import NotificationFailIcon from 'components/icons/NotificationFailIcon';
import NotificationWarnIcon from 'components/icons/NotificationWarnIcon';
import NotificationInfoIcon from 'components/icons/NotificationInfoIcon';

import styles from './notification.module.css';

import { MessageType } from 'enums/message';

type ServerNotificationData =
  | string
  | {
      title?: string;
      body?: string;
      duration?: number;
    };

interface NotificationOptions {
  screenWidth?: number;
  width?: number;
  text?: string;
  description?: string;
  duration?: number;
  data?: ServerNotificationData;
}

interface NotificationOptionsGeneral extends NotificationOptions {
  type: MessageType;
}

type ShowNotificationGeneral = (options: NotificationOptionsGeneral) => void;
type ShowNotificationTyped = (type: MessageType, options?: string | NotificationOptions) => void;
type ShowNotification = (options?: string | NotificationOptions) => void;

const iconMap: {
  [key in MessageType]?: React.FC<any>;
} = {
  [MessageType.SUCCESS]: NotificationSuccessIcon,
  [MessageType.INFO]: NotificationInfoIcon,
  [MessageType.WARNING]: NotificationWarnIcon,
  [MessageType.ERROR]: NotificationFailIcon,
};

export const handleTop = (width: number = window.screen.width) => {
  if (width >= 2560) return 150;
  if (width <= 2499 && width >= 1950) return 110;
  if (width <= 1949 && width >= 1500) return 80;
  return 70;
};

export const show: ShowNotificationGeneral = ({
  type = MessageType.INFO,
  screenWidth,
  width,
  text,
  description,
  duration,
  data,
}) => {
  if (data !== null && data !== undefined) {
    if (data instanceof Object) {
      text = text || data.title;
      description = description || data.body;
      duration = duration || data.duration;
    } else {
      text = text || String(data);
    }
  }

  duration = duration || 7;

  const Icon = iconMap[type];

  const notificationParams = {
    message: (
      <div className={styles.wrapperNotification}>
        {Icon ? <Icon className={styles.iconNotification} /> : null}
        <div className={styles.text}>{text}</div>
      </div>
    ),
    className: styles.notification,
    top: handleTop(screenWidth || width),
    description,
    duration,
  };

  switch (type) {
    case MessageType.SUCCESS: {
      antdNotification.success(notificationParams);
      return;
    }
    case MessageType.INFO: {
      antdNotification.info(notificationParams);
      return;
    }
    case MessageType.WARNING: {
      antdNotification.warning(notificationParams);
      return;
    }
    case MessageType.ERROR: {
      antdNotification.error(notificationParams);
      return;
    }
  }
};

const showTyped: ShowNotificationTyped = (type, options) => {
  const opts: NotificationOptionsGeneral = { type };

  if (isString(options)) opts.text = options;
  else Object.assign(opts, options);

  return show(opts);
};

export const success: ShowNotification = (options) => showTyped(MessageType.SUCCESS, options);
export const info: ShowNotification = (options) => showTyped(MessageType.INFO, options);
export const warning: ShowNotification = (options) => showTyped(MessageType.WARNING, options);
export const error: ShowNotification = (options) => showTyped(MessageType.ERROR, options);
export const warn = warning;

const notification = {
  show,
  success,
  info,
  warning,
  warn,
  error,
};

export default notification;
