import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 10H15V12H9V5H11V10Z" fill="#E7B728"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
