import { DatePicker } from 'antd';
import CalendarIcon from 'components/icons/CalendarIcon';
import DisabledIcon from 'components/icons/DisabledIcon';
import { ControlType } from 'interfaces/BaseComponentInterface';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import React, { useEffect, useState } from 'react';
import FormatUtils from 'utils/FormatUtils';
import styles from './InputDate.module.css';

import moment from 'moment';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import HintIcon from '../SelectControl/HintIcon';

export interface InputDateProps extends BaseComponentProps {
  editor: any;
  value?: any;
}

const InputDate: React.FC<InputDateProps> = (props) => {
  const { ownProps, ...inputProps } = props;
  if (typeof inputProps.value === 'string') inputProps.value = moment(inputProps.value);
  const [readOnly, setReadOnly] = useState<boolean>(!!ownProps.component?.readonly);
  const isDateTime = ownProps.controlType === ControlType.DATETIME;
  const suf = readOnly ? (
    <DisabledIcon className={styles.icon} />
  ) : (
    <CalendarIcon className={styles.icon} />
  );

  const disabledBeforeConstraint = ownProps.component?.params?.beforeConstraint;
  // блокировка даты после текущего значения
  const disabledAfterConstraint = ownProps.component?.params?.afterConstraint;
  const disabledTime = disabledBeforeConstraint || disabledAfterConstraint;

  const disabledDate = (current: any) => {
    if (disabledBeforeConstraint) {
      return current && current <= moment().startOf('day');
    } else if (disabledAfterConstraint) {
      return current && current > moment();
    }
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = (current: any) => {
    if (current > moment()) {
      return;
    } else {
      if (disabledBeforeConstraint) {
        return {
          disabledMinutes: () => range(0, 60).splice(0, Number(moment().format('LT').slice(3, 5))),
          disabledHours: () => range(0, 24).splice(0, Number(moment().format('LT').slice(0, 2))),
        };
      } else if (disabledAfterConstraint) {
        return {
          disabledMinutes: () => range(0, 60).splice(Number(moment().format('LT').slice(3, 5)), 24),
          disabledHours: () => range(0, 24).splice(Number(moment().format('LT').slice(0, 2)), 24),
        };
      }
    }
  };

  useEffect(() => {
    if (
      ownProps.component?.propName === props.editor.disabledDatePicker.field &&
      ownProps.component
    ) {
      setReadOnly(props.editor.disabledDatePicker.value);
      ownProps.component.required = !props.editor.disabledDatePicker.value;
    }
  }, [props.editor.disabledDatePicker]);

  useEffect(() => {
    if (props.ownProps.component && 'readonly' in props.ownProps.component) setReadOnly(props.ownProps.component.readonly!);
  }, [props.ownProps.component?.readonly])

  return (
    <div className={styles.container}>
      <DatePicker
        use12Hours={false}
        showTime={isDateTime && true}
        style={{ width: '100%' }}
        {...(inputProps as any)}
        disabled={readOnly}
        placeholder={''}
        inputReadOnly={readOnly}
        disabledDate={disabledTime && disabledDate}
        disabledTime={disabledTime && disabledDateTime}
        format={isDateTime ? FormatUtils.dateTimeFormat : FormatUtils.dateFormat}
        suffixIcon={suf}
        className={styles.input}
      />
      {ownProps.component?.hint && <div className={styles.info}><HintIcon hint={ownProps.component?.hint} /></div>}
    </div>
  );
};

export default connect((state: AppState, props: any) => {
  return {
    editor: state.editor,
  };
})(React.memo(InputDate));
