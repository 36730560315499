import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9499 6.65363C20.1587 7.433 19.6962 8.23408 18.9169 8.4429L3.65819 12.5314C3.22649 12.6471 2.77147 12.4542 2.55455 12.0634L0 7.46173L1.41117 7.08364L3.8147 9.46459L8.77734 8.13484L4.38426 1.24537L6.26581 0.741211L13.0362 6.99364L18.1606 5.62058C18.94 5.41174 19.741 5.87426 19.9499 6.65363ZM2.46832 15.3104H18.0517V17.2583H2.46832V15.3104Z" fill="#226CC0"/>
  </svg>

);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
