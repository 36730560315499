import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg
    width='1.6em'
    height='1em'
    viewBox='0 0 50 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M50 12.8205H10.5556L20.5556 3.58974L16.6667 0L0 15.3846L16.6667 30.7692L20.5556 27.1795L10.5556 17.9487H50V12.8205Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
