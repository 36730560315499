import { useRef, useEffect } from 'react';
import axios from 'axios';

export const useCancelToken = () => {
  const cancelTokenSource = useRef(axios.CancelToken.source());

  useEffect(
    () => () => {
      try {
        cancelTokenSource.current.cancel('Operation canceled by the user.');
      } catch {
        /* no-op */
      }
    },
    []
  );

  return {
    cancelToken: cancelTokenSource.current.token,
    cancelTokenSource: cancelTokenSource.current,
  };
};

export default useCancelToken;
