import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='-50 -100 300 700'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='100' cy='250' r='330' fill='white' stroke='var(--color-additional-elements)' stroke-width='3%' />
    <path
      d='M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z'
      fill='#6c91f0'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
