import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 84 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.8437 0C5.68701 0 3.91502 0.576941 2.68842 1.88428C1.46267 3.19161 0.898438 5.11205 0.898438 7.40436V47.5958C0.898438 49.8835 1.45586 51.7768 2.68842 53.0826C3.92107 54.3885 5.72828 55 7.9049 55H76.9871C79.1092 55 80.8965 54.4031 82.1113 53.0826C83.326 51.7627 83.8711 49.8276 83.8711 47.497V7.30558C83.8711 5.0569 83.3192 3.17784 82.1113 1.88522C80.9035 0.592599 79.1514 0.000945269 77.0492 0.000945269L7.8437 0ZM7.8437 2.11515H77.0494C78.5948 2.11515 79.6676 2.45083 80.4138 3.07351C68.4071 13.1308 56.9331 24.055 44.854 33.9442C43.1577 34.834 41.6115 34.834 39.9152 33.9442C28.0448 23.7402 16.1946 13.3914 4.35546 3.10562C5.11475 2.4608 6.21846 2.11405 7.84317 2.11405L7.8437 2.11515ZM81.5561 4.79256C81.7834 5.46467 81.8957 6.29539 81.8957 7.30471V47.4961C81.8957 48.9097 81.6511 49.978 81.2474 50.7681L55.5953 27.3351L81.5561 4.79256ZM3.24351 4.82502L29.1725 27.3345L3.49045 50.7675C3.08737 49.9951 2.8731 48.9703 2.8731 47.5943V7.4029C2.8731 6.36191 3.00745 5.51568 3.24379 4.82446L3.24351 4.82502ZM30.7476 28.7227C33.494 31.0754 36.2356 33.5211 38.9897 35.8289C41.2007 37.0122 43.5696 37.0122 45.7806 35.8289C48.5345 33.5122 51.2844 31.0924 54.0227 28.7227L79.8282 52.2558C79.1233 52.6512 78.2042 52.8836 76.988 52.8836H7.90578C6.64213 52.8836 5.6706 52.6542 4.9423 52.2558L30.7476 28.7227Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
