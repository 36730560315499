import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg
    width='1.4em'
    height='1.4em'
    viewBox='0 0 58 43'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M49.691 16.4682H46.9303V10.9789H42.6081L37.0869 7.31928H31.2869V1.82982H38.6485V0H16.5636V1.82982H23.9252V7.31909H18.0635L11.6222 12.8084H6.44136V18.2976H1.84044V10.064H0V32.9364H1.84044V25.6173H6.44155V32.9364H11.718L18.1594 36.596H28.5265V34.7662H18.6489L12.8829 31.4908V14.1444L18.7445 9.1491H36.5292L41.409 12.3832V15.5533H43.2494V12.8085H45.0899V34.766H43.2494V36.5958H46.9303V32.0213H49.691V33.8511H57.0526V13.7235H49.691V16.4682V16.4682ZM6.44155 23.7873H1.84044V20.1276H6.44155V23.7873V23.7873ZM11.0425 31.1064H8.2818V14.6384H11.0425V31.1064ZM29.4465 7.31909H25.7656V1.82982H29.4465V7.31909ZM49.691 30.1915H46.9303V18.2978H49.691V30.1915ZM51.5314 15.5533H55.2123V32.0213H51.5314V15.5533Z'
      fill='#1248C0'
    />
    <path
      d='M37.1892 20.1277H30.3144L34.9155 13.7235H26.1166L20.5951 24.7022H26.385L23.9051 33.3351L37.1892 20.1277ZM23.5737 22.8724L27.2546 15.5533H31.3384L26.7373 21.9575H32.7452L27.6253 27.0479L28.8261 22.8724H23.5737Z'
      fill='#1248C0'
    />
    <path d='M41.4092 34.7664H36.8081V36.5962H41.4092V34.7664Z' fill='#1248C0' />
    <path
      d='M34.9679 34.766H36.8082V30.1914H34.9677V34.766H30.3668V36.5958H34.9677V41.1703H36.8082V36.5958H34.9679V34.766Z'
      fill='#1248C0'
    />
    <path
      d='M55.2121 37.5107H53.3716V39.3404H51.5314V41.1702H53.3716V43H55.2121V41.1702H53.3718V39.3406H55.2121V41.1702H57.0525V39.3404H55.2121V37.5107Z'
      fill='#1248C0'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
