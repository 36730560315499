import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.625 6.5625C5.10724 6.5625 4.6875 6.98224 4.6875 7.5C4.6875 8.01777 5.10724 8.4375 5.625 8.4375H13.125C13.6428 8.4375 14.0625 8.01777 14.0625 7.5C14.0625 6.98224 13.6428 6.5625 13.125 6.5625H5.625Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M4.6875 11.25C4.6875 10.7322 5.10724 10.3125 5.625 10.3125H13.125C13.6428 10.3125 14.0625 10.7322 14.0625 11.25C14.0625 11.7678 13.6428 12.1875 13.125 12.1875H5.625C5.10724 12.1875 4.6875 11.7678 4.6875 11.25Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M5.625 14.0625C5.10724 14.0625 4.6875 14.4822 4.6875 15C4.6875 15.5178 5.10724 15.9375 5.625 15.9375H16.875C17.3928 15.9375 17.8125 15.5178 17.8125 15C17.8125 14.4822 17.3928 14.0625 16.875 14.0625H5.625Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M4.6875 18.75C4.6875 18.2322 5.10724 17.8125 5.625 17.8125H16.875C17.3928 17.8125 17.8125 18.2322 17.8125 18.75C17.8125 19.2678 17.3928 19.6875 16.875 19.6875H5.625C5.10724 19.6875 4.6875 19.2678 4.6875 18.75Z'
      fill='var(--color-additional-elements)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.8125 0C1.2592 0 0 1.2592 0 2.8125V23.4375C0 24.9908 1.25919 26.25 2.8125 26.25H3.75V27.1875C3.75 28.7408 5.00919 30 6.5625 30H23.4375C24.9908 30 26.25 28.7408 26.25 27.1875V6.5625C26.25 5.0092 24.9908 3.75 23.4375 3.75H22.5V2.8125C22.5 1.2592 21.2408 0 19.6875 0H2.8125ZM20.625 2.8125C20.625 2.29473 20.2053 1.875 19.6875 1.875H2.8125C2.29474 1.875 1.875 2.29474 1.875 2.8125V23.4375C1.875 23.9553 2.29474 24.375 2.8125 24.375H19.6875C20.2053 24.375 20.625 23.9553 20.625 23.4375V2.8125ZM22.5 5.625H23.4375C23.9553 5.625 24.375 6.04474 24.375 6.5625V27.1875C24.375 27.7053 23.9553 28.125 23.4375 28.125H6.5625C6.04474 28.125 5.625 27.7053 5.625 27.1875V26.25H19.6875C21.2408 26.25 22.5 24.9908 22.5 23.4375V5.625Z'
      fill='var(--color-additional-elements)'
    />
    <circle cx='26.5' cy='26.5' r='7.5' fill='var(--color-additional-elements)'/>
    <path
      d='M30.0102 27.3678V28.6981C30.0102 28.7246 30.0065 28.7492 30.0008 28.7719C29.9743 28.9025 29.8589 29.0009 29.7226 29.0009H24.2858C24.14 29.0009 24.0189 28.8893 24.0038 28.7473C24 28.7303 24 28.7152 24 28.6962V27.3659C24 27.2088 24.1287 27.082 24.2839 27.082C24.3614 27.082 24.4334 27.1142 24.4845 27.1653C24.5355 27.2164 24.5677 27.2883 24.5677 27.3659V28.4313H29.4444V27.3659C29.4444 27.2088 29.5731 27.082 29.7283 27.082C29.8059 27.082 29.8778 27.1142 29.9289 27.1653C29.9781 27.2183 30.0102 27.2902 30.0102 27.3678Z'
      fill='white'
    />
    <path
      d='M25.3698 24.4977L26.7645 23.103C26.7664 23.0993 26.7702 23.0974 26.7721 23.0955C26.8232 23.0444 26.8894 23.0122 26.9556 23.0028C26.9613 23.0028 26.967 23.0009 26.9727 23.0009C26.984 22.999 26.9954 22.999 27.0067 22.999L27.0389 23.0009C27.0446 23.0009 27.0503 23.0028 27.0559 23.0028C27.1241 23.0122 27.1884 23.0444 27.2395 23.0955C27.2414 23.0974 27.2452 23.1012 27.2471 23.103L28.6418 24.4977C28.7061 24.5621 28.7383 24.6472 28.7383 24.7324C28.7383 24.8176 28.7061 24.9027 28.6418 24.9671C28.5131 25.0957 28.303 25.0957 28.1725 24.9671L27.3341 24.1287V27.2001C27.3341 27.3818 27.1846 27.5312 27.003 27.5312C26.9121 27.5312 26.8289 27.4934 26.7683 27.4347C26.7077 27.3742 26.6718 27.2928 26.6718 27.2001V24.1287L25.8335 24.9671C25.7048 25.0957 25.4947 25.0957 25.3641 24.9671C25.2411 24.8365 25.2411 24.6264 25.3698 24.4977Z'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
