import React from 'react'
import styles from './Announcement.module.css';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';

interface IAnnouncement {
  date: string,
  title: string,
  info: string,
  link: string
}

function Announcement({date,info,title, link}: IAnnouncement) {
  return (
    <div className={styles.container}>
      <div className={styles.date}>
        {date}
      </div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          {info}
        </div>
        <div>
          <a href={link} target='_blank' rel="noreferrer">
            <ArrowRightIcon />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Announcement