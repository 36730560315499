import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0371 4.04792C14.0371 5.11875 13.1594 6.72498 11.9995 6.72498C10.8083 6.72498 9.96191 5.11875 9.96191 4.04792C9.96191 2.91411 10.871 2.00077 11.9995 2.00077C13.1281 1.96927 14.0371 2.91411 14.0371 4.04792Z" fill="black"/>
    <path d="M16.201 8.52007V10.8822C16.201 11.0711 16.0756 11.1971 15.8875 11.1971H14.5082V9.24445C14.5082 9.05548 14.3828 8.92951 14.1947 8.92951C14.038 8.92951 13.8812 9.05548 13.8812 9.24445V11.1971H10.1508V9.24445C10.1508 9.05548 9.99406 8.92951 9.83732 8.92951C9.64924 8.92951 9.52384 9.05548 9.52384 9.24445V11.1971H8.14453C7.98779 11.1971 7.83105 11.0711 7.83105 10.8822V8.52007C7.83105 7.22879 8.6461 6.15797 9.80598 5.74854C10.2448 6.63039 11.0285 7.35477 12.0317 7.35477C13.0348 7.35477 13.8185 6.63039 14.2574 5.74854C14.6963 5.90601 15.0724 6.12647 15.3859 6.44142C15.8561 6.97683 16.201 7.70121 16.201 8.52007Z" fill="black"/>
    <path d="M14.3205 14.5047C14.2578 14.6307 14.1637 14.6937 14.0383 14.6937H13.1292V18.4415C13.1292 18.599 12.9725 18.7565 12.8158 18.7565H11.217C11.0603 18.7565 10.9035 18.599 10.9035 18.4415V14.6937H9.99444C9.86905 14.6937 9.74365 14.6307 9.71231 14.5047C9.64961 14.3787 9.68096 14.2527 9.775 14.1583L11.8126 11.7961C11.8753 11.7332 11.9694 11.7017 12.0634 11.7017C12.1574 11.7017 12.2515 11.7332 12.3142 11.7961L14.3518 14.1583C14.3518 14.2527 14.3518 14.3787 14.3205 14.5047Z" fill="black"/>
    <path d="M19.837 14.8191C19.837 15.89 18.9592 17.4962 17.7993 17.4962C16.6081 17.4962 15.7617 15.89 15.7617 14.8191C15.7617 13.6853 16.6708 12.772 17.7993 12.772C18.9279 12.772 19.837 13.6853 19.837 14.8191Z" fill="black"/>
    <path d="M21.9999 19.3233V21.6854C21.9999 21.8744 21.8745 22.0004 21.6864 22.0004H20.3071V20.0477C20.3071 19.8587 20.1817 19.7327 19.9936 19.7327C19.8369 19.7327 19.6801 19.8587 19.6801 20.0477V22.0004H15.9184V20.0477C15.9184 19.8587 15.7616 19.7327 15.6049 19.7327C15.4168 19.7327 15.2914 19.8587 15.2914 20.0477V22.0004H13.9121C13.7554 22.0004 13.5986 21.8744 13.5986 21.6854V19.3233C13.5986 18.032 14.4137 16.9612 15.5736 16.5518C16.0124 17.4336 16.7961 18.158 17.7993 18.158C18.8024 18.158 19.5861 17.4336 20.025 16.5518C20.4638 16.7092 20.84 16.9297 21.1535 17.2446C21.6551 17.7801 21.9999 18.5044 21.9999 19.3233Z" fill="black"/>
    <path d="M8.26967 14.8191C8.26967 15.89 7.39193 17.4962 6.23205 17.4962C5.00948 17.5277 4.16309 15.9214 4.16309 14.8191C4.16309 13.6853 5.07218 12.772 6.2007 12.772C7.32923 12.772 8.26967 13.6853 8.26967 14.8191Z" fill="black"/>
    <path d="M10.4013 19.3233V21.6854C10.4013 21.8744 10.2759 22.0004 10.0878 22.0004H8.70846V20.0477C8.70846 19.8587 8.58307 19.7327 8.39498 19.7327C8.23824 19.7327 8.0815 19.8587 8.0815 20.0477V22.0004H4.3511V20.0477C4.3511 19.8587 4.19436 19.7327 4.03762 19.7327C3.84953 19.7327 3.72414 19.8587 3.72414 20.0477V22.0004H2.31348C2.15674 22.0004 2 21.8744 2 21.6854V19.3233C2 18.032 2.81505 16.9612 3.97492 16.5518C4.41379 17.4336 5.19749 18.158 6.20063 18.158C7.20376 18.158 7.98746 17.4336 8.42633 16.5518C8.8652 16.7092 9.24138 16.9297 9.55486 17.2446C10.0878 17.7801 10.4013 18.5044 10.4013 19.3233Z" fill="black"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
