import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 25 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.948 14.233C23.5502 13.8352 23.0675 13.6365 22.499 13.6365H21.8177V9.54531C21.8177 6.93173 20.8802 4.68766 19.0054 2.8126C17.1303 0.937532 14.8862 0 12.2724 0C9.65855 0 7.41422 0.937532 5.53942 2.8126C3.66435 4.68766 2.72708 6.93173 2.72708 9.54531V13.6365H2.04531C1.4773 13.6365 0.994282 13.8352 0.596517 14.233C0.198752 14.6305 0 15.1135 0 15.682V27.9547C0 28.5227 0.198752 29.0057 0.596517 29.4035C0.994282 29.801 1.4773 30 2.04531 30H22.4995C23.068 30 23.5508 29.8012 23.9485 29.4035C24.346 29.0057 24.5451 28.5227 24.5451 27.9547V15.6818C24.5453 15.1138 24.346 14.6307 23.948 14.233ZM17.7268 13.6365H6.81797V9.54531C6.81797 8.03977 7.35068 6.75416 8.41609 5.68901C9.4815 4.62359 10.7669 4.09115 12.2727 4.09115C13.7785 4.09115 15.0638 4.62359 16.129 5.68901C17.1941 6.75416 17.7268 8.03977 17.7268 9.54531V13.6365Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
