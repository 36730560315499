import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import axios, { AxiosResponse } from 'axios';
import styles from '../questions/Question.module.css';
import { Form, Pagination, Select } from 'antd';
import { CardElem } from '../../utils/types';
import CardItem from '../../components/card/CardItem';
import stylesMain from '../main/MainPage.module.css';
import stylesSelect from '../../components/controls/SelectControl/SelectControl.module.css';
import FormItem from 'antd/lib/form/FormItem';
import notification from 'components/messages/notification';
import { useWindowWidth } from '@react-hook/window-size';
import { useForm } from 'antd/lib/form/Form';
import DropdownIcon from '../../components/icons/DropdownIcon';
import { getPopupContainer } from '../../components/controls/SelectControl/SelectControl';
import { useDispatch } from 'react-redux';
import CacheStorage from 'utils/CacheStorage';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';

const Title = loadable(() => import('components/layout/content/title/Title'));

type ValueCategory = {
  category: string | null;
};

const News: React.FC = () => {
  const [cards, setCards] = useState<CardElem[]>([]);
  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [choiceNewsCategory, setChoiceNewsCategory] = useState([]);
  const [value, setValue] = useState<string | null>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const width = useWindowWidth();
  const [form] = useForm();
  const ref: any = useRef(null);
  const limit = 12;

  const getCards = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('limit', `${limit}`);
    bodyFormData.append('offset', `${((page === 0 ? 2 : page) - 1) * limit}`);
    // @ts-ignore
    bodyFormData.append('category', value);
    axios.post('/Landing/allNews', bodyFormData).then((res: AxiosResponseExt) => {
      if (res.error) {
        notification.error({
          text: res.error,
          width,
        });
      } else {
        setCards(res.data.data);
        setTotalCount(res.data.totalCount);
      }
    });
  };

  useEffect(() => {
    getCards();
  }, [page, value]);
  const container: HTMLDivElement | null = document.querySelector('#container');

  const handleChangePagination = (page: number) => {
    setPage(page);
    if (container !== null) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getChoice = (choiceNames: any, res?: any) => {
    axios.get(`/SPChoice/list?choiceNames=${choiceNames}`).then((response: AxiosResponseExt) => {
      if (!response.data) {
        notification.error({
          text: response.error,
          screenWidth: width,
        });
      }
      if (Object.keys(response.data).length > 0) {
        CacheStorage.set('NewsCategory', response.data.NewsCategory);
        setChoiceNewsCategory(Object.values(response.data.NewsCategory.choiceItems));
      } else {
        setChoiceNewsCategory(Object.values(res.choiceItems));
      }
    });
  };

  useEffect(() => {
    CacheStorage.get('NewsCategory').then((res) => {
      if (!!res) {
        getChoice(`NewsCategory=${res.Version}`, res);
      } else {
        getChoice(['NewsCategory=null']);
      }
    });
  }, []);

  const onFormChange = (value: ValueCategory) => {
    if (value.category === '__EMPTY__') {
      form.setFieldsValue({ category: '' });
      setValue(null);
    } else {
      setValue(value.category);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ category: '' });
  }, []);
  useEffect(() => {
    setPage(totalCount === 0 ? 0 : 1);
  }, [totalCount]);

  return (
    <>
      <div className={styles.header}>
        <Title title={'Новости и мероприятия'} goBack={false} />
        <Pagination
          className={styles.pagination}
          simple={true}
          current={page}
          defaultCurrent={1}
          total={totalCount}
          defaultPageSize={limit}
          onChange={(page) => handleChangePagination(page)}
        />
      </div>
      <Form form={form} onValuesChange={onFormChange}>
        <FormItem colon={false} className={styles.formItem} label={'Категория'} name={'category'}>
          <Select
            getPopupContainer={getPopupContainer}
            dropdownClassName={stylesSelect.dropdown}
            style={{ width: '20%' }}
            className={stylesSelect.input}
            ref={ref}
            onDropdownVisibleChange={(open: boolean) => {
              setOpened(open);
            }}
            showSearch={true}
            showAction={['click', 'focus']}
            suffixIcon={
              <DropdownIcon
                onClick={() => {
                  if (ref && ref.current && ref.current.focus && ref.current.blur) {
                    if (!opened) {
                      ref.current.focus();
                    } else {
                      ref.current.blur();
                    }
                  }
                }}
                className={stylesSelect.icon}
              />
            }
          >
            <Select.Option key={`__EMPTY__`} value={`__EMPTY__`}>
              Выбрать значение
            </Select.Option>
            {choiceNewsCategory.map((value: { index: string; label: string }) => {
              return (
                <Select.Option
                  key={JSON.stringify(value)}
                  value={value.index}
                >{`${value.label}`}</Select.Option>
              );
            })}
          </Select>
        </FormItem>
      </Form>
      <div className={styles.wrapper} id={'container'}>
        <div className={stylesMain.cardsContainer}>
          {cards.map((item, i) => {
            return <CardItem key={i} {...item} />;
          })}
        </div>
      </div>
    </>
  );
};
export default News;
