import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#D1FFE7"/>
    <rect x="8" y="21.7448" width="5.33333" height="13.7808" rx="2.66667" transform="rotate(-45 8 21.7448)" fill="#72D9A4"/>
    <rect x="28.3984" y="8" width="5.33333" height="24.1345" rx="2.66667" transform="rotate(35 28.3984 8)" fill="#72D9A4"/>
  </svg>

);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
