import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width=".9em" height=".9em" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.88889 26H40V30H8.88889V26ZM2.66667 30C1.19391 30 0 29.1046 0 28C0 26.8954 1.19391 26 2.66667 26C4.13943 26 5.33333 26.8954 5.33333 28C5.33333 29.1046 4.13943 30 2.66667 30ZM8.88889 17.3333H40V21.3333H8.88889V17.3333ZM2.66667 21.3333C1.19391 21.3333 0 20.4379 0 19.3333C0 18.2288 1.19391 17.3333 2.66667 17.3333C4.13943 17.3333 5.33333 18.2288 5.33333 19.3333C5.33333 20.4379 4.13943 21.3333 2.66667 21.3333ZM8.88889 8.66667H40V12.6667H8.88889V8.66667ZM2.66667 12.6667C1.19391 12.6667 0 11.7712 0 10.6667C0 9.5621 1.19391 8.66667 2.66667 8.66667C4.13943 8.66667 5.33333 9.5621 5.33333 10.6667C5.33333 11.7712 4.13943 12.6667 2.66667 12.6667ZM8.88889 0H40V4H8.88889V0ZM2.66667 4C1.19391 4 0 3.10457 0 2C0 0.895431 1.19391 0 2.66667 0C4.13943 0 5.33333 0.895431 5.33333 2C5.33333 3.10457 4.13943 4 2.66667 4Z"
      fill="var(--color-additional-elements)"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
