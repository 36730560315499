import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.4269 7.5C5.83517 7.5 5.35547 7.9797 5.35547 8.57143C5.35547 9.16317 5.83517 9.64286 6.4269 9.64286H14.9983C15.5901 9.64286 16.0698 9.16317 16.0698 8.57143C16.0698 7.9797 15.5901 7.5 14.9983 7.5H6.4269Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M5.35547 12.8566C5.35547 12.2648 5.83517 11.7852 6.4269 11.7852H14.9983C15.5901 11.7852 16.0698 12.2648 16.0698 12.8566C16.0698 13.4483 15.5901 13.928 14.9983 13.928H6.4269C5.83517 13.928 5.35547 13.4483 5.35547 12.8566Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M6.4269 16.0723C5.83517 16.0723 5.35547 16.5519 5.35547 17.1437C5.35547 17.7354 5.83517 18.2151 6.4269 18.2151H19.284C19.8758 18.2151 20.3555 17.7354 20.3555 17.1437C20.3555 16.5519 19.8758 16.0723 19.284 16.0723H6.4269Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M5.35547 21.4289C5.35547 20.8371 5.83517 20.3574 6.4269 20.3574H19.284C19.8758 20.3574 20.3555 20.8371 20.3555 21.4289C20.3555 22.0206 19.8758 22.5003 19.284 22.5003H6.4269C5.83517 22.5003 5.35547 22.0206 5.35547 21.4289Z'
      fill='var(--color-additional-elements)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.21429 0C1.43909 0 0 1.43909 0 3.21429V26.7857C0 28.561 1.43908 30 3.21429 30H4.28571V31.0714C4.28571 32.8467 5.72479 34.2857 7.5 34.2857H26.7857C28.561 34.2857 30 32.8467 30 31.0714V7.5C30 5.7248 28.561 4.28571 26.7857 4.28571H25.7143V3.21429C25.7143 1.43909 24.2752 0 22.5 0H3.21429ZM23.5714 3.21429C23.5714 2.62255 23.0917 2.14286 22.5 2.14286H3.21429C2.62256 2.14286 2.14286 2.62256 2.14286 3.21429V26.7857C2.14286 27.3775 2.62256 27.8571 3.21429 27.8571H22.5C23.0917 27.8571 23.5714 27.3775 23.5714 26.7857V3.21429ZM25.7143 6.42857H26.7857C27.3775 6.42857 27.8571 6.90827 27.8571 7.5V31.0714C27.8571 31.6632 27.3775 32.1429 26.7857 32.1429H7.5C6.90827 32.1429 6.42857 31.6632 6.42857 31.0714V30H22.5C24.2752 30 25.7143 28.561 25.7143 26.7857V6.42857Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.42885 7.5C5.83712 7.5 5.35742 7.9797 5.35742 8.57143C5.35742 9.16317 5.83712 9.64286 6.42885 9.64286H15.0003C15.592 9.64286 16.0717 9.16317 16.0717 8.57143C16.0717 7.9797 15.592 7.5 15.0003 7.5H6.42885Z'
      fill='white'
    />
    <path
      d='M5.35742 12.8576C5.35742 12.2658 5.83712 11.7861 6.42885 11.7861H15.0003C15.592 11.7861 16.0717 12.2658 16.0717 12.8576C16.0717 13.4493 15.592 13.929 15.0003 13.929H6.42885C5.83712 13.929 5.35742 13.4493 5.35742 12.8576Z'
      fill='white'
    />
    <path
      d='M6.42885 16.0713C5.83712 16.0713 5.35742 16.551 5.35742 17.1427C5.35742 17.7345 5.83712 18.2141 6.42885 18.2141H19.286C19.8777 18.2141 20.3574 17.7345 20.3574 17.1427C20.3574 16.551 19.8777 16.0713 19.286 16.0713H6.42885Z'
      fill='white'
    />
    <path
      d='M5.35742 21.4289C5.35742 20.8371 5.83712 20.3574 6.42885 20.3574H19.286C19.8777 20.3574 20.3574 20.8371 20.3574 21.4289C20.3574 22.0206 19.8777 22.5003 19.286 22.5003H6.42885C5.83712 22.5003 5.35742 22.0206 5.35742 21.4289Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.21429 0C1.43909 0 0 1.43909 0 3.21429V26.7857C0 28.561 1.43908 30 3.21429 30H4.28571V31.0714C4.28571 32.8467 5.72479 34.2857 7.5 34.2857H26.7857C28.561 34.2857 30 32.8467 30 31.0714V7.5C30 5.7248 28.561 4.28571 26.7857 4.28571H25.7143V3.21429C25.7143 1.43909 24.2752 0 22.5 0H3.21429ZM23.5714 3.21429C23.5714 2.62255 23.0917 2.14286 22.5 2.14286H3.21429C2.62256 2.14286 2.14286 2.62256 2.14286 3.21429V26.7857C2.14286 27.3775 2.62256 27.8571 3.21429 27.8571H22.5C23.0917 27.8571 23.5714 27.3775 23.5714 26.7857V3.21429ZM25.7143 6.42857H26.7857C27.3775 6.42857 27.8571 6.90827 27.8571 7.5V31.0714C27.8571 31.6632 27.3775 32.1429 26.7857 32.1429H7.5C6.90827 32.1429 6.42857 31.6632 6.42857 31.0714V30H22.5C24.2752 30 25.7143 28.561 25.7143 26.7857V6.42857Z'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
