import React from 'react';
import styles from '../../index.module.css';
import { TableViewTools } from '../TableViewTools';
import TableViewHeader from '../TableViewHeader';
import TableViewCounter from '../TableViewCounter';
import Title from 'components/layout/content/title/Title';

const TableViewTopPanel = (props: any) => {
  const {
    simpleTable,
    isCardTable,
    titleProps,
    tableViewCounterProps,
    tableViewToolsProps,
    tableViewHeaderProps,
    readonly
  } = props;

  return (
    <div className={styles.topPanel}>
      <div>
        {!simpleTable && !isCardTable && (
          <div className={styles.wrapper}>
            <Title {...titleProps} />
          </div>
        )}
        <TableViewTools {...tableViewToolsProps} readonly={readonly} />
        <TableViewHeader {...tableViewHeaderProps} />
      </div>
      <TableViewCounter {...tableViewCounterProps} readonly={readonly} />
    </div>
  );
};

export default TableViewTopPanel;
