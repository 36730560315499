import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 36 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26.9379 5.33789H2.34097C1.05019 5.33789 0 6.38808 0 7.67886V23.444C0 24.7348 1.05019 25.785 2.34097 25.785H4.84C5.06479 25.785 5.27202 25.9061 5.38266 26.1011L7.1037 29.1199C7.41103 29.6591 7.96423 29.9892 8.58591 30.0015C8.5982 30.0015 8.6105 30.0015 8.62455 30.0015C9.23042 30.0015 9.78186 29.6977 10.1032 29.1814L12.0508 26.0765C12.1667 25.8938 12.3652 25.7832 12.5812 25.7832H26.9396C28.2304 25.7832 29.2806 24.733 29.2806 23.4422V7.67886C29.2788 6.38808 28.2287 5.33789 26.9379 5.33789ZM28.1567 23.444C28.1567 24.1166 27.6105 24.6628 26.9379 24.6628H12.5812C11.9753 24.6628 11.4221 24.9683 11.1007 25.4811L9.15315 28.5861C8.98456 28.8583 8.71938 28.8811 8.6105 28.8793C8.50337 28.8776 8.23819 28.8407 8.08013 28.5632L6.35909 25.5444C6.04825 25 5.46695 24.661 4.84 24.661H2.34097C1.66836 24.661 1.12219 24.1148 1.12219 23.4422V7.67886C1.12219 7.00625 1.66836 6.46008 2.34097 6.46008H26.9379C27.6105 6.46008 28.1567 7.00625 28.1567 7.67886V23.444Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M33.0213 0H8.42435C7.13356 0 6.08337 1.05019 6.08337 2.34097V3.10139C6.08337 3.41224 6.33451 3.66337 6.64535 3.66337C6.95619 3.66337 7.20732 3.41224 7.20732 3.10139V2.34097C7.20732 1.66836 7.75349 1.12219 8.4261 1.12219H33.023C33.6956 1.12219 34.2418 1.66836 34.2418 2.34097V18.1061C34.2418 18.7787 33.6956 19.3249 33.023 19.3249H31.9886C31.6778 19.3249 31.4266 19.576 31.4266 19.8869C31.4266 20.1977 31.6778 20.4488 31.9886 20.4488H33.023C34.3138 20.4488 35.364 19.3986 35.364 18.1079V2.34097C35.3622 1.05019 34.312 0 33.0213 0Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M23.1973 10.2832H6.08346C5.77262 10.2832 5.52148 10.5343 5.52148 10.8452C5.52148 11.156 5.77262 11.4072 6.08346 11.4072H23.1991C23.5099 11.4072 23.7611 11.156 23.7611 10.8452C23.7593 10.5343 23.5082 10.2832 23.1973 10.2832Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M23.1973 15.002H6.08346C5.77262 15.002 5.52148 15.2531 5.52148 15.5639C5.52148 15.8748 5.77262 16.1259 6.08346 16.1259H23.1991C23.5099 16.1259 23.7611 15.8748 23.7611 15.5639C23.7593 15.2531 23.5082 15.002 23.1973 15.002Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M23.1973 19.7148H6.08346C5.77262 19.7148 5.52148 19.966 5.52148 20.2768C5.52148 20.5877 5.77262 20.8388 6.08346 20.8388H23.1991C23.5099 20.8388 23.7611 20.5877 23.7611 20.2768C23.7593 19.966 23.5082 19.7148 23.1973 19.7148Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
