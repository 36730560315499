import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ModalForm from 'components/modals/ModalForm';
import TableView from 'components/TableView';
import { RefreshID } from 'enums/refresh';
import { EntityListProps } from 'interfaces/EntityList';
import { useCrumbs } from 'hooks';

const EntityList: React.FC<EntityListProps & RouteComponentProps> = (props: any) => {
  const { addCrumbWithNewChain } = useCrumbs();
  addCrumbWithNewChain();

  return (
    <>
      <ModalForm />
      <TableView {...props} refreshID={RefreshID.ENTITY_LIST} />
    </>
  );
};

export default EntityList;
