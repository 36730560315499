import { combineReducers } from 'redux';
import meta from './meta';
import menu from './menu';
import filters from './filters';
import user from './user';
import editorDetails from './editor_details';
import editorErrors from './editor_errors';
import editorFiles from './editor_files';
import editorButtons from './editor_buttons';
import editor from './editor';
import choiceLists from './choice_lists';
import inputSearchValue from './inputSearchValue';
import tableDate from './tableDate';
import tableData from './tableData';
import searchConfigurations from './searchConfigurations';
import { AppSettings, settings } from './settings';
import { EditorResponsesInfoState, editorResponses } from './editor_responses';
import filtersConfiguration from './filtersConfiguration';
import typeUser from './typeUser';
import crumbs, { CrumbsStoreType } from './crumbs';
import checkedFilters from './chekedFilters';
import modal, { ModalState } from './modal';
import selectedRows, { SelectedRowsStoreStateType } from './selectedRows';
import refresh, { RefreshState } from './refresh';

export interface AppState {
  meta: any;
  settings: AppSettings;
  menu: any;
  filters: any;
  user: any;
  editorDetails: any;
  editorButtons: any;
  editorErrors: any;
  editorFiles: any;
  editorResponses: EditorResponsesInfoState;
  editor: any;
  choiceLists: any;
  crumbs: CrumbsStoreType;
  inputSearchValue: any;
  tableDate: any;
  tableData: any;
  filtersConfiguration: any;
  searchConfigurations: any;
  typeUser: any;
  checkedFilters: any;
  modal: ModalState;
  selectedRows: SelectedRowsStoreStateType;
  refresh: RefreshState;
}

const allReducers = combineReducers({
  meta,
  settings,
  menu,
  filters,
  user,
  editorDetails,
  editorButtons,
  editorErrors,
  editorFiles,
  editorResponses,
  editor,
  choiceLists,
  crumbs,
  inputSearchValue,
  tableDate,
  tableData,
  filtersConfiguration,
  searchConfigurations,
  typeUser,
  checkedFilters,
  modal,
  selectedRows,
  refresh,
});

export default allReducers;
