import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg
    width='0.8em'
    height='0.8em'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='20.6094'
      width='5'
      height='28.9528'
      rx='2.5'
      transform='rotate(45 20.6094 0)'
      fill='var(--color-additional-elements)'
    />
    <rect
      y='3.66406'
      width='5'
      height='28.9528'
      rx='2.5'
      transform='rotate(-45 0 3.66406)'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
