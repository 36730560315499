import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 18.9429L6.52962 20.0002V14.8901L2 13.5156V18.9429Z" fill="black"/>
    <path d="M6.94727 14.8901V20.0002L11.4769 18.9429V13.5156L6.94727 14.8901Z" fill="black"/>
    <path d="M6.73899 11.9648L2.03516 13.1631L6.73899 14.608L11.4428 13.1631L6.73899 11.9648Z" fill="black"/>
    <path d="M12.5225 18.9429L17.0521 20.0002V14.8901L12.5225 13.5156V18.9429Z" fill="black"/>
    <path d="M17.4697 14.8901V20.0002L21.9993 18.9429V13.5156L17.4697 14.8901Z" fill="black"/>
    <path d="M17.2614 11.9648L12.5576 13.1631L17.2614 14.608L21.9653 13.1631L17.2614 11.9648Z" fill="black"/>
    <path d="M7.26172 11.0133L11.7913 12.0354V6.92523L7.26172 5.55078V11.0133Z" fill="black"/>
    <path d="M12.209 6.92523V12.0354L16.7386 11.0133V5.55078L12.209 6.92523Z" fill="black"/>
    <path d="M12.0007 4L7.29688 5.19824L12.0007 6.64317L16.7045 5.19824L12.0007 4Z" fill="black"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
