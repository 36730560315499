import { FormInstance } from 'antd';
import { FormObjectData } from 'interfaces/Form';
import { ObjectData, ObjectParams } from 'interfaces/Object';
import { generateId } from './MiscUtils';

export const isNewID = (id?: string | null): boolean => !id || id.startsWith('new');

export const isFilledObjectData = (objectData?: ObjectData): objectData is ObjectData =>
  Boolean(objectData && objectData.classType && objectData.params);

export const isNewObjectData = (objectData?: ObjectData): boolean =>
  isFilledObjectData(objectData) && isNewID(objectData.id);

// получает айди с объекта,
// который будет расцениваться как parentId для дочерних элементов
export const getChildrenParentID = (objectData?: ObjectData, form?: FormInstance): string => {
  const objectParams: ObjectParams = objectData?.params || {};
  const formValues: FormObjectData = form?.getFieldsValue(true) || {};

  return (
    objectParams.VersionSeriesId ||
    formValues.VersionSeriesId ||
    objectParams.ID ||
    formValues.ID ||
    ''
  );
};

export const getDocumentId = (objectData?: ObjectData, form?: FormInstance): string => {
  const formValues: FormObjectData = form?.getFieldsValue(true) || {};

  return (
    objectData?.params?.VersionSeriesId ||
    formValues.VersionSeriesId ||
    objectData?.id ||
    formValues.ID ||
    ''
  );
};

// получает parentClassName для дочерних элементов
export const getChildrenParentClassName = (objectData?: ObjectData, form?: FormInstance): string =>
  objectData?.classType || form?.getFieldValue('ClassName') || '';

export const genNewObjectID = (): string => generateId('new');

export const ensureObjectID = (objectData?: ObjectData): ObjectData | undefined => {
  if (isFilledObjectData(objectData) && !objectData.id) {
    objectData.id = genNewObjectID();
  }
  return objectData;
};

const ObjectUtils = {
  isNewID,
  isFilledObjectData,
  isNewObjectData,
  getChildrenParentID,
  getChildrenParentClassName,
  genNewObjectID,
  ensureObjectID,
};

export default ObjectUtils;
