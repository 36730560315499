import { Action, ActionType } from '../actionTypes';

export interface FiltersParams {
  key: string;
  config: any;
}

const initialState = {};

const searchConfigurations = (
  state: any = initialState,
  action: Action<ActionType, FiltersParams>
) => {
  switch (action.type) {
    case ActionType.SET_SEARCH_CONFIGURATIONS:
      const newState = { ...state };
      newState[action.payload?.key || '_undefined_'] = action.payload?.config;
      return newState;
    default:
      return state;
  }
};

export default searchConfigurations;
