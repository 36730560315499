import { DatePicker } from 'antd';
import React from 'react';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import FormatUtils from 'utils/FormatUtils';
import CalendarIcon from 'components/icons/CalendarIcon';
import DisabledIcon from 'components/icons/DisabledIcon';
import styles from './InputDate.module.css';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';

const { RangePicker } = DatePicker;

const InputDataRange: React.FC<BaseComponentProps> = (props) => {
  const { ownProps, ...inputProps } = props;
  const readOnly = !!ownProps.component?.readonly;
  const isDateTime = ownProps.isDateTime === true;
  const suf = readOnly ? (
    <DisabledIcon className={styles.icon} />
  ) : (
    <CalendarIcon className={styles.icon} />
  );
  const disabledTime: any = ownProps.component?.propName === 'periodData';

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <RangePicker
      use12Hours={false}
      showTime={isDateTime}
      style={{ width: '100%' }}
      {...(inputProps as any)}
      disabled={readOnly}
      placeholder={''}
      inputReadOnly={readOnly}
      disabledDate={disabledTime && disabledDate}
      format={isDateTime ? FormatUtils.dateTimeFormat : FormatUtils.dateFormat}
      suffixIcon={suf}
      className={styles.input}
    />
  );
};

export default React.memo(InputDataRange);
