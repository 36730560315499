import React from 'react';

export interface GlobalLoaderContextValue {
  setLoading: (loading: boolean) => void;
}
/* tslint:disable */
const initialState: GlobalLoaderContextValue = { setLoading: (loading) => {} }
const GlobalLoaderContext = React.createContext(initialState)

export default GlobalLoaderContext
