import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 33 40' xmlns='http://www.w3.org/2000/svg'>
    <path d='M31.0607 4.05052H28.9079V1.30724C28.9079 0.58645 28.3221 0 27.6021 0H1.30683C0.586206 0 0 0.58645 0 1.30724V34.6432C0 35.3608 0.586206 35.9447 1.30683 35.9447H3.45927V38.6938C3.45927 39.414 4.04531 40 4.76561 40H31.0606C31.7781 40 32.3617 39.414 32.3617 38.6938V5.35686C32.3617 4.63656 31.7781 4.05052 31.0607 4.05052ZM1.30683 34.9677C1.12503 34.9677 0.977009 34.8221 0.977009 34.6432V1.30724C0.977009 1.12519 1.12495 0.97701 1.30683 0.97701H27.602C27.7802 0.97701 27.9308 1.1282 27.9308 1.30724V4.05052H4.76561C4.04523 4.05052 3.45927 4.63656 3.45927 5.35686V34.9677H1.30683ZM31.3847 38.6938C31.3847 38.8722 31.2363 39.023 31.0607 39.023H4.76561C4.58706 39.023 4.43628 38.8722 4.43628 38.6938V35.4562V5.35686C4.43628 5.1784 4.58714 5.02753 4.76561 5.02753H28.4193H31.0606C31.2362 5.02753 31.3846 5.1784 31.3846 5.35686V38.6938H31.3847Z' />
    <path d='M24.2752 7.92969C21.2416 7.92969 18.7736 10.401 18.7736 13.4386C18.7736 16.4736 21.2416 18.9428 24.2752 18.9428C27.3088 18.9428 29.7768 16.4736 29.7768 13.4386C29.7768 10.401 27.3088 7.92969 24.2752 7.92969ZM24.2752 17.9658C21.7802 17.9658 19.7506 15.9349 19.7506 13.4386C19.7506 10.9397 21.7803 8.9067 24.2752 8.9067C26.7701 8.9067 28.7998 10.9397 28.7998 13.4386C28.7998 15.9349 26.7701 17.9658 24.2752 17.9658Z' />
    <path d='M26.2456 11.2525L23.0232 14.476L22.3818 13.3659C22.2469 13.1323 21.948 13.0524 21.7145 13.1873C21.4809 13.3223 21.4009 13.6211 21.5359 13.8547L22.4957 15.516C22.5716 15.6475 22.7044 15.7361 22.8549 15.7559C22.8763 15.7588 22.8974 15.7601 22.9186 15.7601C23.0473 15.7601 23.1719 15.7092 23.2641 15.6169L26.9365 11.9433C27.1272 11.7525 27.1272 11.4431 26.9364 11.2525C26.7456 11.0616 26.4362 11.0617 26.2456 11.2525Z' />
    <path d='M6.53319 13.7739H14.2078C14.4775 13.7739 14.6963 13.5551 14.6963 13.2854C14.6963 13.0156 14.4775 12.7969 14.2078 12.7969H6.53319C6.26345 12.7969 6.04468 13.0156 6.04468 13.2854C6.04468 13.5551 6.26345 13.7739 6.53319 13.7739Z' />
    <path d='M6.53319 16.9653H15.8028C16.0725 16.9653 16.2913 16.7465 16.2913 16.4768C16.2913 16.2071 16.0725 15.9883 15.8028 15.9883H6.53319C6.26345 15.9883 6.04468 16.2071 6.04468 16.4768C6.04468 16.7465 6.26345 16.9653 6.53319 16.9653Z' />
    <path d='M6.53319 20.1567H17.4739C17.7436 20.1567 17.9624 19.938 17.9624 19.6682C17.9624 19.3984 17.7436 19.1797 17.4739 19.1797H6.53319C6.26345 19.1797 6.04468 19.3985 6.04468 19.6682C6.04468 19.9379 6.26345 20.1567 6.53319 20.1567Z' />
    <path d='M6.53319 23.3481H24.9199C25.1897 23.3481 25.4084 23.1293 25.4084 22.8596C25.4084 22.5899 25.1897 22.3711 24.9199 22.3711H6.53319C6.26345 22.3711 6.04468 22.5899 6.04468 22.8596C6.04468 23.1293 6.26345 23.3481 6.53319 23.3481Z' />
    <path d='M6.53319 26.5434H19.221C19.4907 26.5434 19.7095 26.3246 19.7095 26.0549C19.7095 25.7852 19.4907 25.5664 19.221 25.5664H6.53319C6.26345 25.5664 6.04468 25.7852 6.04468 26.0549C6.04468 26.3246 6.26345 26.5434 6.53319 26.5434Z' />
    <path d='M27.0484 28.7578H6.53319C6.26345 28.7578 6.04468 28.9766 6.04468 29.2463C6.04468 29.5161 6.26345 29.7348 6.53319 29.7348H27.0484C27.3182 29.7348 27.5369 29.5161 27.5369 29.2463C27.5369 28.9766 27.3183 28.7578 27.0484 28.7578Z' />
    <path d='M6.53319 32.9262H15.0461C15.3159 32.9262 15.5346 32.7075 15.5346 32.4377C15.5346 32.168 15.3159 31.9492 15.0461 31.9492H6.53319C6.26345 31.9492 6.04468 32.168 6.04468 32.4377C6.04468 32.7075 6.26345 32.9262 6.53319 32.9262Z' />
    <path d='M27.0484 35.1406H6.53319C6.26345 35.1406 6.04468 35.3594 6.04468 35.6291C6.04468 35.8989 6.26345 36.1176 6.53319 36.1176H27.0484C27.3182 36.1176 27.5369 35.8989 27.5369 35.6291C27.5369 35.3594 27.3183 35.1406 27.0484 35.1406Z' />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
