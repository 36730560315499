import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M37 26.8919V35.0818C37 35.2449 36.9767 35.3963 36.9417 35.5361C36.7787 36.34 36.068 36.9458 35.2292 36.9458H1.75913C0.862091 36.9458 0.116499 36.2584 0.0232998 35.3847C8.85343e-08 35.2799 0 35.1867 0 35.0702V26.8803C0 25.9134 0.792192 25.1328 1.74748 25.1328C2.22513 25.1328 2.66782 25.3309 2.98237 25.6454C3.29691 25.96 3.49496 26.4026 3.49496 26.8803V33.4392H33.5167V26.8803C33.5167 25.9134 34.3089 25.1328 35.2642 25.1328C35.7418 25.1328 36.1845 25.3309 36.4991 25.6454C36.802 25.9716 37 26.4143 37 26.8919Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M28.5766 18.6747L19.9907 27.2607C19.979 27.284 19.9557 27.2957 19.9441 27.3073C19.6295 27.6219 19.2218 27.8199 18.814 27.8781C18.7791 27.8781 18.7441 27.8898 18.7092 27.8898C18.6393 27.9014 18.5694 27.9014 18.4995 27.9014L18.3014 27.8898C18.2665 27.8898 18.2315 27.8781 18.1966 27.8781C17.7772 27.8199 17.3811 27.6219 17.0665 27.3073C17.0549 27.2957 17.0316 27.2724 17.0199 27.2607L8.43399 18.6747C8.03789 18.2787 7.83984 17.7544 7.83984 17.2302C7.83984 16.7059 8.03789 16.1817 8.43399 15.7856C9.22618 14.9934 10.5193 14.9934 11.3232 15.7856L16.4841 20.9465V2.03873C16.4841 0.92034 17.4044 0 18.5228 0C19.082 0 19.5946 0.232997 19.9674 0.594143C20.3402 0.966939 20.5615 1.46788 20.5615 2.03873V20.9465L25.7224 15.7856C26.5146 14.9934 27.8077 14.9934 28.6116 15.7856C29.3688 16.5894 29.3688 17.8826 28.5766 18.6747Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M37 26.8919V35.0818C37 35.2449 36.9767 35.3963 36.9417 35.5361C36.7787 36.34 36.068 36.9458 35.2292 36.9458H1.75913C0.862091 36.9458 0.116499 36.2584 0.0232998 35.3847C8.85343e-08 35.2799 0 35.1867 0 35.0702V26.8803C0 25.9134 0.792192 25.1328 1.74748 25.1328C2.22513 25.1328 2.66782 25.3309 2.98237 25.6454C3.29691 25.96 3.49496 26.4026 3.49496 26.8803V33.4392H33.5167V26.8803C33.5167 25.9134 34.3089 25.1328 35.2642 25.1328C35.7418 25.1328 36.1845 25.3309 36.4991 25.6454C36.802 25.9716 37 26.4143 37 26.8919Z'
      fill='white'
    />
    <path
      d='M28.5766 18.6747L19.9907 27.2607C19.979 27.284 19.9557 27.2957 19.9441 27.3073C19.6295 27.6219 19.2218 27.8199 18.814 27.8781C18.7791 27.8781 18.7441 27.8898 18.7092 27.8898C18.6393 27.9014 18.5694 27.9014 18.4995 27.9014L18.3014 27.8898C18.2665 27.8898 18.2315 27.8781 18.1966 27.8781C17.7772 27.8199 17.3811 27.6219 17.0665 27.3073C17.0549 27.2957 17.0316 27.2724 17.0199 27.2607L8.43399 18.6747C8.03789 18.2787 7.83984 17.7544 7.83984 17.2302C7.83984 16.7059 8.03789 16.1817 8.43399 15.7856C9.22618 14.9934 10.5193 14.9934 11.3232 15.7856L16.4841 20.9465V2.03873C16.4841 0.92034 17.4044 0 18.5228 0C19.082 0 19.5946 0.232997 19.9674 0.594143C20.3402 0.966939 20.5615 1.46788 20.5615 2.03873V20.9465L25.7224 15.7856C26.5146 14.9934 27.8077 14.9934 28.6116 15.7856C29.3688 16.5894 29.3688 17.8826 28.5766 18.6747Z'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
