import { Button, Collapse, Row } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { AppState } from 'store/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styles from './InboxGroupList.module.css';
import EntityList from '../entityList/EntityList';
import EngineeringTechnicalRequestIcon from '../../components/icons/EngineeringTechnicalRequestIcon';
import { useHistory } from 'react-router';
import TwoColumnGrid from '../../components/layout/content/TwoColumnGrid';
import SiderWithTabs from '../../components/navigation/Sider/SiderWithTabs';
import entityEditorDetalisStyle from '../entityEditor/Tabs/EntityEditorDetails.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import Mto from '../MTO/Mto';
import stylesEntityList from '../entityList/EntityList.module.css';
import DeleteIcon from '../../components/icons/DeleteIcon';
import FilterSearchIcon from '../../components/icons/FilterSearchIcon';
import EDRequestIcon from '../../components/icons/EDRequestIcon';
import LinkedZkPIcon from '../../components/icons/case/LinkedZkPIcon';
import AviaExploit from '../AviaExploit/AviaExploit';
import composeLink from 'utils/composeLink';
import Title from 'components/layout/content/title/Title';

export interface InboxGroupProps {
  menu: any;
}

export interface InboxGroupParams {
  groupID?: string;
}

const InboxGroupList: React.FC<InboxGroupProps & RouteComponentProps> = (props) => {
  const { menu } = props;
  const params: InboxGroupParams = props.match.params;
  const groupID = params.groupID;
  const { Panel } = Collapse;
  const history = useHistory();
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);
  const curInboxGroup = menu.inboxGroups.find((el: any) => {
    return el.inboxGroup.label === groupID;
  });

  if (!curInboxGroup || !curInboxGroup.inboxGroup || !curInboxGroup.inboxGroup.inboxes) {
    return null;
  }
  const keys: any[] =
    curInboxGroup.inboxGroup &&
    curInboxGroup.inboxGroup.inboxes.map((key: any, i: number) => {
      return `panel_${i}`;
    });

  if (menu.inboxGroups.length === 0) {
    return <span>Загрузка...</span>;
  }

  const getIcon = (icon: string) => {
    if (icon === 'etr') {
      return <EngineeringTechnicalRequestIcon className={styles.icon} />;
    } else if (icon === 'ed') {
      return <EDRequestIcon className={styles.icon} />;
    } else if (icon === 'LinkedZkPIcon') {
      return <LinkedZkPIcon className={stylesEntityList.iconButton}/>
    } else {
      return <></>;
    }
  };

  const resetFilters = () => {
    setIsReset(true);
    setIsSearch(false);
  };

  const allSearch = () => {
    setIsSearch(true);
  };

  return (
    <TwoColumnGrid
      buttonState={true}
      inboxGroup={groupID}
      className='main-content__wrap'
      sider={<SiderWithTabs tabs={undefined} filters={undefined} />}
    >
      <Form className={['app-content', 'main-content__wrap'].join(' ')}>
        <div className={styles.wrapperTitle}>
          <Title title={groupID}/>
          <div className={styles.wrapper}>
            {curInboxGroup &&
              curInboxGroup.tools &&
              curInboxGroup.tools.map((tool: any, i: number) => {
                return (
                  <Button
                    key={i}
                    htmlType='button'
                    className={stylesEntityList.button}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        composeLink({
                          inbox: tool.inboxName,
                          className: menu.inboxes[tool.inboxName].caseName,
                          id: 'new',
                        })
                      );
                    }}
                  >
                    {getIcon(tool.icon)}
                    {tool.label}
                  </Button>
                );
              })}
            {!['mto', 'aviaExploit'].includes(curInboxGroup.mainPageInboxes[0]) ? (
              <>
                <Button
                  style={{ marginBottom: 0 }}
                  onClick={resetFilters}
                  className={stylesEntityList.button}
                  icon={
                    <DeleteIcon
                      className={stylesEntityList.iconButton}
                    />
                  }
                >
                  Сбросить
                </Button>
                <Button
                  style={{ marginBottom: 0 }}
                  onClick={allSearch}
                  className={stylesEntityList.button}
                  icon={
                    <FilterSearchIcon
                      className={stylesEntityList.iconButton}
                    />
                  }
                >
                  Применить
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Row className={[styles.list, 'main-content'].join(' ')}>
          <Collapse
            className={entityEditorDetalisStyle.collapse}
            ghost={true}
            defaultActiveKey={keys}
            expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
          >
            {curInboxGroup &&
              curInboxGroup.mainPageInboxes &&
              curInboxGroup.mainPageInboxes.map((inbox: string, i: number) => {
                if (menu.inboxes[inbox]) {
                  return (
                    <Panel key={`panel_${i}`} header={menu.inboxes[inbox].label}>
                      <EntityList
                        {...props}
                        setIsSearch={setIsSearch}
                        setIsReset={setIsReset}
                        isSearch={isSearch}
                        isReset={isReset}
                        isShortView={true}
                        inboxName={menu.inboxes[inbox].name}
                        className={menu.inboxes[inbox].caseName}
                      />
                    </Panel>
                  );
                }

                if (inbox === 'mto') {
                  return <Mto />;
                }

                if (inbox === 'aviaExploit') {
                  return <AviaExploit />;
                }

                return <React.Fragment key={`fragment_${i}`} />;
              })}
          </Collapse>
        </Row>
      </Form>
    </TwoColumnGrid>
  );
};

export default connect((state: AppState) => {
  return {
    menu: state.menu,
  };
})(InboxGroupList);
