import { ensureObjectID } from 'utils/ObjectUtils';
import { Action, ActionType } from '../actionTypes';
import { cloneDeep } from 'lodash';

const initialState = {
  changedNotifications: []
};

const editorDetails = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_EDITOR_DETAILS:
      return ensureObjectID({ ...action.payload });
    case ActionType.UPDATE_EDITOR_NOTIFICATIONS:
      let newChanged = cloneDeep(state.changedNotifications);
      let event = {
        choiceCode: action.payload.choiceCode,
        unsubscribed: action.payload.value
      }
      !!newChanged && !!newChanged.find((event: any) => event.choiceCode === action.payload.choiceCode) ?
        newChanged.splice(newChanged.findIndex((event: any) => event.choiceCode === action.payload.choiceCode), 1) :
        !!newChanged ? newChanged.push(event) : newChanged = [event]
      return {
        ...state,
        changedNotifications: newChanged
      }
    default:
      return state;
  }
};

export default editorDetails;
