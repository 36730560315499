import React from 'react';
import styles from './IntegralIndicators.module.css';
import MiscUtils from 'utils/MiscUtils';
import { RowItem } from 'utils/types';

interface IntegralIndicatorsProps {
  title: string;
  data: RowItem[];
  sum: string;
  columns: string[];
  width?: string | undefined;
}

const IntegralIndicators: React.FC<IntegralIndicatorsProps> = (props) => {
  const { title, data, sum, columns } = props;

  const handleClassName = (item: any): string => {
    if (item === 'Суммарно') {
      return styles.headerColumn;
    } else if (item === 'AOG') {
      return styles.headerColumnRed;
    } else if (item === 'Critical') {
      return styles.headerColumnBlue;
    } else if (item === 'Routine') {
      return styles.headerColumnGreen;
    } else if (item === 'Work Stoppage' || item === 'Work stoppage') {
      return styles.headerColumnPurple;
    } else {
      return styles.headerColumn;
    }
  };

  const formatValues = (value: any, percent: any): string => {
    if (MiscUtils.isBlankString(percent) && MiscUtils.isBlankString(value)) {
      return ``;
    } else if (MiscUtils.isBlankString(value) && !MiscUtils.isBlankString(percent)) {
      return `${percent}`;
    } else if (MiscUtils.isBlankString(percent) && !MiscUtils.isBlankString(value)) {
      return `${value}`;
    }
    return `${value}/${percent}`;
  };

  return (
    <div className={styles.wrapper} style={props.width ? { width: props.width } : {}}>
      <div className={styles.wrapperTitle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.sum}>{sum}</div>
      </div>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.header}>
            <th />
            {columns.map((column: any, i: number) => {
              return (
                <th key={i} className={handleClassName(column)}>
                  {column}
                </th>
              );
            })}
          </tr>
          {data?.map((item: any, a: number) => {
            return (
              <tr className={styles.row} key={`item_${a}`}>
                <td className={styles.headerRow}>{item?.label}</td>
                {!!item.values && item.values.map((value: any, i: number) => {
                  if (item?.percent) {
                    return (
                      <td className={styles.cell} key={`value_${i}`}>
                        {formatValues(value, item?.percent[i])}{' '}
                      </td>
                    );
                  } else {
                    return (
                      <td className={styles.cell} key={`value_${i}`}>
                        {value}{' '}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IntegralIndicators;
