import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width=".9em" height=".9em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5186 4.45563V15.4814H35.5444C41.4852 15.4814 41.4852 24.5186 35.5444 24.5186H24.5186V35.5444C24.5186 41.4852 15.4814 41.4852 15.4814 35.5444V24.5186H4.45563C-1.48521 24.5186 -1.48521 15.4814 4.45563 15.4814H15.4814V4.45563C15.4814 -1.48521 24.5186 -1.48521 24.5186 4.45563Z"
      fill="var(--color-additional-elements)"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
