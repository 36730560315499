import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import appStore from 'store/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import registerAxiosMiddleware from 'integration/axiosMiddleware';
import GlobalLoader from 'components/layout/loader/GlobalLoader';
import devToolsInit from 'utils/devTools';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

moment.locale('ru');


if (process.env.NODE_ENV === 'development') devToolsInit(appStore);

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <BrowserRouter>
      <Provider store={appStore}>
        <ConfigProvider locale={ruRu}>
          <ErrorBoundary>
            <GlobalLoader>
              <App />
            </GlobalLoader>
          </ErrorBoundary>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

registerAxiosMiddleware(appStore);
