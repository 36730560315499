const initialState = '';

const inputSearchValue = (state: any = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return action.payload;
    default:
      return state;
  }
};

export default inputSearchValue;
