import { Action, ActionType } from '../actionTypes';

const initialState = {
  refreshTime: null,
  disabledTableControls: {},
  disabledDatePicker: {},
  form: null,
};

const editor = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_EDITOR_FORM: {
      if (!action.payload) return state;
      return { ...state, form: action.payload };
    }
    case ActionType.UPDATE_EDITOR_REFRESH_TIME: {
      const newState = { ...state };
      newState.refreshTime = new Date().getTime();
      return newState;
    }
    case ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS: {
      const newState = { ...state };
      newState.disabledTableControls[action.payload.inboxName] = action.payload.value;
      return newState;
    }

    case ActionType.UPDATE_EDITOR_DISABLED_DATEPICKER: {
      const newState = { ...state };
      newState.disabledDatePicker = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default editor;
