import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 37 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2279 7.16551L13.1517 10.0414L13.6988 9.50331L10.7712 6.62745C10.658 6.51613 10.7712 6.12649 10.7712 6.12649L8.79057 4.44922L8.00586 5.22106L9.71486 7.16551C9.71486 7.16551 10.1299 7.07274 10.2279 7.16551Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M17.3536 11.7422L15.4258 13.6384L19.6172 17.7537C19.6978 17.8333 19.7936 17.8965 19.8992 17.9397C20.0047 17.9828 20.1178 18.005 20.2321 18.005C20.3464 18.005 20.4595 17.9828 20.5651 17.9397C20.6706 17.8965 20.7664 17.8333 20.8471 17.7537L21.5299 17.082C21.6109 17.0027 21.6752 16.9084 21.719 16.8046C21.7629 16.7008 21.7854 16.5896 21.7854 16.4771C21.7854 16.3647 21.7629 16.2534 21.719 16.1496C21.6752 16.0458 21.6109 15.9516 21.5299 15.8723L17.3536 11.7422Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M20.0634 7.78481C19.9544 7.89202 19.825 7.97706 19.6826 8.03508C19.5402 8.0931 19.3876 8.12297 19.2334 8.12297C19.0793 8.12297 18.9267 8.0931 18.7842 8.03508C18.6418 7.97706 18.5124 7.89202 18.4034 7.78481C18.2945 7.6776 18.208 7.55033 18.149 7.41026C18.09 7.27018 18.0597 7.12005 18.0597 6.96844C18.0597 6.81682 18.09 6.66669 18.149 6.52662C18.208 6.38655 18.2945 6.25927 18.4034 6.15207L20.2407 4.36347C19.8324 4.17763 19.389 4.07778 18.9392 4.07031C18.0768 4.0771 17.2517 4.41707 16.6419 5.01687C16.0321 5.61667 15.6865 6.42822 15.6796 7.27643C15.6872 7.71894 15.7887 8.155 15.9776 8.55666L8.34184 16.0525C8.23284 16.1597 8.14638 16.2869 8.08739 16.427C8.02841 16.5671 7.99805 16.7172 7.99805 16.8688C7.99805 17.0204 8.02841 17.1706 8.08739 17.3106C8.14638 17.4507 8.23284 17.578 8.34184 17.6852C8.45083 17.7924 8.58023 17.8774 8.72263 17.9355C8.86504 17.9935 9.01767 18.0234 9.17182 18.0234C9.32596 18.0234 9.47859 17.9935 9.621 17.9355C9.76341 17.8774 9.8928 17.7924 10.0018 17.6852L17.6338 10.1709C18.0422 10.3567 18.4855 10.4565 18.9354 10.464C19.7977 10.4572 20.6228 10.1172 21.2326 9.51745C21.8424 8.91765 22.188 8.1061 22.1949 7.25788C22.1826 6.81431 22.076 6.3782 21.8818 5.97766L20.0634 7.78481Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M25.2766 0H4.90442C3.60369 0 2.35623 0.508244 1.43647 1.41292C0.516714 2.3176 0 3.54461 0 4.82402V18.1828C0 19.4623 0.516714 20.6893 1.43647 21.5939C2.35623 22.4986 3.60369 23.0069 4.90442 23.0069H11.9857L18.1916 29.022C18.5011 29.3294 18.9214 29.5042 19.3611 29.5082C19.5977 29.5095 19.831 29.4535 20.0402 29.3449C20.2848 29.2192 20.4841 29.0225 20.611 28.7815C20.7379 28.5405 20.7863 28.2669 20.7495 27.9979L20.6174 23.0069H25.2766C26.5774 23.0069 27.8248 22.4986 28.7446 21.5939C29.6643 20.6893 30.1811 19.4623 30.1811 18.1828V4.82402C30.1811 3.54461 29.6643 2.3176 28.7446 1.41292C27.8248 0.508244 26.5774 0 25.2766 0ZM27.9175 18.1828C27.9175 18.8718 27.6392 19.5325 27.144 20.0196C26.6487 20.5067 25.977 20.7804 25.2766 20.7804H19.4441C19.2929 20.7803 19.1433 20.8101 19.0039 20.8679C18.8646 20.9257 18.7385 21.0103 18.633 21.1169C18.5275 21.2234 18.4448 21.3497 18.3897 21.4882C18.3346 21.6267 18.3083 21.7746 18.3124 21.9233L18.4255 26.1239L13.2457 21.0995C13.0334 20.8943 12.7474 20.7797 12.4497 20.7804H4.90442C4.20403 20.7804 3.53232 20.5067 3.03706 20.0196C2.54181 19.5325 2.26358 18.8718 2.26358 18.1828V4.82402C2.26358 4.13511 2.54181 3.47441 3.03706 2.98728C3.53232 2.50014 4.20403 2.22647 4.90442 2.22647H25.2766C25.977 2.22647 26.6487 2.50014 27.144 2.98728C27.6392 3.47441 27.9175 4.13511 27.9175 4.82402V18.1828Z'
      fill='var(--color-dark-2)'
    />
    <ellipse cx='29.4004' cy='22.131' rx='7.5' ry='7.37705' fill='var(--color-additional-elements)' />
    <rect x='29.0996' y='18.8867' width='1.2' height='6.49181' rx='0.599998' fill='white' />
    <rect
      x='26.4004'
      y='22.7227'
      width='1.18033'
      height='6.60001'
      rx='0.590166'
      transform='rotate(-90 26.4004 22.7227)'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
