import React, { useEffect, useRef, useState } from 'react';
import Title from 'components/layout/content/title/Title';
import styles from './MultiPieChart.module.css';
import styleTooltip from '../entityList/EntityList.module.css';
import { Chart, PieController, ArcElement, ChartData, ChartDataset } from 'chart.js';
import { Tooltip } from 'antd';
import { toLower } from 'lodash';
import dataLabels from 'chartjs-plugin-datalabels';
import { ItemDataTooltipPieChart, RowItem } from 'utils/types';

interface MultiPieChartProps {
  data: ItemDataTooltipPieChart[];
  title: string;
  dataTooltip?: RowItem[];
  isRA?: boolean;
}

const MultiPieChart: React.FC<MultiPieChartProps> = (props) => {
  const { data, title, dataTooltip, isRA } = props;
  const ref = useRef<any>();
  const [chart, setChart] = useState<Chart>();
  const dataPie: ChartData = {
    datasets: data,
  };

  useEffect(() => {
    if (ref.current != null && !chart) {
      Chart.register([ArcElement, PieController]);
      const chartInstance: Chart = new Chart(ref.current, {
        type: 'pie',
        data: dataPie,
        options: {
          responsive: false,
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              labels: {
                generateLabels(chart) {
                  const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                  const labelsOriginal = original.call(this, chart);
                  let datasetColors = chart.data.datasets.map((e) => {
                    return e.backgroundColor;
                  });
                  datasetColors = datasetColors.flat();
                  labelsOriginal.forEach((label: any) => {
                    label.datasetIndex = (label.index - (label.index % 2)) / 2;
                    label.hidden = !chart.isDatasetVisible(label.datasetIndex);
                    label.fillStyle = datasetColors[label.index];
                  });
                  return labelsOriginal;
                },
              },
            },
          },
          events: [],
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        },
      });
      setChart(chartInstance);
    }
  }, [ref]);

  useEffect(() => {
    if (chart) {
      chart.update();
    }
  }, [chart, dataPie]);

  return (
    <div className={styles.wrapPie}>
      <h2 className={styles.title}>{title}</h2>
      <Tooltip
        placement='right'
        trigger={'hover'}
        title={
          dataTooltip &&
          dataTooltip.map((element: RowItem, n: number) => {
            if (element.percent) {
              return (
                <div className={styles.tooltip} key={`element_${n}`}>
                  <div className={styles.titleWrap}>
                    <div className={styles.header}>{`${element.label}, шт / %: `}</div>
                    <div
                      className={styles.value}
                    >{`${element.values[0]}/${element.percent[0]}`}</div>
                  </div>
                  <div>
                    {data.map((item: ItemDataTooltipPieChart, j: number) => {
                      if (item.title === 'Просроченные') {
                        return item.columns.map((column: string, i: number) => {
                          return (
                            <div className={styles.titleWrap} key={`column_${i}`}>
                              <div className={styles.wrapColorAndLabel}>
                                <div
                                  className={styles.row}
                                  style={{
                                    backgroundColor: item.backgroundColor[i],
                                  }}
                                />
                                {`${column}, ${toLower(element.label)}`}
                              </div>
                              <div className={styles.value}>{`${isRA ? element.values[i] : element.values[i + 1]}/${
                                isRA ? element.percent[i] : element.percent[i + 1]
                              }`}</div>
                            </div>
                          );
                        });
                      } else {
                        return (
                          <div className={styles.titleWrap} key={`column_${j}`}>
                            <div className={styles.wrapColorAndLabel}>
                              <div
                                className={styles.row}
                                style={{
                                  backgroundColor: item.backgroundColor[n],
                                }}
                              />
                              {`${item.columns}, ${toLower(element.label)}`}
                            </div>
                            <div className={styles.value}>{`${isRA ? element.values[j] : element.values[j + 1]}/${
                              isRA ? element.percent[j] : element.percent[j + 1]
                            }`}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        }
        color='var(--color-light)'
        overlayClassName={`${styleTooltip.tooltip} ${styles.reportTooltip}`}
      >
        <canvas className={styles.pie} ref={ref} />
      </Tooltip>
      <div className={styles.wrapColor}>
        {data.map((item: ItemDataTooltipPieChart) => {
          if (item.title === 'Просроченные') {
            return item.columns.map((columns: string, i: number) => {
              return (
                <div className={styles.wrapper} key={`column_${i}`}>
                  <div
                    className={styles.row}
                    style={{
                      backgroundColor: item.backgroundColor[i],
                    }}
                  />
                  {`${item.title} ${columns}:`}
                </div>
              );
            });
          } else {
            // @ts-ignore
            return item.title.map((itemData: string, i: number) => {
              return (
                <div className={styles.wrapper} key={`column_${i}`}>
                  <div
                    className={styles.row}
                    style={{
                      backgroundColor: item.backgroundColor[i],
                    }}
                  />
                  {`${item.columns}, ${toLower(itemData)}`}
                </div>
              );
            });
          }
        })}
      </div>
    </div>
  );
};

export default MultiPieChart;
