import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#DDE4F3' />
    <path
      d='M8.54396 8.86133C8.21765 8.86133 7.95312 9.10824 7.95312 9.41281C7.95312 9.71739 8.21765 9.96429 8.54396 9.96429H13.2706C13.597 9.96429 13.8615 9.71739 13.8615 9.41281C13.8615 9.10824 13.597 8.86133 13.2706 8.86133H8.54396Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M7.95312 11.6179C7.95312 11.3133 8.21765 11.0664 8.54396 11.0664H13.2706C13.597 11.0664 13.8615 11.3133 13.8615 11.6179C13.8615 11.9225 13.597 12.1694 13.2706 12.1694H8.54396C8.21765 12.1694 7.95312 11.9225 7.95312 11.6179Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M8.54396 13.2715C8.21765 13.2715 7.95312 13.5184 7.95312 13.823C7.95312 14.1276 8.21765 14.3745 8.54396 14.3745H15.634C15.9603 14.3745 16.2248 14.1276 16.2248 13.823C16.2248 13.5184 15.9603 13.2715 15.634 13.2715H8.54396Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M7.95312 16.03C7.95312 15.7254 8.21765 15.4785 8.54396 15.4785H15.634C15.9603 15.4785 16.2248 15.7254 16.2248 16.03C16.2248 16.3346 15.9603 16.5815 15.634 16.5815H8.54396C8.21765 16.5815 7.95312 16.3346 7.95312 16.03Z'
      fill='var(--color-additional-elements)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.7725 5C5.79358 5 5 5.74072 5 6.65445V18.7871C5 19.7008 5.79357 20.4415 6.7725 20.4415H7.36334V20.993C7.36334 21.9068 8.15691 22.6475 9.13584 22.6475H19.7709C20.7498 22.6475 21.5434 21.9068 21.5434 20.993V8.86038C21.5434 7.94666 20.7498 7.20593 19.7709 7.20593H19.18V6.65445C19.18 5.74072 18.3865 5 17.4075 5H6.7725ZM17.9984 6.65445C17.9984 6.34987 17.7338 6.10297 17.4075 6.10297H6.7725C6.4462 6.10297 6.18167 6.34988 6.18167 6.65445V18.7871C6.18167 19.0917 6.4462 19.3386 6.7725 19.3386H17.4075C17.7338 19.3386 17.9984 19.0917 17.9984 18.7871V6.65445ZM19.18 8.3089H19.7709C20.0972 8.3089 20.3617 8.55581 20.3617 8.86038V20.993C20.3617 21.2976 20.0972 21.5445 19.7709 21.5445H9.13584C8.80953 21.5445 8.545 21.2976 8.545 20.993V20.4415H17.4075C18.3865 20.4415 19.18 19.7008 19.18 18.7871V8.3089Z'
      fill='#AACFE4'
    />
    <path
      d='M19.1131 15.0625C17.9652 15.0625 17.0312 15.9685 17.0312 17.082C17.0312 18.196 17.9652 19.1023 19.1131 19.1023C20.2608 19.1023 21.195 18.196 21.195 17.082C21.195 15.9685 20.2608 15.0625 19.1131 15.0625Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M20.4907 20.312L21.0036 20.4286C21.2311 20.4801 21.4553 20.34 21.5017 20.1172L21.605 19.6165C21.6523 19.394 21.8708 19.1495 22.0912 19.0731L22.5868 18.9011C22.8075 18.8251 22.9183 18.5905 22.8344 18.3784L22.641 17.9024C22.5569 17.6908 22.6046 17.3698 22.7481 17.1908L23.0698 16.7858C23.2133 16.6062 23.1748 16.3501 22.9851 16.2168L22.5589 15.9164C22.3696 15.7832 22.2296 15.4892 22.2514 15.2625L22.2968 14.7533C22.316 14.527 22.1415 14.3309 21.907 14.3192L21.3827 14.2902C21.1473 14.2771 20.8678 14.1022 20.759 13.9012L20.514 13.449C20.405 13.248 20.1471 13.1749 19.9446 13.2869L19.4856 13.539C19.283 13.6503 18.9479 13.6503 18.7453 13.539L18.2861 13.2869C18.0833 13.175 17.8258 13.248 17.7168 13.449L17.4718 13.9012C17.3628 14.1021 17.0835 14.2771 16.8481 14.2902L16.3234 14.3192C16.0893 14.3309 15.9151 14.527 15.9341 14.7533L15.9799 15.2625C16.0013 15.4892 15.8614 15.7832 15.6724 15.9164L15.2458 16.2168C15.0562 16.3502 15.0175 16.6062 15.1611 16.7858L15.4829 17.1908C15.6262 17.3698 15.674 17.6908 15.5899 17.9024L15.3965 18.3784C15.3123 18.5905 15.4236 18.8251 15.6438 18.9011L16.1398 19.0731C16.3601 19.1495 16.5786 19.394 16.6259 19.6165L16.7292 20.1172C16.7759 20.34 16.9997 20.48 17.2272 20.4286L17.7402 20.312C17.9687 20.2603 18.2902 20.3518 18.4518 20.5143L18.8194 20.8817C18.9825 21.0443 19.2481 21.0443 19.4113 20.8817L19.7788 20.5143C19.9407 20.3518 20.2623 20.2604 20.4907 20.312ZM19.1155 19.6259C17.6703 19.6259 16.4948 18.485 16.4948 17.0828C16.4948 15.6809 17.6705 14.5404 19.1155 14.5404C20.5606 14.5404 21.7361 15.681 21.7361 17.0828C21.7361 18.4853 20.5606 19.6259 19.1155 19.6259Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M24.9855 23.1933L22.8997 19.2324C22.8497 19.262 22.7964 19.2868 22.74 19.3063L22.2451 19.4779C22.1686 19.5045 22.0583 19.6274 22.0422 19.7044L21.9391 20.203C21.8581 20.5889 21.5067 20.8689 21.1035 20.8689C21.0381 20.8689 20.9722 20.8614 20.9086 20.8469L20.3956 20.7306C20.3911 20.7294 20.3786 20.7275 20.3572 20.7275C20.2517 20.7275 20.1367 20.7777 20.101 20.8135L19.7329 21.1815C19.689 21.2252 19.6409 21.2631 19.5898 21.296L21.4993 24.9211C21.5246 24.9696 21.5757 24.9996 21.6302 24.9996C21.6341 24.9996 21.638 24.9994 21.6421 24.9992C21.7011 24.9946 21.7517 24.9557 21.77 24.9008L22.5133 22.6729L24.8097 23.3938C24.866 23.4119 24.9281 23.3947 24.9665 23.3507C25.0051 23.3069 25.0124 23.2447 24.9855 23.1933Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M18.1354 20.8146C18.0991 20.7783 17.984 20.7276 17.8786 20.7276C17.8581 20.7276 17.8457 20.7298 17.8401 20.7309L17.327 20.8473C17.2632 20.8617 17.1974 20.869 17.132 20.869C16.729 20.869 16.3777 20.5897 16.297 20.2048L16.1934 19.7027C16.1775 19.6282 16.0661 19.504 15.9914 19.4781L15.495 19.306C15.439 19.2867 15.3859 19.2616 15.3363 19.2324L13.2505 23.1933C13.2235 23.245 13.2308 23.3069 13.2696 23.3507C13.3081 23.3947 13.3703 23.4119 13.4265 23.3938L15.7229 22.6729L16.466 24.9008C16.4844 24.9557 16.535 24.9946 16.594 24.9992C16.598 24.9994 16.6018 24.9996 16.6057 24.9996C16.6604 24.9996 16.7113 24.9696 16.7366 24.9217L18.6458 21.2968C18.5949 21.2638 18.5469 21.2261 18.5032 21.1823L18.1354 20.8146Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
