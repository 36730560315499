import { ActionType, Action } from '../actionTypes';

const initialState = {};

const choiceLists = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_CHOICE_LISTS:
      const newState = { ...state, ...action.payload };
      return newState;
    default:
      return state;
  }
};

export default choiceLists;
