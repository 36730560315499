import { ActionType, Action } from '../actionTypes';

export interface FiltersParams {
  key: string;
  config: any;
}

const initialState = {};

const filtersConfiguration = (
  state: any = initialState,
  action: Action<ActionType, FiltersParams>
) => {
  switch (action.type) {
    case ActionType.UPDATE_SEARCH_CONFIGURATIONS:
      const newState = { ...state };
      newState[action.payload?.key || '_undefined_'] = action.payload?.config;
      return newState;
    default:
      return state;
  }
};

export default filtersConfiguration;
