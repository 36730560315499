import { DataModal } from 'utils/types';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { useState } from 'react';

export type getDataModal = (toolName: string, width: number, isChange?: boolean, onOk?:()=>void) => void;

type UseModalWithText = () => {
  getDataModal: getDataModal,
  dataModal: DataModal,
  resetDataModal: () => any
};

export const useModalWithText: UseModalWithText = () => {
  const [dataModal, setDataModal] = useState<DataModal>({
    header: '',
    text: '',
    ok: '',
    cancel: '',
  });

  const getDataModal = async (toolName: string, width: number, isChange?: boolean, onOk?:()=>void) => {
    const response: AxiosResponseNull = await ServerAPI.getModalWindow({
      toolName: String(toolName),
      isChange: String(isChange)
    }).catch((serverError: ServerError) => serverError.notify(width));
    const modalData = response?.data?.[0];
    if (modalData) {
      if(onOk){
        modalData.onOk = onOk
      }
      setDataModal(modalData);
      return modalData
    }

  };

  const resetDataModal = () => {
    setDataModal({
      header: '',
      text: '',
      ok: '',
      cancel: '',
    })
  }

  return {
    getDataModal,
    dataModal,
    resetDataModal
  };
};

export default useModalWithText;