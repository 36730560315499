import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse cx='25' cy='24.2188' rx='25' ry='24.2188' fill='#72D9A4' />
    <rect
      x='14'
      y='25.7031'
      width='4.54106'
      height='11.7336'
      rx='2.27053'
      transform='rotate(-45 14 25.7031)'
      fill='white'
    />
    <rect
      x='31.3672'
      y='14'
      width='4.54106'
      height='20.5493'
      rx='2.27053'
      transform='rotate(35 31.3672 14)'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
