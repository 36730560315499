import React from 'react'

function ZNKEDAttachIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="#D4F0FF"/>
      <path d="M19.0385 41.6667C16.724 41.6667 14.4091 40.7933 12.6475 39.046C10.9399 37.3533 10 35.103 10 32.7094C10 30.3159 10.9399 28.0655 12.6475 26.3725L28.9455 10.2145C30.1686 9.00157 31.7956 8.33337 33.5253 8.33337C35.2551 8.33337 36.8821 9.00157 38.1055 10.2145C40.6315 12.7185 40.6315 16.7928 38.1055 19.2967L22.0745 35.1911C20.5184 36.7342 17.9849 36.7342 16.4288 35.1911C15.6746 34.4437 15.2594 33.4498 15.2594 32.3926C15.2594 31.3355 15.6746 30.3415 16.4288 29.5941L28.9984 17.1323C29.528 16.6072 30.3861 16.6072 30.9157 17.1323C31.4453 17.6573 31.4453 18.5081 30.9157 19.0331L18.3457 31.4946C18.1038 31.7345 17.9707 32.0535 17.9707 32.3926C17.9707 32.7317 18.1038 33.0504 18.3457 33.2902C18.8457 33.7859 19.6577 33.7855 20.1569 33.2906L36.1886 17.3959C37.6569 15.9398 37.6569 13.5715 36.1886 12.1154C35.4771 11.4104 34.5312 11.0218 33.5253 11.0218C32.5194 11.0218 31.5735 11.4104 30.8621 12.1154L14.5644 28.2737C13.3691 29.4584 12.7113 31.0339 12.7113 32.7094C12.7113 34.3849 13.3695 35.9604 14.5644 37.1451C17.0315 39.5911 21.045 39.5907 23.5121 37.1455L36.3484 24.4192C36.8779 23.8942 37.7361 23.8942 38.2657 24.4192C38.7952 24.9443 38.7952 25.795 38.2657 26.3201L25.4294 39.046C23.6678 40.7929 21.3529 41.6667 19.0385 41.6667Z" fill="#004D77"/>
    </svg>
  )
}

export default ZNKEDAttachIcon