import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table as AntdTable } from 'antd';
import cn from 'classnames';

import { useVT } from 'virtualizedtableforantd4';

import { TableNoResults } from 'components/TableGrid/TableNoResults';
import { TablePreLoader } from 'components/TableGrid/TablePreLoader';

import { isEven } from 'components/TableGrid/utils/isEven';
import { onRow } from 'components/TableGrid/utils/onRow';

import { AppState } from 'store/reducers';

import { ActionType } from 'store/actionTypes';

import { TableField } from 'interfaces/EntityList';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import styles from 'components/TableGrid/index.module.css';
import { useRouteMatch } from 'react-router-dom';
import { IScroll } from 'interfaces/Table';

export interface EntityListTableProps {
  dataSource: TableField[];
  screenResolution?: IScroll;
  onChange?: (pagination: {}, filters: {}, sorter: { order: string; field: string }) => void;
  loading?: boolean;
  className?: string;
  disableInifinityScroll?: boolean;
  isVisible?: boolean;
  setIsScrollEnd?: (isEnd: boolean) => void;
  isCardTable?: boolean;
  withRowsSelect?: boolean;
  fillCriteriaFrom?: any;
  massReplaceTool?: any;
  columns?: any;
  tableId?: string;
}

const fixScrollY = (scrollY: any) => {
  if (!scrollY || !scrollY.y) {
    return '100%';
  }
  if (`${scrollY.y}`.indexOf('%') !== -1) {
    return scrollY.y;
  }
  return scrollY.y - 20;
};

const TableGrid: React.FC<EntityListTableProps> = (props) => {
  const {
    dataSource,
    loading,
    className = '',
    screenResolution,
    onChange,
    isVisible,
    setIsScrollEnd,
    isCardTable,
    withRowsSelect,
    fillCriteriaFrom,
    massReplaceTool,
    tableId,
    columns,
  } = props;
  const disableInifinityScroll: boolean = props.disableInifinityScroll === true;
  const { cardForm } = useContext(EntityEditorContext);
  const dispatch = useDispatch();

  const selectedRows = useSelector((state: AppState) => state.selectedRows);
  const match: any = useRouteMatch();

  const [scroll, setScroll] = useState({
    y: fixScrollY(screenResolution),
  });

  const vtId = useRef(Math.random());
  const [vt] = useVT(
    () => ({
      id: vtId.current,
      initTop: 0,
      onScroll: ({ isEnd }) => {
        if (setIsScrollEnd) setIsScrollEnd(isEnd && true);
      },
      scroll,
    }),
    []
  );

  const [listTableProps, setListTableProps] = useState<any>({
    components: vt,
  });

  useEffect(() => {
    if (disableInifinityScroll) {
      const newListTableProps: any = { ...listTableProps };
      delete newListTableProps.components;
      setListTableProps(newListTableProps);
    }
  }, [disableInifinityScroll]);

  useEffect(() => {
    if (screenResolution && screenResolution.y) {
      if (fixScrollY(screenResolution) !== scroll.y) {
        setScroll({
          y: fixScrollY(screenResolution),
        });
      }
    }
  }, [screenResolution, scroll]);

  // const rowKey = () => uniqueId(`${className ? className : 'table'}`);

  const rowClassName = (record: any, idx: number) => !isEven(idx + 1) && styles.evenNumber;

  const onSelectedRowKeysChange = (selectedRowKeys: any, _selectedRows: any[]) => {
    const rows = [];
    const ids = [];
    if (match.params.useKB) {
      if (selectedRowKeys.length !== 0 || _selectedRows.length !== 0) {
        rows.push(_selectedRows.at(-1));
        ids.push(rows[0].VersionSeriesId, rows[0].id);
      }
    } else {
      for (const row of _selectedRows) {
        rows.push(row);
      }

      for (const row of rows) {
        ids.push(row.id);
      }
    }

    dispatch({
      type: ActionType.UPDATE_SELECTED_ROWS,
      payload: {
        name: tableId,
        data: ids,
      },
    });
  };

  const getSelectedRowsKey = () => tableId && selectedRows[tableId];

  const rowSelection = {
    selectedRowKeys: getSelectedRowsKey(),
    onChange: onSelectedRowKeysChange,
    getCheckboxProps: (record: any) => ({
      disabled: cardForm?.getFieldValue(fillCriteriaFrom)?.includes(record.id),
    }),
  };

  useEffect(() => {
    if (columns.length > 0) {
      columns[0].fixed = 'left'
    }
  }, [columns])

  return (
    <>
      {(loading || loading === null) && !dataSource?.length ? (
        <TablePreLoader />
      ) : !dataSource?.length || !isVisible ? (
        <TableNoResults small={isCardTable} />
      ) : (
        <div className={cn(className, { [styles.table]: !className })}>
          <AntdTable
            className={cn('js-parentNode', { [styles.disableSelectAllCheckbox]: massReplaceTool })}
            loading={loading}
            onChange={onChange}
            onRow={onRow}
            rowClassName={rowClassName}
            columns={columns}
            dataSource={dataSource}
            scroll={{y:screenResolution?.y, x: 'auto'}}
            pagination={false}
            showSorterTooltip={false}
            rowSelection={(withRowsSelect || massReplaceTool) && rowSelection}
            {...listTableProps}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(TableGrid);
