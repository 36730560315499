import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#FFEAD1' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.5 8C13.601 8 8 13.601 8 20.5C8 27.399 13.601 33 20.5 33C27.399 33 33 27.399 33 20.5C33 13.601 27.399 8 20.5 8ZM20.5 9.66667C26.479 9.66667 31.3333 14.521 31.3333 20.5C31.3333 26.479 26.479 31.3333 20.5 31.3333C14.521 31.3333 9.66667 26.479 9.66667 20.5C9.66667 14.521 14.521 9.66667 20.5 9.66667ZM19.6667 12.1667V20.5C19.6667 20.7634 19.7909 21.0108 20.0017 21.1683L26.5441 26.0436C26.9125 26.3185 27.4358 26.2428 27.71 25.8736C27.9849 25.5053 27.9092 24.9818 27.5399 24.7078L21.3333 20.0819V12.1671C21.3333 11.7071 20.96 11.3338 20.4999 11.3338C20.0399 11.3338 19.6666 11.7071 19.6666 12.1671L19.6667 12.1667Z'
      fill='#FF8B00'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
