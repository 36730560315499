import { cloneDeep } from 'lodash';
import { Action, ActionType } from '../actionTypes';

export interface FiltersParams {
  filtersId: string | number;
  filters?: any;
  reset?: boolean;
}

export interface FiltersState {
  filters?: any;
  reset?: boolean;
}

export interface FiltersMap {
  [key: string | number]: FiltersState;
}

const initialState = {};

const filters = (state: FiltersMap = initialState, action: Action<ActionType, FiltersParams>) => {
  const { filtersId, filters, reset } = action.payload || {};

  switch (action.type) {
    case ActionType.UPDATE_FILTERS:
      return {
        ...state,
        [filtersId || '_undefined_']: cloneDeep({ filters, reset }),
      };
    case ActionType.REMOVE_FILTERS:
      if (!filtersId) return state;

      const newState = { ...state };
      delete newState[filtersId];

      return newState;
    default:
      return state;
  }
};

export default filters;
