import React, { useState } from 'react';
import loadable from '@loadable/component';
import styles from './Question.module.css';
import stylesEntityEditor from '../entityEditor/Tabs/EntityEditorDetails.module.css';
import stylesMain from '../main/MainPage.module.css';
import { Collapse, Pagination } from 'antd';
import collapseStyles from '../../components/controls/Collapse/Collapse.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import SelectControl from '../../components/controls/SelectControl/SelectControl';

const Title = loadable(() => import('components/layout/content/title/Title'));

const Questions: React.FC = () => {
  const data = [
    {
      name: '',
      label: 'Какое ПО необходимо установить для подключения нового сервиса?',
      category: 'Оперативно - ситуационный центр',
      info: 'Подсистема «Оперативно-ситуационный центр» обеспечивает инженерно-техническую и конструкторскую поддержку эксплуатантов и организаций по ТОиР ВС, а также контроль и информирование пользователей о состоянии запросов инженерно-технической и конструкторской поддержки\n',
    },
    {
      name: '',
      label: 'Каким образом осуществляется техническая поддержка??',
      category: 'Другое',
      info: '',
    },
    {
      name: '',
      label: 'На какой платформе функционирует система?',
      category: '',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: '',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
    {
      name: '',
      label: 'Какие модули являются необходимыми для работы системы?',
      category: 'Летная эксплуатация',
      info: '',
    },
  ];

  const { Panel } = Collapse;

  return (
    <>
      <div className={styles.header}>
        <Title title={'Частые вопросы'} goBack={true} />
        <Pagination className={styles.pagination} simple={true} defaultCurrent={2} total={50} />
      </div>
      <div className={styles.wrapper}>
        {data.map((item: any, i: number) => {
          return (
            <Collapse
              className={collapseStyles.collapse}
              ghost={true}
              expandIcon={({ isActive }) => <AccordionIcon active={isActive === true} />}
              key={`${i}`}
            >
              <Panel
                className={stylesMain.panel}
                header={
                  <div className={stylesMain.wrapTitlePanel}>
                    <div>{item.label}</div>
                    <div className={stylesMain.categoryQuestion}>{item.category}</div>
                  </div>
                }
                key={`table_panel_${i}`}
              >
                <div className={stylesMain.infoQuestion}>{item.info}</div>
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </>
  );
};

export default Questions;
