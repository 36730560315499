import { FormInstance } from 'antd';
import _ from 'lodash';

import { ensureArray, processFalsy } from 'utils/MiscUtils';
import { ensureObjectID } from 'utils/ObjectUtils';
import { Action, ActionType } from '../actionTypes';
import { JsObjectNested, ObjectData } from 'interfaces/Object';
import { FormObjectData } from 'interfaces/Form';

export enum ModalType {
  ATTACH = 'attach',
  CREATE = 'create',
  COPY = 'copy',
  EDIT = 'edit',
  READONLY = 'readonly',
  MASS_REPLACE = 'massReplace',
  IMPORT_FILES = 'importFiles',
}

type MassReplaceParamsType = {
  occurrencesField: string;
  tableId: string;
};

export interface ModalState {
  modalDetails: ObjectData;
  parentDetails: ObjectData | null;
  visible: boolean;
  type: ModalType | null;
  parentId: string;
  layoutParentId: string;
  parentClassName: string;
  objectId: string;
  objectClassName: string;
  toolName: string;
  attachParams: any;
  refreshIDs: string | string[];
  customLayout: string;
  layoutParams?: JsObjectNested;
  cardForm?: FormInstance;
  cardFormValues?: FormObjectData;
  massReplaceParams?: MassReplaceParamsType;
}

export type ModalPayload = Partial<ModalState>;

const initialState: ModalState = {
  modalDetails: {},
  parentDetails: null,
  visible: false,
  type: null,
  parentId: '',
  layoutParentId: '',
  parentClassName: '',
  objectId: '',
  objectClassName: '',
  toolName: '',
  attachParams: {},
  refreshIDs: [],
  customLayout: '',
};

export const modal = (
  state: ModalState = initialState,
  { type, payload }: Action<ActionType, ModalPayload>
) => {
  switch (type) {
    case ActionType.UPDATE_MODAL_DATA: {
      const data = { ...payload };

      if (data.modalDetails && !_.isEqual(data.modalDetails, state.modalDetails)) {
        data.modalDetails = ensureObjectID(_.cloneDeep(data.modalDetails));
      }

      if (data.refreshIDs)
        data.refreshIDs = ensureArray(data.refreshIDs, { flat: true, noFalsy: true });

      return { ...state, ...processFalsy(data, initialState) };
    }
    case ActionType.RESET_MODAL_DATA: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default modal;
