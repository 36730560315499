import React, { useEffect, useState } from 'react';
import styles from './ModalCard.module.css';
import { Button, Modal, Carousel } from 'antd';
import CloseModalIcon from '../icons/card/CloseModalIcon';
import stylesCard from '../card/CardItem.module.css';
import entityEditorStyles from '../../pages/entityEditor/Buttons/EntityEditorButtons.module.css';
import FileModalPreview from '../controls/FilesList/FileModalPreview';
import { categoryIcons } from '../card/CardItem';
import { CardElem } from '../../utils/types';
import { downloadItem } from '../controls/FilesList/FileItem';
import { isArray } from 'lodash';
import ClosePreviewIcon from '../icons/ClosePreviewIcon';
import { useWindowWidth } from '@react-hook/window-size';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  card: CardElem;
}

const ModalCard: React.FC<ModalProps> = (props) => {
  const { isOpen, onClose, card } = props;
  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const width = useWindowWidth();

  const handlePreviewImage = (img: string) => {
    setImage(img);
    setIsOpenPreview(true);
  };

  const handleDownloadFiles = (files: string[]) => {
    files.forEach((item) => {
      downloadItem(item, width);
    });
  };

  useEffect(() => {
    if (card.files.length === 0) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [card]);

  return (
    <>
      <Modal
        className={styles.modal}
        visible={isOpen}
        onCancel={onClose}
        footer={false}
        width={'74%'}
        title={
          <div className={styles.wrapTitle}>
            <div className={styles.title}>{card.title}</div>
            <CloseModalIcon onClick={onClose} className={styles.icon} />
          </div>
        }
      >
        <div className={styles.modalContent}>
          <div className={styles.wrapImage}>
            <div className={styles.wrapInfo}>
              <div className={stylesCard.cardDate}>{card.date}</div>
              <div className={styles.wrapCategory}>
                <div className={styles.cardIcon}>
                  {card.important ? categoryIcons.important : categoryIcons[card.category]}
                </div>
                <div className={styles.category}>{card.category}</div>
              </div>
            </div>

            <Carousel className={styles.carousel} autoplay={true}>
              {card.imgs.map((img, i) => (
                <div key={i}>
                  <img
                    onClick={() => handlePreviewImage(img)}
                    className={styles.cardImage}
                    alt='Изображение новости'
                    src={img}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <div className={styles.wrapInfo}>
              <div className={styles.titleFile}>{card.notes}</div>
              <Button
                className={entityEditorStyles.button}
                onClick={() => handleDownloadFiles(card.files)}
                disabled={disabled}
              >
                Скачать
              </Button>
            </div>
            <div className={styles.cardInfo}>
              {card.info !== undefined &&
                !isArray(card.info) &&
                card.info.split('\n').map((item: string, i: number) => {
                  if (item) {
                    return (
                      <div key={i}>
                        <div>{item}</div>
                        <br />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </Modal>
      <FileModalPreview
        isOpen={isOpenPreview}
        onClose={() => setIsOpenPreview(false)}
        result={image}
        pdf={false}
      />
    </>
  );
};

export default ModalCard;
