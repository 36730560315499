import { Select, Input, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { LabeledValue } from 'antd/lib/select';
import axios from 'axios';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { debounce } from 'lodash';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { convertDataToType, getLinkedData } from '../SelectControl/SelectControl';
import styles from '../SelectControl/SelectControl.module.css';
import DropdownIcon from 'components/icons/DropdownIcon';
import DisabledIcon from 'components/icons/DisabledIcon';
import { DataType } from 'interfaces/BaseComponentInterface';
import stylesChunked from '../../../pages/entityEditor/Tabs/EntityEditorDetails.module.css';
import stylesEntityList from '../../../pages/entityList/EntityList.module.css';
import { useWindowWidth } from '@react-hook/window-size';
import notification from '../../messages/notification';
import HintIcon from '../SelectControl/HintIcon';

const InputAutosuggest: React.FC<BaseComponentProps> = (props) => {
  const { ownProps, ...inputProps } = props;
  const readOnly: boolean = !!ownProps.component?.readonly;
  const choiceName: string = ownProps?.component?.choiceName || '';
  const dataType: DataType = ownProps.component?.dataType || DataType.STRING;
  // @ts-ignore
  const isChunked = ownProps?.component?.isChunked;
  const width = useWindowWidth();
  const context = useContext(EntityEditorContext);
  const formValue: any = context?.form?.getFieldValue(ownProps.component?.propName || '');

  const [value, setValue] = useState<any>({});
  const [values, setValues] = useState<LabeledValue[]>([]);

  useEffect(() => {
    const valuesIndex: number = values.findIndex((v) => v.value === formValue);
    // костыль для фильтров с autosuggest, данный рест запрашивает лейбл при связке двух полей (наименование компонента и P/N в таблице добавления ВС)
    if (
      typeof formValue !== 'undefined' &&
      valuesIndex === -1 &&
      ownProps.controlType === 'autosuggest'
    ) {
      const body: FormData = new FormData();
      body.append('index', `${formValue}`);
      body.append('choiceName', `${choiceName}`);
      axios.post(`/SPChoice/getChoiceLabel`, body).then((response: AxiosResponseExt) => {
        if (!response.error && response.data !== null) {
          const responseValues: any[] = convertDataToType(dataType, [response.data]).reduce(
            (res: any, cur: any) => {
              res.push({
                label: cur.label,
                value: cur.index,
              });
              return res;
            },
            []
          );
          setValues([...values].concat(responseValues));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, choiceName, convertDataToType, dataType]);

  const handleGetValues = async (value: string) => {
    const body: FormData = new FormData();
    body.append('phrase', `${value}`);
    body.append('choiceName', `${choiceName}`);
    return axios.post(`/SPChoice/autocomplete`, body).then((response: AxiosResponseExt) => {
      if (!response.error) {
        return convertDataToType(dataType, Object.values(response.data)).map((d: any) => {
          return {
            label: d.label,
            value: d.index,
          };
        }) as LabeledValue[];
      } else {
        notification.error({
          text: response.error,
          width,
        });
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(async (value: string) => {
      const newValues = await handleGetValues(value);
      if (!!newValues) setValues(newValues);
    }, 500),
    []
  );

  const [opened, setOpened] = useState<boolean>(false);

  const ref: any = useRef(null);

  const suf = readOnly ? (
    <DisabledIcon />
  ) : (
    <DropdownIcon
      onClick={() => {
        if (ref && ref.current && ref.current.focus && ref.current.blur) {
          if (!opened) {
            ref.current.focus();
          } else {
            ref.current.blur();
          }
        }
      }}
      className={styles.icon}
    />
  );

  const onClear = () => {
    if (ownProps.component?.jsonConfig) {
      context.form?.setFieldsValue({
        [Object.keys(ownProps.component.jsonConfig.linked)[0]]: null,
      });
    }
  };

  if (isChunked) {
    return (
      <div style={{ display: 'flex' }}>
        <Typography.Text
          className={stylesChunked.readOnlyLabel}
          ellipsis={{
            tooltip: {
              title: ownProps?.component?.label,
              children: ownProps?.component?.label,
              placement: 'leftBottom',
              arrow: false,
              autoAdjustOverflow: false,
              color: 'var(--color-light)',
              overlayClassName: stylesEntityList.tooltip,
            },
          }}
        >
          {' '}
          {ownProps?.component?.label}
        </Typography.Text>
        <div className={stylesChunked.readOnlyValue}>{values.length > 0 ?values[0].label : '-'}</div>
      </div>
    );
  }

  return (
    <>
      <Input hidden={true} />
      <div className={styles.container}>
        <FormItem noStyle={true}>
          <Select
            disabled={readOnly}
            ref={ref}
            showSearch={true}
            dropdownClassName={styles.dropdown}
            suffixIcon={suf}
            options={values}
            onSearch={handleSearch}
            value={value.label}
            filterOption={(_, option) => {
              return option as any;
            }}
            onSelect={(_: any, option: any) => {
              setValue(option);
              getLinkedData(ownProps, context);
            }}
            {...inputProps}
            className={styles.input}
            onDropdownVisibleChange={(open: boolean) => {
              setOpened(open);
            }}
            allowClear={true}
            onClear={onClear}
          />
          {ownProps.component?.hint && <div className={styles.info}><HintIcon hint={ownProps.component?.hint} /></div>}
        </FormItem>
        <div>
        </div> 
      </div>
    </>
  );
};

export default InputAutosuggest;
