import Icon from '@ant-design/icons';

const IconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#DDE4F3' />
    <path
      d='M19.9377 11.1445C17.4123 11.1445 15.3574 13.1988 15.3574 15.7236C15.3574 18.2494 17.4123 20.3045 19.9377 20.3045C22.4629 20.3045 24.518 18.2494 24.518 15.7236C24.518 13.1988 22.4629 11.1445 19.9377 11.1445Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M22.9637 23.0479L24.0922 23.3123C24.5927 23.429 25.0858 23.1113 25.1881 22.6061L25.4154 21.4708C25.5194 20.9664 26 20.4119 26.4849 20.2389L27.5754 19.8489C28.0608 19.6764 28.3047 19.1445 28.1201 18.6635L27.6945 17.5842C27.5096 17.1044 27.6145 16.3766 27.9301 15.9709L28.6381 15.0526C28.9537 14.6454 28.8689 14.0646 28.4517 13.7623L27.5141 13.0813C27.0974 12.7793 26.7895 12.1125 26.8375 11.5987L26.9374 10.4441C26.9795 9.93086 26.5957 9.48634 26.0798 9.45967L24.9261 9.39388C24.4084 9.36425 23.7935 8.96774 23.554 8.51196L23.015 7.48661C22.7752 7.03084 22.2077 6.86518 21.7623 7.11915L20.7524 7.69079C20.3067 7.94298 19.5694 7.94298 19.1237 7.69079L18.1134 7.11915C17.6672 6.86548 17.1005 7.03084 16.8608 7.48661L16.3218 8.51196C16.082 8.96744 15.4674 9.36425 14.9497 9.39388L13.7951 9.45967C13.2801 9.48634 12.8969 9.93086 12.9387 10.4441L13.0394 11.5987C13.0866 12.1125 12.7787 12.7793 12.3629 13.0813L11.4244 13.7623C11.0071 14.0648 10.9221 14.6454 11.238 15.0526L11.9459 15.9709C12.2613 16.3766 12.3665 17.1044 12.1815 17.5842L11.756 18.6635C11.5708 19.1445 11.8155 19.6764 12.3001 19.8489L13.3912 20.2389C13.876 20.4119 14.3567 20.9664 14.4607 21.4708L14.688 22.6061C14.7908 23.1113 15.2831 23.4287 15.7836 23.3123L16.9124 23.0479C17.415 22.9306 18.1223 23.138 18.4779 23.5067L19.2867 24.3397C19.6455 24.7083 20.2299 24.7083 20.5888 24.3397L21.3975 23.5067C21.7537 23.138 22.4611 22.9309 22.9637 23.0479ZM19.938 21.4921C16.7586 21.4921 14.1724 18.9053 14.1724 15.7259C14.1724 12.5473 16.7589 9.96138 19.938 9.96138C23.1175 9.96138 25.7037 12.5476 25.7037 15.7259C25.7037 18.9059 23.1175 21.4921 19.938 21.4921Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M32.8416 29.5747L28.2528 20.5938C28.1428 20.6607 28.0255 20.717 27.9013 20.7612L26.8125 21.1503C26.6442 21.2107 26.4015 21.4893 26.3662 21.6638L26.1392 22.7944C25.9611 23.6695 25.188 24.3043 24.301 24.3043C24.157 24.3043 24.0121 24.2874 23.8722 24.2545L22.7434 23.9907C22.7337 23.9881 22.7061 23.9836 22.659 23.9836C22.4269 23.9836 22.1739 24.0974 22.0953 24.1786L21.2854 25.0131C21.1888 25.1121 21.083 25.198 20.9707 25.2727L25.1717 33.4924C25.2274 33.6023 25.3397 33.6702 25.4597 33.6702C25.4683 33.6702 25.4769 33.6699 25.4858 33.6693C25.6156 33.659 25.727 33.5706 25.7673 33.4462L27.4025 28.3947L32.4549 30.0293C32.5788 30.0702 32.7154 30.0314 32.7999 29.9315C32.8849 29.8323 32.9009 29.6912 32.8416 29.5747Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M17.7829 24.1813C17.7029 24.0989 17.4498 23.9839 17.2177 23.9839C17.1727 23.9839 17.1454 23.989 17.133 23.9913L16.0042 24.2554C15.8637 24.288 15.7191 24.3046 15.5751 24.3046C14.6884 24.3046 13.9156 23.6713 13.7381 22.7985L13.5102 21.66C13.4752 21.4911 13.2301 21.2096 13.0657 21.1509L11.9736 20.7606C11.8504 20.7167 11.7336 20.6598 11.6245 20.5938L7.03537 29.5747C6.9761 29.6918 6.99211 29.8323 7.07745 29.9315C7.16221 30.0314 7.29912 30.0702 7.42269 30.0293L12.4751 28.3947L14.11 33.4462C14.1503 33.5706 14.2617 33.659 14.3915 33.6693C14.4004 33.6699 14.4087 33.6702 14.4173 33.6702C14.5376 33.6702 14.6496 33.6023 14.7053 33.4936L18.9057 25.2745C18.7937 25.1998 18.6882 25.1142 18.5919 25.0149L17.7829 24.1813Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.45455 16L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V15C20 15.5523 19.5523 16 19 16H4.45455ZM3.76282 14H18V2H2V15.3851L3.76282 14ZM9.2929 9.1213L13.5355 4.87868L14.9497 6.29289L9.2929 11.9497L5.40381 8.0607L6.81802 6.64645L9.2929 9.1213Z" fill="#2B9647"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
