import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import TableView from 'components/TableView';

const CardTable = (props: any) => {
  const { params } = useRouteMatch();
  const { objectID, className } = params || ({} as any);

  return <TableView {...props} match={{ params: { objectID, className } }} isCardTable={true} />;
};

export default CardTable;
