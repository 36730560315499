import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M50 25C50 11.1921 38.8063 0 25 0C11.1937 0 0 11.1921 0 25C0 38.8079 11.1937 50 25 50C38.8063 50 50 38.8063 50 25ZM45.3124 25C45.3124 36.2203 36.2187 45.3109 25 45.3109C13.7813 45.3109 4.68758 36.2203 4.68758 25C4.68758 13.7813 13.7813 4.68758 25 4.68758C36.2187 4.68758 45.3124 13.7829 45.3124 25ZM35.5749 24.0579L26.5422 15.1265C25.6938 13.7485 24.3063 13.7485 23.4594 15.1265L14.4251 24.0577C13.5782 25.4342 14.272 26.5639 15.9673 26.5639H20.3126V34.3764C20.3126 35.2404 21.0126 35.9388 21.8751 35.9388H28.1251C28.9891 35.9388 29.6876 35.2404 29.6876 34.3764V26.5639H34.0329C35.7296 26.5625 36.4234 25.4344 35.5749 24.0579Z' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
