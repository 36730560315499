import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ObjectData } from 'interfaces/Object';
import { ActionType } from 'store/actionTypes';

type UseUpdateEditor = () => {
  updateEditorErrors: (errors?: any[]) => void;
  updateEditorDetails: (objectData?: ObjectData) => void;
};

export const useUpdateEditor: UseUpdateEditor = () => {
  const dispatch = useDispatch();

  const updateEditorErrors = useCallback(
    (errors: any[] = []) => {
      dispatch({
        type: ActionType.UPDATE_EDITOR_ERRORS,
        payload: errors,
      });
    },
    [dispatch]
  );

  const updateEditorDetails = useCallback(
    (objectData: ObjectData = {}) => {
      dispatch({
        type: ActionType.UPDATE_EDITOR_DETAILS,
        payload: objectData,
      });
    },
    [dispatch]
  );

  return {
    updateEditorErrors,
    updateEditorDetails,
  };
};

export default useUpdateEditor;
