import React, { useEffect } from 'react';
import _ from 'lodash';
import useRefreshSubscribe from 'hooks/useRefreshSubscribe';

type WithComponentRefresh = (WrappedComponent: React.FC<any>) => React.FC<any>;

const withComponentRefresh: WithComponentRefresh = (WrappedComponent) => {
  const RefreshProvider: React.FC<any> = (props) => {
    const {
      refreshCount,
      refreshSubscriber: refreshSelf,
      resetRefreshSubscriber: resetRefreshSelf,
    } = useRefreshSubscribe(props.refreshID);

    const addProps = {
      refreshCount,
      refreshSelf,
      resetRefreshSelf,
    };

    // on unmount reset subscriber refresh key
    // only if subscriber refreshed
    useEffect(
      () => () => {
        if (refreshCount) resetRefreshSelf();
      },
      [refreshCount, resetRefreshSelf]
    );

    return <WrappedComponent {...props} {...addProps} />;
  };

  return RefreshProvider;
};

export default withComponentRefresh;
