import { Spin } from 'antd';
import Form, { FormInstance } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { ControlType } from 'interfaces/BaseComponentInterface';
import { cloneDeep, isEqual } from 'lodash';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import { getFilters } from 'store/selectors/filters';
import { getSearchConfiguration } from 'store/selectors/searchConfiguration';
import ControlFactory, { FilterComponent } from 'utils/ControlFactory';
import MiscUtils from 'utils/MiscUtils';
import { genNewObjectID } from 'utils/ObjectUtils';

export interface FiltersConfig {
  className: string;
  config: any[];
  initConfig: any[];
}

interface EntityListFiltersProps {
  filtersForm: FormInstance;
  factory?: ControlFactory;
  config?: FiltersConfig | null;
  showFilters?: boolean;
  showSorting?: boolean;
  action?: (config: any) => void;
  filters?: any;
  className: string;
  configKey?: string;
  inboxName: string;
  populateFactoryAndConfigFromStorage?: string;
  groupID?: any;
  tableId?: string;
  filtersConfigLoadingRef?: MutableRefObject<boolean>;
  checkedFilters?: any;
}

let filterTimeoutId: any = null;
let valuesChangeTimerId: any = null;

// Функция для рекурсивного обхода вложенных фильтров
const bypassNestedFilters = (param: any, filters: any, checkedFilters: any) => {
  if (param.conditions) {
    param.conditions.map((item: any) => {
      bypassNestedFilters(item, filters, checkedFilters);
    });
  }
  if (filters.hasOwnProperty(param.field) && param.visible) {
    if (checkedFilters !== undefined && checkedFilters.fields.length > 0) {
      checkedFilters.fields.map((field: any) => {
        if (field.fieldName === param.field) {
          param.value = field.checked ? null : filters[param.field];
        }
      });
    } else {
      param.value = MiscUtils.isBlankString(filters[param.field]) ? null : filters[param.field];
    }
  }
};

export const updateFiltersConfig = (values: any, config: any, form?: any, checkedFilters?: any, setIsReady?:(isReady: boolean)=>void) => {
  const filters: any = values?.filters || values || {};

  if (form) {
    form.setFieldsValue({ ...filters });
  }

  const newConfig = { ...config };

  // reset filters
  if (Object.keys(filters).length === 0) {
    newConfig.config = cloneDeep(newConfig.initConfig);
  }

  // update filters values
  newConfig.config?.criteria?.conditions?.forEach((param: any) => {
    if (checkedFilters !== undefined && checkedFilters.fields.length > 0) {
      checkedFilters.fields.map((field: any) => {
        if (field.fieldName === param.field) {
          param.condition.name = field.checked ? 'NOT_NULL' : field.condition;
        }
      });
    }
    bypassNestedFilters(param, filters, checkedFilters);
  });

  if(setIsReady){
    setIsReady(false)
  }

  return newConfig;
};

export const createFactory = (config: any, dispatch: any) => {
  const newFactory: ControlFactory = new ControlFactory(dispatch);
  config.config.criteria.conditions.forEach((filter: any) => {
    const options = {
      additionProps: {},
      component: {
        ...filter,
        ...{
          propName: filter.field,
          label: filter.label,
        },
      },
    };
    newFactory.addComponent(options);
  });
  return newFactory;
};

const EntityListFilters: React.FC<EntityListFiltersProps & RouteComponentProps> = (props) => {
  const {
    populateFactoryAndConfigFromStorage,
    tableId,
    showFilters,
    showSorting,
    config,
    action,
    filters,
    className,
    filtersForm,
    filtersConfigLoadingRef,
    groupID,
  } = props;

  const dispatch = useDispatch();
  const [factory, setFactory] = useState<ControlFactory | null>(null);
  const prevFiltersRef = useRef(null);
const [isReady, setIsReady]=useState(false)
  const filtersId: string = populateFactoryAndConfigFromStorage || tableId || className;

  useEffect(() => {
    if (config !== null && populateFactoryAndConfigFromStorage && !props.factory) {
      const newFactory: ControlFactory = createFactory(config, dispatch);
      setIsReady(true)
      newFactory._fetchChoiceListData().then(() => {
        setFactory(newFactory);
      });
    }
  }, [props.factory, config, populateFactoryAndConfigFromStorage, dispatch]);

  useEffect(() => {
    if (props.factory !== undefined) {
      setFactory(props.factory);
      setIsReady(true)
    }
  }, [props.factory]);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload: {
        filtersId,
        filters: {},
        reset: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersId]);

  useEffect(() => {
    if (prevFiltersRef.current && isEqual(prevFiltersRef.current, filters)) return;
    prevFiltersRef.current = filters;
    // sync config and className
    if (
      factory != null &&
      config?.config &&
      className === config.className &&
      !filtersConfigLoadingRef?.current 
    ) {
      if (filters?.filters && action) {
        action(updateFiltersConfig(filters, config, filtersForm, props.checkedFilters));
      } else {
        dispatch({
          type: ActionType.UPDATE_FILTERS,
          payload: {
            filtersId,
            filters: filtersForm.getFieldsValue(true),
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, className, filters, groupID]);

  useEffect(() => {
    if (factory && action && config?.className && filters && isReady) {
      action(updateFiltersConfig(filters, config, filtersForm, props.checkedFilters, setIsReady));
    }
  }, [factory,config?.className, isReady]);

  return (
    <Spin spinning={!config}>
      {showFilters ? (
        <div style={{ display: 'none' }}>
          <Form
            form={filtersForm}
            layout='vertical'
            onValuesChange={(changedValues: any) => {
              if (factory && factory.isFetchValue(changedValues)) {
                clearTimeout(valuesChangeTimerId);
                valuesChangeTimerId = setTimeout(() => {
                  factory.handleComponentChange(changedValues, filtersForm);
                }, 3500);
              } else {
                clearTimeout(filterTimeoutId);
                filterTimeoutId = setTimeout(() => {
                  dispatch({
                    type: ActionType.UPDATE_FILTERS,
                    payload: {
                      filtersId,
                      filters: filtersForm.getFieldsValue(true),
                    },
                  });
                }, 3500);
              }
            }}
          >
            <EntityEditorContext.Provider
              value={{
                form: filtersForm,
                factory,
                documentId: genNewObjectID(),
              }}
            >
              {factory != null &&
                factory.getComponents().map((filter: FilterComponent, index: number) => {
                  if (filter.isHidden || filter.isCustom) {
                    return <React.Fragment key={`f_${index}`} />;
                  }
                  const formItemProps: any = {
                    name: filter.propName,
                    label: filter.type === 'checkbox' ? '' : filter.label,
                  };
                  if (filter.type === ControlType.CHECKBOX) {
                    formItemProps.valuePropName = 'checked';
                  }
                  return (
                    <div key={`f_${index}`}>
                      <FormItem {...formItemProps}>{filter.component}</FormItem>
                    </div>
                  );
                })}
            </EntityEditorContext.Provider>
          </Form>
        </div>
      ) : (
        <></>
      )}
      {showSorting ? <div>Sorting</div> : <></>}
    </Spin>
  );
};

export default connect((state: AppState, props: any) => {
  const newProps: any = {};
  const { populateFactoryAndConfigFromStorage, className, tableId, config } = props;
  const checkedFilters = state.checkedFilters;
  if (populateFactoryAndConfigFromStorage && config === null) {
    newProps.config = getSearchConfiguration(state, populateFactoryAndConfigFromStorage) || null;
    newProps.filters = getFilters(state, populateFactoryAndConfigFromStorage);
  } else {
    newProps.filters = getFilters(state, tableId || className || '');
  }
  newProps.checkedFilters = checkedFilters;
  return newProps;
})(EntityListFilters);
