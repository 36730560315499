import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg
    width='1.2em'
    height='1.2em'
    viewBox='0 0 25 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.5 0C7.14286 0 2.67857 3.57143 0 8.03571C2.67857 12.5 7.14286 16.0714 12.5 16.0714C17.8571 16.0714 22.3214 12.5 25 8.03571C22.3214 3.57143 17.8571 0 12.5 0ZM12.5 13.8393C9.29464 13.8393 6.69643 11.2411 6.69643 8.03571C6.69643 4.83036 9.29464 2.23214 12.5 2.23214C15.7054 2.23214 18.3036 4.83036 18.3036 8.03571C18.3036 11.2411 15.7054 13.8393 12.5 13.8393Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M12.4987 4.46484C11.954 4.46484 11.4433 4.59609 10.9808 4.81484C11.4996 4.99967 11.8737 5.48984 11.8737 6.07199C11.8737 6.81217 11.2746 7.41127 10.5344 7.41127C9.95225 7.41127 9.46207 7.03806 9.27725 6.51842C9.0585 6.98092 8.92725 7.49163 8.92725 8.03627C8.92725 10.0086 10.5264 11.6077 12.4987 11.6077C14.471 11.6077 16.0701 10.0086 16.0701 8.03627C16.0701 6.06395 14.471 4.46484 12.4987 4.46484Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
