import { useCallback, useState } from 'react';
import { TableConfiguration, TableField } from '../../../interfaces/EntityList';
import ControlFactory from '../../../utils/ControlFactory';
import { Count } from '../../../utils/types';
import { FiltersConfig } from '../../../pages/entityList/entityListFilters/EntityListFilters';

export const useTableViewSettings = ({ dispatch }: any) => {
  const limit: number = 30;
  const [items, setItems] = useState<TableField[]>([]);
  const [offset, setOffset] = useState<any>(0);
  const [columns, setColumns] = useState<any>([]);
  const [factory, setFactory] = useState<ControlFactory>();
  const [tableConfiguration, setTableConfiguration] = useState<TableConfiguration>();

  const [count, setCount] = useState<Count>({
    count: 0,
    openCount: 0,
    closedCount: 0,
  });

  const [filtersConfiguration, setFiltersConfiguration] = useState<FiltersConfig>({
    className: '',
    config: [],
    initConfig: [],
  });

  const setFactoryConfiguration = (tabLayout: any, meta = {}) => {
    const factoryInstance: ControlFactory = new ControlFactory(dispatch);
    factoryInstance.setConfig(tabLayout, meta);
    return factoryInstance;
  };

  const resetSettings = useCallback(() => {
    setItems([]);
    setColumns([]);
    setOffset(0);
    setCount({ count: 0, openCount: 0, closedCount: 0 });
    setFactory(undefined);
    setTableConfiguration(undefined);
  }, []);

  return {
    resetSettings,
    items,
    offset,
    columns,
    factory,
    tableConfiguration,
    count,
    filtersConfiguration,
    limit,
    setTableConfiguration,
    setFactory,
    setColumns,
    setFiltersConfiguration,
    setCount,
    setItems,
    setOffset,
    setFactoryConfiguration,
  };
};
