import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <line x1='2.31894' y1='3.28558' x2='15.3189' y2='27.2856' stroke='#701D2B' strokeWidth='3' />
    <line x1='14.7519' y1='28.1679' x2='26.7519' y2='10.1679' stroke='#701D2B' strokeWidth='3' />
    <path d='M1 0.5H0.5V1V6V6.5H1H6H6.5V6V1V0.5H6H1Z' fill='white' stroke='#701D2B' />
    <path d='M26 6.5H25.5V7V12V12.5H26H31H31.5V12V7V6.5H31H26Z' fill='white' stroke='#701D2B' />
    <path d='M13 25.5H12.5V26V31V31.5H13H18H18.5V31V26V25.5H18H13Z' fill='white' stroke='#701D2B' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
