import { useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';

type UseKnowledgeBaseArgsType = {
  refreshSelf: () => void;
  componentData: any;
};

export const useKnowledgeBase = ({ refreshSelf, componentData }: UseKnowledgeBaseArgsType) => {
  const { isCardTable, inboxName } = componentData;

  const isKnowledgeBase: boolean | null = useSelector((state: any) => {
    if (isCardTable) return false;
    const inbox: any = state?.menu?.inboxes[inboxName];
    return !!inbox?.params?.knowledgeBase;
  });

  const knowledgeBaseDelete = useCallback(
    async (formData: FormData) => {
      const response: AxiosResponseExt = await axios.post('/KnowledgeBaseDelete', formData);
      if (response.error) return;
      refreshSelf();
    },
    [refreshSelf]
  );

  return { knowledgeBaseDelete, isKnowledgeBase };
};
