import { Moment } from 'moment';
import { ReactNode } from 'react';

export type onClickMenuParamsType = {
  key: string;
  keyPath: string[];
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
};
export interface ICascadeFilterItem {
  key: string;
  inboxName?: string;
  children?: ICascadeFilterItem[];
}

export interface ItemType extends ICascadeFilterItem {
  onTitleClick: ({ key, domEvent }: onClickMenuParamsType) => void;
  children?: ItemType[];
  label: string | ReactNode;
};

export interface ISearchResult {
  count: number;
  documents: ISearchRootEntity[];
}

export interface ISearchRootEntity extends ISearchEntity {
  children: ISearchEntity[],
}

export interface ISearchEntity {
  title: string,
  highlights: {
    [key: string]: string
  },
  link: string,
  icon: SearchIconsEnum
}

export enum SearchIconsEnum {
  ETRIcon = 'ETRIcon',
  ETRMessageIcon = 'ETRMessageIcon',
  ZNKEDIcon = 'ZNKEDIcon',
  ZNKEDAttachIcon = 'ZNKEDAttachIcon'
}

export type ItemValueForPeriod = {
  label: string;
  value: string;
  date: Moment[] | (() => Moment[]);
};

export type RowItem = {
  label: string;
  percent: string[];
  values: number[];
};

export type ItemDataTooltip = {
  columns: string[];
  headerTitle: string;
  value: string;
  valuePercent: string;
  rows: RowItem[];
  headerValue?: string;
};

export type ItemDataTooltipForPie = {
  columns: string[];
  headerTitle: string;
  value: number;
  valuePercent: string;
  rows: RowItem[];
};

export type ItemDataTooltipPieChart = {
  backgroundColor: string[];
  columns: string[];
  data: any[];
  title: string | string[];
};

export type TypeUser = {
  typeUser: boolean;
  userData: UserData;
};

type UserData = {
  external: boolean;
  name: string;
  showCreateTool: boolean;
  showExportTool: boolean;
  showPasswordDialog: boolean;
};

export type Count = {
  count: number;
  openCount: number;
  closedCount: number;
};

export type cellWidth = {
  name: string;
  width: number;
};

export type FiltersConfiguration = {
  className: string;
  config: [];
  initConfig: [];
};

export type CardElem = {
  date: string;
  category: string;
  files: [];
  imgs: string[];
  important: boolean;
  info: string | string[];
  notes: string | null;
  title: string;
  icon?: string;
};

export type FavouriteElem = {
  id: string,
  classType: string,
  isDirty: boolean,
  params: {
      ObjectState: string,
      LastModifier:string,
      InboxList: string[],
      ToDelete: boolean,
      ClassName: string,
      Title: string ,
      Author: string,
      DateCreated: string,
      ID: string,
      Files: string[],
      DateLastModified: string
  }
}

export type ToolBlock = {
  id: string;
  label: string;
  link: string;
};

export type Landing = {
  id: string;
  label: string;
  link: string;
  tool?: ToolBlock;
  data?: CardElem[];
  info?: string;
  href?: string;
};

export type CsdpLanding = Omit<Landing, 'data'> & {
  data: FavouriteElem
}

export type Support = {
  title: string;
  icon: string;
  info: {
    [key: string]: string
  },
}

export type SupportBlock = Omit<Landing, 'data'> & {
  data: Support[]
}

export type Icons = {
  [T: string]: JSX.Element;
};

type ChoiceList = {
  index: string;
  label: string;
  params: { ChoiceCode: number; ObjectState: string; Title: string };
};

export type ChoiceLists = {
  [T: string]: ChoiceList;
};

export type DataModal = {
  header: string;
  text: string[] | string;
  ok: string;
  cancel: string;
};

type ContactData = {
  title: string;
  info: string[];
  icon: string;
};

export type Contact = {
  label: string;
  info: string;
  data: ContactData[];
};

export type FilterCheckbox = {
  label?: string;
  index: string;
  checked?: boolean;
  value?: string;
  params?: { ChoiceCode: number; ObjectState: string; Title: string };
};

export interface DOMEvent<T extends EventTarget> extends Event {
  readonly target: T;
}
