import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#FFEAD1' />
    <rect
      x='19.8008'
      y='7'
      width='4'
      height='18.1009'
      rx='2'
      transform='rotate(45 19.8008 7)'
      fill='#FF8B00'
    />
    <rect
      x='7'
      y='9.82812'
      width='4'
      height='18.1009'
      rx='2'
      transform='rotate(-45 7 9.82812)'
      fill='#FF8B00'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.45455 16L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V15C20 15.5523 19.5523 16 19 16H4.45455ZM2 15.3851L3.76282 14H18V2H2V15.3851ZM11.4142 8L13.8891 10.4749L12.4749 11.8891L10 9.4142L7.52513 11.8891L6.11091 10.4749L8.5858 8L6.11091 5.52513L7.52513 4.11091L10 6.58579L12.4749 4.11091L13.8891 5.52513L11.4142 8Z" fill="#BD2A17"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
