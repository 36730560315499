import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { ISort } from 'interfaces/Table';

export const nullSort: ISort = {
  nameColumn: null,
  orderDirection: null,
};

export const useTableViewHandleChange = () => {
  const [sort, setSort] = useState<ISort>(nullSort);

  const resetSort = useCallback(() => {
    setSort((prevState: ISort) => {
      if (_.isEqual(prevState, nullSort)) return prevState;

      return { ...nullSort };
    });
  }, []);

  const handleTableChange = (
    pagination: {},
    filters: {},
    sorter: { order: string; field: string }
  ) => {
    if (sorter.order !== undefined) {
      setSort((prevState: ISort) => {
        const newState: ISort = { nameColumn: sorter.field, orderDirection: sorter.order };

        if (_.isEqual(newState, prevState)) return prevState;

        return newState;
      });
    } else {
      resetSort();
    }
  };

  return { handleTableChange, sort, resetSort };
};
