import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='0.875em' height='1em' viewBox='0 0 36 40' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 7.77778V4.44444H13.3333C13.3333 3.2657 13.8016 2.13524 14.6351 1.30175C15.4686 0.468252 16.599 0 17.7778 0C18.9565 0 20.087 0.468252 20.9205 1.30175C21.754 2.13524 22.2222 3.2657 22.2222 4.44444H25.5556V7.77778H10ZM35.5556 6.66667V37.7778C35.5556 38.3671 35.3214 38.9324 34.9047 39.3491C34.4879 39.7659 33.9227 40 33.3333 40H2.22222C1.63285 40 1.06762 39.7659 0.650874 39.3491C0.234127 38.9324 0 38.3671 0 37.7778V6.66667C0 6.0773 0.234127 5.51207 0.650874 5.09532C1.06762 4.67857 1.63285 4.44444 2.22222 4.44444H6.66667V11.1111H28.8889V4.44444H33.3333C33.9227 4.44444 34.4879 4.67857 34.9047 5.09532C35.3214 5.51207 35.5556 6.0773 35.5556 6.66667ZM28.238 31.762L21.5344 25.0586C22.1322 23.8199 22.3299 22.426 22.1001 21.07C21.8702 19.714 21.2243 18.463 20.2518 17.4905C19.2793 16.518 18.0284 15.872 16.6724 15.6422C15.3164 15.4124 13.9224 15.61 12.6838 16.2078L17.1091 20.6331L13.9663 23.7758L9.54102 19.3504C8.94327 20.589 8.74562 21.983 8.97544 23.339C9.20527 24.695 9.8512 25.9459 10.8237 26.9185C11.7962 27.891 13.0472 28.5369 14.4032 28.7667C15.7592 28.9966 17.1531 28.7989 18.3918 28.2012L25.0953 34.9047C25.5124 35.3199 26.0771 35.5527 26.6656 35.5521C27.2541 35.5514 27.8183 35.3173 28.2345 34.9012C28.6506 34.4851 28.8847 33.9209 28.8854 33.3324C28.886 32.7439 28.6532 32.1791 28.238 31.762Z' />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
