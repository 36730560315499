import { cloneDeep } from 'lodash';
import { ActionType, Action } from '../actionTypes';

const initialState = {
  buttons: {},
  currentTab: '',
};

const editorButtons = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_EDITOR_TAB: {
      const newState = { ...state };
      newState.currentTab = action.payload;
      return { ...newState };
    }

    case ActionType.UPDATE_EDITOR_ALL_BUTTONS: {
      const newState = cloneDeep(state);
      newState.buttons = action.payload;
      return newState;
    }

    case ActionType.UPDATE_EDITOR_BUTTONS: {
      const newState = { ...state };
      newState.buttons[action.payload.tab] = action.payload.buttons;
      return { ...newState };
    }
    default:
      return state;
  }
};

export default editorButtons;
