import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const configsSelector = (state: AppState, key: string) => {
  return state.searchConfigurations[key];
};

export const getSearchConfiguration = createSelector([configsSelector], (config) => {
  return config;
});
