import { useWindowWidth } from '@react-hook/window-size';
import TabsCrud from 'controllers/TabsCrud';

type UseTabsCrud = (args: { className?: string; objectID?: string }) => {
  getTabsData: () => Promise<any>;
};

export const useTabsCrud: UseTabsCrud = ({ className, objectID }) => {
  const width = useWindowWidth();

  const getTabsData = () =>
    TabsCrud.getTabsData({
      className,
      objectID,
      width,
    });

  return {
    getTabsData,
  };
};

export default useTabsCrud;
