import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg
    width='0.9em'
    height='0.8em'
    viewBox='0 0 24 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
    focusable='false'
  >
    <path
      d='M2.8134 0L11.9719 9.27901L21.1304 0L23.9438 2.87063L11.9719 15.0001L0 2.87063L2.8134 0Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg
    width='0.9em'
    height='0.8em'
    viewBox='0 0 24 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
    focusable='false'
    transform='rotate(270)'
  >
    <path
      d='M2.8134 0L11.9719 9.27901L21.1304 0L23.9438 2.87063L11.9719 15.0001L0 2.87063L2.8134 0Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
