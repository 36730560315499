import React, { MutableRefObject } from 'react';
import ControlFactory from 'utils/ControlFactory';
import { RefStoreType, ToggleProcessKeyWrapperType } from '../../../hooks/useRefStore';
import { FormInstance } from 'antd';
import { SaveObject } from 'interfaces/Object';

export interface EntityEditorContextData {
  className?: any;
  form?: FormInstance;
  documentId: string;
  factory?: ControlFactory | null;
  onFormFinish?: SaveObject;
  onValuesChange?: (changedValues: any, allValues?: any) => void;
  refStore?: MutableRefObject<RefStoreType>;
  toggleProcessKeyWrapper?: ToggleProcessKeyWrapperType;
  inModal?: boolean;
  parentTableId?: string;
  cardForm?: FormInstance;
}

const initialState = { documentId: '' };
const EntityEditorContext = React.createContext<EntityEditorContextData>(initialState);

export default EntityEditorContext;
