import { Input } from 'antd';
import styles from './Search.module.css';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../store/reducers';
import SearchIcon from '../../icons/SearchIcon';
import { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import notification from 'components/messages/notification';
import { useWindowWidth } from '@react-hook/window-size';
import CascadeFilter from '../CascadeFilter/CascadeFilter';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { ICascadeFilterItem, ItemType } from 'utils/types';
import { useHistory } from "react-router-dom";
import composeLink from 'utils/composeLink';
import {deflate} from 'pako';

export const branchMapperForServer = (items: ItemType[]): ICascadeFilterItem[] => {
  return items.map(item => {
    let clone: any = {...item}
    if (!!clone.children && clone.children.length > 0) {
      clone.children = branchMapperForServer(clone.children);
    }
    const {onTitleClick: _, label: __, ...otherProps} = clone;
    return otherProps
  })
}

export interface SearchProps {
  placeholder: string;
}

const Search: React.FC<SearchProps> = (props) => {
  const curPlaceholder = props.placeholder;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [items, setItems] = useState<ItemType[]>([]);
  const width = useWindowWidth();
  const history = useHistory();
  const [cascadeFilterBranch, setCascadeFilterBranch] = useState<ItemType | null>(null);

  const handleInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    dispatch({
      type: 'UPDATE_VALUE',
      payload: target.value,
    });
  };

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('desktopName', 'default');
    axios.post('/CascadeFilter/', bodyFormData).then((response: AxiosResponseExt) => {
      if (response.error) {
        notification.error({
          text: response.error,
          width,
        });
      } else {
        setItems(response.data);
      }
    });
  }, []);

  const onSearch = async (value: string) => {
    if (!!value && !!cascadeFilterBranch) {
      const compressed = deflate(JSON.stringify(branchMapperForServer([cascadeFilterBranch])[0]))
      history.push(composeLink({
        search: value,
        cascadeFilterBranch: compressed.toString()
      }))
    }
  }

  const onCascadeFilterChange = (value: ItemType) => {
    setCascadeFilterBranch(value);
  }

  return (
    <div
      className={styles.search}
      style={{border: process.env.REACT_APP_TYPE === 'csdp' ? '2px solid var(--color-light-2)' : 'none'}}
    >
      {!!items ? <CascadeFilter items={items} onSelectBranch={onCascadeFilterChange} /> : <></>}
      <Search
        enterButton={'Найти'}
        onChange={handleInput}
        placeholder={curPlaceholder}
        prefix={<SearchIcon className={styles.iconSearch} />}
        onSearch={onSearch}
      />
    </div>
  );
};

export default connect((state: AppState) => {
  return {};
})(Search);
