import axios from 'axios';
import { Service } from 'axios-middleware';
import { Store } from 'redux';
import { ActionType } from 'store/actionTypes';
import keycloak from '../keycloak';

export const baseUrl: string = `/api`;

const registerAxiosMiddleware = (appStore: Store) => {
  axios.defaults.baseURL = baseUrl;

  const handleError = (error: any) => {
    if (axios.isCancel(error) || error.message === 'Request aborted') {
      return;
    }
    let message: any = error.message ? error.message : error.response.data;

    if (error && error.response && error.response.data) {
      try {
        const parsedData = JSON.parse(error.response.data);
        if (parsedData && parsedData.message) {
          message = parsedData.message;
        }
      } catch {
        /* no-op */
      }
    }

    return Promise.resolve({
      error: message,
    });
  };

  const service = new Service(axios);

  service.register({
    onRequest(config: any) {
      return {
        ...config,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
          'X-User-Localtime-Offset': -new Date().getTimezoneOffset(),
          Authorization: `Bearer ${keycloak.token}`,
          ...config.headers,
        },
      };
    },
    onRequestError(error: any) {
      return handleError(error);
    },
    onResponseError(error: any) {
      if (error && error.response && error.response.status === 401) {
        appStore.dispatch({
          type: ActionType.UPDATE_USER,
          payload: { id: false },
        });
        return Promise.resolve({
          error: `Пользователь не авторизован`,
        });
      }
      return handleError(error);
    },
  });
};

export default registerAxiosMiddleware;
