import EngineeringTechnicalRequestIcon from '../../../components/icons/EngineeringTechnicalRequestIcon';
import AddAnswerIcon from '../../../components/icons/AddAnswerIcon';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import EDIcon from '../../../components/icons/EDIcon';

export function handleIcon(controller: any) {
  let resStatus: any;

  // Создать связанный запрос
  if (controller === 'createLinkedObjectCaseTool') {
    resStatus = <EngineeringTechnicalRequestIcon />;
  }
  // Использовать БЗ
  else if (controller === 'useKnowledgeBase') {
    resStatus = <EDIcon />;
  }
  // Прикрепить сообщение
  else if (controller === 'addAnswer') {
    resStatus = <AddAnswerIcon />;
  }
  // Формирование ISR
  else if (controller === 'parameterReportTool') {
    resStatus = <DownloadIcon />;
  }
  // Такого контроллера нет
  else {
    resStatus = '';
  }

  return resStatus;
}
