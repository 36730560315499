import React from 'react'
import styles from './AppBlock.module.css';

interface IAppBlockProps {
  children: React.ReactNode
}

function AppBlock({children}: IAppBlockProps) {
  return (
    <div className={styles.appBlock}>{children}</div>
  )
}

export default React.memo(AppBlock)