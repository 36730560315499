import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 38 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.24649 5.24102H3.93136V32.1365H1.99688C0.936037 32.1365 0 31.0757 0 29.7028V4.49219C0.624025 4.99141 1.37286 5.24102 2.24649 5.24102Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M25.7098 3.93136C18.7831 3.93136 13.3541 3.93136 2.18409 3.93136C0.99844 3.93136 0 3.05772 0 1.93448C0.0624025 0.873635 0.99844 0 2.24649 0H25.7722C25.3354 0.561623 25.0858 1.24805 25.0858 1.99688C25.0234 2.68331 25.273 3.36974 25.7098 3.93136Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M5.17969 5.23828V32.1338H15.4761C15.1017 31.8217 14.8521 31.3225 14.8521 30.7609V27.5784C14.8521 26.5799 15.6633 25.7687 16.6617 25.7063L17.3482 25.6439C17.473 25.3319 17.5978 25.0823 17.7226 24.8327L17.2234 24.3334C16.5369 23.5846 16.5993 22.4614 17.2858 21.7749L19.5323 19.5285C20.2187 18.842 21.3419 18.7796 22.0908 19.4661L22.59 19.9653C22.8396 19.8405 23.1516 19.7157 23.4012 19.6533L23.4636 18.9668C23.526 17.9684 24.3373 17.1572 25.3357 17.1572H25.7101V5.23828H5.17969ZM16.1625 21.2133H8.98624C8.61183 21.2133 8.36222 20.9013 8.36222 20.5893C8.36222 20.2773 8.67423 19.9653 8.98624 19.9653H16.1625C16.5369 19.9653 16.7866 20.2773 16.7866 20.5893C16.7866 20.9013 16.5369 21.2133 16.1625 21.2133ZM21.966 17.5316H8.98624C8.61183 17.5316 8.36222 17.2196 8.36222 16.9075C8.36222 16.5331 8.67423 16.2835 8.98624 16.2835H21.966C22.3404 16.2835 22.59 16.5955 22.59 16.9075C22.59 17.282 22.3404 17.5316 21.966 17.5316ZM21.966 13.8498H8.98624C8.61183 13.8498 8.36222 13.5378 8.36222 13.2258C8.36222 12.8514 8.67423 12.6018 8.98624 12.6018H21.966C22.3404 12.6018 22.59 12.9138 22.59 13.2258C22.59 13.5378 22.3404 13.8498 21.966 13.8498ZM21.966 10.1681H8.98624C8.61183 10.1681 8.36222 9.85607 8.36222 9.54405C8.36222 9.16964 8.67423 8.92003 8.98624 8.92003H21.966C22.3404 8.92003 22.59 9.23204 22.59 9.54405C22.59 9.85607 22.3404 10.1681 21.966 10.1681Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M37.1292 27.0178L35.5067 26.9554C35.3195 26.1442 35.0075 25.3953 34.5707 24.6465L35.6315 23.4609C35.8187 23.2112 35.8187 22.8992 35.6315 22.6496L33.385 20.4031C33.1354 20.1535 32.8234 20.1535 32.5738 20.4031L31.3881 21.464C30.7017 21.0272 29.8905 20.7151 29.0792 20.5279L29.0168 18.9679C29.0168 18.6559 28.7672 18.4062 28.4552 18.4062H25.2727C24.9607 18.4062 24.7111 18.6559 24.7111 18.9679L24.6487 20.5903C23.8374 20.7775 23.0886 21.0896 22.3398 21.5264L21.1541 20.4655C20.9045 20.2783 20.5925 20.2783 20.3429 20.4655L18.0964 22.712C17.9092 22.9616 17.8468 23.2736 18.0964 23.5233L19.1572 24.7089C18.7204 25.3953 18.4084 26.2066 18.2212 27.0178L16.5987 27.0802C16.2867 27.0802 16.0371 27.3298 16.0371 27.6418V30.8243C16.0371 31.1364 16.2867 31.386 16.5987 31.386L18.2212 31.4484C18.4084 32.2596 18.7204 33.0084 19.1572 33.7573L18.0964 34.9429C17.9092 35.1925 17.9092 35.5669 18.1588 35.7541L18.2212 35.8165L20.4053 38.0006C20.6549 38.2502 20.9669 38.2502 21.2165 38.0006L22.4022 36.9398C23.151 37.3766 23.8998 37.6886 24.7111 37.8758L24.7735 39.4359C24.7735 39.7479 25.0231 39.9975 25.3351 39.9975H28.5176C28.8296 39.9975 29.0792 39.7479 29.0792 39.4359L29.1416 37.8758C29.9529 37.6886 30.7017 37.3766 31.4505 36.9398L32.6362 38.0006C32.8858 38.1878 33.1978 38.1878 33.4474 38.0006L35.6939 35.7541C35.9435 35.5045 35.9435 35.1925 35.6939 34.9429L34.5707 33.6949C35.0075 33.0084 35.3195 32.1972 35.5067 31.386L37.0668 31.3236C37.3788 31.3236 37.6284 31.074 37.6284 30.7619V27.5794C37.6284 27.2674 37.4412 27.0178 37.1292 27.0178ZM30.8265 29.2019C30.8265 31.3236 29.0792 33.0708 26.8951 33.0708C24.7735 33.0708 23.0262 31.3236 23.0262 29.2019C23.0262 27.0802 24.7735 25.3329 26.8951 25.3329C29.0168 25.2705 30.8265 27.0178 30.8265 29.2019Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
