import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import styles from './ModalControl.module.css';
import CloseIcon from '../../icons/CloseIcon';
import buttonStyle from '../../../pages/entityEditor/Buttons/EntityEditorButtons.module.css'

export interface ModalProps {
  onOk?: any;
  onCancel?: () => void;
  title: string;
  cancelText?: string;
  okText: string;
  okButtonProps?: boolean;
  cancelButtonProps?: boolean;
  children: JSX.Element;
  isVisible: boolean;
}

const ModalControl: React.FC<ModalProps> = (props) => {
  const {
    onOk,
    onCancel,
    title,
    okText,
    okButtonProps,
    cancelButtonProps,
    children,
    isVisible,
    cancelText,
  } = props;

  return (
    <Modal
      className='modal-onlytext'
      title={title}
      okText={okText}
      cancelText={cancelText}
      closable={true}
      centered={true}
      okButtonProps={{
        disabled: okButtonProps,
      }}
      cancelButtonProps={{
        disabled: cancelButtonProps,
      }}
      visible={isVisible}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      onOk={onOk}
      footer={
     [
      <div className={buttonStyle.button} onClick={onOk}>{okText}</div>,
      cancelText ? <div className={buttonStyle.button}  onClick={onCancel}>{cancelText}</div>: <></>
     ]
      }
    >
      {children}
    </Modal>
  );
};

export default ModalControl;
