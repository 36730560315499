import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 37 30' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.2275 7.285L13.1512 10.2088L13.6983 9.66176L10.7707 6.73797C10.6575 6.62479 10.7707 6.22867 10.7707 6.22867L8.79008 4.52344L8.00537 5.30815L9.71437 7.285C9.71437 7.285 10.1294 7.19069 10.2275 7.285Z' />
    <path d='M17.3541 11.9395L15.4263 13.8673L19.6177 18.0511C19.6983 18.1321 19.7941 18.1964 19.8996 18.2402C20.0052 18.2841 20.1183 18.3066 20.2326 18.3066C20.3469 18.3066 20.46 18.2841 20.5656 18.2402C20.6711 18.1964 20.7669 18.1321 20.8475 18.0511L21.5304 17.3683C21.6114 17.2876 21.6756 17.1918 21.7195 17.0863C21.7633 16.9808 21.7859 16.8676 21.7859 16.7533C21.7859 16.6391 21.7633 16.5259 21.7195 16.4204C21.6756 16.3148 21.6114 16.219 21.5304 16.1384L17.3541 11.9395Z' />
    <path d='M20.0629 7.91508C19.9539 8.02407 19.8245 8.11053 19.6821 8.16952C19.5397 8.22851 19.3871 8.25887 19.2329 8.25887C19.0788 8.25887 18.9262 8.22851 18.7838 8.16952C18.6413 8.11053 18.512 8.02407 18.403 7.91508C18.294 7.80608 18.2075 7.67669 18.1485 7.53428C18.0895 7.39187 18.0592 7.23924 18.0592 7.0851C18.0592 6.93096 18.0895 6.77833 18.1485 6.63592C18.2075 6.49351 18.294 6.36411 18.403 6.25512L20.2402 4.43671C19.8319 4.24778 19.3886 4.14626 18.9387 4.13867C18.0763 4.14557 17.2512 4.49121 16.6414 5.101C16.0317 5.7108 15.686 6.53587 15.6791 7.39823C15.6867 7.8481 15.7882 8.29143 15.9772 8.69978L8.34135 16.3205C8.23235 16.4295 8.14589 16.5589 8.08691 16.7013C8.02792 16.8437 7.99756 16.9963 7.99756 17.1505C7.99756 17.3046 8.02792 17.4573 8.08691 17.5997C8.14589 17.7421 8.23235 17.8715 8.34135 17.9805C8.45034 18.0895 8.57974 18.1759 8.72215 18.2349C8.86455 18.2939 9.01719 18.3243 9.17133 18.3243C9.32547 18.3243 9.4781 18.2939 9.62051 18.2349C9.76292 18.1759 9.89231 18.0895 10.0013 17.9805L17.6333 10.3409C18.0417 10.5298 18.485 10.6313 18.9349 10.6389C19.7973 10.632 20.6223 10.2864 21.2321 9.67659C21.8419 9.06679 22.1876 8.24172 22.1945 7.37936C22.1821 6.9284 22.0755 6.48502 21.8813 6.07781L20.0629 7.91508Z' />
    <path d='M25.2766 0H4.90442C3.60369 0 2.35623 0.516715 1.43647 1.43647C0.516714 2.35623 0 3.60369 0 4.90442V18.4859C0 19.7866 0.516714 21.0341 1.43647 21.9539C2.35623 22.8736 3.60369 23.3903 4.90442 23.3903H11.9857L18.1916 29.5058C18.5011 29.8183 18.9214 29.9959 19.3612 30C19.5977 30.0014 19.831 29.9444 20.0402 29.834C20.2848 29.7062 20.4841 29.5062 20.611 29.2612C20.7379 29.0162 20.7863 28.738 20.7495 28.4645L20.6174 23.3903H25.2766C26.5774 23.3903 27.8248 22.8736 28.7446 21.9539C29.6643 21.0341 30.1811 19.7866 30.1811 18.4859V4.90442C30.1811 3.60369 29.6643 2.35623 28.7446 1.43647C27.8248 0.516715 26.5774 0 25.2766 0ZM27.9175 18.4859C27.9175 19.1863 27.6393 19.858 27.144 20.3533C26.6487 20.8485 25.977 21.1267 25.2766 21.1267H19.4441C19.2929 21.1267 19.1433 21.1569 19.0039 21.2157C18.8646 21.2744 18.7385 21.3605 18.633 21.4688C18.5275 21.5772 18.4448 21.7055 18.3897 21.8463C18.3346 21.9871 18.3083 22.1376 18.3124 22.2887L18.4255 26.5593L13.2457 21.4512C13.0334 21.2426 12.7474 21.126 12.4497 21.1267H4.90442C4.20403 21.1267 3.53232 20.8485 3.03706 20.3533C2.54181 19.858 2.26358 19.1863 2.26358 18.4859V4.90442C2.26358 4.20403 2.54181 3.53232 3.03706 3.03706C3.53232 2.54181 4.20403 2.26358 4.90442 2.26358H25.2766C25.977 2.26358 26.6487 2.54181 27.144 3.03706C27.6393 3.53232 27.9175 4.20403 27.9175 4.90442V18.4859Z' />
    <circle cx='29.3999' cy='22.5' r='7.5' fill='var(--color-additional-elements)' />
    <rect x='29.1001' y='19.2012' width='1.2' height='6.6' rx='0.6' fill='white' />
    <rect
      x='26.3999'
      y='23.1016'
      width='1.2'
      height='6.6'
      rx='0.6'
      transform='rotate(-90 26.3999 23.1016)'
      fill='white'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 37 30' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2275 7.285L13.1512 10.2088L13.6983 9.66176L10.7707 6.73797C10.6575 6.62479 10.7707 6.22867 10.7707 6.22867L8.79008 4.52344L8.00537 5.30815L9.71437 7.285C9.71437 7.285 10.1294 7.19069 10.2275 7.285Z'
      fill='white'
    />
    <path
      d='M17.3541 11.9395L15.4263 13.8673L19.6177 18.0511C19.6983 18.1321 19.7941 18.1964 19.8996 18.2402C20.0052 18.2841 20.1183 18.3066 20.2326 18.3066C20.3469 18.3066 20.46 18.2841 20.5656 18.2402C20.6711 18.1964 20.7669 18.1321 20.8475 18.0511L21.5304 17.3683C21.6114 17.2876 21.6756 17.1918 21.7195 17.0863C21.7633 16.9808 21.7859 16.8676 21.7859 16.7533C21.7859 16.6391 21.7633 16.5259 21.7195 16.4204C21.6756 16.3148 21.6114 16.219 21.5304 16.1384L17.3541 11.9395Z'
      fill='white'
    />
    <path
      d='M20.0629 7.91508C19.9539 8.02407 19.8245 8.11053 19.6821 8.16952C19.5397 8.22851 19.3871 8.25887 19.2329 8.25887C19.0788 8.25887 18.9262 8.22851 18.7838 8.16952C18.6413 8.11053 18.512 8.02407 18.403 7.91508C18.294 7.80608 18.2075 7.67669 18.1485 7.53428C18.0895 7.39187 18.0592 7.23924 18.0592 7.0851C18.0592 6.93096 18.0895 6.77833 18.1485 6.63592C18.2075 6.49351 18.294 6.36411 18.403 6.25512L20.2402 4.43671C19.8319 4.24778 19.3886 4.14626 18.9387 4.13867C18.0763 4.14557 17.2512 4.49121 16.6414 5.101C16.0317 5.7108 15.686 6.53587 15.6791 7.39823C15.6867 7.8481 15.7882 8.29143 15.9772 8.69978L8.34135 16.3205C8.23235 16.4295 8.14589 16.5589 8.08691 16.7013C8.02792 16.8437 7.99756 16.9963 7.99756 17.1505C7.99756 17.3046 8.02792 17.4573 8.08691 17.5997C8.14589 17.7421 8.23235 17.8715 8.34135 17.9805C8.45034 18.0895 8.57974 18.1759 8.72215 18.2349C8.86455 18.2939 9.01719 18.3243 9.17133 18.3243C9.32547 18.3243 9.4781 18.2939 9.62051 18.2349C9.76292 18.1759 9.89231 18.0895 10.0013 17.9805L17.6333 10.3409C18.0417 10.5298 18.485 10.6313 18.9349 10.6389C19.7973 10.632 20.6223 10.2864 21.2321 9.67659C21.8419 9.06679 22.1876 8.24172 22.1945 7.37936C22.1821 6.9284 22.0755 6.48502 21.8813 6.07781L20.0629 7.91508Z'
      fill='white'
    />
    <path
      d='M25.2766 0H4.90442C3.60369 0 2.35623 0.516715 1.43647 1.43647C0.516714 2.35623 0 3.60369 0 4.90442V18.4859C0 19.7866 0.516714 21.0341 1.43647 21.9539C2.35623 22.8736 3.60369 23.3903 4.90442 23.3903H11.9857L18.1916 29.5058C18.5011 29.8183 18.9214 29.9959 19.3612 30C19.5977 30.0014 19.831 29.9444 20.0402 29.834C20.2848 29.7062 20.4841 29.5062 20.611 29.2612C20.7379 29.0162 20.7863 28.738 20.7495 28.4645L20.6174 23.3903H25.2766C26.5774 23.3903 27.8248 22.8736 28.7446 21.9539C29.6643 21.0341 30.1811 19.7866 30.1811 18.4859V4.90442C30.1811 3.60369 29.6643 2.35623 28.7446 1.43647C27.8248 0.516715 26.5774 0 25.2766 0ZM27.9175 18.4859C27.9175 19.1863 27.6393 19.858 27.144 20.3533C26.6487 20.8485 25.977 21.1267 25.2766 21.1267H19.4441C19.2929 21.1267 19.1433 21.1569 19.0039 21.2157C18.8646 21.2744 18.7385 21.3605 18.633 21.4688C18.5275 21.5772 18.4448 21.7055 18.3897 21.8463C18.3346 21.9871 18.3083 22.1376 18.3124 22.2887L18.4255 26.5593L13.2457 21.4512C13.0334 21.2426 12.7474 21.126 12.4497 21.1267H4.90442C4.20403 21.1267 3.53232 20.8485 3.03706 20.3533C2.54181 19.858 2.26358 19.1863 2.26358 18.4859V4.90442C2.26358 4.20403 2.54181 3.53232 3.03706 3.03706C3.53232 2.54181 4.20403 2.26358 4.90442 2.26358H25.2766C25.977 2.26358 26.6487 2.54181 27.144 3.03706C27.6393 3.53232 27.9175 4.20403 27.9175 4.90442V18.4859Z'
      fill='white'
    />
    <circle cx='29.3999' cy='22.5' r='7.5' fill='white' />
    <rect x='29.1001' y='19.2012' width='1.2' height='6.6' rx='0.6' fill='var(--color-additional-elements)' />
    <rect
      x='26.3999'
      y='23.1016'
      width='1.2'
      height='6.6'
      rx='0.6'
      transform='rotate(-90 26.3999 23.1016)'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
