import { ActionType, Action } from '../actionTypes';

export interface EditorResponsesInfoState {
  selected?: string;
}

const initialState: EditorResponsesInfoState = {};

export const editorResponses = (
  state: EditorResponsesInfoState = initialState,
  action: Action<ActionType, any>
) => {
  switch (action.type) {
    case ActionType.UPDATE_EDITOR_RESPONSES_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default editorResponses;
