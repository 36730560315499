import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 41 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M31.2357 5.80469H2.71446C1.21774 5.80469 0 6.96269 0 8.38598V25.7695C0 27.1928 1.21774 28.3508 2.71446 28.3508H5.6122C5.87285 28.3508 6.11314 28.4844 6.24143 28.6994L8.23706 32.0281C8.59342 32.6226 9.23488 32.9867 9.95575 33.0002C9.97 33.0002 9.98425 33.0002 10.0005 33.0002C10.7031 33.0002 11.3425 32.6652 11.7152 32.0959L13.9735 28.6723C14.1079 28.4709 14.338 28.3489 14.5885 28.3489H31.2377C32.7344 28.3489 33.9522 27.1909 33.9522 25.7676V8.38598C33.9501 6.96269 32.7324 5.80469 31.2357 5.80469ZM32.6489 25.7695C32.6489 26.5112 32.0156 27.1134 31.2357 27.1134H14.5885C13.8859 27.1134 13.2445 27.4504 12.8718 28.0158L10.6135 31.4394C10.418 31.7396 10.1105 31.7648 9.98426 31.7628C9.86004 31.7609 9.55255 31.7202 9.36928 31.4143L7.37365 28.0855C7.01321 27.4852 6.33918 27.1115 5.6122 27.1115H2.71446C1.93454 27.1115 1.30123 26.5092 1.30123 25.7676V8.38598C1.30123 7.64432 1.93454 7.04208 2.71446 7.04208H31.2357C32.0156 7.04208 32.6489 7.64432 32.6489 8.38598V25.7695Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M38.2825 0H9.76134C8.26462 0 7.04688 1.1425 7.04688 2.54674V3.374C7.04688 3.71216 7.33807 3.98537 7.69851 3.98537C8.05894 3.98537 8.35014 3.71216 8.35014 3.374V2.54674C8.35014 1.815 8.98345 1.22083 9.76337 1.22083H38.2846C39.0645 1.22083 39.6978 1.815 39.6978 2.54674V19.6976C39.6978 20.4293 39.0645 21.0235 38.2846 21.0235H37.0852C36.7247 21.0235 36.4335 21.2967 36.4335 21.6349C36.4335 21.973 36.7247 22.2462 37.0852 22.2462H38.2846C39.7813 22.2462 40.999 21.1037 40.999 19.6995V2.54674C40.997 1.1425 39.7793 0 38.2825 0Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M26.9022 11.1855H7.05788C6.69745 11.1855 6.40625 11.7932 6.40625 12.5453C6.40625 13.2975 6.69745 13.9051 7.05788 13.9051H26.9042C27.2647 13.9051 27.5558 13.2975 27.5558 12.5453C27.5538 11.7932 27.2626 11.1855 26.9022 11.1855Z'
      fill='var(--color-primary)'
    />
    <path
      d='M26.9022 16.3223H7.05788C6.69745 16.3223 6.40625 16.9299 6.40625 17.682C6.40625 18.4342 6.69745 19.0418 7.05788 19.0418H26.9042C27.2647 19.0418 27.5558 18.4342 27.5558 17.682C27.5538 16.9299 27.2626 16.3223 26.9022 16.3223Z'
      fill='var(--color-primary)'
    />
    <path
      d='M26.9022 21.4473H7.05788C6.69745 21.4473 6.40625 22.0549 6.40625 22.807C6.40625 23.5592 6.69745 24.1668 7.05788 24.1668H26.9042C27.2647 24.1668 27.5558 23.5592 27.5558 22.807C27.5538 22.0549 27.2626 21.4473 26.9022 21.4473Z'
      fill='var(--color-primary)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
