import './global.css'; // !!!IMPORTANT!!! Must import first
import { useKeycloak } from '@react-keycloak/web';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Col, Layout, Row } from 'antd';
import 'antd/dist/antd.css';
import { Content } from 'antd/lib/layout/layout';
import 'assets/fonts/lato/font.css';
import 'assets/fonts/stolzl/font.css';
import axios from 'axios';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import Header from 'components/layout/header/Header';
import LeftMenu from 'components/navigation/leftmenu/LeftMenu';
import UserAgreement from 'components/userAgreement/UserAgreement';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import Routes from 'router/Routes';
import { AppState } from 'store/reducers';
import FormatUtils from 'utils/FormatUtils';
import styles from './App.module.css';
import { AxiosResponseExt } from './interfaces/AxiosResponseExt';
import { ActionType } from './store/actionTypes';
import { useCrumbs } from 'hooks';
import Login from 'components/login/Login';
import notification from 'components/messages/notification';
import { useWindowSize } from '@react-hook/window-size';
import CacheStorage from 'utils/CacheStorage';

const { Sider } = Layout;

interface AppProps {
  user: any;
  menu?: any;
}

function ErrorFallback(errors: any) {
  const { error } = errors;
  return (
    <div role='alert'>
      <p>Ошибка:</p>
      <pre style={{ color: 'var(--color-bright-9)' }}>{error.message}</pre>
    </div>
  );
}

const App: React.FC<AppProps> = (props) => {
  const [userAgreementVisible, setUserAgreementVisible] = useState<boolean>(false);
  const { keycloak, initialized } = useKeycloak();
  const currentYear: number = new Date().getFullYear();

  const dispatch = useDispatch();
  const { initCrumbs } = useCrumbs();
  const location = useLocation();
  const username = useSelector((state: AppState) => state.typeUser.userData?.user?.params?.Name);
  const [isResetPass, setIsResetPass] = useState(false);
  const isLoggedIn: any = keycloak.authenticated;
  const haveInboxGroup: any = Object.values(props.menu.inboxGroups).length > 0;
  const [width] = useWindowSize();
  const history = useHistory();

  const getUserInfo = () => {
    return new Promise<boolean>(async (resolve) => {
      axios
        .get('/UserInfo')
        .then((userInfo: AxiosResponseExt) => {
          if (userInfo.error) {
            dispatch({
              type: ActionType.UPDATE_USER,
              payload: { id: false },
            });
            resolve(false);
          } else {
            dispatch({
              type: ActionType.UPDATE_USER_DATA,
              payload: userInfo.data || {},
            });
            resolve(true);
          }
        })
        .catch((err: any) => {
          dispatch({
            type: ActionType.UPDATE_USER,
            payload: { id: false },
          });
          dispatch({
            type: ActionType.UPDATE_USER_DATA,
            payload: {},
          });
          resolve(false);
        });
    });
  };



  useEffect(() => {
    if (initialized) {
  
      axios.get('contentCrud/getFrontTheme')
        .then((res: AxiosResponseExt) => {
          // add default theme
          require('./index.css');
          if (res.error) {
            notification.error({
              text: res.error,
              width,
            });
          } else {
            // add theme from server
            const themeFromServer = document.createElement('link');
            themeFromServer.setAttribute('href', `${res.data.contentURL}`);
            themeFromServer.setAttribute('rel', 'stylesheet');
            themeFromServer.setAttribute('type', 'text/css');
            document.head.appendChild(themeFromServer);
          }
        })
      }


  }, [initialized])

  useEffect(() => {
    if (!isLoggedIn && initialized && !location.pathname.includes('reset-pass')) {
      keycloak.logout();
      axios.get('/auth/logout')
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { id: false },
      });
      CacheStorage.clearAll();
    }

  }, [isLoggedIn, initialized])

  useEffect(() => {
    if (isResetPass === true) return
    if (isLoggedIn) {
      if (haveInboxGroup) {
        getUserInfo();
        if (location.pathname.includes('reset-pass')) {
          history.push('/');
        }
      }
    } else if (isLoggedIn !== undefined && !isLoggedIn) {
      if (location.pathname.includes('reset-pass')) {
        axios
          .get(location.pathname, {
            validateStatus: (status: number) => status >= 200 && status <= 500,
          })
          .then((res: AxiosResponseExt) => {
            if (res.status === 200) {
              setIsResetPass(true);
            } else if (res.status === 404) {
              keycloak.login();
            }
          });
      } else {
        keycloak.login();
      }
    }
  }, [isLoggedIn, props.menu, isResetPass]);

  useEffect(() => {
    if (!username) return;
    const crumbsJSON: string | null = localStorage.getItem('crumbs');
    const crumbsParsed = crumbsJSON && JSON.parse(crumbsJSON);
    const crumbsGroups = crumbsParsed?.groups?.[username];
    const crumbsTimestamps = crumbsParsed?.timestamps;
    const deletedChainsIDs = [];
    if (!crumbsGroups) return;
    if (crumbsTimestamps) {
      for (const chainId in crumbsTimestamps) {
        const timestamp = crumbsTimestamps[chainId];
        if (moment().diff(timestamp, 'hours') >= 2) {
          deletedChainsIDs.push(chainId);
          if (crumbsGroups.chains?.[chainId]) {
            for (const crumbToDelete of crumbsGroups.chains[chainId]) {
              if (crumbsGroups.routes?.[crumbToDelete.path] === chainId) {
                delete crumbsGroups.routes[crumbToDelete.path];
              }
            }

            delete crumbsGroups.chains[chainId];
          }
        }
      }
      if (deletedChainsIDs.length > 0) {
        for (const chainId of deletedChainsIDs) {
          delete crumbsTimestamps[chainId];
        }
        localStorage.setItem('crumbs', JSON.stringify(crumbsParsed));
      }
    }

    initCrumbs(crumbsGroups);
  }, [username]);

  const getDynamicEls = (isLoggedIn: boolean) => {
    const privateLeftMenu = isLoggedIn ? <LeftMenu /> : <></>;
    const privateContent =
      isLoggedIn && haveInboxGroup ? (
        <Layout>
          <Content className={styles.content}>
            <React.Suspense fallback={<>Загрузка...</>}>
              <Switch>
                {Routes.map((routeProps: RouteProps, index: number) => (
                  // key убран, с ним исходные компоненты, например EntityEditor - ремаунтятся при смене урлы
                  // что мешает в некоторых кейсах
                  <Route {...routeProps} />
                ))}
              </Switch>
            </React.Suspense>
          </Content>
        </Layout>
      ) : (
        <></>
      );
    const footer = isLoggedIn ? (
      <></>
    ) : (
      <div className={styles.footer}>
        <div className={styles.col}>
          <Row>
            <Col>
              <div className={styles.image} />
            </Col>
          </Row>
          <Row>
            <UserAgreement
              visible={userAgreementVisible}
              onClose={() => setUserAgreementVisible(false)}
            />
          </Row>
          <Row>
            <Col>
              По всем вопросам обращаться{' '}
              <a href='mailto:RRJservice.IT.support@irkut.com'>RRJservice.IT.support@irkut.com</a>
            </Col>
          </Row>
          <Row>
            <Col>© 2021-{currentYear} ПАО “Корпорация “Иркут” Все права защищены</Col>
          </Row>
        </div>
        <div className={styles.col}>
          <Row>
            <Col>
              <div className={styles.logo} />
            </Col>
          </Row>
        </div>
      </div>
    );
    return {
      privateLeftMenu,
      privateContent,
      footer,
    };
  };

  const { privateLeftMenu, privateContent, footer } = getDynamicEls(isLoggedIn);

  try {
    return (
      <div
        id={'irkut'}
        className={FormatUtils.getClassName([styles.app, !isLoggedIn ? 'guest' : ''])}
        style={{
          borderBottom: process.env.REACT_APP_TYPE === 'csdp' ? '10px solid var(--color-additional-elements)' : 'none',
        }}
      >
        {isLoggedIn ? (
          <>
            <Header />
            <Layout className={styles.layout}>
              <Sider className={styles.sider}>
                <ErrorBoundary>{privateLeftMenu}</ErrorBoundary>
              </Sider>
              <ErrorBoundary>{privateContent}</ErrorBoundary>
            </Layout>
          </>
        ) : isResetPass ? (
          <Login setIsResetPass={setIsResetPass} token={location.pathname.split('/')[3]} />
        ) : (
          <>
            <Layout className={styles.layout}>
              <ErrorBoundary>
                <></>
              </ErrorBoundary>
            </Layout>
          </>
        )}
        {/* {footer} */}
      </div>
    );
  } catch (error) {
    return <ErrorFallback error={error} />;
  }
};

export default connect((state: AppState) => {
  return {
    user: state.user,
    menu: state.menu,
  };
})(App);
