import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width="0.9em" height="1em" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.623 16.5057L21.603 18.204V18.2354C21.7598 18.833 21.6657 19.4934 21.3522 20.0281C21.0386 20.6256 20.5056 21.0345 19.8472 21.2232C19.2201 21.4119 18.5303 21.3175 17.9346 21.003L14.4543 19.0531L14.7678 18.4556L18.2481 20.4055C18.6871 20.6571 19.1574 20.6885 19.6277 20.5627C20.098 20.4369 20.4742 20.1224 20.7251 19.6821C20.8505 19.462 20.9132 19.2104 20.9446 18.9588C21.0386 18.3612 20.7564 17.7322 20.2234 17.4492L11.7892 12.7317C11.7892 12.7317 10.9112 12.1341 9.71978 12.4801C9.71978 12.4801 9.71978 12.4801 9.68843 12.4801C9.46895 12.5115 0.407604 15.1219 0.0313542 15.2162H0L1.9126 21.7893L7.96395 20.0595C8.59103 20.4369 13.2001 23.2989 13.7645 23.6448C14.7991 24.2423 16.0533 23.9278 16.3355 23.8335C16.3669 23.8335 28.689 20.3111 28.689 20.3111C29.5356 20.0595 30.2567 18.9273 29.9118 17.7637C29.6296 16.7887 28.595 16.2226 27.623 16.5057Z" fill="#B0B7BD"/>
    <path d="M28.1741 5.72388H27.0767C26.9199 5.06343 26.6691 4.46588 26.3242 3.89979L27.2021 3.01919C27.2648 2.95629 27.2962 2.86194 27.2962 2.76759C27.2962 2.67324 27.2648 2.57889 27.2021 2.51599L25.9793 1.28945C25.8539 1.16365 25.6344 1.16365 25.509 1.28945L24.5997 2.20149C24.098 1.88699 23.5337 1.66684 22.9693 1.50959V0.345949C22.9693 0.15725 22.8125 0 22.6244 0H20.9313C20.7431 0 20.5864 0.15725 20.5864 0.345949V1.47814C19.9593 1.60394 19.3949 1.85554 18.8932 2.17004L17.9213 1.1951C17.7959 1.0693 17.5764 1.0693 17.451 1.1951L16.2281 2.42164C16.1654 2.48454 16.1341 2.57889 16.1341 2.67324C16.1341 2.76759 16.1654 2.86194 16.2281 2.92484L17.1688 3.86834C16.7925 4.43443 16.5417 5.03198 16.3849 5.72388H15.2875C15.0994 5.72388 14.9426 5.88113 14.9426 6.06983V7.76812C14.9426 7.95682 15.0994 8.11407 15.2875 8.11407H16.3849C16.5103 8.71162 16.7612 9.27772 17.0747 9.78092L16.1968 10.6615C16.1341 10.7244 16.1027 10.8188 16.1027 10.9131C16.1027 11.0075 16.1341 11.1018 16.1968 11.1647L17.4196 12.3913C17.4823 12.4542 17.5764 12.4856 17.6704 12.4856C17.7645 12.4856 17.8586 12.4542 17.9213 12.3913L18.7678 11.5421C19.3322 11.9195 19.9593 12.1711 20.6491 12.3284V13.3033C20.6491 13.492 20.8058 13.6493 20.994 13.6493H22.6871C22.8752 13.6493 23.032 13.492 23.032 13.3033V12.2969C23.6904 12.1397 24.2862 11.8881 24.8192 11.5421L25.5403 12.2655C25.603 12.3284 25.6971 12.3598 25.7912 12.3598C25.8852 12.3598 25.9793 12.3284 26.042 12.2655L27.2334 11.0704C27.2961 11.0075 27.3275 10.9131 27.3275 10.8188C27.3275 10.7244 27.2961 10.6301 27.2334 10.5672L26.4809 9.81237C26.7945 9.30917 27.014 8.74307 27.1707 8.11407H28.2681C28.4563 8.11407 28.613 7.95682 28.613 7.76812V6.06983C28.4876 5.88113 28.3622 5.72388 28.1741 5.72388ZM21.7151 10.2841C19.8652 10.2841 18.3602 8.77452 18.3602 6.91898C18.3602 5.06343 19.8652 3.55384 21.7151 3.55384C23.565 3.55384 25.07 5.06343 25.07 6.91898C25.07 8.77452 23.565 10.2841 21.7151 10.2841Z" fill="var(--color-additional-elements)"/>
  </svg>
);

const ActiveIconSvg = () => (
  <svg width="0.9em" height="1em" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.623 16.5057L21.603 18.204V18.2354C21.7598 18.833 21.6657 19.4934 21.3522 20.0281C21.0386 20.6256 20.5056 21.0345 19.8472 21.2232C19.2201 21.4119 18.5303 21.3175 17.9346 21.003L14.4543 19.0531L14.7678 18.4556L18.2481 20.4055C18.6871 20.6571 19.1574 20.6885 19.6277 20.5627C20.098 20.4369 20.4742 20.1224 20.7251 19.6821C20.8505 19.462 20.9132 19.2104 20.9446 18.9588C21.0386 18.3612 20.7564 17.7322 20.2234 17.4492L11.7892 12.7317C11.7892 12.7317 10.9112 12.1341 9.71978 12.4801C9.71978 12.4801 9.71978 12.4801 9.68843 12.4801C9.46895 12.5115 0.407604 15.1219 0.0313542 15.2162H0L1.9126 21.7893L7.96395 20.0595C8.59103 20.4369 13.2001 23.2989 13.7645 23.6448C14.7991 24.2423 16.0533 23.9278 16.3355 23.8335C16.3669 23.8335 28.689 20.3111 28.689 20.3111C29.5356 20.0595 30.2567 18.9273 29.9118 17.7637C29.6296 16.7887 28.595 16.2226 27.623 16.5057Z" fill="white"/>
    <path d="M28.1741 5.72388H27.0767C26.9199 5.06343 26.6691 4.46588 26.3242 3.89979L27.2021 3.01919C27.2648 2.95629 27.2962 2.86194 27.2962 2.76759C27.2962 2.67324 27.2648 2.57889 27.2021 2.51599L25.9793 1.28945C25.8539 1.16365 25.6344 1.16365 25.509 1.28945L24.5997 2.20149C24.098 1.88699 23.5337 1.66684 22.9693 1.50959V0.345949C22.9693 0.15725 22.8125 0 22.6244 0H20.9313C20.7431 0 20.5864 0.15725 20.5864 0.345949V1.47814C19.9593 1.60394 19.3949 1.85554 18.8932 2.17004L17.9213 1.1951C17.7959 1.0693 17.5764 1.0693 17.451 1.1951L16.2281 2.42164C16.1654 2.48454 16.1341 2.57889 16.1341 2.67324C16.1341 2.76759 16.1654 2.86194 16.2281 2.92484L17.1688 3.86834C16.7925 4.43443 16.5417 5.03198 16.3849 5.72388H15.2875C15.0994 5.72388 14.9426 5.88113 14.9426 6.06983V7.76812C14.9426 7.95682 15.0994 8.11407 15.2875 8.11407H16.3849C16.5103 8.71162 16.7612 9.27772 17.0747 9.78092L16.1968 10.6615C16.1341 10.7244 16.1027 10.8188 16.1027 10.9131C16.1027 11.0075 16.1341 11.1018 16.1968 11.1647L17.4196 12.3913C17.4823 12.4542 17.5764 12.4856 17.6704 12.4856C17.7645 12.4856 17.8586 12.4542 17.9213 12.3913L18.7678 11.5421C19.3322 11.9195 19.9593 12.1711 20.6491 12.3284V13.3033C20.6491 13.492 20.8058 13.6493 20.994 13.6493H22.6871C22.8752 13.6493 23.032 13.492 23.032 13.3033V12.2969C23.6904 12.1397 24.2862 11.8881 24.8192 11.5421L25.5403 12.2655C25.603 12.3284 25.6971 12.3598 25.7912 12.3598C25.8852 12.3598 25.9793 12.3284 26.042 12.2655L27.2334 11.0704C27.2961 11.0075 27.3275 10.9131 27.3275 10.8188C27.3275 10.7244 27.2961 10.6301 27.2334 10.5672L26.4809 9.81237C26.7945 9.30917 27.014 8.74307 27.1707 8.11407H28.2681C28.4563 8.11407 28.613 7.95682 28.613 7.76812V6.06983C28.4876 5.88113 28.3622 5.72388 28.1741 5.72388ZM21.7151 10.2841C19.8652 10.2841 18.3602 8.77452 18.3602 6.91898C18.3602 5.06343 19.8652 3.55384 21.7151 3.55384C23.565 3.55384 25.07 5.06343 25.07 6.91898C25.07 8.77452 23.565 10.2841 21.7151 10.2841Z" fill="white"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
