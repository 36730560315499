import { CancelToken } from 'axios';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { Dispatch } from 'redux';
import { ActionType } from 'store/actionTypes';

export const wrapFile = (file: any, linkedId: string) => ({
  toSave: false,
  file,
  documentId: linkedId || '',
});

export type GetFiles = (args: {
  className: string;
  linkedId: string;
  dispatch?: Dispatch;
  cancelToken?: CancelToken;
  width?: number;
}) => Promise<any>;

export const getFiles: GetFiles = async ({ className, linkedId, dispatch, cancelToken, width }) => {
  const contentResponse: AxiosResponseNull = await ServerAPI.getFiles(
    { property: { className, linkedId } },
    { cancelToken }
  ).catch((serverError: ServerError) => serverError.notify(width));

  const files = contentResponse?.data?.map?.((file: any) => wrapFile(file, linkedId));

  if (files && dispatch) {
    dispatch({
      type: ActionType.UPDATE_EDITOR_FILES,
      payload: files,
    });
  }

  return files;
};

export type AttachFiles = (args: {
  fileIDs?: string[];
  linkedClassName?: string;
  linkedId?: string;
  cancelToken?: CancelToken;
  width?: number;
  dispatch?: Dispatch;
}) => Promise<any>;

export const attachFiles: AttachFiles = async ({
  fileIDs,
  linkedClassName,
  linkedId,
  cancelToken,
  width,
  dispatch,
}) => {
  if (!fileIDs?.length || !linkedClassName || !linkedId) return;

  const response: AxiosResponseNull = await ServerAPI.attachFiles(
    {
      contentIds: fileIDs,
      property: {
        className: linkedClassName,
        linkedId,
      },
    },
    { cancelToken }
  ).catch((serverError: ServerError) => serverError.notify(width));

  if (response && dispatch) {
    // remove uploaded files from store
    dispatch({
      type: ActionType.REMOVE_EDITOR_FILES,
      payload: fileIDs,
    });
  }

  return response?.data;
};

const ContentCrud = {
  getFiles,
  attachFiles,
};

export default ContentCrud;
