import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='4.51621'
      height='16.6379'
      rx='2.2581'
      transform='matrix(0.795237 0.606299 -0.692232 0.721675 18.4092 0)'
    />
    <path d='M5.3174 18.4214C4.44617 18.9869 3.32176 18.1938 3.74464 17.3121L5.40108 13.8581C5.67909 13.2784 6.49336 13.0823 7.0353 13.4646L8.79156 14.7033C9.3335 15.0856 9.30288 15.8344 8.73011 16.2062L5.3174 18.4214Z' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
