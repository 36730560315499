import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.97271 7.52644C6.16684 7.52644 7.13487 6.58389 7.13487 5.42118C7.13487 4.25848 6.16684 3.31592 4.97271 3.31592C3.77858 3.31592 2.81055 4.25848 2.81055 5.42118C2.81055 6.58389 3.77858 7.52644 4.97271 7.52644Z" fill="black"/>
    <path d="M7.94634 18.0528C9.14047 18.0528 10.1085 17.1103 10.1085 15.9475C10.1085 14.7848 9.14047 13.8423 7.94634 13.8423C6.75221 13.8423 5.78418 14.7848 5.78418 15.9475C5.78418 17.1103 6.75221 18.0528 7.94634 18.0528Z" fill="black"/>
    <path d="M8.72973 18.5791H7.18919C5.97297 18.5791 5 19.2896 5 20.3949V22.0002H10.9459V20.3949C10.9189 19.2896 9.94595 18.5791 8.72973 18.5791Z" fill="black"/>
    <path d="M16.0538 18.0528C17.2479 18.0528 18.2159 17.1103 18.2159 15.9475C18.2159 14.7848 17.2479 13.8423 16.0538 13.8423C14.8596 13.8423 13.8916 14.7848 13.8916 15.9475C13.8916 17.1103 14.8596 18.0528 16.0538 18.0528Z" fill="black"/>
    <path d="M15.2702 18.5791H16.8108C18.027 18.5791 19 19.2896 19 20.3949V22.0002H13.0811V20.3949C13.0811 19.2896 14.054 18.5791 15.2702 18.5791Z" fill="black"/>
    <path d="M13.8916 2V7.52632L19.27 5.68421L13.8916 9.10526V11.4737H21.9997V2H13.8916Z" fill="black"/>
    <path d="M13.3514 8.78927C13.3514 8.28927 12.9459 7.89453 12.4324 7.89453C12.2432 7.89453 12.0811 7.94716 11.9459 8.02611C11.9459 8.02611 9.89189 8.99979 9.32432 8.99979C8.75676 8.99979 7.08108 8.05243 6.27027 8.05243C5.45946 8.05243 4.13513 8.05243 4.13513 8.05243C2.91892 8.05243 2 8.8419 2 10.0261V16.2103H4.64865C4.64865 14.1051 5.78378 12.7893 7.94595 12.7893V10.7103C7.94595 10.7103 8.81081 10.9735 9.35135 10.9735C9.89189 10.9735 12.7838 9.63137 12.7838 9.63137C13.1081 9.49979 13.3514 9.184 13.3514 8.78927Z" fill="black"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
