import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5 0C10.7812 0 9.375 1.40625 9.375 3.125V46.875C9.375 48.5938 10.7812 50 12.5 50H43.75C45.4688 50 46.875 48.5938 46.875 46.875V12.5L34.375 0H12.5Z'
      fill='#E2E5E7'
    />
    <path
      d='M37.5 12.5H46.875L34.375 0V9.375C34.375 11.0938 35.7812 12.5 37.5 12.5Z'
      fill='var(--color-dark-2)'
    />
    <path d='M46.875 21.875L37.5 12.5H46.875V21.875Z' fill='#CAD1D8' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
