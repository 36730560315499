import React from 'react';
import styles from './LogoWrap.module.css';
import logo from '../../../images/logo.svg';
import logoCSDP from '../../../images/logo.csdp.svg';

const LogoWrap: React.FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        {
          process.env.REACT_APP_TYPE === 'irk' ?
            <img src={logo} alt='irkut' /> :
            <img src={logoCSDP} alt="csdp" />
        }
      </div>
    </div>
  );
};

export default LogoWrap;
