import React from 'react';
import { Row } from 'antd';
import cn from 'classnames';

import Kpi from 'pages/kpi/Kpi';
import Reports from 'pages/reports/Reports';
import FileModalPreview from 'components/controls/FilesList/FileModalPreview';
import TwoColumnGrid from '../layout/content/TwoColumnGrid';
import EntityListFilters from '../../pages/entityList/entityListFilters/EntityListFilters';
import TableGrid from 'components/TableGrid';
import withComponentRefresh from 'components/hocs/withComponentRefresh';
import SiderWithTabs from '../navigation/Sider/SiderWithTabs';
import TableViewTopPanel from './components/TableViewTopPanel';

import {
  useTableViewDataAggregate,
  useTableViewProcesses,
  useTableViewComponentsProps,
} from 'components/TableView/hooks';

import styles from './index.module.css';

const TableView: React.FC<any> = (props) => {
  const componentData = useTableViewDataAggregate({ props });
  const processesData = useTableViewProcesses({ componentData });

  const {
    entityListFiltersProps,
    tableProps,
    twoColumnGridProps,
    fileModalPreviewProps,
    tableViewTopPanelProps,
  } = useTableViewComponentsProps({ componentData, processesData });

  if (componentData.controllerName === 'GetKPIReportOSC') return <Kpi />;
  if (componentData.controllerName === 'GetReport') return <Reports width={processesData.width} />;
  if (componentData.controllerName === 'GetKPIReportRA') return <Kpi isRA/>;
  if (componentData.controllerName === 'GetReportRA') return <Reports isRA width={processesData.width} />;

  return (
    <>
      {componentData.isShortView ? (
        <>
          {componentData.isCardTable && <TableViewTopPanel {...tableViewTopPanelProps} readonly={props.component?.readonly}/>}
          <EntityListFilters {...entityListFiltersProps} />
          <TableGrid {...tableProps} dataSource={props.component?.readonly && process.env.REACT_APP_TYPE !== 'irk' ? null : tableProps?.dataSource} />
        </>
      ) : (
        <TwoColumnGrid
          {...twoColumnGridProps}
          sider={<SiderWithTabs filters={<EntityListFilters {...entityListFiltersProps} />} />}
        >
          <TableViewTopPanel {...tableViewTopPanelProps} readonly={props.component?.readonly}/>
          <Row
            gutter={[0, 20]}
            className={cn(styles['history-wrap'], 'main-content')}
            style={{ display: 'block' }}
          >
            <div id='wrapTable' className={styles.wrapTable}>
              <TableGrid {...tableProps} />
            </div>
          </Row>
        </TwoColumnGrid>
      )}
      <FileModalPreview {...fileModalPreviewProps} />
    </>
  );
};

export default withComponentRefresh(TableView);
