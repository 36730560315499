import { ActionType } from '../actionTypes';

const initialState: any[] = [];

const tableDate = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GET_TABLE_DATE:
      return action.payload;
    case ActionType.GET_TABLE_COLUMNS:
      return action.payload;
    default:
      return state;
  }
};
export default tableDate;
