import { useWindowWidth } from '@react-hook/window-size';
import ToolsCrud from 'controllers/ToolsCrud';

type UseToolsCrud = (args: { className?: string; objectID?: string }) => {
  getToolsData: () => Promise<any>;
};

export const useToolsCrud: UseToolsCrud = ({ className, objectID }) => {
  const width = useWindowWidth();

  const getToolsData = () =>
    ToolsCrud.getToolsData({
      className,
      objectID,
      width,
    });

  return {
    getToolsData,
  };
};

export default useToolsCrud;
