import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_363_7640)'>
      <path d='M2.36382 8.25935L0.0557585 12.1991V12.1993C-0.00730051 12.3058 -0.0173978 12.4322 0.0281441 12.5461C0.0728631 12.6601 0.168691 12.7509 0.291306 12.7959L2.30383 13.5364V18.6146C2.30466 18.7939 2.42851 18.9531 2.61316 19.0125L11.8454 21.9747C11.9435 22.0084 12.0519 22.0084 12.15 21.9747L21.3823 19.0125C21.5669 18.9531 21.6908 18.7939 21.6916 18.6146V13.5364L23.7089 12.8085C23.8315 12.7637 23.9273 12.6727 23.972 12.5589C24.0174 12.4448 24.0073 12.3184 23.9442 12.2119L21.6361 8.27216V8.27197C21.5801 8.17335 21.484 8.09892 21.3684 8.06472L12.1362 5.52563C12.0484 5.50448 11.9563 5.50448 11.8685 5.52563L2.63622 8.06472C2.52144 8.09476 2.42376 8.16466 2.36379 8.25931L2.36382 8.25935ZM20.7682 18.3141L12.4592 20.9802V12.8L14.1117 15.8427C14.19 15.985 14.3487 16.0749 14.5226 16.0755C14.5811 16.0756 14.639 16.0654 14.6935 16.0458L20.7683 13.8538L20.7682 18.3141ZM11.9976 6.34221L19.6234 8.45812L11.9976 10.574L4.37171 8.45812L11.9976 6.34221ZM3.22691 13.8536L9.30174 16.0456C9.35614 16.0652 9.41405 16.0754 9.47258 16.0752C9.64651 16.0747 9.80518 15.9848 9.88349 15.8425L11.536 12.7998V20.98L3.22698 18.3139L3.22691 13.8536Z' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.6079 2.00002L13.0048 3.09153C13.3355 3.15217 13.6663 3.27346 13.997 3.45539L14.9893 2.7883L16.5107 3.94046L16.0477 5.03197C16.2461 5.27455 16.4446 5.57776 16.5769 5.88099L17.7675 6.00227L18.1644 7.76087L17.0399 8.30666C16.9738 8.60989 16.9076 8.97375 16.8415 9.27697L17.7015 10.0653L16.7092 11.642L15.4524 11.3994C15.1878 11.5814 14.9232 11.8239 14.6585 12.0059L14.7247 13.158L12.8726 13.7645L12.0787 12.7941C11.748 12.8548 11.4172 12.8548 11.0864 12.7941L10.3588 13.7645L8.50662 13.158L8.57278 11.9452C8.24201 11.7633 8.04356 11.5814 7.77895 11.3388L6.52215 11.5814L5.59603 10.0654L6.45599 9.15569C6.32369 8.91312 6.25754 8.54926 6.25754 8.24603L5.13306 7.76087L5.46382 6.00227L6.72062 5.82034C6.85292 5.51711 7.05139 5.27455 7.24984 4.97132L6.78678 3.94046L8.24203 2.7883L9.30046 3.39474C9.63123 3.27345 9.96198 3.15216 10.2927 3.09151L10.6896 2H12.608L12.6079 2.00002ZM11.6156 4.48631C13.6662 4.48631 15.3199 6.00233 15.3199 7.88225C15.3199 9.76217 13.6662 11.2175 11.6156 11.2175C9.63112 11.2175 7.97746 9.7621 7.97746 7.88225C7.97746 6.00239 9.63116 4.48631 11.6156 4.48631V4.48631Z'
      />
    </g>
    <defs>
      <clipPath id='clip0_363_7640'>
        <rect width='24' height='24' />
      </clipPath>
    </defs>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
