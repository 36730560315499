import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.68051 0.557712C3.9136 0.119332 5.34337 0.393622 6.33034 1.38059C7.45127 2.5015 7.65297 4.19354 6.93537 5.51997L17.6441 16.2298L16.2299 17.6441L5.52089 6.93488C4.19432 7.65299 2.50176 7.45149 1.38059 6.33034C0.393345 5.34309 0.119175 3.91278 0.558085 2.67945L2.7948 4.91613C3.38059 5.50191 4.33034 5.50191 4.91612 4.91613C5.50191 4.33034 5.50191 3.38059 4.91612 2.79481L2.68051 0.557712ZM13.0479 2.44125L16.2299 0.673482L17.6441 2.0877L15.8763 5.26968L14.1085 5.62323L11.9872 7.74459L10.573 6.33034L12.6943 4.20902L13.0479 2.44125ZM6.33034 10.573L7.74458 11.9872L2.44125 17.2905C2.05073 17.681 1.41756 17.681 1.02704 17.2905C0.664415 16.9279 0.638515 16.356 0.949335 15.9635L1.02704 15.8763L6.33034 10.573Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
