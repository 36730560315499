import { Button, Collapse } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from './MainPage.module.css';
import collapseStyles from '../../components/controls/Collapse/Collapse.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import { useHistory } from 'react-router';
import CardItem from '../../components/card/CardItem';
import axios from 'axios';
import notification from 'components/messages/notification';
import { useWindowWidth } from '@react-hook/window-size';
import { CardElem, Landing } from '../../utils/types';
import landing from '../../images/landing.svg';
import SectionContact from '../../components/sectionContact/SectionContact';
import useIsVisibleHook from '../../hooks/UseIsVisibleHook/useIsVisibleHook';
import { connect } from 'react-redux';
import { AppState } from '../../store/reducers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';

interface MainPage {
  menu?: any;
}

const MainPage: React.FC<MainPage & RouteComponentProps> = (props) => {
  const [dataLanding, setDataLanding] = useState<Landing[]>([]);
  const [activePanel, setActivePanel] = useState('');
  const [appType, setAppType] = useState<'irk' | 'csdp'>('irk');
  const width = useWindowWidth();
  const history = useHistory();
  const { Panel } = Collapse;

  useEffect(() => {
    document.title ='RADiUS'
    if (process.env.REACT_APP_TYPE === 'csdp') {
      document.title = 'КИС ППО';
      setAppType('csdp');
    }
  }, []);

  useEffect(() => {
    if (Object.values(props.menu.inboxGroups).length > 0) {
      axios
        .get('/Landing')
        .then((res: AxiosResponseExt) => {
          if (res.error) {
            notification.error({
              text: res.error,
              width,
            });
          } else {
            setDataLanding(res.data);
          }
        })
        .catch((err) => {
          notification.error({
            text: '',
            width,
          });
        });
    }
  }, [props.menu]);

  const [targetRefImg, isVisibleImg]: any = useIsVisibleHook({
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  });
  const [targetRefNews, isVisibleNews]: any = useIsVisibleHook({
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [targetRefContacts, isVisibleContacts]: any = useIsVisibleHook({
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  });

  useEffect(() => {
    if (isVisibleImg) {
      setActivePanel(targetRefImg.current.id);
    } else if (isVisibleNews) {
      setActivePanel(targetRefNews.current.id);
    } else if (isVisibleContacts) {
      setActivePanel(targetRefContacts.current.id);
    } else {
      setActivePanel('');
    }
  }, [isVisibleImg, isVisibleNews, isVisibleContacts]);

  return (
    <>
      <div className={styles.wrapPage}>
        {dataLanding && (
          <>
            <nav className={styles.navigatePanel}>
              {dataLanding.map((block: Landing, i: number) => {
                return (
                  <a
                    key={i}
                    style={activePanel === block.id ? { borderBottom: '2px solid var(--color-primary)' } : {}}
                    href={block.link}
                    className={styles.elementNavigate}
                    onClick={() => setActivePanel(block.id)}
                  >
                    {block.label}
                  </a>
                );
              })}
            </nav>

            <div className={`${styles.wrapContent} test`}>
              {
                process.env.REACT_APP_TYPE === 'irk' && <img className={styles.img} src={landing} id={'about'} ref={targetRefImg} />
              }
              <div className={styles.wrapSection}>
                {dataLanding.map((block: Landing, n: number) => {
                  if (
                    block.id !== 'support' &&
                    block.id !== 'about' &&
                    block.id !== 'info' &&
                    block.id !== 'questions'
                  ) {
                    return (
                      <section
                        id={block.id}
                        className={styles.wrapTitle}
                        key={n}
                        ref={targetRefNews}
                      >
                        <div className={styles.title}>{block.label}</div>
                        <div className={block.id !== 'questions' ? styles.cardsContainer : ''}>
                          {block.data?.map((item: CardElem, i: number) => {
                            if (block.id !== 'questions') {
                              return <CardItem key={i} {...item} />;
                            } else {
                              return (
                                <></>
                                // <Collapse
                                //   className={collapseStyles.collapse}
                                //   ghost={true}
                                //   expandIcon={({ isActive }) => (
                                //     <AccordionIcon active={isActive === true} />
                                //   )}
                                //   key={`${i}`}
                                // >
                                //   <Panel
                                //     className={styles.panel}
                                //     header={
                                //       <div className={styles.wrapTitlePanel}>
                                //         {/*<div>{item.label}</div>*/}
                                //         <div className={styles.categoryQuestion}>
                                //           {item.category}
                                //         </div>
                                //       </div>
                                //     }
                                //     key={`table_panel_${i}`}
                                //   >
                                //     <div className={styles.infoQuestion}>{item.info}</div>
                                //   </Panel>
                                // </Collapse>
                              );
                            }
                          })}
                        </div>
                        <Button
                          onClick={() => history.push(`${block.tool?.link}`)}
                          className={styles.blockButton}
                        >
                          {block.tool?.label}
                        </Button>
                      </section>
                    );
                  } else if (block.id === 'support') {
                    return (
                      <section id={block.id} className={styles.support} ref={targetRefContacts}>
                        <div className={`${styles.imageSupport} ${process.env.REACT_APP_TYPE === 'csdp' && styles.noBg}`} />
                        <SectionContact
                          block={block}
                          cssClassWrap={styles.supportInfo}
                          cssClassSupportContact={styles.supportContact}
                          cssClassSupportContactsWrap={styles.supportContactsWrap}
                        />
                      </section>
                    );
                  }
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default connect((state: AppState) => {
  return {
    menu: state.menu,
  };
})(withRouter(MainPage));
