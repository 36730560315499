import { DataType } from './BaseComponentInterface';

export interface TableField {
  choiceName?: string;
  className?: string;
  colspan: number;
  dataController?: any;
  daysOfWeekDisabled?: any;
  dependChoice?: any;
  dependValue?: any;
  fieldName: string;
  fieldTable?: any;
  forbidHolidays: boolean;
  formatSelection?: any;
  hidden: boolean;
  jsonConfig?: any;
  label: string;
  mapField: boolean;
  maxWidth?: number;
  readonly: boolean;
  renderer?: string;
  skipChoiceProcessing: boolean;
  sortable: boolean;
  style?: string;
  template?: string;
  virtual: boolean;
  width?: string | number;
  isFiltered?: boolean;
  // dataType?:DataType | undefined
}

export enum OrderDiretion {
  DESK = 'DESC',
  ASK = 'ASK',
}

export interface TableOrder {
  fieldName: string;
  direction: OrderDiretion;
}

export interface TableConfiguration {
  checkboxMode?: any;
  configTreeData?: any;
  fields: TableField[];
  id: string;
  isEditable: boolean;
  maxCount: number;
  name: string;
  orderBy: TableOrder;
  paginationEnabled: boolean;
  params: any;
  rowsPerPage: number;
  subscription?: any;
  viewConfig: string;
}

export interface EntityListProps {
  menu: any;
  choiceLists?: any;
  inboxName: any;
  className: any;
  isShortView: boolean;
  filters?: any;
  meta?: any;
  typeUser?: any;
  isReset?: boolean;
  isSearch?: boolean;
  setIsSearch?: (isSearch: boolean) => void;
  setIsReset?: (isReset: boolean) => void;
}

export const MAX_SIZE = 2560;
export const SMALL_SIZE = 1499;
