import React, { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '@react-hook/window-size';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { isArray } from 'lodash';

import styles from 'pages/entityList/EntityList.module.css';

import { ShowModalEdit, ShowModalReadOnly } from 'hooks/useModalForm';
import { TransitionType } from 'interfaces/CardTransition';
import { TableField } from 'interfaces/EntityList';
import { TableRecord } from 'interfaces/Table';
import { ModalType } from 'store/reducers/modal';
import FormatUtils from 'utils/FormatUtils';
import MiscUtils from 'utils/MiscUtils';
import { drawFieldName } from '../draw/linkContainer';

export type LinkContainerPropsType = {
  fieldName: any;
  record: TableRecord;
  field: TableField;
  refreshID?: string;
  showModalEdit?: ShowModalEdit;
  showModalCopy?: ShowModalEdit;
  showModalReadOnly?: ShowModalReadOnly;
};

export const LinkContainer: FC<LinkContainerPropsType> = ({
  fieldName,
  record,
  field,
  showModalEdit,
  showModalCopy,
  showModalReadOnly,
  refreshID,
}) => {
  const [withTooltip, setWithTooltip] = useState<boolean>(false);
  const [width] = useWindowSize();
  const linkRef = useRef<any>(null);
  const containerId: string = `listItem_${record.id ? record.id : record.ID}_${field.fieldName}`;
  const value: string[] = `${MiscUtils.notNullString(fieldName, '')}`.split(' ');
  let hasLargeWords: boolean = false;
  value.forEach((v: string) => {
    if (v.length > 300) hasLargeWords = true;
  });

  useEffect(() => {
    const target = linkRef?.current;

    const parent = linkRef?.current?.parentElement;

    setWithTooltip(
      target.offsetWidth >
        parent.offsetWidth -
          (parent.className === 'ant-table-cell' ? (window.innerWidth > 1500 ? 30 : 4) : 0) ||
        (isArray(fieldName) && fieldName.length > 1)
    );
  }, [fieldName, width]);

  const getLinkPath = useCallback(() => {
    const to = '#';
    switch (record['.transitionType']) {
      case TransitionType.MODAL:
      case TransitionType.NEW_TAB:
        return to;
    }
    return record['.link'] || to;
  }, [record['.link'], record['.transitionType']]);

  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    switch (record['.transitionType']) {
      case TransitionType.NONE: {
        event.preventDefault();
        return;
      }
      case TransitionType.SOME_TAB:
      case TransitionType.SAME_TAB: {
        if (!record['.link']) event.preventDefault();
        return;
      }
      case TransitionType.NEW_TAB: {
        event.preventDefault();
        if (record['.link']) window.open(record['.link'], '_blank')?.focus();
        return;
      }
      case TransitionType.MODAL: {
        event.preventDefault();
        if (!record || !record.id || !record.classType) return;
        // const modalDetails: any = FormatUtils.getObjectDataByTableRecord(record);
        switch (record['.modalType']) {
          case ModalType.EDIT: {
            return showModalEdit?.({
              objectId: record.id,
              objectClassName: record.classType,
              parentClassName: record.parentClassType,
              refreshIDs: refreshID,
            });
          }
          case ModalType.COPY: {
            return showModalCopy?.({
              objectId: record.id,
              objectClassName: record.classType,
              parentClassName: record.parentClassType,
              refreshIDs: refreshID,
            });
          }
          case ModalType.READONLY: {
            return showModalReadOnly?.({
              objectId: record.id,
              objectClassName: record.classType,
              parentClassName: record.parentClassType,
              refreshIDs: refreshID,
            });
          }
        }
      }
    }
  };

  const link = (
    <Link
      ref={linkRef}
      to={getLinkPath()}
      id={containerId}
      key={containerId}
      className={styles.link}
      onClick={onClick}
    >
      {drawFieldName(fieldName)}
    </Link>
  );

  if (!withTooltip) return link;

  return (
    <Tooltip
      color={'var(--color-light)'}
      placement={'leftBottom'}
      overlayClassName={cn(styles.tooltip, {
        [styles.tooltipArrayContainer]: isArray(fieldName),
        [styles.tooltipWordBreak]: hasLargeWords,
      })}
      title={
        field.style === 'multiselect' || isArray(fieldName) ? (
          <div className={styles.tooltipArray}>
            {isArray(fieldName)
              ? fieldName.map((value: string, index: number) => <div key={index}>{value}</div>)
              : drawFieldName(fieldName)}
          </div>
        ) : (
          fieldName
        )
      }
    >
      {link}
    </Tooltip>
  );
};
