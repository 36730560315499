import { Modal } from 'antd';
import axios from 'axios';
import FileModalPreview from 'components/controls/FilesList/FileModalPreview';
import { Agreement } from 'components/login/Login';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import React, { useEffect, useState } from 'react';
import { result } from 'lodash';

interface UserAgreementProps {
  visible: boolean;
  onClose: () => void;
}

const UserAgreement: React.FC<UserAgreementProps> = (props) => {
  const { visible, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [pdf, setPdf] = useState<any>();
  const [pdfResult, setPdfResult] = useState<any>();

  useEffect(() => {
    if (!pdf && !pdfResult && visible) {
      getAgreement().then((response: AxiosResponseExt) =>
        getPreview(!response.error ? response.data : null)
      );
    }
  }, [pdf, pdfResult, visible]);

  const getAgreement = () => {
    setLoading(true);
    return axios.get('/Auth/getAgreement');
  };

  const getPreview = (agreement: Agreement) => {
    if (agreement === null && agreement === undefined) {
      setLoading(false);
      return;
    }
    const body: FormData = new FormData();
    const saveParams: any = {
      className: agreement.className,
      linkedId: agreement.VersionSeriesId,
    };
    body.append('contentId', `${agreement.contentId}`);
    body.append('property', JSON.stringify(saveParams));
    return axios
      .post('/contentCrud/preview', body, { responseType: 'arraybuffer' })
      .then((res: any) => {
        if (res.headers['content-type'] === 'application/pdf') {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          setPdf(URL.createObjectURL(blob));
          setPdfResult(undefined);
        } else {
          setPdfResult(window.URL.createObjectURL(new Blob([res.data])));
          setPdf(undefined);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  return (
    <>
      {pdf !== undefined && result !== undefined && (
        <FileModalPreview
          isOpen={visible}
          onClose={onClose}
          result={pdfResult}
          pdf={pdf}
          loading={loading}
        />
      )}
    </>
  );
};

export default UserAgreement;
