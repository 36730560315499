import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.66699 36.668C6.66699 33.1317 8.07175 29.7404 10.5722 27.2399C13.0727 24.7394 16.4641 23.3346 20.0003 23.3346C23.5365 23.3346 26.9279 24.7394 29.4284 27.2399C31.9289 29.7404 33.3337 33.1317 33.3337 36.668H30.0003C30.0003 34.0158 28.9468 31.4723 27.0714 29.5969C25.196 27.7215 22.6525 26.668 20.0003 26.668C17.3482 26.668 14.8046 27.7215 12.9293 29.5969C11.0539 31.4723 10.0003 34.0158 10.0003 36.668H6.66699ZM20.0003 21.668C14.4753 21.668 10.0003 17.193 10.0003 11.668C10.0003 6.14297 14.4753 1.66797 20.0003 1.66797C25.5253 1.66797 30.0003 6.14297 30.0003 11.668C30.0003 17.193 25.5253 21.668 20.0003 21.668ZM20.0003 18.3346C23.6837 18.3346 26.667 15.3513 26.667 11.668C26.667 7.98464 23.6837 5.0013 20.0003 5.0013C16.317 5.0013 13.3337 7.98464 13.3337 11.668C13.3337 15.3513 16.317 18.3346 20.0003 18.3346Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
