import React from 'react'

function ETRMessageIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="24.6359" cy="25" rx="24.6359" ry="25" fill="#D4F0FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0127 26.6279L39.2896 15H9.98216L23.259 26.6279C24.0458 27.3721 25.2259 27.3721 26.0127 26.6279ZM30.0449 26.1628L27.5863 28.3023C26.7995 29.0465 25.7177 29.4186 24.6359 29.4186C23.554 29.4186 22.4722 29.0465 21.5871 28.3023L19.2268 26.1628L9.88381 35H39.3879L30.0449 26.1628ZM31.8152 24.6744L41.0598 33.4186V16.4884L31.8152 24.6744ZM17.4565 24.6744L8.21191 16.4884V33.4186L17.4565 24.6744Z" fill="#004D77"/>
    </svg>
  )
}

export default ETRMessageIcon