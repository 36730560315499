import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='25' cy='25' r='24.5' fill='white' stroke='var(--color-additional-elements)' />
    <path
      d='M27.984 9.176V22.904C27.984 24.36 27.904 25.8 27.744 27.224C27.6 28.648 27.392 30.128 27.12 31.664H22.656C22.384 30.128 22.168 28.648 22.008 27.224C21.864 25.8 21.792 24.36 21.792 22.904V9.176H27.984ZM20.904 40.52C20.904 39.992 21 39.496 21.192 39.032C21.4 38.552 21.68 38.144 22.032 37.808C22.384 37.456 22.792 37.184 23.256 36.992C23.736 36.784 24.256 36.68 24.816 36.68C25.36 36.68 25.864 36.784 26.328 36.992C26.808 37.184 27.224 37.456 27.576 37.808C27.928 38.144 28.208 38.552 28.416 39.032C28.624 39.496 28.728 39.992 28.728 40.52C28.728 41.064 28.624 41.568 28.416 42.032C28.208 42.496 27.928 42.904 27.576 43.256C27.224 43.592 26.808 43.856 26.328 44.048C25.864 44.24 25.36 44.336 24.816 44.336C24.256 44.336 23.736 44.24 23.256 44.048C22.792 43.856 22.384 43.592 22.032 43.256C21.68 42.904 21.4 42.496 21.192 42.032C21 41.568 20.904 41.064 20.904 40.52Z'
      fill='#F00808'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
