import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LocationState, Pathname } from 'history';
import { ActionType } from 'store/actionTypes';
import { DispatchType } from 'interfaces/Misc';
import { getFilteredLocationPath } from 'utils/NavUtils';
import { AppState } from 'store/reducers';
import { ChainIdType, CrumbsStoreType } from 'store/reducers/crumbs';

type CrumbType = {
  path: Pathname;
  title?: string;
  versionSeriesId?: string;
  newList: boolean;
  ensureLast?: boolean;
};

type AddCrumbType = (args?: {
  versionSeriesId?: string;
  title?: string;
  path?: string;
  ensureLast?: boolean;
}) => void;

type InitCrumbsType = (payload: InitCrumbsPayloadType) => void;
type SetActiveChainIdType = (activeChainId: ChainIdType) => void;
type UpdateCrumbsDispatchType = (args: CrumbType) => void;
type CrumbsChainType = CrumbType[];
type InitCrumbsPayloadType = CrumbsChainType[];
type GetDetailsTitle = (crumbs: CrumbsStoreType) => string;

type UseCrumbsDispatchType = DispatchType<
  CrumbType | InitCrumbsPayloadType | { activeChainId: ChainIdType; path: string }
>;

export const useCrumbs = () => {
  const dispatch = useDispatch<UseCrumbsDispatchType>();
  const location = useLocation<LocationState>();
  const path = getFilteredLocationPath(location);
  const username = useSelector((state: AppState) => state.typeUser.userData?.user?.params?.Name);

  const updateCrumbs: UpdateCrumbsDispatchType = useCallback(
    (payload) => {
      if (!username) return;
      dispatch({
        type: ActionType.UPDATE_CRUMBS,
        payload: {
          ...payload,
          username,
        },
      });
    },
    [dispatch, username]
  );

  const addCrumb: AddCrumbType = useCallback(
    (args) => {
      updateCrumbs({
        title: args?.title,
        path: args?.path || path,
        versionSeriesId: args?.versionSeriesId,
        newList: false,
        ensureLast: args?.ensureLast,
      });
    },
    [path, updateCrumbs]
  );

  const addCrumbWithNewChain: AddCrumbType = useCallback(
    (args) => {
      updateCrumbs({
        title: args?.title,
        path: args?.path || path,
        versionSeriesId: args?.versionSeriesId,
        newList: true,
        ensureLast: args?.ensureLast,
      });
    },
    [path, updateCrumbs]
  );

  const initCrumbs: InitCrumbsType = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.INIT_CRUMBS,
        payload,
      });
    },
    [dispatch]
  );

  const setActiveChainId: SetActiveChainIdType = useCallback(
    (activeChainId) => {
      dispatch({
        type: ActionType.CRUMBS_SET_ACTIVE_CHAIN_ID,
        payload: {
          activeChainId,
          path,
        },
      });
    },
    [dispatch, path]
  );

  const getDetailsTitle: GetDetailsTitle = useCallback(
    (crumbs) => {
      const nullResult = '';

      const chainId = crumbs.routes[location.pathname];
      if (!chainId) return nullResult;

      const chain = crumbs.chains[chainId];
      if (!chain) return nullResult;

      const item = chain.find((item) => item.path === location.pathname);
      return item?.title || nullResult;
    },
    [location.pathname]
  );

  return { addCrumb, addCrumbWithNewChain, initCrumbs, setActiveChainId, getDetailsTitle };
};
