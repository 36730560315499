import { ActionType, Action } from '../actionTypes';

const initialState: any[] = [];

const editorErrors = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_EDITOR_ERRORS:
      return [...action.payload];
    default:
      return state;
  }
};

export default editorErrors;
