interface ReplacementParams {
  template: string;
  vars: string[];
}

export const buildTitle = (
  inboxName?: string,
  inboxLabel?: any,
  replacements?: ReplacementParams
) => {
  if (inboxLabel && inboxName) {
    if (replacements != null) {
      let result: string = replacements.template.replaceAll(`$_label`, inboxLabel);
      replacements.vars.forEach((v: string, i: number) => {
        result = result.replace(`$${i}`, v);
      });
      return result;
    }
    return inboxLabel;
  }
  return ``;
};

export const getFilteredHash = (hash: string): string => {
  if (hash.startsWith('#state=')) return '';

  if (hash.includes('&state')) {
    hash = hash.split('&state')[0];
  }

  return hash.trim();
};

export const getFilteredLocationPath = ({ pathname, hash }: any) =>
  pathname + getFilteredHash(hash);

const NavUtils = { buildTitle, getFilteredLocationPath };

export default NavUtils;
