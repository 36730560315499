import { createSelector } from 'reselect';
import { AppState } from 'store/reducers';
import { FileSaveParams } from 'store/reducers/editor_files';

const fileSaveParams = (state: AppState) => {
  const params: FileSaveParams = {};
  const details: any = state.editorDetails || {};
  if (details != null && details.classType != null && details.params != null) {
    params.className = details.classType;
    params.VersionSeriesId = details.params.VersionSeriesId;
  }
  return params;
};

const editorRefreshTime = (state: AppState) => {
  return state.editor.refreshTime;
};

export const getFileSaveParams = createSelector([fileSaveParams], (params) => {
  return params;
});

export const getEditorRefreshTime = createSelector([editorRefreshTime], (refresh) => {
  return refresh;
});
