import { isArray } from 'lodash';

export type DrawFieldNameType = (fieldName: string | undefined) => string | undefined;

export const drawFieldName: DrawFieldNameType = (fieldName: string | undefined) => {
  if (fieldName !== undefined) {
    if (isArray(fieldName)) return fieldName.length > 1 ? `...` : fieldName.join();
    const fieldNameAsString = `${fieldName}`;
    if (fieldNameAsString.length > 1000) return `${fieldNameAsString.slice(0, 999)}...`;
    return fieldNameAsString;
  }
};
