import React from 'react';
import styles from './AviaExploit.module.css';
import aeImage from '../../images/aviaExploit.png';

const AviaExploit: React.FC = () => {
  return (
    <div>
      <img src={aeImage} className={styles.img} />
    </div>
  );
};

export default AviaExploit;
