import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg
    width='0.78em'
    height='1em'
    viewBox='0 0 39 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.51648 5.97581H4.21928V32.4304H2.26422C1.19209 32.4304 0.246094 31.3869 0.246094 30.0366V5.23926C0.876757 5.73029 1.63355 5.97581 2.51648 5.97581Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M26.2294 4.68724C19.2291 4.68724 13.7423 4.68724 2.45342 4.68724C1.25516 4.68724 0.246094 3.82792 0.246094 2.72309C0.30916 1.67963 1.25516 0.820312 2.51648 0.820312H26.2925C25.851 1.37273 25.5988 2.04791 25.5988 2.78447C25.5357 3.45965 25.788 4.13482 26.2294 4.68724Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M5.48145 5.9707V32.4253H15.8874C15.509 32.1184 15.2567 31.6273 15.2567 31.0749V27.9446C15.2567 26.9625 16.0766 26.1646 17.0857 26.1032L17.7794 26.0418C17.9055 25.7349 18.0317 25.4894 18.1578 25.2439L17.6533 24.7528C16.9595 24.0163 17.0226 22.9114 17.7163 22.2363L19.9867 20.0266C20.6804 19.3514 21.8156 19.2901 22.5724 19.9652L23.077 20.4563C23.3292 20.3335 23.6446 20.2107 23.8968 20.1494L23.9599 19.4742C24.023 18.4921 24.8428 17.6942 25.8519 17.6942H26.2303V5.9707H5.48145ZM16.5811 21.6839H9.32849C8.9501 21.6839 8.69783 21.377 8.69783 21.0701C8.69783 20.7632 9.01316 20.4563 9.32849 20.4563H16.5811C16.9595 20.4563 17.2118 20.7632 17.2118 21.0701C17.2118 21.377 16.9595 21.6839 16.5811 21.6839ZM22.4463 18.0625H9.32849C8.9501 18.0625 8.69783 17.7556 8.69783 17.4487C8.69783 17.0804 9.01316 16.8349 9.32849 16.8349H22.4463C22.8247 16.8349 23.077 17.1418 23.077 17.4487C23.077 17.8169 22.8247 18.0625 22.4463 18.0625ZM22.4463 14.4411H9.32849C8.9501 14.4411 8.69783 14.1342 8.69783 13.8273C8.69783 13.459 9.01316 13.2135 9.32849 13.2135H22.4463C22.8247 13.2135 23.077 13.5204 23.077 13.8273C23.077 14.1342 22.8247 14.4411 22.4463 14.4411ZM22.4463 10.8197H9.32849C8.9501 10.8197 8.69783 10.5128 8.69783 10.2059C8.69783 9.83761 9.01316 9.59209 9.32849 9.59209H22.4463C22.8247 9.59209 23.077 9.89899 23.077 10.2059C23.077 10.5128 22.8247 10.8197 22.4463 10.8197Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M37.7696 27.3942L36.1298 27.3328C35.9406 26.5349 35.6253 25.7983 35.1838 25.0618L36.256 23.8956C36.4452 23.6501 36.4452 23.3432 36.256 23.0976L33.9856 20.888C33.7333 20.6425 33.418 20.6425 33.1657 20.888L31.9674 21.9314C31.2737 21.5018 30.4539 21.1949 29.634 21.0107L29.5709 19.4762C29.5709 19.1693 29.3187 18.9238 29.0033 18.9238H25.7869C25.4716 18.9238 25.2193 19.1693 25.2193 19.4762L25.1563 21.0721C24.3364 21.2562 23.5796 21.5631 22.8228 21.9928L21.6246 20.9494C21.3723 20.7652 21.057 20.7652 20.8047 20.9494L18.5343 23.159C18.3451 23.4045 18.282 23.7114 18.5343 23.9569L19.6064 25.1232C19.165 25.7983 18.8496 26.5963 18.6604 27.3942L17.0207 27.4556C16.7054 27.4556 16.4531 27.7011 16.4531 28.008V31.1384C16.4531 31.4453 16.7054 31.6908 17.0207 31.6908L18.6604 31.7521C18.8496 32.5501 19.165 33.2866 19.6064 34.0232L18.5343 35.1894C18.3451 35.4349 18.3451 35.8032 18.5974 35.9873L18.6604 36.0487L20.8678 38.197C21.12 38.4425 21.4354 38.4425 21.6876 38.197L22.8859 37.1535C23.6427 37.5832 24.3995 37.8901 25.2193 38.0742L25.2824 39.6087C25.2824 39.9156 25.5347 40.1611 25.85 40.1611H29.0664C29.3817 40.1611 29.634 39.9156 29.634 39.6087L29.6971 38.0742C30.5169 37.8901 31.2737 37.5832 32.0305 37.1535L33.2288 38.197C33.481 38.3811 33.7964 38.3811 34.0486 38.197L36.319 35.9873C36.5713 35.7418 36.5713 35.4349 36.319 35.1894L35.1838 33.9618C35.6253 33.2866 35.9406 32.4887 36.1298 31.6908L37.7065 31.6294C38.0218 31.6294 38.2741 31.3839 38.2741 31.077V27.9466C38.2741 27.6397 38.0849 27.3942 37.7696 27.3942ZM31.3999 29.5425C31.3999 31.6294 29.634 33.348 27.4267 33.348C25.2824 33.348 23.5166 31.6294 23.5166 29.5425C23.5166 27.4556 25.2824 25.737 27.4267 25.737C29.5709 25.6756 31.3999 27.3942 31.3999 29.5425Z'
      fill='var(--color-additional-elements)'
    />
    <ellipse
      cx='26.8585'
      cy='29.0157'
      rx='9.836'
      ry='10.1063'
      transform='rotate(90 26.8585 29.0157)'
      fill='white'
    />
    <path
      d='M26.8387 20.9746H26.8386C26.2966 20.9746 25.8333 21.4056 25.8333 21.9664C25.8333 22.5265 26.2959 22.9582 26.8386 22.9582C28.5159 22.9582 30.0878 23.5933 31.2699 24.7437C32.4514 25.8937 33.0984 27.4172 33.0984 29.0375C33.0984 29.5982 33.5612 30.0293 34.1037 30.0293C34.6454 30.0293 35.1094 29.5993 35.1094 29.0378C35.1094 26.8805 34.2451 24.8529 32.6822 23.3317C31.1196 21.811 29.0428 20.975 26.8387 20.9746Z'
      fill='var(--color-additional-elements)'
      stroke='white'
    />
    <path
      d='M20.4019 38.0874C22.2808 39.3623 24.5088 40.0489 26.8425 40.0489C29.8561 40.0489 32.695 38.9056 34.8302 36.8275C39.2439 32.5319 39.2443 25.5375 34.8303 21.2418C30.4242 16.9532 23.2611 16.9532 18.8551 21.2418C16.7194 23.3204 15.5395 26.0893 15.5395 29.0348C15.5395 31.3063 16.2425 33.473 17.5446 35.2998L7.4175 45.156C7.0219 45.5401 6.82148 46.0483 6.82148 46.5577C6.82148 47.067 7.02192 47.5749 7.41655 47.9594L7.41675 47.9596C8.20304 48.7248 9.47245 48.725 10.2585 47.9595L20.4019 38.0874ZM20.7393 37.7533C20.7395 37.753 20.7393 37.7533 20.739 37.7537L20.7393 37.7533ZM20.7434 34.9516C19.1165 33.3678 18.2239 31.2679 18.2239 29.0345C18.2239 26.8011 19.1165 24.7012 20.7434 23.1174C24.1044 19.8464 29.5803 19.8463 32.9416 23.1174C34.6198 24.7511 35.4567 26.8928 35.4567 29.0345C35.4567 31.1758 34.6195 33.3185 32.9416 34.9515C31.3139 36.5354 29.1498 37.4092 26.8425 37.4092C24.5349 37.4092 22.3708 36.5354 20.7434 34.9516Z'
      fill='var(--color-additional-elements)'
      stroke='white'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg
    width='0.78em'
    height='1em'
    viewBox='0 0 39 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.51648 5.97581H4.21928V32.4304H2.26422C1.19209 32.4304 0.246094 31.3869 0.246094 30.0366V5.23926C0.876757 5.73029 1.63355 5.97581 2.51648 5.97581Z'
      fill='white'
    />
    <path
      d='M26.2294 4.68724C19.2291 4.68724 13.7423 4.68724 2.45342 4.68724C1.25516 4.68724 0.246094 3.82792 0.246094 2.72309C0.30916 1.67963 1.25516 0.820312 2.51648 0.820312H26.2925C25.851 1.37273 25.5988 2.04791 25.5988 2.78447C25.5357 3.45965 25.788 4.13482 26.2294 4.68724Z'
      fill='white'
    />
    <path
      d='M5.48145 5.9707V32.4253H15.8874C15.509 32.1184 15.2567 31.6273 15.2567 31.0749V27.9446C15.2567 26.9625 16.0766 26.1646 17.0857 26.1032L17.7794 26.0418C17.9055 25.7349 18.0317 25.4894 18.1578 25.2439L17.6533 24.7528C16.9595 24.0163 17.0226 22.9114 17.7163 22.2363L19.9867 20.0266C20.6804 19.3514 21.8156 19.2901 22.5724 19.9652L23.077 20.4563C23.3292 20.3335 23.6446 20.2107 23.8968 20.1494L23.9599 19.4742C24.023 18.4921 24.8428 17.6942 25.8519 17.6942H26.2303V5.9707H5.48145ZM16.5811 21.6839H9.32849C8.9501 21.6839 8.69783 21.377 8.69783 21.0701C8.69783 20.7632 9.01316 20.4563 9.32849 20.4563H16.5811C16.9595 20.4563 17.2118 20.7632 17.2118 21.0701C17.2118 21.377 16.9595 21.6839 16.5811 21.6839ZM22.4463 18.0625H9.32849C8.9501 18.0625 8.69783 17.7556 8.69783 17.4487C8.69783 17.0804 9.01316 16.8349 9.32849 16.8349H22.4463C22.8247 16.8349 23.077 17.1418 23.077 17.4487C23.077 17.8169 22.8247 18.0625 22.4463 18.0625ZM22.4463 14.4411H9.32849C8.9501 14.4411 8.69783 14.1342 8.69783 13.8273C8.69783 13.459 9.01316 13.2135 9.32849 13.2135H22.4463C22.8247 13.2135 23.077 13.5204 23.077 13.8273C23.077 14.1342 22.8247 14.4411 22.4463 14.4411ZM22.4463 10.8197H9.32849C8.9501 10.8197 8.69783 10.5128 8.69783 10.2059C8.69783 9.83761 9.01316 9.59209 9.32849 9.59209H22.4463C22.8247 9.59209 23.077 9.89899 23.077 10.2059C23.077 10.5128 22.8247 10.8197 22.4463 10.8197Z'
      fill='white'
    />
    <path
      d='M37.7696 27.3942L36.1298 27.3328C35.9406 26.5349 35.6253 25.7983 35.1838 25.0618L36.256 23.8956C36.4452 23.6501 36.4452 23.3432 36.256 23.0976L33.9856 20.888C33.7333 20.6425 33.418 20.6425 33.1657 20.888L31.9674 21.9314C31.2737 21.5018 30.4539 21.1949 29.634 21.0107L29.5709 19.4762C29.5709 19.1693 29.3187 18.9238 29.0033 18.9238H25.7869C25.4716 18.9238 25.2193 19.1693 25.2193 19.4762L25.1563 21.0721C24.3364 21.2562 23.5796 21.5631 22.8228 21.9928L21.6246 20.9494C21.3723 20.7652 21.057 20.7652 20.8047 20.9494L18.5343 23.159C18.3451 23.4045 18.282 23.7114 18.5343 23.9569L19.6064 25.1232C19.165 25.7983 18.8496 26.5963 18.6604 27.3942L17.0207 27.4556C16.7054 27.4556 16.4531 27.7011 16.4531 28.008V31.1384C16.4531 31.4453 16.7054 31.6908 17.0207 31.6908L18.6604 31.7521C18.8496 32.5501 19.165 33.2866 19.6064 34.0232L18.5343 35.1894C18.3451 35.4349 18.3451 35.8032 18.5974 35.9873L18.6604 36.0487L20.8678 38.197C21.12 38.4425 21.4354 38.4425 21.6876 38.197L22.8859 37.1535C23.6427 37.5832 24.3995 37.8901 25.2193 38.0742L25.2824 39.6087C25.2824 39.9156 25.5347 40.1611 25.85 40.1611H29.0664C29.3817 40.1611 29.634 39.9156 29.634 39.6087L29.6971 38.0742C30.5169 37.8901 31.2737 37.5832 32.0305 37.1535L33.2288 38.197C33.481 38.3811 33.7964 38.3811 34.0486 38.197L36.319 35.9873C36.5713 35.7418 36.5713 35.4349 36.319 35.1894L35.1838 33.9618C35.6253 33.2866 35.9406 32.4887 36.1298 31.6908L37.7065 31.6294C38.0218 31.6294 38.2741 31.3839 38.2741 31.077V27.9466C38.2741 27.6397 38.0849 27.3942 37.7696 27.3942ZM31.3999 29.5425C31.3999 31.6294 29.634 33.348 27.4267 33.348C25.2824 33.348 23.5166 31.6294 23.5166 29.5425C23.5166 27.4556 25.2824 25.737 27.4267 25.737C29.5709 25.6756 31.3999 27.3942 31.3999 29.5425Z'
      fill='var(--color-additional-elements)'
    />
    <ellipse
      cx='26.8585'
      cy='29.0157'
      rx='9.836'
      ry='10.1063'
      transform='rotate(90 26.8585 29.0157)'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M26.8387 20.9746H26.8386C26.2966 20.9746 25.8333 21.4056 25.8333 21.9664C25.8333 22.5265 26.2959 22.9582 26.8386 22.9582C28.5159 22.9582 30.0878 23.5933 31.2699 24.7437C32.4514 25.8937 33.0984 27.4172 33.0984 29.0375C33.0984 29.5982 33.5612 30.0293 34.1037 30.0293C34.6454 30.0293 35.1094 29.5993 35.1094 29.0378C35.1094 26.8805 34.2451 24.8529 32.6822 23.3317C31.1196 21.811 29.0428 20.975 26.8387 20.9746Z'
      fill='white'
      stroke='var(--color-additional-elements)'
    />
    <path
      d='M20.4019 38.0874C22.2808 39.3623 24.5088 40.0489 26.8425 40.0489C29.8561 40.0489 32.695 38.9056 34.8302 36.8275C39.2439 32.5319 39.2443 25.5375 34.8303 21.2418C30.4242 16.9532 23.2611 16.9532 18.8551 21.2418C16.7194 23.3204 15.5395 26.0893 15.5395 29.0348C15.5395 31.3063 16.2425 33.473 17.5446 35.2998L7.4175 45.156C7.0219 45.5401 6.82148 46.0483 6.82148 46.5577C6.82148 47.067 7.02192 47.5749 7.41655 47.9594L7.41675 47.9596C8.20304 48.7248 9.47245 48.725 10.2585 47.9595L20.4019 38.0874ZM20.7393 37.7533C20.7395 37.753 20.7393 37.7533 20.739 37.7537L20.7393 37.7533ZM20.7434 34.9516C19.1165 33.3678 18.2239 31.2679 18.2239 29.0345C18.2239 26.8011 19.1165 24.7012 20.7434 23.1174C24.1044 19.8464 29.5803 19.8463 32.9416 23.1174C34.6198 24.7511 35.4567 26.8928 35.4567 29.0345C35.4567 31.1758 34.6195 33.3185 32.9416 34.9515C31.3139 36.5354 29.1498 37.4092 26.8425 37.4092C24.5349 37.4092 22.3708 36.5354 20.7434 34.9516Z'
      fill='white'
      stroke='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
