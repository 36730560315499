import Icon from '@ant-design/icons';

const IconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#FFEAD1' />
    <rect
      width='5.32993'
      height='19.999'
      rx='2.66497'
      transform='matrix(0.758775 0.651353 -0.648495 0.761218 28.7285 8.16992)'
      fill='#FF8B00'
    />
    <path
      d='M13.4799 31.8958C12.6596 32.4954 11.5716 31.6314 11.9698 30.6966L14.1909 25.4821C14.4496 24.8746 15.2157 24.6802 15.7328 25.0908L18.0877 26.961C18.6048 27.3717 18.589 28.1619 18.0559 28.5515L13.4799 31.8958Z'
      fill='#FF8B00'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 0C17.5523 0 18 0.44772 18 1V4.757L16 6.757V2H2V18H16V15.242L18 13.242V19C18 19.5523 17.5523 20 17 20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H17ZM18.7782 6.80761L20.1924 8.2218L12.4142 16L10.9979 15.9979L11 14.5858L18.7782 6.80761ZM10 10V12H5V10H10ZM13 6V8H5V6H13Z" fill="#E7B728"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
