import { useEffect, useRef, useState } from 'react';

const useIsVisibleHook = (options: any) => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callBack = (entries: any) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBack, options);
    if (targetRef.current) observer.observe(targetRef.current);
    return () => {
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef, options]);
  return [targetRef, isVisible];
};

export default useIsVisibleHook;
