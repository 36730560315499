import * as React from 'react';
import { MinimalButton, Spinner, TextBox } from '@react-pdf-viewer/core';
import {
  Match,
  NextIcon,
  PreviousIcon,
  RenderSearchProps,
  SearchPlugin,
} from '@react-pdf-viewer/search';

enum SearchStatus {
  NotSearchedYet,
  Searching,
  FoundResults,
}

interface SearchSidebarProps {
  searchPluginInstance: SearchPlugin;
}

const SearchSidebar: React.FC<SearchSidebarProps> = ({ searchPluginInstance }) => {
  const [searchStatus, setSearchStatus] = React.useState(SearchStatus.NotSearchedYet);
  const [matches, setMatches] = React.useState<Match[]>([]);

  const { Search } = searchPluginInstance;

  const renderMatchSample = (match: Match) => {
    //  match.startIndex    match.endIndex
    //      |                       |
    //      ▼                       ▼
    //  ....[_____props.keyword_____]....

    const wordsBefore = match.pageText.substr(match.startIndex - 20, 20);
    let words = wordsBefore.split(' ');
    words.shift();
    const begin = words.length === 0 ? wordsBefore : words.join(' ');

    const wordsAfter = match.pageText.substr(match.endIndex, 60);
    words = wordsAfter.split(' ');
    words.pop();
    const end = words.length === 0 ? wordsAfter : words.join(' ');

    return (
      <div>
        {begin}
        <span style={{ backgroundColor: 'var(--color-bright-21)' }}>
          {match.pageText.substring(match.startIndex, match.endIndex)}
        </span>
        {end}
      </div>
    );
  };

  return (
    <Search>
      {(renderSearchProps: RenderSearchProps) => {
        const {
          currentMatch,
          keyword,
          setKeyword,
          jumpToMatch,
          jumpToNextMatch,
          jumpToPreviousMatch,
          search,
        } = renderSearchProps;

        const handleSearchKeyDown = (e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && keyword) {
            setSearchStatus(SearchStatus.Searching);
            search().then((matches) => {
              setSearchStatus(SearchStatus.FoundResults);
              setMatches(matches);
            });
          }
        };

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <div style={{ padding: '.5rem' }}>
              <div style={{ position: 'relative' }}>
                <TextBox
                  placeholder='Введите запрос и нажмите Enter'
                  value={keyword}
                  onChange={setKeyword}
                  onKeyDown={handleSearchKeyDown}
                />
                {searchStatus === SearchStatus.Searching && (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      bottom: 0,
                      position: 'absolute',
                      right: '.5rem',
                      top: 0,
                    }}
                  >
                    <Spinner size='1.5rem' />
                  </div>
                )}
              </div>
            </div>
            {searchStatus === SearchStatus.FoundResults && (
              <>
                {matches.length === 0 && 'Ничего не найдено'}
                {matches.length > 0 && (
                  <>
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        padding: '.5rem',
                      }}
                    >
                      <div
                        style={{
                          color: 'var(--color-dark-7)',
                          fontSize: '.8rem',
                          marginRight: '.5rem',
                        }}
                      >
                        Найдено {matches.length} результата (-ов)
                      </div>
                      <div style={{ marginLeft: 'auto', marginRight: '.5rem' }}>
                        <MinimalButton onClick={jumpToPreviousMatch}>
                          <PreviousIcon />
                        </MinimalButton>
                      </div>
                      <MinimalButton onClick={jumpToNextMatch}>
                        <NextIcon />
                      </MinimalButton>
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid var(--color-light-23)',
                        flex: 1,
                        overflow: 'auto',
                        padding: '.5rem 1rem',
                      }}
                    >
                      {matches.map((match, index) => (
                        <div key={index} style={{ margin: '1rem 0' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '.5rem',
                            }}
                          >
                            <div>#{index + 1}</div>
                            <div
                              style={{
                                color: 'var(--color-dark-7)',
                                fontSize: '.8rem',
                                textAlign: 'right',
                              }}
                            >
                              Страница {match.pageIndex + 1}
                            </div>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                currentMatch === index + 1 ? 'var(--color-light-22)' : '',
                              border: '1px solid var(--color-light-23)',
                              borderRadius: '.25rem',
                              cursor: 'pointer',
                              overflowWrap: 'break-word',
                              padding: '.5rem',
                            }}
                            onClick={() => jumpToMatch(index + 1)}
                          >
                            {renderMatchSample(match)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      }}
    </Search>
  );
};

export default SearchSidebar;
