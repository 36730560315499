import { Typography } from 'antd';
import styles from './CascadeFilter.module.css';
import { Dropdown, Space } from 'antd';
import DropdownIcon from 'components/icons/DropdownIcon';
import { useEffect, useState } from 'react';
import stylesEntityList from 'pages/entityList/EntityList.module.css';
import { ItemType, onClickMenuParamsType } from 'utils/types';

export interface CascadeFilterProps {
  items: ItemType[];
  onSelectBranch: (branch: ItemType) => void;
}

const CascadeFilter: React.FC<CascadeFilterProps> = (props) => {

  const { items, onSelectBranch } = props;
  const [filterValue, setFilterValue] = useState({ value: '', label: '' });
  const [selectedKeys, setSelectedKeys] = useState<string[]>([filterValue.value]);
  const [isOpen, setIsopen] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const getSelectedBranch = (key: string, obj: ItemType[]): ItemType | undefined => {
    let value: ItemType | undefined = undefined;
    obj.some((item) => {
      if (item.key === key) {
          value = item;
          return true;
      }
      let children = item.children;
      if (!!children && children.length > 0) {
          value = getSelectedBranch(key, children);
          return value !== undefined;
      }
    });
    return value;
  }

  const onTitleClick = ({ key, domEvent }: onClickMenuParamsType) => {
    const target = domEvent.target as HTMLDivElement;
    if (target.closest('svg')) {
    } else {
      setIsopen(false);
      if (target.textContent) {
        setFilterValue({ value: key, label: target.textContent });
      }
      setSelectedKeys([...selectedKeys, key]);
      const selectedBranch = getSelectedBranch(key, items);
      if (!!selectedBranch) {
        onSelectBranch(selectedBranch);
      }
    }
  };

  const addActionTitle = (arr: ItemType[]) => {
    arr.forEach((item: ItemType) => {
      item.label = (
        <Typography.Text
          className={styles.readOnlyLabel}
          ellipsis={{
            tooltip: {
              title: item.label,
              children: item.label,
              placement: 'leftBottom',
              arrow: false,
              autoAdjustOverflow: false,
              color: 'var(--color-light)',
              overlayClassName: stylesEntityList.tooltip,
            },
          }}
        >
          {' '}
          {item.label}
        </Typography.Text>
      );
      if (item.children) {
        item.onTitleClick = onTitleClick;
        addActionTitle(item.children);
      }
    });
  };

  const onClickMenu = ({ key, keyPath, domEvent }: onClickMenuParamsType) => {
    const target = domEvent.target as HTMLDivElement;
    if (target.textContent) {
      setFilterValue({ value: key, label: target.textContent });
      setSelectedKeys(keyPath);
      const selectedBranch = getSelectedBranch(key, items);
      if (!!selectedBranch) {
        onSelectBranch(selectedBranch);
      }
    }
  };

  const onOpenChange = (param: boolean) => {
    setIsopen(param);
  };

  useEffect(() => {
    if (filterValue.label !== '') {
      setIsopen(false);
      setOpenKeys([]);
    }
  }, [filterValue]);

  const onOpenChangeSubmenu = (param: string[]) => {
    setOpenKeys(selectedKeys);
    setOpenKeys([...param]);
  };

  useEffect(() => {
    if (items.length > 0) {
      setFilterValue({ value: items[0].key, label: typeof items[0].label === 'string' ? items[0].label : '' });
      onSelectBranch(items[0]);
      items[0].onTitleClick = onTitleClick;
      if (items[0].children) {
        addActionTitle(items[0].children);
      }
    }
  }, [items]);

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={onOpenChange}
      open={isOpen}
      menu={{
        items,
        triggerSubMenuAction: 'click',
        expandIcon: <DropdownIcon className={styles.iconDropDown} active={true} />,
        onClick: onClickMenu,
        openKeys,
        onOpenChange: onOpenChangeSubmenu,
        selectedKeys,
      }}
      overlayClassName={styles.cascadeFilter}
    >
      <Space>
        {filterValue.label}
        <DropdownIcon className={styles.iconDropDown} />
      </Space>
    </Dropdown>
  );
};

export default CascadeFilter;
