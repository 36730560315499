import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8078 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17316 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2.00001 14.6522 3.05358 17.1957 4.92894 19.0711C6.8043 20.9464 9.34784 22 12 22ZM12.0001 5.39365C12.2138 5.39365 12.4227 5.45703 12.6004 5.57578C12.7782 5.69452 12.9167 5.8633 12.9985 6.06077C13.0802 6.25823 13.1017 6.47552 13.06 6.68515C13.0183 6.89478 12.9153 7.08733 12.7642 7.23847C12.6131 7.3896 12.4205 7.49252 12.2109 7.53422C12.0012 7.57592 11.784 7.55452 11.5865 7.47272C11.389 7.39093 11.2203 7.25242 11.1015 7.07471C10.9828 6.89699 10.9194 6.68805 10.9194 6.47432C10.9194 6.3324 10.9473 6.19188 11.0016 6.06077C11.056 5.92965 11.1356 5.81052 11.2359 5.71017C11.3363 5.60982 11.4554 5.53022 11.5865 5.47591C11.7176 5.4216 11.8581 5.39365 12.0001 5.39365ZM9.29807 10.3888C9.68576 9.88131 10.1582 9.44458 10.6946 9.09788C11.7263 8.45978 13.0809 8.75116 13.0809 9.93433V15.8576C13.0809 16.7445 13.6505 16.8632 14.3478 16.5502C14.4062 16.523 14.472 16.5161 14.5348 16.5305C14.5975 16.5449 14.6537 16.5799 14.6943 16.6299C14.735 16.6798 14.7578 16.7419 14.7592 16.8063C14.7605 16.8707 14.7404 16.9337 14.7019 16.9853C14.3142 17.4928 13.8417 17.9296 13.3054 18.2763C12.2737 18.9144 10.9191 18.623 10.9191 17.4398V11.5165C10.9191 10.6297 10.3494 10.5109 9.65212 10.824C9.59374 10.8512 9.52796 10.8581 9.46519 10.8436C9.40243 10.8292 9.34628 10.7942 9.30564 10.7443C9.265 10.6943 9.24218 10.6322 9.24081 10.5678C9.23944 10.5035 9.25959 10.4405 9.29807 10.3888Z" fill="black"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
