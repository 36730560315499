import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance, Row } from 'antd';
import cn from 'classnames';

import BackIcon from 'components/icons/BackIcon';
import { useEditorChanged, useCrumbs } from 'hooks';
import styles from './Title.module.css';

import { AppState } from 'store/reducers';
import { ActionType } from 'store/actionTypes';
import { getFilteredLocationPath } from 'utils/NavUtils';
import { ConfirmObjectSave } from 'interfaces/Object';
import { CrumbsItem } from 'store/reducers/crumbs';

interface TitleProps {
  title?: string;
  handler?: any;
  buttons?: JSX.Element;
  goTo?: string;
  goBack?: any;
  inbox?: string;
  confirmObjectSave?: ConfirmObjectSave;
  form?: FormInstance;
}

const Title: React.FC<TitleProps> = (props) => {
  const { goBack, title: propsTitle, buttons, form, confirmObjectSave } = props;

  const crumbs = useSelector((state: AppState) => state.crumbs);
  const { chains, activeChainId } = crumbs;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { checkEditorChanged } = useEditorChanged({ form });
  const { setActiveChainId } = useCrumbs();
  const path = getFilteredLocationPath(location);

  const [titleChain, setTitleChain] = useState<string[]>([]);


  const allow = () => {
    if (!activeChainId) return;

    const currentCrumbIndex = chains[activeChainId]?.findIndex((i: any) => i.path === path);

    if (currentCrumbIndex && currentCrumbIndex > 0) history.goBack();
  };

  const handleBack = () => {
    dispatch({
      type: ActionType.CLEAR_EDITOR_FILES,
    });

    if (checkEditorChanged() && confirmObjectSave) {
      confirmObjectSave(allow);
      return;
    }

    allow();
  };

  useEffect(() => {
    if (crumbs.activeChainId) return;

    for (const crumbPath in crumbs.routes) {
      if (crumbPath === path) {
        setActiveChainId(crumbs.routes[crumbPath]);
      }
    }
  }, [crumbs.routes, path]);

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) {
      handleBack();
    }
  };

  useEffect(() => {
    if (goBack) document.addEventListener('keydown', handleEsc);

    return () => document.removeEventListener('keydown', handleEsc);
  }, [location]);

  useEffect(() => {
    const activeChain = crumbs.activeChainId ? crumbs?.chains?.[crumbs.activeChainId] : [];
    const activeIndex = activeChain?.findIndex?.((item: any) => item.path === path);
    const newTitleChain = (activeChain?.slice(1, activeIndex + 1) || []).filter(item => !!item.title).map(
      (item) => item.title || ''
    );
    if (newTitleChain.length) {
      newTitleChain.forEach((item: string) => {
        if (item.includes('Профиль пользователя')) {
          setTitleChain([item])
        } else {
          setTitleChain(newTitleChain)
        }
      })

    }
  }, [crumbs, path]);

  useEffect(() => {
    if (props.title !== undefined) {
      document.title = props.title
    } else if (titleChain.length === 1) {
      document.title = titleChain[0];
    } else if (titleChain.length > 1) {
      document.title = titleChain.join('>')
    }
  }, [titleChain, props.title]);

  return (
    <Row className={styles.wrap}>
      <div className={styles.titleArea} />
      {goBack && (
        <div className={styles.back}>
          <div onClick={handleBack}>
            <BackIcon className={styles.icon} />
          </div>
        </div>
      )}
      {propsTitle ? (
        <h2 className={styles.title}>{propsTitle}</h2>
      ) : titleChain.length ? (
        <div className={styles.overflowedBox}>
          {titleChain.map((title: string, index: number) => {
            const last = titleChain.length - 1 === index;
            return (
              <>
                {index > 0 ? <div className={styles.separator}>{' > '}</div> : null}
                <h2 className={cn({ [styles.overflowed]: !last, [styles.title]: styles.title })}>
                  {title}
                </h2>
              </>
            );
          })}
        </div>
      ) : null}
      {buttons ? <div className={styles.buttonsArea}>{buttons}</div> : <></>}
    </Row>
  );
};

export default Title;
