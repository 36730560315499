import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#D4F0FF' />
    <path
      d='M9.2 8.9375C9.2 8.54238 9.54544 8.2 9.98958 8.2H16.837L20.675 11.836V22.0625C20.675 22.4576 20.3296 22.8 19.8854 22.8H9.98958C9.54544 22.8 9.2 22.4576 9.2 22.0625V8.9375Z'
      fill='#AACFE4'
      stroke='#558EAD'
      strokeWidth='0.4'
    />
    <path
      d='M17.9036 11.75H20.8724L16.9141 8V10.8125C16.9141 11.3281 17.3594 11.75 17.9036 11.75Z'
      fill='#558EAD'
    />
    <path d='M20.875 14.5625L17.9062 11.75H20.875V14.5625Z' fill='#7FAFC9' />
    <rect x='11' y='15' width='7' height='1' rx='0.5' fill='#558EAD' />
    <rect x='11' y='13' width='4' height='1' rx='0.5' fill='#558EAD' />
    <rect x='11' y='17' width='7' height='1' rx='0.5' fill='#558EAD' />
    <rect x='11' y='19' width='7' height='1' rx='0.5' fill='#558EAD' />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
