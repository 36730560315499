import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
    <path d="M16.7325 18.0353L13.9421 18.0357L8.96708 11.0181H6.8817L9.16481 18.0366L6.54845 18.037L4.08538 14.251H2L4.08538 20.9993L19.7213 21.0002C19.7221 17.662 16.7325 18.0353 16.7325 18.0353Z" fill="black"/>
    <path d="M21.9995 7.94933C21.9999 6.29434 20.5173 6.47906 20.5173 6.47906H19.1341L16.6674 3H15.6334L16.7655 6.48035H15.4682L14.2469 4.60308H13.2129L14.2469 7.94869L21.9995 7.94933Z" fill="black"/>
    </g>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
