import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg
    width='0.78em'
    height='1em'
    viewBox='0 0 30 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.17113 27.126H23.823V25.3769H6.17113V27.126ZM6.17113 14.8705H23.823V13.1214H6.17113V14.8705ZM24.708 0H5.29205C2.36765 0 0 2.34767 0 5.24738V29.7468C0 32.6465 2.37351 35 5.29205 35H24.708C27.6324 35 30 32.6465 30 29.7468V5.24738C30 2.34767 27.6324 0 24.708 0ZM28.236 29.7468C28.236 31.6819 26.6536 33.2451 24.708 33.2451H5.29205C3.3405 33.2451 1.76402 31.6761 1.76402 29.7468V5.24738C1.76402 3.3123 3.34636 1.74913 5.29205 1.74913H24.708C26.6595 1.74913 28.236 3.31811 28.236 5.24738V29.7468ZM6.17113 21.0012H23.823V19.252H6.17113V21.0012Z'
      fill='var(--color-dark-2)'
    />
    <path d='M23.823 14.8705H6.17113V13.1214H23.823V14.8705Z' fill='var(--color-dark-2)' />
  </svg>
);

const ActiveIconSvg = () => (
  <svg
    width='0.78em'
    height='1em'
    viewBox='0 0 30 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.17113 27.126H23.823V25.3769H6.17113V27.126ZM6.17113 14.8705H23.823V13.1214H6.17113V14.8705ZM24.708 0H5.29205C2.36765 0 0 2.34767 0 5.24738V29.7468C0 32.6465 2.37351 35 5.29205 35H24.708C27.6324 35 30 32.6465 30 29.7468V5.24738C30 2.34767 27.6324 0 24.708 0ZM28.236 29.7468C28.236 31.6819 26.6536 33.2451 24.708 33.2451H5.29205C3.3405 33.2451 1.76402 31.6761 1.76402 29.7468V5.24738C1.76402 3.3123 3.34636 1.74913 5.29205 1.74913H24.708C26.6595 1.74913 28.236 3.31811 28.236 5.24738V29.7468ZM6.17113 21.0012H23.823V19.252H6.17113V21.0012Z'
      fill='white'
    />
    <path d='M23.823 14.8705H6.17113V13.1214H23.823V14.8705Z' fill='white' />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
