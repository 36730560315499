import moment from 'moment';
import { isString } from 'lodash';

export const dateFormats = [
  'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO 8601
  'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  'DD.MM.YYYY HH:MM',
  'DD.MM.YYYY',
  // 'DD/MM/YYYY',
  // 'YYYY-MM-DD',
  // 'DD MMMM YYYY',
  // 'HH:mm:ss',
] as const;

// проверки moment(val, dateFormats, true).isValid() не хватает
// '1234' пройдет как 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export const getDateStringFormat = (val: any): typeof dateFormats[number] | false => {
  if (!isString(val)) return false;

  for (const format of dateFormats) {
    if (val.length === format.length && moment(val, format, true).isValid()) return format;
  }

  return false;
};

export const isDateString = (val: any): boolean => Boolean(getDateStringFormat(val));

export const tryConvertToMoment = (val: any) => {
  const format = getDateStringFormat(val);

  if (!format) return val;

  return moment(val, format);
};

export const tryDateStringify = (val: any) => {
  if (moment.isMoment(val) || moment.isDate(val)) return val.toISOString();
  return val;
};

const DateUtils = {
  dateFormats,
  getDateStringFormat,
  isDateString,
  tryConvertToMoment,
  tryDateStringify,
};

export default DateUtils;
