import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 72 77' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M66.1623 53.5001C62.1847 50.0619 59.0862 48.4607 56.4111 48.4607C53.1545 48.4607 51.0058 50.7989 48.7315 53.2747C48.4753 53.5535 48.2149 53.8374 47.9482 54.1229C47.6291 54.4645 47.2263 54.6239 46.6835 54.6239C44.7544 54.6239 40.6097 52.6463 31.8132 43.2256C27.9242 39.0641 25.0667 35.4979 23.32 32.6252C22.2035 30.7879 20.5212 27.5021 21.8012 26.1311L22.4938 25.3945C26.6322 21.0125 30.207 17.2293 22.225 6.63126C19.4173 2.84228 16.6925 1 13.8926 1C10.1833 1 7.33843 4.21897 4.82885 7.0588C4.37723 7.57043 3.93556 8.06916 3.50438 8.532C2.08509 10.0489 1.33749 12.5196 1.34379 15.6762C1.35794 23.8117 6.59767 38.8527 21.3147 54.465C36.1609 70.3603 50.1888 76 57.6917 76C60.5627 75.9994 62.8172 75.2096 64.2107 73.7162C64.6215 73.277 65.0632 72.8271 65.5148 72.3654C68.1569 69.6709 71.4445 66.3185 71.2831 62.0947C71.1716 59.1932 69.4972 56.3815 66.1623 53.5008L66.1623 53.5001ZM64.0457 70.7138C63.5799 71.1889 63.1267 71.6517 62.705 72.1033C61.7179 73.1603 59.9843 73.719 57.6916 73.719C50.5956 73.719 37.2069 68.2543 22.8182 52.849C8.5622 37.7264 3.48831 23.3707 3.4749 15.673C3.47071 13.1367 4.00092 11.2259 5.01102 10.1467C5.45635 9.66984 5.9111 9.15541 6.37746 8.6281C8.59211 6.12275 11.1012 3.28292 13.8936 3.28292C16.0003 3.28292 18.1792 4.84243 20.5626 8.05753C27.3405 17.0575 24.9525 19.5859 20.9969 23.7732L20.2949 24.5188C18.5089 26.4317 18.9254 29.5779 21.5361 33.87C23.3629 36.8758 26.3147 40.566 30.308 44.8391C37.889 52.9577 43.2462 56.905 46.6831 56.9056H46.6836C47.798 56.9056 48.7295 56.5123 49.4546 55.7364C49.7255 55.4464 49.9906 55.1586 50.2504 54.8748C52.3717 52.5663 54.0468 50.7425 56.4113 50.7425C58.5436 50.7425 61.2958 52.2252 64.8239 55.2759C67.6143 57.6859 69.0702 60.0118 69.1535 62.189C69.2756 65.38 66.4967 68.2135 64.0453 70.7138L64.0457 70.7138Z'
      fill='var(--color-additional-elements)'
      stroke='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
