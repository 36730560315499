import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#D4F0FF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9837 15.6628L23.9895 8.25H6.31744L14.3233 15.6628C14.7977 16.1372 15.5093 16.1372 15.9837 15.6628ZM18.4151 15.3663L16.9326 16.7302C16.4581 17.2047 15.8058 17.4419 15.1535 17.4419C14.5012 17.4419 13.8488 17.2047 13.3151 16.7302L11.8919 15.3663L6.25814 21H24.0488L18.4151 15.3663ZM19.4826 14.4174L25.057 19.9919V9.19884L19.4826 14.4174ZM10.8244 14.4174L5.25 9.19884V19.9919L10.8244 14.4174Z'
      fill='#004D77'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 0H19C19.5523 0 20 0.44772 20 1V17C20 17.5523 19.5523 18 19 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0ZM18 4.23792L10.0718 11.338L2 4.21594V16H18V4.23792ZM2.51146 2L10.0619 8.662L17.501 2H2.51146Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
