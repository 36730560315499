import { ActionType } from '../actionTypes';

type SelectedRowsStoreSelectedRowsType = string[];

export type SelectedRowsStoreStateType = {
  [key: string]: SelectedRowsStoreSelectedRowsType;
};

type SelectedRowsStoreSelectedRowsActionPayloadType = {
  name: string;
  data?: SelectedRowsStoreSelectedRowsType;
};

type SelectedRowsStoreActionsType =
  | ActionType.UPDATE_SELECTED_ROWS
  | ActionType.CLEAR_SELECTED_ROWS;

type SelectedRowsStoreSelectedRowsActionType = {
  type: SelectedRowsStoreActionsType;
  payload: SelectedRowsStoreSelectedRowsActionPayloadType;
};

export type SelectedRowsStoreType = (
  state: SelectedRowsStoreStateType,
  action: SelectedRowsStoreSelectedRowsActionType
) => SelectedRowsStoreStateType | {};

const initialState: SelectedRowsStoreStateType = {};

const selectedRows: SelectedRowsStoreType = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionType.UPDATE_SELECTED_ROWS: {
      return {
        ...state,
        [payload.name]: payload.data,
      };
    }

    case ActionType.CLEAR_SELECTED_ROWS: {
      const newState = { ...state };
      if (newState?.[payload.name]) delete newState[payload.name];
      return newState;
    }

    default:
      return state;
  }
};

export default selectedRows;
