import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
    <path d='M39.3333 0H0.666667C0.489856 0 0.320286 0.0702379 0.195262 0.195262C0.0702379 0.320286 0 0.489856 0 0.666667V39.3333C0 39.5101 0.0702379 39.6797 0.195262 39.8047C0.320286 39.9298 0.489856 40 0.666667 40H39.3333C39.5101 40 39.6797 39.9298 39.8047 39.8047C39.9298 39.6797 40 39.5101 40 39.3333V0.666667C40 0.489856 39.9298 0.320286 39.8047 0.195262C39.6797 0.0702379 39.5101 0 39.3333 0ZM38.6667 1.33333V5.33333H1.33333V1.33333H38.6667ZM1.33333 38.6667V6.66667H38.6667V38.6667H1.33333Z' />
    <path d='M4.66699 2.66797H2.66699V4.0013H4.66699V2.66797Z' />
    <path d='M8 2.66797H6V4.0013H8V2.66797Z' />
    <path d='M10 9.33203H4.66667C4.48986 9.33203 4.32029 9.40227 4.19526 9.52729C4.07024 9.65232 4 9.82189 4 9.9987V15.332C4 15.5088 4.07024 15.6784 4.19526 15.8034C4.32029 15.9285 4.48986 15.9987 4.66667 15.9987H10C10.1768 15.9987 10.3464 15.9285 10.4714 15.8034C10.5964 15.6784 10.6667 15.5088 10.6667 15.332V9.9987C10.6667 9.82189 10.5964 9.65232 10.4714 9.52729C10.3464 9.40227 10.1768 9.33203 10 9.33203ZM9.33333 14.6654H5.33333V10.6654H9.33333V14.6654Z' />
    <path d='M10 19.332H4.66667C4.48986 19.332 4.32029 19.4023 4.19526 19.5273C4.07024 19.6523 4 19.8219 4 19.9987V25.332C4 25.5088 4.07024 25.6784 4.19526 25.8034C4.32029 25.9285 4.48986 25.9987 4.66667 25.9987H10C10.1768 25.9987 10.3464 25.9285 10.4714 25.8034C10.5964 25.6784 10.6667 25.5088 10.6667 25.332V19.9987C10.6667 19.8219 10.5964 19.6523 10.4714 19.5273C10.3464 19.4023 10.1768 19.332 10 19.332ZM9.33333 24.6654H5.33333V20.6654H9.33333V24.6654Z' />
    <path d='M10 29.332H4.66667C4.48986 29.332 4.32029 29.4023 4.19526 29.5273C4.07024 29.6523 4 29.8219 4 29.9987V35.332C4 35.5088 4.07024 35.6784 4.19526 35.8034C4.32029 35.9285 4.48986 35.9987 4.66667 35.9987H10C10.1768 35.9987 10.3464 35.9285 10.4714 35.8034C10.5964 35.6784 10.6667 35.5088 10.6667 35.332V29.9987C10.6667 29.8219 10.5964 29.6523 10.4714 29.5273C10.3464 29.4023 10.1768 29.332 10 29.332ZM9.33333 34.6654H5.33333V30.6654H9.33333V34.6654Z' />
    <path d='M13.3333 11.332H12V12.6654H13.3333V11.332Z' />
    <path d='M20.1957 14.1966L21.1383 15.1393L23.805 12.4726C23.93 12.3476 24.0002 12.1781 24.0002 12.0013C24.0002 11.8245 23.93 11.655 23.805 11.5299L21.1383 8.86328L20.1957 9.80595L21.7243 11.3346H14.667V12.6679H21.7243L20.1957 14.1966Z' />
    <path d='M13.3333 22H12V23.3333H13.3333V22Z' />
    <path d='M33.805 19.5273L32.8623 20.47L34.391 21.9987H14.667V23.332H34.391L32.8623 24.8607L33.805 25.8033L36.4717 23.1367C36.5966 23.0117 36.6668 22.8421 36.6668 22.6653C36.6668 22.4886 36.5966 22.319 36.4717 22.194L33.805 19.5273Z' />
    <path d='M13.3333 32.668H12V34.0013H13.3333V32.668Z' />
    <path d='M27.1378 30.1953L26.1952 31.138L27.7238 32.6666H14.6665V34H27.7238L26.1952 35.5286L27.1378 36.4713L29.8045 33.8046C29.9295 33.6796 29.9997 33.5101 29.9997 33.3333C29.9997 33.1565 29.9295 32.987 29.8045 32.862L27.1378 30.1953Z' />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
