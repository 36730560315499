import React, { useContext, useEffect, useState } from 'react';
import { Switch, Tooltip } from 'antd';
import { BaseComponentProps, FormComponentData } from 'interfaces/BaseComponentProps';
import { DataType } from 'interfaces/BaseComponentInterface';
import styles from './SwitchControl.module.css';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducers';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import inputCheckboxStyles from '../Checkbox/InputCheckbox.module.css';
import { JsObject } from 'interfaces/Object';
import HintIcon from '../SelectControl/HintIcon';

export interface SwitchProps extends BaseComponentProps {
  value?: string;
  mockData?: any;
}

const sortAndTypeValues = (vals: any, dataType?: DataType) => {
  const data: any[] = Object.values(vals || {}).sort((v1: any, v2: any) =>
    v1.index > v2.index ? 1 : -1
  );

  if (data.length !== 2) {
    return [];
  }

  data.forEach((item: any) => {
    if (item.params != null && item.params.ChoiceCode != null) {
      item.index = item.params.ChoiceCode;
      return;
    }
    if (dataType === DataType.LONG) item.index = Number(item.index);
  });

  return data;
};

const SwitchControl: React.FC<SwitchProps> = (props) => {
  const { ownProps, ...selectProps } = props;

  const context = useContext(EntityEditorContext);
  const { form } = context;

  const componentData: FormComponentData | JsObject = ownProps.component || {};
  const hint: any = componentData.hint;
  const readOnly: boolean = Boolean(componentData.readonly);
  const disabled: boolean = Boolean(componentData.readonly);
  const propName: string | undefined = componentData.propName;
  const dataType: DataType | undefined = componentData.dataType;
  const choiceName = componentData.choiceName;

  const choiceValues = useSelector(
    (state: AppState) => choiceName && state.choiceLists[choiceName]?.choiceItems
  );
  const [values, setValues] = useState<any[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!values.length || !propName) return;

    const formValue: any = form?.getFieldValue(propName);
    if (!formValue && values.length === 2) {
      form?.setFieldsValue({ [propName]: values[0].index });
    } else {
      const foundValIndex = values.findIndex(
        (curVal) => String(curVal.index) === String(formValue)
      );
      if (foundValIndex > -1) setIsChecked(foundValIndex === 1);
    }
  }, [values]);

  useEffect(() => {
    if (ownProps.values != null) {
      setValues(sortAndTypeValues(ownProps.values, dataType));
    } else if (context && form && choiceValues && ownProps && propName) {
      const formValue: any = form.getFieldValue(propName);
      const _choiceValues: any[] = sortAndTypeValues(choiceValues, dataType);
      if (
        _choiceValues.length > 0 &&
        _choiceValues.findIndex((val: any) => formValue === val.index) === -1
      ) {
        form.setFieldsValue({
          [propName]: formValue,
        });
      }

      setValues(_choiceValues);
    }
  }, [context, form, choiceValues, ownProps]);

  const onSwitch = () => {
    const oldChecked = isChecked;
    if (propName != null && values.length === 2) {
      form?.setFieldsValue({
        [propName]: oldChecked ? values[0].index : values[1].index,
      });
    }
    setIsChecked(!isChecked);
  };

  const tooltipEl = disabled ? (
    <></>
  ) : (
    <div className={styles.info}>
      <HintIcon hint={hint} cssClassTooltip={inputCheckboxStyles.tooltip} />
    </div>
  );

  return (
    <div className={styles.wrap}>
      <label>
        <Switch
          className={styles.switch}
          disabled={readOnly}
          checked={isChecked}
          onClick={onSwitch}
        />
        <div className={styles.row}>
          <span className={isChecked ? '' : styles.active}>
            {values.length > 0
              ? values[0]?.label
              : props.mockData !== undefined
                ? props.mockData[0].label
                : ''}
          </span>
          <span className={styles.active}>/</span>
          <span className={isChecked ? styles.active : ''}>
            {values.length > 0
              ? values[1]?.label
              : props.mockData !== undefined
                ? props.mockData[1].label
                : ''}
          </span>
        </div>
      </label>
      {tooltipEl}
    </div>
  );
};

export default SwitchControl;
