import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#FFEAD1' />
    <path
      d='M9.72339 14.5673C9.72349 15.8858 10.1792 17.1638 11.0135 18.1849C11.8477 19.206 13.0092 19.9074 14.3012 20.1705V20.173C14.4161 20.1934 14.5259 20.2363 14.6242 20.2991C14.7225 20.362 14.8074 20.4436 14.8742 20.5393C15.0091 20.7326 15.0616 20.9715 15.0203 21.2035C14.9999 21.3184 14.957 21.4281 14.8941 21.5264C14.8313 21.6247 14.7497 21.7097 14.654 21.7764C14.5583 21.8432 14.4504 21.8905 14.3364 21.9155C14.2225 21.9406 14.1047 21.943 13.9898 21.9225V21.9225C13.9865 21.9225 13.9831 21.921 13.9798 21.9204C13.9623 21.9172 13.9453 21.9132 13.9281 21.909C12.3355 21.5818 10.8923 20.7459 9.81599 19.5272C8.73969 18.3085 8.08857 16.7731 7.96073 15.1522C7.83288 13.5313 8.23524 11.9128 9.10718 10.5404C9.97912 9.16809 11.2734 8.11627 12.7951 7.54342C14.3167 6.97056 15.9833 6.90772 17.5438 7.36435C19.1043 7.82098 20.4741 8.77235 21.447 10.0751C22.4198 11.3779 22.9429 12.9616 22.9375 14.5875C22.932 16.2134 22.3984 17.7935 21.4169 19.0898C21.8821 19.8113 22.3964 20.6119 22.564 20.883C22.8934 21.4165 22.4023 21.3562 22.4023 21.3562C22.4023 21.3562 17.3481 21.3862 16.9768 21.3562C16.6055 21.3262 16.8031 21.0029 16.8031 21.0029C16.8031 21.0029 18.9113 16.4404 19.067 16.1111C19.2227 15.7818 19.4623 16.0693 19.4623 16.0693L20.3666 17.4649C20.8122 16.7074 21.0771 15.8574 21.1408 14.981C21.2045 14.1045 21.0653 13.2251 20.7339 12.4112C20.4026 11.5973 19.888 10.8707 19.2302 10.2879C18.5724 9.70518 17.7892 9.28194 16.9412 9.05109C16.0933 8.82024 15.2036 8.788 14.3411 8.95688C13.4787 9.12575 12.6669 9.4912 11.9686 10.0248C11.2704 10.5584 10.7046 11.2459 10.3152 12.0337C9.9258 12.8215 9.72328 13.6885 9.72339 14.5673V14.5673Z'
      fill='#FF8B00'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
