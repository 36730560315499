import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='25.6074'
      width='6.21249'
      height='35.9738'
      rx='3.10625'
      transform='rotate(45 25.6074 0)'
      fill='#E5E5E5'
    />
    <rect
      y='4.55469'
      width='6.21249'
      height='35.9738'
      rx='3.10625'
      transform='rotate(-45 0 4.55469)'
      fill='#E5E5E5'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
