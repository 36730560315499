import { ObjectMeta, ObjectParams } from './Object';

export enum TableType {
  FK = 'fk', // foreign key - внешний ключ, айди на родителя лежат на чайлдах
  ARRAY = 'array',
  FK_ARRAY = 'fk-array',
  REGISTRY = 'registry',
}

export enum RowToolActionType {
  EDIT = 'edit',
  PREVIEW = 'preview',
  DELETE = 'delete',
  UNPIN = 'unpin',
  DOWNLOAD = 'download',
  PREVIEW_ATTACHMENT = 'previewAttachment',
  COPY = 'copy',
}

export enum ToolsIconType {
  ED = 'EDIcon',
  EDIT = 'EditIcon',
  PREVIEW = 'PreviewIcon',
  DELETE = 'DeleteIcon',
  UNPIN = 'UnpinIcon',
  DOWNLOAD = 'DownloadIcon',
  PREVIEW_ATTACHMENT = 'PreviewAttachmentIcon',
  HAS_ATTACHMENT = 'hasAttachment',
  COPY = 'CopyIcon',
}

export enum ToolActionType {
  CREATE = 'create',
  MASS_REPLACE = 'massReplace',
  EXPORT = 'export',
  ADD = 'add',
  ATTACH = 'attach',
  FIELD_CHANGE = 'fieldChange',
  IMPORT_OBJECTS = 'importObjects',
  GET_TEMPLATE = 'getTemplate',
  GEN_ETR_RES_REP_1 = 'generateEngineeringTechnicalRequestAndResponseReportOne',
  GEN_ETR_RES_REP_2 = 'generateEngineeringTechnicalRequestAndResponseReportTwo',
  GEN_ETR_RES_REP_3 = 'generateEngineeringTechnicalRequestAndResponseReportThree',
  GEN_ED_AND_RES_REP_1 = 'generateEDAndResponseReportOne',
  GEN_ED_AND_RES_REP_2 = 'generateEDAndResponseReportTwo',
  GEN_ED_AND_RES_REP_3 = 'generateEDAndResponseReportThree',
}

export interface TableRecord extends ObjectParams, ObjectMeta {
  '.transitionType'?: string;
  '.link'?: string;
  '.modalType'?: string;
}

export interface ISort {
  nameColumn: string | null;
  orderDirection: string | null;
}

export interface IScroll {
  x?: number | string | true;
  y?: number | string;
}
