import { TransitionType } from 'interfaces/CardTransition';
import { ModalType } from 'store/reducers/modal';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from 'store/reducers';
import { getMetaByClassType } from 'store/selectors/meta';
import { TableType } from 'interfaces/Table';
import useObjectDetails from 'hooks/useObjectDetails';

import {
  ParamsFromConfigType,
  ParamsFromMatchType,
  ParamsFromPropsType,
  ParamsFromStoreType,
  ParamsGeneralType,
  ParamsToolsSettings,
  UseTableViewDataAggregateType,
} from 'interfaces/TableView.dto';

export const useTableViewDataAggregate: UseTableViewDataAggregateType = ({ props }: any) => {
  const inboxName = props.inboxName || props.match.params.inboxName;

  const {
    choiceLists,
    inputSearchValue,
    meta,
    typeUser,
    editor,
    selectedRows,
    modalEditorDetails,
    inboxParams,
    inboxGroups,
    inboxLabel,
  } = useSelector(
    (state: AppState) => ({
      inboxParams: state.menu.inboxes[inboxName]?.params,
      inboxGroups: state.menu.inboxGroups,
      inboxLabel: state.menu.inboxes[inboxName]?.label,
      choiceLists: state.choiceLists,
      inputSearchValue: state.inputSearchValue,
      meta: getMetaByClassType(state, { className: props.className || props.match.params }),
      typeUser: state.typeUser,
      editor: state.editor,
      selectedRows: state.selectedRows,
      modalEditorDetails: state.tableData.tableModalData,
    }),
    shallowEqual
  );

  const { details, getPropValue, getChildrenParentID, getChildrenParentClassName } =
    useObjectDetails();

  const componentParams = props.component?.params || {};
  const matchParams = props.match.params;
  const configParams = inboxParams || componentParams || {};

  const paramsFromProps: ParamsFromPropsType = {
    isSearch: props.isSearch,
    isReset: props.isReset,
    setIsSearch: props.setIsSearch,
    setIsReset: props.setIsReset,
    isCardTable: props.isCardTable || false,
    simpleTable: props.simpleTable,
    withRowsSelect: props.withRowsSelect,
    refreshID: props.refreshID,
    refreshCount: props.refreshCount,
    refreshSelf: props.refreshSelf,
  };

  const paramsFromMatch: ParamsFromMatchType = {
    useKB: matchParams.useKB,
    controllerName: matchParams.controllerName,
    groupID: matchParams.groupID,
    objectID: matchParams.objectID,
  };

  const paramsFromConfig: ParamsFromConfigType = {
    fillCriteriaFrom: configParams.fillCriteriaFrom || '',
    conditionId: configParams.conditionId || 'children',
    rowTools: configParams.rowTools || [],
    transitionType: configParams.transitionType || TransitionType.SAME_TAB,
    inboxViewName: configParams.inboxViewName,
    searchName: configParams.searchName,
    showFiltersTools: configParams.showFiltersTools,
    modalType: configParams.modalType || ModalType.EDIT,
    initTools: configParams?.tools,
  };

  const paramsGeneral: ParamsGeneralType = {
    className: props?.className || componentParams?.className || props.match.params.className,
    parentId: getChildrenParentID(),
    parentClassName: getChildrenParentClassName(),
    isShortView: paramsFromProps.isCardTable || props.isShortView,
    inboxName,
  };

  const paramsFromStore: ParamsFromStoreType = {
    choiceLists,
    inputSearchValue,
    meta,
    typeUser,
    editor,
    selectedRows,
    modalEditorDetails,
    inboxGroups,
    inboxLabel,
    details,
  };

  const paramsToolsSettings: ParamsToolsSettings = {
    toolsSettings: {
      attach: props?.addGuidItemsTable || componentParams?.addGuidItemsTable,
      massReplace: inboxParams?.massReplace,
    },
  };

  const tableType: TableType =
    componentParams.tableType ||
    (!paramsFromProps.isCardTable && TableType.REGISTRY) ||
    (paramsFromConfig.fillCriteriaFrom &&
      Array.isArray(getPropValue(paramsFromConfig.fillCriteriaFrom)) &&
      TableType.ARRAY) ||
    (['ID', 'VersionSeriesId'].includes(paramsFromConfig.fillCriteriaFrom) && TableType.FK) ||
    TableType.ARRAY;

  return {
    ...paramsFromProps,
    ...paramsFromMatch,
    ...paramsFromConfig,
    ...paramsFromStore,
    ...paramsGeneral,
    ...paramsToolsSettings,

    propsTableId: props.tableId,
    tableType,
  };
};
