import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#FFEAD1' />
    <path
      d='M10.8932 9.76C11.1399 9.55333 11.4065 9.36 11.6932 9.18C11.9865 9 12.2999 8.84333 12.6332 8.71C12.9665 8.57 13.3232 8.46333 13.7032 8.39C14.0899 8.31 14.5065 8.27 14.9532 8.27C15.5799 8.27 16.1432 8.35333 16.6432 8.52C17.1499 8.68667 17.5799 8.92667 17.9332 9.24C18.2865 9.54667 18.5565 9.92 18.7432 10.36C18.9365 10.7933 19.0332 11.2767 19.0332 11.81C19.0332 12.3167 18.9632 12.7567 18.8232 13.13C18.6832 13.4967 18.5065 13.8167 18.2932 14.09C18.0799 14.3633 17.8432 14.6 17.5832 14.8C17.3299 15 17.0865 15.1867 16.8532 15.36C16.6265 15.5267 16.4265 15.6933 16.2532 15.86C16.0865 16.0267 15.9799 16.2133 15.9332 16.42L15.6532 17.82H13.5332L13.3132 16.21C13.2599 15.8833 13.2932 15.6 13.4132 15.36C13.5332 15.1133 13.6965 14.8933 13.9032 14.7C14.1165 14.5 14.3532 14.3133 14.6132 14.14C14.8732 13.96 15.1165 13.77 15.3432 13.57C15.5699 13.3633 15.7599 13.1367 15.9132 12.89C16.0732 12.6367 16.1532 12.3367 16.1532 11.99C16.1532 11.59 16.0199 11.2733 15.7532 11.04C15.4932 10.8 15.1332 10.68 14.6732 10.68C14.3199 10.68 14.0232 10.7167 13.7832 10.79C13.5499 10.8633 13.3465 10.9467 13.1732 11.04C13.0065 11.1267 12.8599 11.2067 12.7332 11.28C12.6065 11.3533 12.4799 11.39 12.3532 11.39C12.0665 11.39 11.8532 11.2667 11.7132 11.02L10.8932 9.76ZM12.7332 21.43C12.7332 21.19 12.7765 20.9667 12.8632 20.76C12.9565 20.5533 13.0799 20.3733 13.2332 20.22C13.3932 20.0667 13.5799 19.9467 13.7932 19.86C14.0065 19.7667 14.2399 19.72 14.4932 19.72C14.7399 19.72 14.9699 19.7667 15.1832 19.86C15.3965 19.9467 15.5832 20.0667 15.7432 20.22C15.9032 20.3733 16.0265 20.5533 16.1132 20.76C16.2065 20.9667 16.2532 21.19 16.2532 21.43C16.2532 21.67 16.2065 21.8967 16.1132 22.11C16.0265 22.3167 15.9032 22.4967 15.7432 22.65C15.5832 22.8033 15.3965 22.9233 15.1832 23.01C14.9699 23.0967 14.7399 23.14 14.4932 23.14C14.2399 23.14 14.0065 23.0967 13.7932 23.01C13.5799 22.9233 13.3932 22.8033 13.2332 22.65C13.0799 22.4967 12.9565 22.3167 12.8632 22.11C12.7765 21.8967 12.7332 21.67 12.7332 21.43Z'
      fill='#FF8B00'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.20711 2V5H2.20711V2H5.20711ZM0.20711 0V7H7.20713V0H0.20711ZM10.2071 0H18.2071V2H10.2071V0ZM10.2071 7H18.2071V9H10.2071V7ZM10.2071 14H18.2071V16H10.2071V14ZM7.91423 12.2071L6.5 10.7929L3.20711 14.0858L1.41421 12.2929L0 13.7071L3.20711 16.9142L7.91423 12.2071Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
