import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg
    width='0.8em'
    height='0.8em'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <ellipse
      cx='19.3321'
      cy='10.666'
      rx='9.99994'
      ry='9.99993'
      transform='rotate(90 19.3321 10.666)'
      fill='white'
    />
    <path
      d='M27 10.6873C27 8.63336 26.2003 6.7027 24.748 5.25037C23.2957 3.79804 21.365 2.99838 19.3111 2.99805C19.0351 2.99805 18.8111 3.22171 18.8111 3.49804C18.8111 3.77404 19.0347 3.99804 19.3111 3.99804C21.0977 3.99804 22.7774 4.69404 24.0407 5.95737C25.304 7.2207 25.9997 8.90036 25.9997 10.687C25.9997 10.9633 26.2233 11.187 26.4997 11.187C26.776 11.187 27 10.9637 27 10.6873Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M12.8882 19.2332C12.8896 19.2319 12.8906 19.2302 12.8919 19.2289C14.7342 20.6199 16.9665 21.3739 19.3155 21.3739C22.1705 21.3739 24.8552 20.2619 26.8741 18.2429C31.0418 14.0753 31.0421 7.29363 26.8741 3.12598C22.7065 -1.04199 15.9249 -1.04199 11.7573 3.12598C9.73827 5.14497 8.62627 7.82929 8.62627 10.6846C8.62627 13.0336 9.38027 15.2659 10.7713 17.1082C10.7699 17.1096 10.7683 17.1106 10.7669 17.1119L0.439663 27.4392C0.146665 27.7315 0 28.1155 0 28.4995C0 28.8835 0.146666 29.2675 0.439331 29.5605C1.02533 30.1465 1.97532 30.1465 2.56098 29.5605L12.8882 19.2332ZM12.9356 17.0642C11.2316 15.3599 10.2929 13.0943 10.2929 10.6843C10.2929 8.27429 11.2316 6.00863 12.9356 4.30431C16.4532 0.786662 22.1775 0.786662 25.6955 4.30431C27.4545 6.06363 28.3338 8.37362 28.3338 10.6843C28.3338 12.9946 27.4541 15.3056 25.6955 17.0642C23.9912 18.7682 21.7255 19.7069 19.3155 19.7069C16.9052 19.7069 14.6396 18.7682 12.9356 17.0642Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
