import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width="1.2rem" height="1.2rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3086 2.12646V5.68302H21.874L18.3086 2.12646Z" fill="black"/>
    <path d="M2 17.194V20.4139C2 21.3819 2.88608 22.1606 3.89873 21.9712C4.65823 21.8239 5.16456 21.1294 5.16456 20.3507V16.689H2.48523C2.23207 16.689 2 16.9205 2 17.194Z" fill="black"/>
    <path d="M17.7169 6.86133C17.3793 6.86133 17.1262 6.58775 17.1262 6.27208V2H7.35824C6.80972 2 6.36668 2.44194 6.36668 2.9891V20.4141C6.36668 21.0034 6.17681 21.5505 5.86035 21.9925H20.4173C21.2823 21.9925 21.9996 21.277 21.9996 20.4141V6.86133H17.7169ZM8.89833 5.15671C8.89833 4.81999 9.17259 4.56746 9.48904 4.56746H14.1726C14.5101 4.56746 14.7633 4.84104 14.7633 5.15671V8.7764C14.7633 9.11311 14.489 9.36565 14.1726 9.36565H9.51014C9.17259 9.36565 8.91942 9.09207 8.91942 8.7764V5.15671H8.89833ZM18.8561 19.425H9.51014C9.17259 19.425 8.91942 19.1514 8.91942 18.8358C8.91942 18.5201 9.19369 18.2465 9.51014 18.2465H18.8561C19.1937 18.2465 19.4468 18.5201 19.4468 18.8358C19.4468 19.1514 19.1726 19.425 18.8561 19.425ZM18.8561 16.0789H9.51014C9.17259 16.0789 8.91942 15.8053 8.91942 15.4897C8.91942 15.174 9.19369 14.9004 9.51014 14.9004H18.8561C19.1937 14.9004 19.4468 15.174 19.4468 15.4897C19.4468 15.8053 19.1726 16.0789 18.8561 16.0789ZM18.8561 12.7118H9.51014C9.17259 12.7118 8.91942 12.4382 8.91942 12.1225C8.91942 11.8068 9.19369 11.5333 9.51014 11.5333H18.8561C19.1937 11.5333 19.4468 11.8068 19.4468 12.1225C19.4468 12.4592 19.1726 12.7118 18.8561 12.7118Z" fill="black"/>
    <path d="M13.5826 5.74609H10.1016V8.16624H13.5826V5.74609Z" fill="black"/>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
