import { cloneDeep } from 'lodash';
import moment from 'moment';
import { valueForPeriod } from 'pages/reports/utils';

export const indicatorKpi = {
 choiceItems:{
  percentIndicator: {
    index: 'percentIndicator',
    label: 'Процент запросов, обработанных в срок, %.',
    params: {
      ChoiceCode: 'percentIndicator',
      Title: 'Процент запросов, обработанных в срок, %.',
      ObjectState: 'ALIVE',
    },
  },
  timeIndicator: {
    index: 'timeIndicator',
    label: 'Время обработки запроса',
    params: {
      ChoiceCode: 'timeIndicator',
      Title: 'Время обработки запроса',
      ObjectState: 'ALIVE',
    },
  }
 }
};

export const indicatorKpiRA = {
  choiceItems:{
   percentIndicator: {
     index: 'percentIndicator',
     label: 'Процент РА, обработанных в срок, %.',
     params: {
       ChoiceCode: 'percentIndicator',
       Title: 'Процент РА, обработанных в срок, %.',
       ObjectState: 'ALIVE',
     },
   },
   timeIndicator: {
     index: 'timeIndicator',
     label: 'Время обработки РА',
     params: {
       ChoiceCode: 'timeIndicator',
       Title: 'Время обработки РА',
       ObjectState: 'ALIVE',
     },
   }
  }
 };

export const periodText = {
 choiceItems:{
  currentMonth: {
    index: 'currentMonth',
    label: 'Текущий месяц',
    params: {
      ChoiceCode: 'currentMonth',
      Title: 'Текущий месяц',
      ObjectState: 'ALIVE',
    },
  },
  lastMonth: {
    index: 'lastMonth',
    label: 'Прошлый месяц',
    params: {
      ChoiceCode: 'lastMonth',
      Title: 'Прошлый месяц',
      ObjectState: 'ALIVE',
    },
  },
  currentQuarter: {
    index: 'currentQuarter',
    label: 'Текущий квартал',
    params: {
      ChoiceCode: 'currentQuarter',
      Title: 'Текущий квартал',
      ObjectState: 'ALIVE',
    },
  },
  lastQuarter: {
    index: 'lastQuarter',
    label: 'Прошлый квартал',
    params: {
      ChoiceCode: 'lastQuarter',
      Title: 'Прошлый квартал',
      ObjectState: 'ALIVE',
    },
  },
  currentSemester: {
    index: 'currentSemester',
    label: 'Текущее полугодие',
    params: {
      ChoiceCode: 'currentSemester',
      Title: 'Текущее полугодие',
      ObjectState: 'ALIVE',
    },
  },
  lastSemester: {
    index: 'lastSemester',
    label: 'Прошлое полугодие',
    params: {
      ChoiceCode: 'lastSemester',
      Title: 'Прошлое полугодие',
      ObjectState: 'ALIVE',
    },
  },
  currentYear: {
    index: 'currentYear',
    label: 'Текущий год',
    params: {
      ChoiceCode: 'currentYear',
      Title: 'Текущий год',
      ObjectState: 'ALIVE',
    },
  },
  lastYear: {
    index: 'lastYear',
    label: 'Прошлый год',
    params: {
      ChoiceCode: 'lastYear',
      Title: 'Прошлый год',
      ObjectState: 'ALIVE',
    },
  },
  arbitraryPeriod: {
    index: 'arbitraryPeriod',
    label: 'Произвольный период',
    params: {
      ChoiceCode: 'arbitraryPeriod',
      Title: 'Произвольный период',
      ObjectState: 'ALIVE',
    },
  }
 }
};

export const layout = (isRA?: boolean) => ({
  id: '',
  name: 'VirtualChoiceList',
  panels: [
    {
      componentsRows: [
        {
          components: [
            {
              renderer: 'select',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Показатель KPI',
              system: false,
              choiceName: 'indicatorKpi',
              cssClass: 'span3',
              label: 'Показатель KPI',
              params: {},
              propName: 'indicatorKpi',
              propertyType: 'string',
              templateName: 'select',
              value: 'percentIndicator',
            },
            {
              renderer: 'input',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Целевой показатель KPI',
              system: false,
              choiceName: 'targetKpi',
              cssClass: 'span3',
              label: 'Целевой показатель KPI',
              params: {},
              propName: 'targetKpi',
              propertyType: 'STRING',
              templateName: 'input',
              defaultValueRaw: '85',
              masked: true,
            },
            {
              renderer: 'select',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Период',
              system: false,
              choiceName: 'periodText',
              cssClass: 'span3',
              label: 'Период',
              params: {},
              propName: 'periodText',
              propertyType: 'string',
              templateName: 'select',
              value: 'currentMonth',
            },
            {
              renderer: 'betweenDatePicker',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: '',
              system: false,
              choiceName: 'periodData',
              cssClass: 'span3',
              label: '',
              params: {},
              propName: 'periodData',
              propertyType: 'string',
              templateName: 'betweenDatePicker',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: process.env.REACT_APP_TYPE === 'irk' ? true : false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Подразделение',
              system: false,
              cssClass: 'span3',
              label: 'Подразделение',
              params: {},
              propName: 'department_',
              propertyType: 'string',
              templateName: 'select',
              choiceName: 'OwnerGroup',
              defaultValueRaw: process.env.REACT_APP_TYPE === 'irk' ? 'ОСЦ' : '',
            },
            {
              renderer: 'input',
              virtual: true,
              hidden: true,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Подразделение',
              system: false,
              choiceName: 'department',
              cssClass: 'span3',
              label: 'Подразделение',
              params: {},
              propName: 'department',
              propertyType: 'string',
              templateName: 'input',
              defaultValueRaw: process.env.REACT_APP_TYPE === 'irk' ? 'ОСЦ' : '',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Исполнитель',
              system: false,
              choiceName: 'OwnerForKPI',
              cssClass: 'span3',
              label: 'Исполнитель',
              params: {},
              propName: 'ownerKpi',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: isRA,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: process.env.REACT_APP_TYPE === 'irk' ? 'Приоритет' : 'Срочность',
              system: false,
              choiceName: 'InternalPriority',
              cssClass: 'span3',
              label: process.env.REACT_APP_TYPE === 'irk' ? 'Приоритет' : 'Срочность',
              params: {},
              propName: 'priority',
              propertyType: 'string',
              templateName: 'select',
              value: '1',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: process.env.REACT_APP_TYPE === 'irk' ? 'Контрагент' : 'Заказчик',
              system: false,
              choiceName: 'OrganizationList',
              cssClass: 'span3',
              label: process.env.REACT_APP_TYPE === 'irk' ? 'Контрагент' : 'Заказчик',
              params: {},
              propName: 'organizationList',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'input',
              virtual: true,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Тип запроса',
              system: false,
              choiceName: 'employee',
              cssClass: 'span3',
              label: 'Тип запроса',
              params: {},
              propName: 'employee',
              propertyType: 'string',
              templateName: 'input',
              defaultValueRaw: 'Инженерно-технический',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Тип ВС',
              system: false,
              choiceName: 'Aircraft_TypeList',
              cssClass: 'span3',
              label: 'Тип ВС',
              params: {},
              propName: process.env.REACT_APP_TYPE === 'irk' ? 'aircraftType' : 'productType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'csdp',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Категория',
              system: false,
              choiceName: isRA ? 'ComplaintType' : 'RequestType',
              cssClass: 'span3',
              label: 'Категория',
              params: {},
              propName: 'requestType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'csdp',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Тип двигателя',
              system: false,
              choiceName: 'ProductType',
              cssClass: 'span3',
              label: 'Тип двигателя',
              params: {},
              propName: 'productType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: JSON.stringify({
                parametrized: {
                  parameters: {
                    AircraftType: 'aircraftType',
                  },
                  required: ['AircraftType'],
                },
              }),
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Модель ВС',
              system: false,
              choiceName: 'AircraftModelByType',
              cssClass: 'span3',
              label: 'Модель ВС',
              params: {},
              propName: 'aircraftModel',
              propertyType: 'string',
              templateName: 'dynamicSelect',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: JSON.stringify({
                parametrized: {
                  parameters: {
                    AircraftType: 'aircraftType',
                  },
                  required: ['AircraftType'],
                },
              }),
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Система ВС',
              system: false,
              choiceName: 'ATA_ByAircraftType',
              cssClass: 'span3',
              label: 'Система ВС',
              params: {},
              propName: 'ataAircraftType',
              propertyType: 'string',
              templateName: 'select',
            },
          ],
        },
      ],
      expanded: true,
      legend: 'Основная информация',
    },
  ],
  params: {
    Title: 'Основная информация',
  },
  readonly: false,
});

export function getDateValueForPeriod(period: any) {
  const values: any[] = valueForPeriod.filter((item) => {
    return item.value === period;
  });
  if (values.length === 1) {
    const value: any = values[0];
    if (typeof value.date === 'function') {
      return value.date();
    }
    return value.date;
  }
  return [];
}
