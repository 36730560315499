import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#D4F0FF' />
    <path
      d='M7.07507 8.35844H7.88138V20.4609H6.95562C6.44795 20.4609 6 19.9836 6 19.3658V8.02148C6.29863 8.24612 6.65699 8.35844 7.07507 8.35844Z'
      fill='#AACFE4'
    />
    <path
      d='M18.3036 7.76904C14.9888 7.76904 12.3907 7.76904 7.04521 7.76904C6.47781 7.76904 6 7.37592 6 6.87048C6.02986 6.39312 6.47781 6 7.07507 6H18.3335C18.1244 6.25272 18.005 6.5616 18.005 6.89856C17.9751 7.20744 18.0946 7.51632 18.3036 7.76904Z'
      fill='#AACFE4'
    />
    <path
      d='M8.48047 8.35742V20.4599H13.4079C13.2287 20.3195 13.1093 20.0949 13.1093 19.8421V18.4101C13.1093 17.9608 13.4975 17.5957 13.9753 17.5677L14.3038 17.5396C14.3635 17.3992 14.4232 17.2869 14.483 17.1745L14.2441 16.9499C13.9156 16.6129 13.9454 16.1075 14.2739 15.7986L15.349 14.7877C15.6775 14.4789 16.215 14.4508 16.5734 14.7597L16.8123 14.9843C16.9317 14.9281 17.0811 14.872 17.2005 14.8439L17.2304 14.535C17.2602 14.0857 17.6485 13.7207 18.1263 13.7207H18.3055V8.35742H8.48047ZM13.7364 15.5459H10.3021C10.1229 15.5459 10.0035 15.4055 10.0035 15.2651C10.0035 15.1247 10.1528 14.9843 10.3021 14.9843H13.7364C13.9156 14.9843 14.035 15.1247 14.035 15.2651C14.035 15.4055 13.9156 15.5459 13.7364 15.5459ZM16.5137 13.8892H10.3021C10.1229 13.8892 10.0035 13.7488 10.0035 13.6084C10.0035 13.4399 10.1528 13.3276 10.3021 13.3276H16.5137C16.6928 13.3276 16.8123 13.468 16.8123 13.6084C16.8123 13.7769 16.6928 13.8892 16.5137 13.8892ZM16.5137 12.2325H10.3021C10.1229 12.2325 10.0035 12.0921 10.0035 11.9517C10.0035 11.7832 10.1528 11.6709 10.3021 11.6709H16.5137C16.6928 11.6709 16.8123 11.8113 16.8123 11.9517C16.8123 12.0921 16.6928 12.2325 16.5137 12.2325ZM16.5137 10.5757H10.3021C10.1229 10.5757 10.0035 10.4353 10.0035 10.2949C10.0035 10.1265 10.1528 10.0141 10.3021 10.0141H16.5137C16.6928 10.0141 16.8123 10.1545 16.8123 10.2949C16.8123 10.4353 16.6928 10.5757 16.5137 10.5757Z'
      fill='#AACFE4'
    />
    <path
      d='M23.7656 18.1582L22.9892 18.1302C22.8996 17.7651 22.7503 17.4282 22.5412 17.0912L23.0489 16.5577C23.1385 16.4454 23.1385 16.305 23.0489 16.1926L21.9738 15.1818C21.8544 15.0694 21.7051 15.0694 21.5856 15.1818L21.0182 15.6591C20.6897 15.4626 20.3015 15.3222 19.9133 15.2379L19.8834 14.5359C19.8834 14.3955 19.764 14.2832 19.6146 14.2832H18.0916C17.9423 14.2832 17.8229 14.3955 17.8229 14.5359L17.793 15.266C17.4048 15.3502 17.0464 15.4906 16.6881 15.6872L16.1207 15.2098C16.0012 15.1256 15.8519 15.1256 15.7324 15.2098L14.6574 16.2207C14.5678 16.333 14.5379 16.4734 14.6574 16.5858L15.165 17.1193C14.956 17.4282 14.8067 17.7932 14.7171 18.1582L13.9406 18.1863C13.7913 18.1863 13.6719 18.2986 13.6719 18.439V19.8711C13.6719 20.0115 13.7913 20.1238 13.9406 20.1238L14.7171 20.1519C14.8067 20.517 14.956 20.8539 15.165 21.1909L14.6574 21.7244C14.5678 21.8367 14.5678 22.0052 14.6872 22.0894L14.7171 22.1175L15.7623 23.1003C15.8817 23.2126 16.0311 23.2126 16.1505 23.1003L16.7179 22.623C17.0763 22.8195 17.4346 22.9599 17.8229 23.0442L17.8527 23.7462C17.8527 23.8866 17.9722 23.9989 18.1215 23.9989H19.6445C19.7938 23.9989 19.9133 23.8866 19.9133 23.7462L19.9431 23.0442C20.3314 22.9599 20.6897 22.8195 21.0481 22.623L21.6155 23.1003C21.7349 23.1846 21.8842 23.1846 22.0037 23.1003L23.0788 22.0894C23.1982 21.9771 23.1982 21.8367 23.0788 21.7244L22.5412 21.1628C22.7503 20.8539 22.8996 20.4889 22.9892 20.1238L23.7358 20.0958C23.8851 20.0958 24.0045 19.9834 24.0045 19.843V18.411C24.0045 18.2706 23.9149 18.1582 23.7656 18.1582ZM20.7494 19.141C20.7494 20.0958 19.9133 20.882 18.8681 20.882C17.8527 20.882 17.0166 20.0958 17.0166 19.141C17.0166 18.1863 17.8527 17.4001 18.8681 17.4001C19.8834 17.372 20.7494 18.1582 20.7494 19.141Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
