import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#D4F0FF' />
    <path
      d='M16.6139 17.6211H11.2768C8.90865 17.6211 7 19.5811 7 21.9687V23.6436C7 23.8575 7.14138 24 7.35345 24H16.9321C16.72 23.6793 16.6139 23.3229 16.6139 22.9666V17.6211Z'
      fill='#004D77'
    />
    <path
      d='M23.0463 15.5508C23.0816 15.6577 23.0816 15.7646 23.0816 15.8359V15.9071C23.0816 16.8337 22.3394 17.5821 21.4204 17.5821H19.8652C18.9462 17.5821 18.2039 16.8337 18.2039 15.9071V15.8359C18.2039 15.729 18.2039 15.6221 18.2393 15.5508C17.7445 15.6221 17.3203 16.0497 17.3203 16.5842V22.9632C17.3203 23.5333 17.7798 23.9966 18.3453 23.9966H22.9756C23.5411 23.9966 24.0006 23.5333 24.0006 22.9632V16.5842C23.9652 16.0497 23.5764 15.6221 23.0463 15.5508ZM19.7238 18.5442C19.8652 18.4017 20.0773 18.4017 20.2186 18.5442L20.5367 18.865L21.4204 17.9741C21.5618 17.8315 21.7738 17.8315 21.9152 17.9741C22.0566 18.1166 22.0566 18.3304 21.9152 18.473L20.7842 19.6133C20.7135 19.6846 20.6428 19.7202 20.5367 19.7202C20.4307 19.7202 20.36 19.6846 20.2893 19.6133L19.7238 19.0431C19.5824 18.9006 19.5824 18.6868 19.7238 18.5442ZM22.2333 22.4286H19.0522C18.8402 22.4286 18.6988 22.2861 18.6988 22.0722C18.6988 21.8584 18.8402 21.7159 19.0522 21.7159H22.2333C22.4454 21.7159 22.5868 21.8584 22.5868 22.0722C22.5868 22.2861 22.41 22.4286 22.2333 22.4286ZM22.2333 21.217H19.0522C18.8402 21.217 18.6988 21.0744 18.6988 20.8606C18.6988 20.6468 18.8402 20.5042 19.0522 20.5042H22.2333C22.4454 20.5042 22.5868 20.6468 22.5868 20.8606C22.5868 21.0744 22.41 21.217 22.2333 21.217Z'
      fill='#004D77'
    />
    <path
      d='M21.4236 14.9102H19.8684C19.3382 14.9102 18.9141 15.3378 18.9141 15.8723V15.9436C18.9141 16.4782 19.3382 16.9058 19.8684 16.9058H21.4236C21.9538 16.9058 22.3779 16.4782 22.3779 15.9436V15.8723C22.3779 15.3378 21.9538 14.9102 21.4236 14.9102Z'
      fill='#004D77'
    />
    <path
      d='M18.276 11.8116C18.276 11.5978 18.1347 11.4552 17.9226 11.4552H17.7812C17.7812 11.384 17.7812 11.277 17.7812 11.2058C17.7812 9.74468 16.9329 8.42613 15.6605 7.82031L15.5898 9.21013C15.5545 9.78032 15.095 10.208 14.5294 10.208H13.5044C12.9389 10.208 12.4794 9.78032 12.4441 9.21013L12.338 7.82031C11.0656 8.42613 10.2173 9.74468 10.2173 11.2058C10.2173 11.277 10.2173 11.384 10.2173 11.4552H10.1113C9.89919 11.4552 9.75781 11.5978 9.75781 11.8116C9.75781 12.0254 9.89919 12.168 10.1113 12.168H10.6414H17.3571H17.8872C18.0993 12.168 18.276 12.0254 18.276 11.8116Z'
      fill='#004D77'
    />
    <path
      d='M13.5017 9.53019H14.5268C14.7035 9.53019 14.8449 9.38764 14.8802 9.20946L14.9862 7.53455C15.0216 7.24945 14.7742 7 14.4914 7H13.5724C13.2897 7 13.0423 7.24945 13.0776 7.53455L13.1836 9.20946C13.1836 9.38764 13.325 9.53019 13.5017 9.53019Z'
      fill='#004D77'
    />
    <path
      d='M14.0338 16.9805C15.801 16.9805 17.3209 14.8067 17.4269 12.918H10.6406C10.7467 14.7711 12.2665 16.9805 14.0338 16.9805Z'
      fill='#004D77'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.81818 16.1693V4.07884C1.81818 3.69655 2.05734 3.35512 2.4166 3.22448L11.2083 0.0274902C11.4442 -0.0583007 11.705 0.0634084 11.7908 0.299336C11.8089 0.349127 11.8182 0.401699 11.8182 0.454672V4.95724L17.5602 6.87124C17.9315 6.99498 18.1818 7.34238 18.1818 7.73368V16.1693H20V17.9875H0V16.1693H1.81818ZM3.63636 16.1693H10V2.40154L3.63636 4.71559V16.1693ZM16.3636 16.1693V8.38896L11.8182 6.87376V16.1693H16.3636Z" fill="#226CC0"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
