import React from 'react'

function ArrowRightIcon() {
  return (
    <svg width="20" height="35" viewBox="0 0 25 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 35.3L15.465 20L0 4.7L4.78437 0L25 20L4.78437 40L0 35.3Z" fill="var(--color-additional-elements)"/>
    </svg>
  )
}

export default ArrowRightIcon