import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18.1818 3.07692H16.3636V1.53846C16.3636 0.692308 15.5455 0 14.5455 0H1.81818C0.818182 0 0 0.692308 0 1.53846V15.3846C0 16.2308 0.818182 16.9231 1.81818 16.9231H3.63636V18.4615C3.63636 19.3077 4.45455 20 5.45455 20H18.1818C19.1818 20 20 19.3077 20 18.4615V4.61538C20 3.76923 19.1818 3.07692 18.1818 3.07692ZM3.63636 4.61538V15.3846H1.81818V1.53846H14.5455V3.07692H5.45455C4.45455 3.07692 3.63636 3.76923 3.63636 4.61538ZM18.1818 18.4615H5.45455V4.61538H18.1818V18.4615Z' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
