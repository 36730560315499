import { Button } from 'antd';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import cn from 'classnames';
import DeleteIcon from 'components/icons/DeleteIcon';
import FilterSearchIcon from 'components/icons/FilterSearchIcon';
import { ControlType } from 'interfaces/BaseComponentInterface';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import { updateFiltersConfig } from 'pages/entityList/entityListFilters/EntityListFilters';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import ControlFactory, { FilterComponent } from 'utils/ControlFactory';
import MiscUtils from 'utils/MiscUtils';
import { genNewObjectID } from 'utils/ObjectUtils';
import stylesEntityList from '../../index.module.css';
import styles from './index.module.css';

interface EntityListCutomerFiltersProps {
  filtersForm: any;
  factory?: ControlFactory;
  filters?: any;
  className: string;
  doSearch: any;
  filtersConfiguration: any;
  tableId: string;
  checkedFilters?: any;
  showFiltersToolsState: any;
  isShortView: boolean;
  count: any;
  selectedRows: any;
}

const TableViewHeader: React.FC<EntityListCutomerFiltersProps> = (props) => {
  const {
    factory,
    filtersForm,
    filtersConfiguration,
    doSearch,
    tableId,
    showFiltersToolsState,
    isShortView,
  } = props;

  const [isResetFilters, setIsResetFilters] = useState(false);
  const [valuesToForm, setValuesToForm] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (factory) {
      const formValues: any = filtersForm.getFieldsValue(true);
      const newValuesToForm: any = {};
      factory
        .getComponents()
        .filter((c) => c.isCustom && c.jsonConfig?.defaultValue)
        .forEach((c: FilterComponent) => {
          newValuesToForm[`${c.propName}`] = c.jsonConfig?.defaultValue;
          if (c.type === ControlType.SELECT) {
            c.className = styles.defaultSelectWidth;
          }
        });

      if (Object.keys(newValuesToForm).length)
        setValuesToForm({ ...formValues, ...newValuesToForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factory]);

  useEffect(() => {
    filtersForm.resetFields();
    filtersForm.setFieldsValue({ ...valuesToForm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesToForm]);

  const clearFilters = () => {
    dispatch({
      type: ActionType.IS_CHECKED_FILTERS,
      payload: {
        reset: true,
      },
    });
    setIsResetFilters(true);
  };

  useEffect(() => {
    if (props.checkedFilters.fields && isResetFilters) {
      filtersForm.resetFields();
      setIsResetFilters(false);
      dispatch({
        type: ActionType.UPDATE_FILTERS,
        payload: {
          filtersId: tableId,
          filters: {},
          reset: true,
        },
      });
    }
  }, [props.checkedFilters]);

  const searchAllFilters = () => {
    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload: {
        filtersId: tableId,
        filters: filtersForm.getFieldsValue(),
        reset: false,
      },
    });
  };
  return (
    <div
      className={cn(stylesEntityList.wrapper, {
        [stylesEntityList.wrapperForCardTable]: isShortView,
        [stylesEntityList.filtersTools_hidden]: !showFiltersToolsState,
      })}
    >
      <div
        className={cn(styles.customFilters, {
          [styles.customFilters_hidden]: !showFiltersToolsState,
        })}
      >
        <Form form={filtersForm} layout='inline' className={styles.form}>
          <EntityEditorContext.Provider
            value={{
              form: filtersForm,
              factory,
              documentId: genNewObjectID(),
            }}
          >
            {showFiltersToolsState && (
              <>
                <Button
                  style={{ marginBottom: 0 }}
                  onClick={clearFilters}
                  className={stylesEntityList.button}
                  icon={
                    <DeleteIcon
                      className={stylesEntityList.iconButton}
                    />
                  }
                >
                  Сбросить
                </Button>

                <Button
                  style={{ marginBottom: 0 }}
                  onClick={searchAllFilters}
                  className={stylesEntityList.button}
                  icon={
                    <FilterSearchIcon
                      className={stylesEntityList.iconButton}
                    />
                  }
                >
                  Применить
                </Button>
              </>
            )}
            {factory != null &&
              factory.getComponents().map((filter: FilterComponent, index: number) => {
                if (filter.isHidden || !filter.isCustom) {
                  return <React.Fragment key={`cf_${index}`} />;
                }
                const formItemProps: any = {
                  name: filter.propName,
                };
                if (filter.type === ControlType.CHECKBOX) {
                  formItemProps.valuePropName = 'checked';
                }
                return (
                  <div key={`cf_${index}`} className={styles.wrap}>
                    <FormItem
                      {...formItemProps}
                      className={`${styles.formItem} ${MiscUtils.notNullString(filter.className)}`}
                      style={{ marginBottom: 0 }}
                    >
                      {filter.component}
                    </FormItem>
                  </div>
                );
              })}
          </EntityEditorContext.Provider>
        </Form>
      </div>
    </div>
  );
};

export default connect((state: AppState) => {
  return {
    checkedFilters: state.checkedFilters,
  };
})(TableViewHeader);
