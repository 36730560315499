import axios from 'axios';

import notification from 'components/messages/notification';
import modal from 'components/messages/modal';
import WrappedError from 'utils/WrappedError';

import { JsObject } from 'interfaces/Object';
import { AxiosResponseExt, AxiosResponseNull } from 'interfaces/AxiosResponseExt';

export interface ServerErrorOptions extends JsObject {
  notificationText?: string;
  response?: AxiosResponseExt;
}

class ServerError extends WrappedError {
  response: AxiosResponseNull;
  canceled: boolean;

  constructor(error: any, options?: ServerErrorOptions) {
    super(error, options);
    this.name = 'ServerError';
    this.response = this.options?.response || null;
    this.canceled = axios.isCancel(this.error);
  }

  notify(width?: number): null {
    if (this.canceled) return null;

    const notificationText = this.options?.notificationText;

    // if (notificationText) {
    //   if (notificationText.length > 50) {
    //     modal.showTextError({
    //       contentText: notificationText,
    //     });
    //   } else {
    //     notification.error({
    //       text: notificationText,
    //       description: this.message,
    //       screenWidth: width,
    //     });
    //   }
    // }

    if (notificationText) {
      modal.showTextError({ contentText: this.message, title: notificationText });
    }

    return null;
  }
}

export default ServerError;
