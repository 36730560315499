import React, { useEffect, useState } from 'react';
import styles from './CardItem.module.css';
import ImportantIcon from '../icons/card/ImportantIcon';
import { Button, Card } from 'antd';
import shave from 'shave';
import { useWindowWidth } from '@react-hook/window-size';
import InWorkIcon from '../icons/entityListIcons/InWorkIcon';
import FunctioningIcon from '../icons/card/FunctioningIcon';
import InDevelopingIcon from '../icons/card/InDevelopingIcon';
import { CardElem, Icons } from '../../utils/types';
import ModalCard from '../modalCard/ModalCard';
import FileModalPreview from '../controls/FilesList/FileModalPreview';

interface CardProps {
  item: CardElem;
  date: string;
}

export const categoryIcons: Icons = {
  Функционирует: <FunctioningIcon />,
  'На обслуживании': <InWorkIcon />,
  'В разработке': <InDevelopingIcon />,
  important: <ImportantIcon />,
};

const CardItem: React.FC<CardElem> = (item) => {
  const width = useWindowWidth();
  const [isOpenModalCard, setIsOpenModalCard] = useState<boolean>(false);
  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<CardElem>({
    date: '',
    info: '',
    category: '',
    files: [],
    imgs: [],
    important: false,
    notes: null,
    title: '',
  });
  const { Meta } = Card;

  const handleHeight = (width: number) => {
    if (width >= 2560) {
      shave('.shave', 95);
    } else if (width <= 2499 && width >= 1950) {
      shave('.shave', 90);
    } else if (width <= 1949 && width >= 1500) {
      shave('.shave', 70);
    } else if (width <= 1499 && width >= 200) {
      shave('.shave', 50);
    } else {
      shave('.shave', 100);
    }
  };

  useEffect(() => {
    handleHeight(width);
  }, [width, item]);

  const openModalCard = (card: CardElem) => {
    setIsOpenModalCard(true);
    setSelectedCard(card);
  };
  const closeModalCard = () => {
    setIsOpenModalCard(false);
  };

  return (
    <>
      <Card
        className={styles.card}
        cover={
          <img
            className={styles.cardImage}
            alt='Изображение новости'
            onClick={() => setIsOpenPreview(true)}
            src={item.imgs[0]}
          />
        }
      >
        <Meta
          title={
            <>
              <div className={styles.titleWrap}>
                <div className={styles.cardDate}>{item.date}</div>
                <div className={styles.cardCategory}>{item.category}</div>
              </div>
              <div className={styles.cardTitleWrap}>
                <div className={styles.cardTitle}>{item.title}</div>
                <div className={styles.cardIcon}>
                  {item.important ? categoryIcons.important : categoryIcons[item.category]}
                </div>
              </div>
            </>
          }
          description={<div className={`${styles.cardInfo} shave`}>{item.info}</div>}
        />
        <Button className={styles.cardButton} onClick={() => openModalCard(item)}>
          Подробнее
        </Button>
      </Card>
      <ModalCard isOpen={isOpenModalCard} onClose={closeModalCard} card={selectedCard} />
      <FileModalPreview
        isOpen={isOpenPreview}
        onClose={() => setIsOpenPreview(false)}
        result={item.imgs[0]}
        pdf={false}
      />
    </>
  );
};

export default CardItem;
