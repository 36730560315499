import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='25' cy='25' r='24.5' fill='white' stroke='var(--color-additional-elements)' />
    <rect
      x='10'
      y='28.2441'
      width='6.43315'
      height='16.6226'
      rx='3.21657'
      transform='rotate(-45 10 28.2441)'
      fill='var(--color-additional-elements)'
    />
    <rect
      x='34.6045'
      y='11.666'
      width='6.43315'
      height='29.1115'
      rx='3.21657'
      transform='rotate(35 34.6045 11.666)'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
