import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { JsObject } from 'interfaces/Object';
import { isNewID } from 'utils/ObjectUtils';

export type GetToolsData = (args: {
  className?: string;
  objectID?: string;
  width?: number;
}) => Promise<any>;

export const getToolsData: GetToolsData = async ({ className, objectID, width }) => {
  const params: JsObject = {
    className: className || '',
  };
  if (!isNewID(objectID)) params.id = objectID;

  const response: AxiosResponseNull = await ServerAPI.getToolsData(params).catch(
    (serverError: ServerError) => serverError.notify(width)
  );

  return response?.data;
};

const ToolsCrud = {
  getToolsData,
};

export default ToolsCrud;
