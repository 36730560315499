import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 52 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M51 25.2187C51 38.594 39.8068 49.4375 26 49.4375C12.1932 49.4375 1 38.594 1 25.2187C1 11.8435 12.1932 1 26 1C39.8068 1 51 11.8435 51 25.2187Z'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M35.1926 34.1221L16.8125 16.3164'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M35.1926 16.3164L16.8125 34.1221'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
