import React from 'react';
import { Input, InputNumber, Tooltip } from 'antd';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { DataType } from 'interfaces/BaseComponentInterface';
import styles from './InputText.module.css';
import DisabledIcon from 'components/icons/DisabledIcon';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormHandler from 'controllers/FormHandler';
import { tryDateStringify } from 'utils/DateUtils';
import { JsObject } from 'interfaces/Object';
import EyeIcon from 'components/icons/EyeIcon';
import EyeInvisibleIcon from 'components/icons/EyeInvisibleIcon';
import HintIcon from '../SelectControl/HintIcon';

const InputText: React.FC<BaseComponentProps> = (props) => {
  const { ownProps } = props;
  const {
    readonly: readOnly,
    hint,
    defaultValueRaw,
    dataType,
    templateName,
  } = ownProps.component || {};

  const suffix = readOnly ? <DisabledIcon /> : <></>;

  const inputBaseProps = {
    defaultValue: defaultValueRaw || '',
    className: styles.input,
    disabled: readOnly,
    readOnly,
    suffix,
    autoComplete: 'off',
    ...FormHandler.getFormProps(props),
  };
  // на всякий случай если залетела дата
  inputBaseProps.value = tryDateStringify(inputBaseProps.value);

  const tooltipEl = hint ? (
    <div className={styles.tooltipContainer}>
      <HintIcon hint={hint} cssClassTooltip={styles.tooltip} />
    </div>
  ) : null;

  if (dataType === DataType.LONG || dataType === DataType.DOUBLE) {
    const { maxValue = 999999999999999, separator = templateName === 'currency' ? ' ' : '' } =
      ownProps.component?.params || {};

    const maxValueLength = String(maxValue).length;
    const separatorCount =
      maxValueLength % 3 === 0 ? maxValueLength / 3 - 1 : Math.floor(maxValueLength / 3);
    const maxLength = maxValueLength + separatorCount * String(separator).length;

    const inputNumberProps: JsObject = {
      ...inputBaseProps,
      maxLength,
      max: maxValue,
      decimalSeparator: ',',
    };

    if (templateName === 'currency') {
      inputNumberProps.formatter = (value: any) => {
        // Разбиваем число на разряды
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
      };
      inputNumberProps.parser = (value: any) => {
        // Удаляем separator и возвращаем число
        const pattern = new RegExp(separator, 'g');
        return Number(value.replace(pattern, ''));
      };
    }

    return (
      <div className={styles.container}>
        <InputNumber {...inputNumberProps} />
        {readOnly && <span className={styles.customIcon}>{suffix}</span>}
        {tooltipEl}
      </div>
    );
  }

  if (ownProps?.component?.propertyType === 'PASSWORD') {
    return (
      <div className={styles.container}>
        <Input.Password {...inputBaseProps} autoComplete='new-password' iconRender={(visible) => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} />
      </div>
    )
  }


  return (
    <div className={styles.container}>
      <Input {...inputBaseProps} maxLength={32767} />
      {tooltipEl}
    </div>
  );
};

export default React.memo(InputText);
