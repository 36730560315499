import { Action, ActionType } from 'store/actionTypes';
import _ from 'lodash';
import { ensureArray } from 'utils/MiscUtils';

export interface RefreshState {
  [key: string]: number;
}

const initialState: RefreshState = {};

export const refresh = (
  state: RefreshState = initialState,
  { type, payload }: Action<ActionType, string | string[]>
): RefreshState => {
  switch (type) {
    case ActionType.REFRESH_COMPONENT: {
      const componentIDs: string[] = ensureArray(payload, { flat: true, noFalsy: true });

      if (!componentIDs.length) return state;

      const newState = { ...state };
      componentIDs.forEach((cid: string) => {
        newState[cid] = newState[cid] || 0;
        newState[cid]++;
      });

      return newState;
    }
    case ActionType.REFRESH_COMPONENT_RESET: {
      if (!payload) return initialState;

      const componentIDs = ensureArray(payload, { flat: true, noFalsy: true });
      if (!componentIDs.length) return state;

      const newState = { ...state };

      let deleted: boolean = false;
      componentIDs.forEach((cid: string) => {
        if (newState[cid]) {
          deleted = true;
          delete newState[cid];
        }
      });

      if (!deleted) return state;

      return newState;
    }
    default:
      return state;
  }
};

export default refresh;
