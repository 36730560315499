import React, { useState } from 'react';
import cn from 'classnames';
import styles from 'components/TableView/index.module.css';
import { Row } from 'antd';
import TableViewToolButton from 'components/TableView/components/TableViewToolButton';

export const TableViewTools = ({ isShortView, tableViewToolsButtonProps, initTools, readonly }: any) => {
  // только чтобы запустить ререндер
  const [refresh, setRefresh] = useState<number>(0);

  const handleChangeVisibleButton = (addTools: any[] = [], removeTools: any[] = []) => {
    if (!initTools) return;

    initTools.forEach((tool: any) => {
      if (!tool.id) return;

      const add = addTools.includes(tool.id);
      const remove = removeTools.includes(tool.id);

      if (add && !remove) tool.hidden = false;
      if (remove && !add) tool.hidden = true;
    });

    setRefresh((prevState) => prevState + 1);
  };

  if (isShortView)
    return (
      <div className={cn(styles.wrapper, styles.wrapperForCardTable)}>
        <Row className={styles.wrapperButton}>
          {initTools?.map((tool: any) => (
            <TableViewToolButton
              {...{
                ...tableViewToolsButtonProps,
                key: String(tool.label + tool.action + tool.icon),
                title: tool.label,
                icon: tool.icon,
                name: tool.action,
                creationType: tool.creationType,
                fields: tool.fields,
                needUpdate: tool.update,
                hidden: tool.hidden,
                addTools: tool.addTools,
                removeTools: tool.removeTools,
                handleChangeVisibleButton,
              }}
              readonly={readonly}
            />
          )) || null}
        </Row>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <Row className={styles.wrapperButton}>
        {initTools?.map((tool: any) => {
          if (!tool) return null;

          const buttons: [string, any][] = Object.entries(tool);

          if (!buttons.length) return null;

          return buttons.map(([name, button]) => {
            return (
              <TableViewToolButton
                {...{
                  key: String(button.title + button.icon + name),
                  ...tableViewToolsButtonProps,
                  ...button,
                  name,
                  handleChangeVisibleButton,
                }}
              />
            );
          });
        }) || null}
      </Row>
    </div>
  );
};
