import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.6663 33.332H3.33301V29.9987H4.99967V18.3837C4.99967 10.0704 11.7163 3.33203 19.9997 3.33203C28.283 3.33203 34.9997 10.0704 34.9997 18.3837V29.9987H36.6663V33.332ZM8.33301 29.9987H31.6663V18.3837C31.6663 11.912 26.443 6.66536 19.9997 6.66536C13.5563 6.66536 8.33301 11.912 8.33301 18.3837V29.9987ZM15.833 34.9987H24.1663C24.1663 36.1038 23.7274 37.1636 22.946 37.945C22.1646 38.7264 21.1047 39.1654 19.9997 39.1654C18.8946 39.1654 17.8348 38.7264 17.0534 37.945C16.272 37.1636 15.833 36.1038 15.833 34.9987Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
