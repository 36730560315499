import { JsObject } from 'interfaces/Object';
import { isBlob, isSimpleObjectOrArray } from './MiscUtils';

export type FormDataFields = JsObject<Blob | any[] | JsObject | string | number | null | undefined>;

type FormDataAppend = (fields: FormDataFields, strict?: boolean) => FormData;
type GenFormDataAppend = (formData: FormData) => FormDataAppend;
type BuildFormData = (fields: FormDataFields | FormData, strict?: boolean) => FormData;

/**
 * Возвращает функцию добавления полей к прокинутой FormData
 */
export const appendFormData: GenFormDataAppend = (formData) => {
  /**
   * Добавляет поля к FormData
   */
  const append: FormDataAppend = (fields, strict) => {
    for (const key in fields) {
      let value = fields[key];

      if (value === null || value === undefined) continue;
      if (strict && !value) continue;

      if (!isBlob(value)) {
        if (isSimpleObjectOrArray(value))
          value = JSON.stringify(value, function (k, v) {
            return v === undefined ? null : v;
          });
        value = String(value);
      }

      formData.append(key, value);
    }

    return formData;
  };

  return append;
};

/**
 * Создает FormData
 */
export const buildFormData: BuildFormData = (fields, strict) => {
  if (fields instanceof FormData) return fields;
  return appendFormData(new FormData())(fields, strict);
};

export default buildFormData;
