import { MAX_SIZE, SMALL_SIZE, TableField } from 'interfaces/EntityList';
import { isNaturalNumber } from 'utils/MiscUtils';
import { cellWidth } from 'utils/types';

export const handleWidthForTableCell = (mock: cellWidth[], field: any, obj: TableField) => {
  mock.forEach((item: cellWidth) => {
    if (field.fieldName === item.name || field.name === item.name) {
      obj.width = item.width;
    }
  });
};

export const getTableHeightForShortView = (windowWidth: any = window.screen.width) => {
  return windowWidth >= MAX_SIZE ? 500 : windowWidth > SMALL_SIZE ? 310 : 195;
};

export const tryParsePercentage = (value: any): string | undefined => {
  if (typeof value === 'string') {
    value = Number(value.replace(/[^0-9]/g, ''));
  }

  return isNaturalNumber(value) ? `${value}%` : undefined;
};
