import { ActionType, Action } from '../actionTypes';

export interface AppSettings {
  dateFormat: string;
}

const initialState = {
  dateFormat: 'DD.MM.YYYY',
};

export const settings = (state: AppSettings = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_APP_SETTINGS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default settings;
