import { useDispatch } from 'react-redux';
import { ActionType } from 'store/actionTypes';

export type RefreshAction = (refreshIDs: string | string[]) => void;

type UseRefreshComponent = () => {
  refreshComponent: RefreshAction;
  resetRefreshComponent: RefreshAction;
  resetRefreshAll: () => void;
};

// provide component id to subscribe component to refresh
// if only trigger refresh needed - id should be omitted
export const useRefreshComponent: UseRefreshComponent = () => {
  const dispatch = useDispatch();

  const refreshComponent: RefreshAction = (refreshIDs) => {
    dispatch({
      type: ActionType.REFRESH_COMPONENT,
      payload: refreshIDs,
    });
  };

  const resetRefreshComponent: RefreshAction = (refreshIDs) => {
    dispatch({
      type: ActionType.REFRESH_COMPONENT_RESET,
      payload: refreshIDs,
    });
  };

  const resetRefreshAll = () => {
    dispatch({
      type: ActionType.REFRESH_COMPONENT_RESET,
    });
  };

  return {
    refreshComponent,
    resetRefreshComponent,
    resetRefreshAll,
  };
};

export default useRefreshComponent;
