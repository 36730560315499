import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormList from 'antd/lib/form/FormList';
import { uniqueId } from 'lodash';

import FileItem from './FileItem';
import FileNew from './FileNew';
import withComponentRefresh from 'components/hocs/withComponentRefresh';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';

import styles from './FilesList.module.css';

import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { AppState } from 'store/reducers';
import { DocumentEditorFiles, EditorFile } from 'store/reducers/editor_files';

interface FilesListProps extends BaseComponentProps {
  standalone?: boolean;
  standaloneFile?: File;
  onFileSelect?: (file: File) => void;
  onFileDelete?: () => void;
  withoutDownload?: boolean;
  withoutPreview?: boolean;
  isInModal?: boolean;
}

const FilesList: React.FC<FilesListProps> = (props) => {
  const {
    ownProps,
    standalone,
    onFileSelect,
    isInModal,
    standaloneFile,
    onFileDelete,
    withoutDownload,
    withoutPreview,
  } = props;

  const propName: string = ownProps.component?.propName || '';
  const deleteIsEnabled: boolean = Boolean(ownProps?.component?.params?.deleteIsEnabled);

  const [ready, setReady] = useState<boolean>(false);
  const context = useContext(EntityEditorContext);
  const { form, documentId } = context || {};
  const documentFiles: DocumentEditorFiles = useSelector(
    (state: AppState) => documentId && state.editorFiles[documentId]
  );

  useEffect(() => {
    if (form || standalone) setReady(true);

    if (form && propName && !form.getFieldValue(propName)) {
      form.setFieldsValue({ [propName]: [] });
    }
  }, [context, form, standalone]);

  return (
    <div className={isInModal ? styles.listInModal : styles.list}>
      <FileNew ownProps={ownProps} standalone={standalone} onFileSelect={onFileSelect} />
      <FormList name={propName}>
        {() =>
          documentFiles && (
            <>
              <React.Fragment key={`f_${documentId}`}>
                {Object.entries(documentFiles).map(([fileId, fileInfo]: [string, EditorFile]) => (
                  <React.Fragment key={`f_${fileId}`}>
                    <FormItem noStyle={true}>
                      <Input hidden={true} />
                    </FormItem>
                    <FileItem fileInfo={fileInfo} deleteIsEnabled={deleteIsEnabled} />
                  </React.Fragment>
                ))}
              </React.Fragment>
            </>
          )
        }
      </FormList>
      {!!standaloneFile && (
        <FileItem
          fileInfo={{
            documentId: uniqueId(),
            toSave: false,
            file: {
              id: uniqueId(),
              params: {
                Url: '#',
                fileName: standaloneFile.name,
                fileSize: standaloneFile.size,
                unsaved: false,
              },
            },
          }}
          deleteFile={onFileDelete}
          standalone={standalone}
          deleteIsEnabled={true}
          withoutDownload={withoutDownload}
          withoutPreview={withoutPreview}
        />
      )}
    </div>
  );
};

export default withComponentRefresh(FilesList);
