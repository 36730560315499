import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg
    width='1.2em'
    height='1.2em'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25 18.1981V23.7394C25 23.8497 24.9843 23.9522 24.9606 24.0468C24.8504 24.5907 24.3703 25.0006 23.8035 25.0006H1.1886C0.582494 25.0006 0.0787154 24.5355 0.0157431 23.9443C5.98205e-08 23.8734 0 23.8103 0 23.7315V18.1902C0 17.5359 0.535265 17.0078 1.18073 17.0078C1.50346 17.0078 1.80258 17.1418 2.01511 17.3546C2.22765 17.5675 2.36146 17.867 2.36146 18.1902V22.628H22.6464V18.1902C22.6464 17.5359 23.1817 17.0078 23.8271 17.0078C24.1499 17.0078 24.449 17.1418 24.6615 17.3546C24.8662 17.5753 25 17.8749 25 18.1981Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M19.3043 12.6355L13.503 18.4448C13.4951 18.4606 13.4794 18.4685 13.4715 18.4764C13.259 18.6892 12.9835 18.8232 12.708 18.8626C12.6843 18.8626 12.6607 18.8705 12.6371 18.8705C12.5899 18.8784 12.5427 18.8784 12.4954 18.8784L12.3616 18.8705C12.338 18.8705 12.3144 18.8626 12.2908 18.8626C12.0074 18.8232 11.7398 18.6892 11.5272 18.4764C11.5194 18.4685 11.5036 18.4527 11.4957 18.4448L5.69442 12.6355C5.42678 12.3675 5.29297 12.0128 5.29297 11.6581C5.29297 11.3034 5.42678 10.9487 5.69442 10.6807C6.22968 10.1447 7.10342 10.1447 7.64656 10.6807L11.1336 14.1726V1.37942C11.1336 0.62271 11.7555 0 12.5112 0C12.889 0 13.2354 0.157648 13.4872 0.402003C13.7391 0.65424 13.8887 0.993184 13.8887 1.37942V14.1726L17.3758 10.6807C17.911 10.1447 18.7848 10.1447 19.3279 10.6807C19.8396 11.2246 19.8396 12.0995 19.3043 12.6355Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
