import { ActionType, Action } from '../actionTypes';

const initialState = {
  id: undefined,
};

const user = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_USER:
      return { ...action.payload };
    default:
      return state;
  }
};

export default user;
