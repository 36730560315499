import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Link,
  RouteComponentProps,
  RouteProps,
  matchPath,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Dispatch } from 'redux';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import axios, { AxiosResponse } from 'axios';
import MenuIcon10 from 'components/icons/menu/MenuIcon10';
import MenuIcon11 from 'components/icons/menu/MenuIcon11';
import MenuIcon12 from 'components/icons/menu/MenuIcon12';
import MenuIcon13 from 'components/icons/menu/MenuIcon13';
import MenuIcon14 from 'components/icons/menu/MenuIcon14';
import MenuIcon15 from 'components/icons/menu/MenuIcon15';
import MenuIcon16 from 'components/icons/menu/MenuIcon16';
import MenuIcon17 from 'components/icons/menu/MenuIcon17';
import MenuIcon18 from 'components/icons/menu/MenuIcon18';
import MenuIcon2 from 'components/icons/menu/MenuIcon2';
import MenuIcon3 from 'components/icons/menu/MenuIcon3';
import MenuIcon4 from 'components/icons/menu/MenuIcon4';
import MenuIcon5 from 'components/icons/menu/MenuIcon5';
import MenuIcon6 from 'components/icons/menu/MenuIcon6';
import MenuIcon7 from 'components/icons/menu/MenuIcon7';
import MenuIcon8 from 'components/icons/menu/MenuIcon8';
import Routes, { EntityEditorRouteParams } from 'router/Routes';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import composeLink from 'utils/composeLink';
import { Icons } from 'utils/types';
import styles from './LeftMenu.module.css';

export interface MenuItemProps {
  key: any;
  title: string;
  inbox: InboxItem;
  icon: string;
  children?: MenuItemProps[];
  description?: string | null;
  hasStartPage: boolean;
}

interface InboxTool {
  controller: string;
  inboxName: string;
  label: string;
  tool: string;
}

interface InboxGroupWrapper {
  inboxGroup: InboxGroup;
  tools: InboxTool[];
  mainPageInboxes?: [];
}

interface InboxGroup {
  description: string | null;
  inboxes: string[];
  label: string;
  icon: string;
  nestedInboxGroups: InboxGroup[];
  controllerName: string | null;
}

interface InboxItem {
  caseName: string;
  classifiers?: any;
  controllerName?: any;
  id: string;
  inboxName: string;
  label: string;
  name: string;
  params: any;
  queueName?: string;
  roles: any[];
  search: string;
  showCount: boolean;
  type?: any;
  hideInMenu?: boolean;
  icon: string;
}

export interface LeftMenuData {
  inboxGroups: InboxGroupWrapper[];
  inboxes: { [key: string]: any };
}

interface LeftMenuProps {
  menu: LeftMenuData;
  location: any;
}

interface MenuSelected {
  group: string[];
  inbox: string[];
}

const LeftMenu: React.FC<LeftMenuProps & RouteComponentProps> = (props) => {
  const { menu, location, history } = props;

  const currentRoute: any = Routes.find((route: RouteProps) => matchPath(location.pathname, route));
  const currentMatch = useRouteMatch<EntityEditorRouteParams>(currentRoute);

  const { inboxName, className, groupID, controllerName } = currentMatch?.params || {};

  const dispatch = useDispatch();
  const [selected, setSelected] = useState<MenuSelected>({
    group: [],
    inbox: [],
  });

  useEffect(() => {
    const getSelectedKey = () => {
      const result: MenuSelected = {
        group: [],
        inbox: [],
      };

      let selectedFound: boolean = false;
      if (menu != null && menu.inboxGroups != null) {
        menu.inboxGroups.forEach((group: InboxGroupWrapper, ig: number) => {
          if (groupID != null && groupID === group.inboxGroup.label) {
            result.group = [`${ig}`];
            result.inbox = [];
            return result;
          }
          const ib: number = group.inboxGroup.inboxes.findIndex(
            (inbox: string) => inbox === inboxName
          );

          if (ib !== -1 && !selectedFound) {
            result.group = [`${ig}`];
            result.inbox = [`${ig}_${ib}`];
            // В CSDP для главной страницы модуля
            if (process.env.REACT_APP_TYPE === 'csdp' && !className && !controllerName) result.inbox = [];
            selectedFound = true;
          }
        });
      }
      return result;
    };
    const selected: MenuSelected = getSelectedKey();
    if (inboxName || !className) setSelected(selected);
  }, [inboxName, className, groupID, menu, controllerName]);

  const _buildItem = (
    data: LeftMenuData,
    group: InboxGroup,
    key: string,
    hasStartPage: boolean
  ) => {
    const item: any = {
      key,
      title: group.label,
      icon: group.icon,
      hasStartPage,
      description: group?.description,
      children: !group.controllerName ? group.inboxes.reduce((result: any[], current: string, index: number) => {
        const inbox: InboxItem | undefined = data.inboxes[current];
        if (inbox != null && !(inbox?.params?.hideInMenu === true)) {
          result.push({
            key: `${key}_${index}`,
            title: inbox.label,
            inbox,
            // className: current,
          });
        }
        return result;
      }, []) : [],
    };
    return item;
  };

  const buildMenuProps = (data: LeftMenuData) => {
    const menu: any = [];
    if (data != null && data.inboxGroups != null) {
      data.inboxGroups.forEach((group: InboxGroupWrapper, groupIndex: number) => {
        menu.push(
          _buildItem(
            data,
            group.inboxGroup,
            `${groupIndex}`,
            group.mainPageInboxes !== undefined ? true : false
          )
        );
      });
    }
    return menu;
  };

  // desktopName - default
  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('desktopName', 'default');
    axios.post('/GetInboxGroup/', bodyFormData).then((response: AxiosResponse) => {
      dispatch({
        type: ActionType.UPDATE_MENU_DATA,
        payload: response.data,
      });
    });
  }, [dispatch]);

  const MenuIcons: Icons = {
    iconPiG: <MenuIcon4 />,
    iconTO: <MenuIcon8 />,
    iconOsc: <MenuIcon5 />,
    iconWebPortal: <MenuIcon11 />,
    iconOperatingData: <MenuIcon6 />,
    iconMTO: <MenuIcon7 />,
    // 'Заказ и поставка ВС': <PlaneIcon/>,
    iconContract: <MenuIcon2 />,
    iconTechnicalPublications: <MenuIcon3 />,
    // Обучение: <MenuIcon9 />,
    iconFlightOperation: <MenuIcon10 />,
    iconManuals: <MenuIcon12 />,
    iconAdmin: <MenuIcon13 />,
    iconTechPublications: <MenuIcon14 />,
    iconMaterialTechSup: <MenuIcon15 />,
    iconLearning: <MenuIcon16 />,
    iconAboutCompany: <MenuIcon17 />,
    iconParkControl: <MenuIcon18 />
  };

  const getTitleRoute = (item: MenuItemProps) => {
    if (item.hasStartPage || item.children?.length === 0) {
      return composeLink({ inboxGroup: item.title });
    } else {
      return composeLink({
        inbox: item.children?.[0].inbox.name,
        className: item.children?.[0].inbox.caseName,
      });
    }
  };

  const isTitleLink = (item: MenuItemProps) => {
    return !!item.children && item.children.length === 1 && !item.description && !!item.children[0].inbox.params.href
  }

  const getTitleLink = (item: MenuItemProps) => {
    if (isTitleLink(item)) {
      return <div className={styles.titleLink}> {item.title}</div>;
    } else {
      return (
        <Link to={getTitleRoute(item)} className={styles.titleLink}>
          {item.title}
        </Link>
      );
    }
  };

  const handleTitleClick = (title: MenuItemProps) => {
    if (title.hasStartPage) {
      history.push(composeLink({ inboxGroup: menu.inboxGroups[title.key].inboxGroup.label }));
    } else {
      if (isTitleLink(title)) {
        window.open(title.children![0].inbox.params.href);
      } else if (process.env.REACT_APP_TYPE === 'csdp') {
        // В CSDP редирект на главную страницу модуля 
        history.push(
          composeLink({
            inboxGroup: menu.inboxGroups[title.key].inboxGroup.label,
          })
        );
      } else {
        history.push(
          composeLink({
            inbox: title.children?.[0].inbox.name,
            className: title.children?.[0].inbox.caseName,
          })
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <Menu selectedKeys={selected.inbox} openKeys={selected.group} mode='inline'>
        {buildMenuProps(menu).map((item: MenuItemProps) => {
          return (
            <SubMenu
              key={item.key}
              icon={MenuIcons[item.icon]}
              onTitleClick={() => handleTitleClick(item)}
              title={getTitleLink(item)}
              className={styles.submenu}
            >
              {item.icon !== 'iconTechnicalPublications' &&
                item.children?.map((subItem: MenuItemProps) => {
                  if (!!subItem.inbox.params.href) {
                    return (
                      <Menu.Item key={subItem.key}>
                        <a target='_blank' href={subItem.inbox.params.href}>
                          {subItem.inbox?.label}
                        </a>
                      </Menu.Item>
                    )
                  }
                  else if (subItem.inbox.controllerName) {
                    return (
                      <Menu.Item key={subItem.key}>
                        <Link
                          to={composeLink({
                            inbox: subItem.inbox?.name || '',
                            controllerName: subItem.inbox?.controllerName || '',
                          })}
                        >
                          {subItem.title}
                        </Link>
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <Menu.Item key={subItem.key}>
                        <Link
                          to={composeLink({
                            inbox: subItem.inbox?.name || '',
                            className: subItem.inbox?.caseName || '',
                          })}
                        >
                          {subItem.title}{' '}
                        </Link>
                      </Menu.Item>
                    );
                  }
                })}
            </SubMenu>
          );
        })}
      </Menu>
    </div>
  );
};

export default connect((state: AppState) => {
  return {
    menu: state.menu,
  };
})(withRouter(LeftMenu));
