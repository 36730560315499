import React, { useEffect } from 'react';
import styles from './SiderWithTabs.module.css';
import TabIcons from 'enums/caseIcon';
import { useDispatch } from 'react-redux';
import { ActionType } from 'store/actionTypes';
import { useHistory, useLocation } from 'react-router-dom';
import { getFilteredHash } from 'utils/NavUtils';

interface SiderTabClickHandler {
  link?: string;
  action?: () => void;
}

export interface SiderTab {
  caption: string;
  active?: boolean;
  id: string;
  icon?: string;
  controller?: string;
  params?: any;
  clickHandler?: SiderTabClickHandler;
}

interface SiderTabsProps {
  tabs?: SiderTab[];
  filters?: any;
  buttons?: JSX.Element;
  form?: any;
}

export const DEFAULT_TAB = 'details';

const SiderWithTabs: React.FC<SiderTabsProps> = (props) => {
  const { tabs, buttons, filters } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { hash } = useLocation();

  const fakeTranslate: any = {
    Requisites: 'Данные запроса',
    Attachments: 'Вложения',
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.UPDATE_EDITOR_ERRORS,
        payload: [],
      });
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {tabs && (
          <div className={styles.tabs}>
            {tabs.map((tab: SiderTab, index: number) => {
              const TabIcon = tab.icon
                ? TabIcons[tab.icon]
                : TabIcons[tab.id]
                ? TabIcons[tab.id]
                : false;
              return (
                <div
                  key={`t${index}`}
                  onClick={() => {
                    const filteredHash = getFilteredHash(hash);

                    if (tab.clickHandler?.link != null) {
                      if (filteredHash) {
                        // если находимся в табе и кликаем на "Детали"
                        if (tab.clickHandler?.link === '#details') {
                          history.goBack();
                          return;
                        }

                        // если переходим на таб, на котором находимся
                        if (filteredHash === tab.clickHandler?.link) return;

                        // если мы находимся в табе и переходим на другой таб
                        if (filteredHash !== tab.clickHandler?.link) {
                          history.replace(tab.clickHandler?.link, null);
                          return;
                        }
                      }

                      // если находимя на EntityEditorDetails и кликаем на "Детали"
                      if (tab.clickHandler?.link === '#details') return;
                      history.push(tab.clickHandler?.link);

                      return;
                    }

                    if (tab.clickHandler?.action) {
                      tab.clickHandler?.action();
                    }
                  }}
                  className={[styles.tab, tab.active ? styles.active : ''].join(' ')}
                >
                  {TabIcon ? (
                    <TabIcon className={styles.icon} active={tab.active === true && process.env.REACT_APP_TYPE === 'irk'} />
                  ) : (
                    <></>
                  )}
                  <div className={styles.caption}>{fakeTranslate[tab.caption] || tab.caption}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className={styles.filters}>{filters ? filters : <></>}</div>

        <div className={styles.buttons}>{buttons ? buttons : <></>}</div>
      </div>
    </div>
  );
};

export default SiderWithTabs;
