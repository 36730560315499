import { useCallback, useRef } from 'react';

export type RefStoreType = {
  blockActions: boolean;
};

export type ToggleProcessKeyWrapperType = (
  key: keyof RefStoreType,
  callback: Promise<any> | ((...a: any | undefined) => any),
  options?: ToggleProcessKeyOptionsType
) => (...args: any[]) => Promise<any>;

type ToggleProcessKeyOptionsType = {
  rollbackDelay?: number;
};

/*
  todo:
   At the moment, the value during storage initialization is
   set inside the hook. However, in the future, it is necessary
   to provide the ability to set any value when initializing the
   hook.
 */

export const useRefStore = () => {
  const refStore = useRef<RefStoreType>({ blockActions: false });

  const toggleProcessKeyWrapper: ToggleProcessKeyWrapperType = useCallback(
    (key, callback, options) => {
      return (...args) => {
        if (refStore.current[key]) return new Promise((resolve: any) => resolve(undefined));

        refStore.current[key] = true;

        const asyncProcess =
          typeof callback === 'function'
            ? Promise.resolve().then(() => callback(...args))
            : callback;

        return asyncProcess.finally(() => {
          if (options?.rollbackDelay) {
            setTimeout(() => {
              refStore.current[key] = false;
            }, options.rollbackDelay);
          } else {
            refStore.current[key] = false;
          }
        });
      };
    },
    []
  );

  return { refStore, toggleProcessKeyWrapper };
};

export default useRefStore;
