import { isNull, isString, isUndefined } from 'lodash';
import store from 'store';
import expirePlugin from 'store/plugins/expire';

// store.addPlugin(expirePlugin);

interface StoreJsAPIWithExpiration extends StoreJsAPI {
  set(key: string, value: any, expire?: number): any;
  removeExpiredKeys(): void;
}

const EXPIRE_TIME = 1000 * 60 * 60 * 24;

class CacheStorage {
  static store: any = {};
  static storeName: string = '__irk_store';
  static storeMeta: string = '__irk_meta';
  static storeAllMeta: string = '__irk_all_meta';
  static storeGlobalPending: string = '__irk_pending';
  static persistenceStore: StoreJsAPIWithExpiration = store as StoreJsAPIWithExpiration;
  static sessionStoreJson: string = '__irk_json@';

  static async get(key: string, skipPersistence: boolean = false) {
    if (this.store[key] || skipPersistence) {
      return this.store[key];
    }
    const value = await this.checkAndRestore(key);
    return value;
  }

  static set(key: string, value: any, skipPersistence: boolean = false) {
    this.store[key] = value;
    if (!skipPersistence) {
      this.persistence(key, value);
    }
  }

  static async clearAll() {
    this.store = {};
    this.persistenceStore.clearAll();
  }

  private static normKey(key: string) {
    return `${this.storeName}_${key}`;
  }

  private static checkAndRestore(key: string) {
    return new Promise((resolve) => {
      // this.persistenceStore.removeExpiredKeys();
      const storeKey = this.normKey(key);
      let value: string | null = this.persistenceStore.get(storeKey);
      if (value !== null && !isUndefined(value)) {
        if (value != null && value.indexOf(this.sessionStoreJson) === 0) {
          try {
            value = JSON.parse(value.replace(this.sessionStoreJson, ''));
          } catch {
            /* no-op */
          }
        }
      }
      this.store[key] = value;
      resolve(value);
    });
  }

  private static persistence(key: string, value: any) {
    if (isNull(key) || isUndefined(key)) {
      return;
    }
    if (isNull(value) || isUndefined(value)) {
      this.persistenceStore.remove(key);
      return;
    }
    let storeValue;
    const storeKey = this.normKey(key);
    storeValue = isString(value) ? value : `${this.sessionStoreJson}${JSON.stringify(value)}`;
    try {
      this.persistenceStore.set(storeKey, storeValue, new Date().getTime() + EXPIRE_TIME);
    } catch (e: any) {
      /* tslint:disable */
      console.log(`Warning: value to large`, e)
    }
  }

  // sync api
  private static getPendingKey(key: string) {
    return `${this.storeGlobalPending}${key}`
  }

  static setPending(key: string) {
    this.store[this.getPendingKey(key)] = true
  }

  static isPending(key: string) {
    return this.store[this.getPendingKey(key)]
  }

  static removePending(key: string) {
    delete this.store[this.getPendingKey(key)]
  }
}

export default CacheStorage
