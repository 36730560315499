import { ActionType, Action } from '../actionTypes';
import { JsObject } from 'interfaces/Object';

export interface CrumbsItem {
  path: string;
  active: boolean;
  title?: string | null;
  ensureLast?: boolean;
}

export type CrumbsChain = CrumbsItem[];
export type ChainIdType = string | null;
export interface CrumbsStoreType {
  routes: JsObject<string>;
  chains: JsObject<CrumbsChain>;
  activeChainId: ChainIdType;
  timestamps: JsObject<string>;
}

const initialState: CrumbsStoreType = {
  routes: {},
  chains: {},
  activeChainId: null,
  timestamps: {},
};

const getUniqueCrumbs = (arr: any[]) => {
  return arr.reduce(
    (acc: any, crumb: any) => {
      if (acc.map[crumb.path]) {
        // если данная крошка уже была
        return acc; // ничего не делаем, возвращаем уже собранное
      }

      acc.map[crumb.path] = true; // помечаем крошку, как обработанную
      acc.crumbs.push(crumb); // добавляем объект в массив крошек
      return acc; // возвращаем собранное
    },
    {
      map: {}, // здесь будут отмечаться обработанные крошки
      crumbs: [], // здесь конечный массив уникальных крошек
    }
  ).crumbs; // получаем конечный массив
};

const generateReturnState = ({
  state,
  path,
  activeChainId,
  chain,
  routesChanges = [],
  username,
}: any) => {
  const finalObj: Pick<CrumbsStoreType, 'routes' | 'chains' | 'activeChainId'> = {
    routes: {
      ...state.routes,
      [path]: activeChainId,
    },
    chains: {
      ...state.chains,
      [activeChainId]: chain,
    },
    activeChainId: null,
  };

  finalObj.chains[activeChainId] = finalObj.chains[activeChainId].map((item: CrumbsItem) => ({
    ...item,
    active: item.path === path,
  }));

  for (const crumb of routesChanges) {
    if (crumb.activeChainId) {
      finalObj.routes[crumb.path] = crumb.activeChainId;
    }
  }

  const crumbsFromLS = localStorage.getItem('crumbs');
  const parsedCrumbsFromLS = crumbsFromLS ? JSON.parse(crumbsFromLS) : {};

  localStorage.setItem(
    'crumbs',
    JSON.stringify({
      groups: {
        ...parsedCrumbsFromLS.groups,
        [username]: finalObj,
      },
      timestamps: {
        ...parsedCrumbsFromLS?.timestamps,
        [activeChainId]: new Date().toISOString(),
      },
    })
  );

  finalObj.activeChainId = activeChainId;

  return finalObj;
};

const crumbs = (state: any = initialState, action: Action<ActionType, any>) => {
  const payload = action?.payload;
  const { path, versionSeriesId } = payload || {};
  let { activeChainId } = state;
  if (!activeChainId) activeChainId = new Date().toISOString();

  switch (action.type) {
    case ActionType.INIT_CRUMBS:
      return payload;

    case ActionType.CRUMBS_SET_ACTIVE_CHAIN_ID:
      if (state.activeChainId === payload.activeChainId) return state;

      let chains = state.chains;

      if (!state.activeChainId && payload.activeChainId && chains?.[payload.activeChainId]) {
        chains = {
          ...chains,
          [payload.activeChainId]: chains?.[payload.activeChainId].map((item: CrumbsItem) => ({
            ...item,
            active: item.path === path,
          })),
        };
      }

      return { ...state, chains, activeChainId: payload.activeChainId };

    case ActionType.UPDATE_CRUMBS:
      let currentList = payload.newList ? [] : state?.chains?.[activeChainId] || [];

      // создание новой цепочки для крамба имеющего флаг newList
      // крамб с этим флагом всегда создастся в новой цепочке
      if (payload.newList) {
        activeChainId = new Date().toISOString();
        delete payload.newList;
      }

      const existIndex = currentList?.findIndex?.((item: any) => item.path === path);

      // возвращаем текущее состояние crumbs если крамб уже существует в активной цепочке
      if (existIndex > -1) {
        // Если мы попали на нулевой крамб активной цепочки, длина которой больше 1
        // то данный крамб является дубликатом последнего крамба цепочки из которой мы перешли в текущую цепочку (оригинальная цепочка).
        // В таком случае назначем в качестве activeChainId тот chainId, который принадлежит оригинальной цепочке.
        if (existIndex === 0 && currentList.length > 1 && state.routes?.[path]) {
          activeChainId = state.routes?.[path];
          currentList = state?.chains?.[activeChainId];
        }

        return generateReturnState({
          state,
          path,
          activeChainId,
          chain: currentList,
          username: payload.username,
        });
      }

      let nearestVersionSeriesIdx: number = -1;

      if (versionSeriesId) {
        for (let i = currentList.length - 1; i >= 0; i--) {
          if (
            currentList[i].versionSeriesId === versionSeriesId &&
            currentList[i].path.split('#')[1] === path.split('#')[1]
          ) {
            nearestVersionSeriesIdx = i;
            break;
          }
        }
      }

      // обработка для версионируемых объектов - если versionSeriesId не изменился
      if (nearestVersionSeriesIdx >= 0) {
        currentList[nearestVersionSeriesIdx] = {
          ...currentList[nearestVersionSeriesIdx],
          title: payload.title,
          path: payload.path,
        };

        return generateReturnState({
          state,
          path,
          activeChainId,
          chain: getUniqueCrumbs(currentList),
          routesChanges: [{ chainId: activeChainId, path: payload.path }],
          username: payload.username,
        });
      }

      let newState = [...currentList];
      const activeCrumbIndex = newState.findIndex((i: any) => i.active);
      const activeCrumbPath = newState?.[activeCrumbIndex]?.path;
      const prevCrumb = newState?.[activeCrumbIndex];

      const activeCrumbLocation = {
        path: activeCrumbPath?.split('#')?.[0],
        hash: activeCrumbPath?.split('#')?.[1],
      };

      const targetCrumbLocation = {
        path: path?.split('#')?.[0],
        hash: path?.split('#')?.[1],
      };

      // используется как свойство routesChanges функции generateReturnState
      // для перечисленных роутов явно меняет принадлежность к цепочке в crumbs.routes
      const routesChanges = [];

      const prevCrumbIsActive = activeCrumbIndex < newState.length - 1;
      const targetCrumbHasSameLevel =
        activeCrumbLocation.path === targetCrumbLocation.path &&
        activeCrumbLocation.hash !== targetCrumbLocation.hash &&
        activeCrumbLocation.hash &&
        targetCrumbLocation.hash;

      // создание дубликата цепочки
      // - если активный крамб не является последним,
      //    (дубликат цепочки состоит из всех крамбсов до активного [включительно])
      // - если активный крамб имеет один и тот же path, но разный hash
      //    (дубликат цепочки состоит из всех крамбсов до активного [НЕ включительно])
      if (prevCrumbIsActive || (targetCrumbHasSameLevel && !prevCrumbIsActive)) {
        activeChainId = new Date().toISOString();

        if (prevCrumbIsActive) {
          newState = newState.slice(0, activeCrumbIndex + 1);
        }

        if (targetCrumbHasSameLevel && !prevCrumbIsActive) {
          newState = newState.slice(0, activeCrumbIndex);
        }

        for (const crumb of newState) {
          routesChanges.push({ chainId: activeChainId, path: crumb.path });
        }
      }

      // создание новой цепочки, если активный крамб имеет флаг ensureLast
      // цепочка содержит активный крамб и тот на который переходим
      if (
        prevCrumb?.ensureLast &&
        payload.path !== prevCrumb.path &&
        targetCrumbLocation.path !== activeCrumbLocation.path
      ) {
        activeChainId = new Date().toISOString();
        newState = [{ ...prevCrumb, ensureLast: false, title: null }];
      }

      // заменяем крамб с id/new на id/<id крамба на который переходим>
      if (newState.length) {
        const idNew = newState[newState.length - 1]?.path?.includes('id/new');
        if (idNew) newState.pop();
      }

      // добавляем пустой нулевой элемент если крамб является первым в цепочке
      // и не является реестром (отсутствует title)
      if (!newState.length && payload.title) {
        newState.push({});
      }

      newState = getUniqueCrumbs([...newState, payload]);

      return generateReturnState({
        state,
        path,
        activeChainId,
        chain: newState,
        routesChanges,
        username: payload.username,
      });

    case ActionType.CLEAR_CRUMBS:
      return initialState;

    default:
      return state;
  }
};

export default crumbs;
