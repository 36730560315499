import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1rem" height="1rem" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.979553 6.82492C0.979553 6.82492 5.56319 27.8187 29.1462 35.2054C30.1301 35.5258 31.1801 35.586 32.1942 35.3803C33.9126 34.9916 36.0119 33.7319 35.8914 29.7315C35.8656 29.2032 35.7155 28.6885 35.4534 28.2292C35.1913 27.7699 34.8246 27.3788 34.383 27.0878L31.094 24.829C30.26 24.2643 29.2441 24.034 28.2481 24.1837C26.3043 24.4714 22.9375 24.8446 21.0053 24.3819C18.1672 23.6899 11.7369 15.203 11.0138 13.7179C10.3334 12.3649 12.4678 11.6846 12.7244 7.88627C12.7592 7.19637 12.5681 6.51394 12.1801 5.94241C11.3443 4.69056 9.65309 2.36958 7.65868 0.942779C4.81286 -1.09828 0.264209 4.44563 0.979553 6.82492Z"
      fill="var(--color-additional-elements)"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
