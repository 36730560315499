import React from 'react'

function ETRIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="#D4F0FF"/>
      <path d="M13.5767 15.7469L20.4415 22.6173L21.7259 21.3319L14.8522 14.4615C14.5865 14.1956 14.8522 13.2648 14.8522 13.2648L10.2018 9.25781L8.35938 11.1017L12.372 15.7469C12.372 15.7469 13.3464 15.5253 13.5767 15.7469Z" fill="#004D77"/>
      <path d="M30.3174 26.6797L25.791 31.2097L35.6321 41.0409C35.8214 41.2312 36.0464 41.3822 36.2942 41.4852C36.542 41.5883 36.8076 41.6413 37.076 41.6413C37.3443 41.6413 37.6099 41.5883 37.8577 41.4852C38.1055 41.3822 38.3305 41.2312 38.5198 41.0409L40.1231 39.4363C40.3132 39.2469 40.4641 39.0217 40.5671 38.7737C40.67 38.5258 40.723 38.2599 40.723 37.9913C40.723 37.7228 40.67 37.4569 40.5671 37.209C40.4641 36.961 40.3132 36.7358 40.1231 36.5464L30.3174 26.6797Z" fill="black"/>
      <path d="M36.6685 17.2332C36.4126 17.4893 36.1087 17.6925 35.7744 17.8311C35.44 17.9697 35.0816 18.041 34.7197 18.041C34.3578 18.041 33.9995 17.9697 33.6651 17.8311C33.3307 17.6925 33.0269 17.4893 32.771 17.2332C32.5151 16.9771 32.3121 16.673 32.1736 16.3384C32.0351 16.0038 31.9638 15.6451 31.9638 15.2829C31.9638 14.9207 32.0351 14.562 32.1736 14.2274C32.3121 13.8928 32.5151 13.5887 32.771 13.3326L37.0848 9.05971C36.126 8.61575 35.0851 8.37721 34.0288 8.35938C32.0041 8.3756 30.0669 9.18777 28.6351 10.6207C27.2033 12.0536 26.3918 13.9923 26.3756 16.0187C26.3934 17.0758 26.6318 18.1175 27.0754 19.0771L9.14704 36.9843C8.89112 37.2404 8.68812 37.5445 8.54963 37.8791C8.41113 38.2137 8.33984 38.5724 8.33984 38.9346C8.33984 39.2968 8.41113 39.6554 8.54963 39.9901C8.68812 40.3247 8.89112 40.6288 9.14704 40.8849C9.40295 41.141 9.70676 41.3442 10.0411 41.4828C10.3755 41.6214 10.7339 41.6927 11.0958 41.6927C11.4577 41.6927 11.8161 41.6214 12.1504 41.4828C12.4848 41.3442 12.7886 41.141 13.0445 40.8849L30.964 22.9334C31.9228 23.3773 32.9637 23.6158 34.02 23.6337C36.0447 23.6175 37.9819 22.8053 39.4137 21.3724C40.8454 19.9395 41.657 18.0007 41.6732 15.9744C41.6443 14.9147 41.3938 13.8728 40.938 12.916L36.6685 17.2332Z" fill="#004D77"/>
    </svg>
  )
}

export default ETRIcon