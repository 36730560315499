// import React from "react";
import { RouteProps } from 'react-router';
// import loadable from '@loadable/component';
import EntityEditor from 'pages/entityEditor/EntityEditor';
import EntityList from 'pages/entityList/EntityList';
import Error404 from 'pages/error404/Error404';
import InboxGroupList from 'pages/inboxgrouplist/InboxGroupList';
import MainPage from 'pages/main/MainPage';
import Kpi from '../pages/kpi/Kpi';
import Questions from '../pages/questions/Questions';
import News from '../pages/news/News';
import composeLink from 'utils/composeLink';
import Login from 'components/login/Login';
import ModuleMainPage from 'pages/ModuleMainPage/ModuleMainPage';
import SearchPage from 'pages/Search/SearchPage';

// const EntityEditor = loadable(() => import('pages/entityEditor/EntityEditor'));
// const EntityList = loadable(() => import('pages/entityList/EntityList'));
// const Error404 = loadable(() => import('pages/error404/Error404'));
// const InboxGroupList = loadable(() => import('pages/inboxgrouplist/InboxGroupList'));
// const MainPage = loadable(() => import('pages/main/MainPage'));
// const Kpi = loadable(() => import('pages/kpi/Kpi'));

export interface EntityEditorRouteParams {
  inboxName?: string;
  className: string;
  objectID: string;
  tabId?: string;
  groupID?: string;
  parentId?: string;
  useKB?: string;
  preFillResponse?: string;
  backUrl?: string;
  parentClassName?: any;
  message?: string;
  controllerName?: string;
}

export interface EntityListRouteParams {
  inboxName: string;
  className?: string;
  useKB?: string;
  controllerName?: string;
}

// variable names in routes
const inboxGroup = ':groupID';
const inbox = ':inboxName';
const className = ':className';
const id = ':objectID';
const parentClassName = ':parentClassName';
const parentId = ':parentId';
const useKB = ':useKB';
const controllerName = ':controllerName';
const message = ':message';
const preFillResponse = ':preFillResponse';
const backUrl = ':backUrl';
const tabId = ':tabId';
const search = ':searchQuery';
const page = ':page';
const cascadeFilterBranch = ':cascadeFilterBranch';

const MainRoutes: RouteProps[] = [
  {
    exact: true,
    path: composeLink({ inboxGroup }),
    component: process.env.REACT_APP_TYPE === 'irk' ? InboxGroupList : ModuleMainPage,
  },
  // {
  //   exact: true,
  //   path: '/kpi',
  //   component: Kpi
  // },
  {
    exact: true,
    path: '/',
    component: process.env.REACT_APP_TYPE === 'irk' ? MainPage : ModuleMainPage,
  },
  {
    exact: true,
    path: '/questions',
    component: Questions,
  },

  {
    exact: true,
    path: '/news',
    component: News,
  },
  {
    exact: true,
    path: '/password-restore/:reset-pass',
    component: Login,
  },
  {
    exact: true,
    path: composeLink({ search, page, cascadeFilterBranch }),
    component: SearchPage,
  }
];

const ErrorRoutes: RouteProps[] = [
  {
    component: Error404,
  },
];

const EntityListRoutes: RouteProps[] = [
  {
    exact: true,
    path: composeLink({ inbox, className }),
    component: EntityList,
  },
  {
    exact: true,
    path: composeLink({ inbox, className, useKB }),
    component: EntityList,
  },
  {
    exact: true,
    path: composeLink({ inbox, controllerName }),
    component: EntityList,
  },
];

const EntityEditorRoutes: RouteProps[] = [
  {
    exact: true,
    path: composeLink({ className, id }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, parentClassName, message }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, useKB }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, preFillResponse }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, parentClassName, parentId }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, parentId }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, backUrl }),
    component: EntityEditor,
  },
  {
    exact: true,
    path: composeLink({ className, id, tabId }),
    component: EntityEditor,
  },
];

const addInboxToRoute = (routeProps: RouteProps) => {
  const newProps = { ...routeProps };
  newProps.path = composeLink({ inbox, suffix: newProps.path as string });
  return newProps;
};

const EntityEditorInboxRoutes: RouteProps[] = EntityEditorRoutes.map(addInboxToRoute);

const Routes: RouteProps[] = [
  ...EntityEditorInboxRoutes,
  ...EntityEditorRoutes,
  ...EntityListRoutes,
  ...MainRoutes,
  ...ErrorRoutes,
];

export default Routes;
