export const onRow = (record: any) => ({
  onClick: (e: any) => {
    if (e.target.text !== undefined || !e.target.closest('svg') || !e.target.closest('span')) {
      const isCheckbox: boolean =
        e.currentTarget.querySelectorAll('.tableCheckbox').length > 0 &&
        (e.target.tagName === 'INPUT' || e.target.querySelectorAll('.tableCheckbox').length > 0);

      if ('.link' in record && isCheckbox) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  },
});
