import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';

type UseRefreshSubscribe = (subscriberID?: string) => {
  refreshSubscriber: () => void;
  resetRefreshSubscriber: () => void;
  refreshCount: number;
};

// provide component id to subscribe component to refresh
export const useRefreshSubscribe: UseRefreshSubscribe = (subscriberID) => {
  const dispatch = useDispatch();

  const refreshCount: number = useSelector(
    (state: AppState) => (subscriberID && state.refresh[subscriberID]) || 0
  );

  const refreshSubscriber = useCallback(() => {
    if (!subscriberID) return;
    dispatch({
      type: ActionType.REFRESH_COMPONENT,
      payload: subscriberID,
    });
  }, [dispatch, subscriberID]);

  const resetRefreshSubscriber = useCallback(() => {
    if (!subscriberID) return;
    dispatch({
      type: ActionType.REFRESH_COMPONENT_RESET,
      payload: subscriberID,
    });
  }, [dispatch, subscriberID]);

  return {
    refreshSubscriber,
    resetRefreshSubscriber,
    refreshCount,
  };
};

export default useRefreshSubscribe;
