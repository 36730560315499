import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.0513 24.6957L34.1897 31.8323L31.8313 34.1907L24.6947 27.0523C22.0392 29.181 18.7363 30.3388 15.333 30.334C7.05301 30.334 0.333008 23.614 0.333008 15.334C0.333008 7.05398 7.05301 0.333984 15.333 0.333984C23.613 0.333984 30.333 7.05398 30.333 15.334C30.3379 18.7373 29.18 22.0402 27.0513 24.6957ZM23.708 23.459C25.8232 21.2838 27.0045 18.368 26.9997 15.334C26.9997 8.88732 21.778 3.66732 15.333 3.66732C8.88634 3.66732 3.66634 8.88732 3.66634 15.334C3.66634 21.779 8.88634 27.0007 15.333 27.0007C18.3671 27.0054 21.2828 25.8242 23.458 23.709L23.708 23.459Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
