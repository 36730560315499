import React from 'react'
import styles from './Icon.module.css';

interface IIconProps {
  isActive?: boolean;
}

function CollpaseIcon({isActive = false}: IIconProps) {
  return (
    <svg className={`${isActive && styles.active} ${styles.icon}`} width="25" height="10" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.75473 1.26367C3.52058 0.550896 4.70636 0.548807 5.47471 1.25888L16.1426 11.1175C16.909 11.8258 18.091 11.8258 18.8574 11.1175L29.5253 1.25888C30.2936 0.548809 31.4794 0.550897 32.2453 1.26367L33.4214 2.35828C34.2733 3.15119 34.271 4.50124 33.4162 5.29114L18.8574 18.7456C18.091 19.4538 16.909 19.4538 16.1426 18.7456L1.58379 5.29114C0.729048 4.50124 0.726669 3.15118 1.57862 2.35828L2.75473 1.26367Z" fill="#4067BF"/>
    </svg>
  )
}

export default CollpaseIcon