import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00048 0C9.55278 0 10.0005 0.44772 10.0005 1V4C10.0005 4.55228 9.55278 5 9.00048 5C8.44818 5 8.00048 4.55228 8.00048 4V1C8.00048 0.44772 8.44818 0 9.00048 0ZM9.00048 15C9.55278 15 10.0005 15.4477 10.0005 16V19C10.0005 19.5523 9.55278 20 9.00048 20C8.44818 20 8.00048 19.5523 8.00048 19V16C8.00048 15.4477 8.44818 15 9.00048 15ZM17.6607 5C17.9369 5.47829 17.773 6.08988 17.2947 6.36602L14.6966 7.86602C14.2183 8.1422 13.6067 7.97829 13.3306 7.5C13.0545 7.02171 13.2183 6.41012 13.6966 6.13398L16.2947 4.63397C16.773 4.35783 17.3846 4.52171 17.6607 5ZM4.67033 12.5C4.94647 12.9783 4.78259 13.5899 4.3043 13.866L1.70623 15.366C1.22793 15.6422 0.616336 15.4783 0.340196 15C0.0640555 14.5217 0.227926 13.9101 0.706226 13.634L3.3043 12.134C3.78259 11.8578 4.39418 12.0217 4.67033 12.5ZM17.6607 15C17.3846 15.4783 16.773 15.6422 16.2947 15.366L13.6966 13.866C13.2183 13.5899 13.0545 12.9783 13.3306 12.5C13.6067 12.0217 14.2183 11.8578 14.6966 12.134L17.2947 13.634C17.773 13.9101 17.9369 14.5217 17.6607 15ZM4.67033 7.5C4.39418 7.97829 3.78259 8.1422 3.3043 7.86602L0.706226 6.36602C0.227926 6.08988 0.0640555 5.47829 0.340196 5C0.616336 4.52171 1.22793 4.35783 1.70623 4.63397L4.3043 6.13398C4.78259 6.41012 4.94647 7.02171 4.67033 7.5Z" fill="#E7B728"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
