import React, { useEffect, useState } from 'react';
import styles from './Mto.module.css';
import mtoImage from '../../images/mto.svg';
import mtoCSDPImage from '../../images/mto.csdp.svg';
import axios from 'axios';
import SectionContact from '../../components/sectionContact/SectionContact';
import { Contact } from '../../utils/types';
import notification from 'components/messages/notification';
import { useWindowWidth } from '@react-hook/window-size';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';

const Mto: React.FC = (props) => {
  const [contacts, setContacts] = useState<Contact>({ label: '', info: '', data: [] });
  const [rapartServices, setRapartServices] = useState<any>([]);
  const width = useWindowWidth();

  const getMto = () => {
    axios.get('/mto').then((res: AxiosResponseExt) => {
      if (res.error) {
        notification.error({
          text: res.error,
          width,
        });
      } else {
        setContacts(res.data.contacts);
        setRapartServices(res.data.rapartServices);
      }
    });
  };

  useEffect(() => {
    getMto();
  }, []);

  return (
    <div className={styles.wrapPage}>
      <div className={styles.wrapMto}>
        {' '}
        <a href='https://www.rapart.aero/' target='_blank'>
          {' '}
          {
            process.env.REACT_APP_TYPE === 'irk' ?
              <img className={styles.img} src={mtoImage} /> :
              <img className={styles.img} src={mtoCSDPImage} />
          }
        </a>
        <div className={styles.aboutRapart}>
          ООО «РАпарт Сервисез»
          <span className={styles.subTitleRapart}> - {rapartServices.about}</span>
        </div>
      </div>

      <div className={styles.wrapperList}>
        <div className={styles.title}>{rapartServices.title}</div>
        <ul className={styles.list}>
          {rapartServices.data &&
            rapartServices.data.map((item: any, i: number) => {
              return (
                <li key={i} className={styles.elementList}>
                  <div>
                    <div className={styles.itemTitle}>{item.title}</div>
                    <div className={styles.itemSubTitle}>{item.subtitle}</div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div className={styles.contacts}>{contacts && <SectionContact block={contacts} />}</div>
    </div>
  );
};

export default Mto;
