import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 21 20' xmlns='http://www.w3.org/2000/svg'>
    <path d='M19.0189 14.3239C18.5882 13.892 18.0464 13.5879 17.4533 13.4456C16.8603 13.3034 16.2394 13.3283 15.6597 13.5176L6.4848 4.34272C7.29444 2.00326 5.1239 -0.449678 2.6949 0.0704726C2.5477 0.101389 2.43757 0.224128 2.42265 0.373633C2.41127 0.476533 2.44665 0.578968 2.51909 0.652797L3.89724 2.03094L3.49072 3.48831L2.01954 3.88114L0.641392 2.503C0.566641 2.44271 0.471892 2.41318 0.376068 2.42025C0.226563 2.43517 0.103821 2.5453 0.0729076 2.69249C-0.461103 5.10424 2.03676 7.31282 4.35224 6.48239L13.5204 15.6573C12.7074 17.9967 14.8986 20.4497 17.3103 19.9295C17.4575 19.8986 17.5677 19.7759 17.5826 19.6264C17.594 19.5235 17.5586 19.421 17.4861 19.3472L16.108 17.9691L16.5145 16.5117L17.9857 16.1189L19.3638 17.497C19.4329 17.5556 19.5183 17.5917 19.6084 17.6004C19.7759 17.6034 19.9213 17.4858 19.9529 17.3214C20.1666 16.2287 19.8156 15.1019 19.0193 14.3239L19.0189 14.3239Z' />
    <path d='M12.5101 9.14541C12.6445 9.27892 12.8615 9.27892 12.996 9.14541L15.5661 6.5786C18.667 7.46408 20.9374 3.64311 18.667 1.33811C16.3964 -0.966895 12.5515 1.33811 13.4336 4.43893L10.8599 7.01264L10.86 7.01248C10.7264 7.14691 10.7264 7.36394 10.86 7.49838L12.5101 9.14541ZM14.7703 3.83247L15.1149 2.49571C15.1465 2.3762 15.2399 2.28284 15.3594 2.251L16.6962 1.90646C16.8188 1.87016 16.9513 1.90462 17.0407 1.99613L18.0193 2.97469V2.97454C18.1108 3.0639 18.1452 3.19664 18.1088 3.31908L17.7643 4.65584V4.65599C17.7326 4.7755 17.6392 4.86886 17.5197 4.90055L16.1829 5.24508C16.1543 5.24847 16.1254 5.24847 16.0968 5.24508C16.0051 5.24554 15.9171 5.20971 15.8521 5.1451L14.8599 4.16316C14.7734 4.07672 14.7393 3.95075 14.7703 3.83247L14.7703 3.83247Z' />
    <path d='M7.49442 10.8545C7.35999 10.721 7.14297 10.721 7.00853 10.8545L4.43498 13.4455C1.33416 12.5635 -0.925994 16.374 1.33416 18.6789C3.59424 20.9839 7.46336 18.6789 6.56756 15.5781L9.14127 13.0043H9.14111C9.27477 12.8699 9.27477 12.6529 9.14111 12.5185L7.49442 10.8545ZM5.00345 16.2293L4.70721 17.3352L4.70706 17.3353C4.67537 17.4549 4.58201 17.5482 4.4625 17.5799L3.3566 17.8761H3.35645C3.23401 17.9126 3.10127 17.8781 3.01191 17.7866L2.21609 16.977C2.12457 16.8876 2.09012 16.755 2.12657 16.6324L2.42281 15.5402C2.4545 15.4207 2.54786 15.3274 2.66737 15.2957L3.77327 14.9994L3.77342 14.9993C3.89601 14.963 4.0286 14.9974 4.11796 15.089L4.9276 15.8986C5.00912 15.9878 5.03788 16.1135 5.00343 16.2293L5.00345 16.2293Z' />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
