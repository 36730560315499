import { useCallback, useContext } from 'react';
import { FormInstance } from 'antd';

import modal from 'components/messages/modal';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';

import useFormHandler from 'hooks/useFormHandler';

import { isNewObjectData } from 'utils/ObjectUtils';
import { ObjectData, SaveObjectExt } from 'interfaces/Object';
import { IS_CHANGED_KEY } from 'interfaces/Form';

export type UpdateEditorChanged = (val: boolean) => void;
export type CheckEditorChanged = () => boolean;
export type CheckEditorChangedOrNew = (details?: ObjectData) => boolean;
export type HandleEditorChanged = (details?: ObjectData) => Promise<ObjectData | boolean>;

type UseEditorChanged = (args?: { form?: FormInstance; saveObject?: SaveObjectExt }) => {
  updateEditorChanged: UpdateEditorChanged;
  checkEditorChanged: CheckEditorChanged;
  checkEditorChangedOrNew: CheckEditorChangedOrNew;
  handleEditorChanged: HandleEditorChanged;
};

export const useEditorChanged: UseEditorChanged = (args) => {
  const context = useContext(EntityEditorContext);
  const form: FormInstance | undefined = args?.form || context.form;
  const saveObject: SaveObjectExt | undefined = args?.saveObject || context.onFormFinish;

  const { validateFields, buildObjectData } = useFormHandler(form);

  const updateEditorChanged: UpdateEditorChanged = useCallback(
    (val) => {
      form?.setFieldsValue({ [IS_CHANGED_KEY]: val });
    },
    [form]
  );

  const checkEditorChanged: CheckEditorChanged = useCallback(() => {
    return form?.getFieldValue(IS_CHANGED_KEY) || false;
  }, [form]);

  const checkEditorChangedOrNew: CheckEditorChangedOrNew = useCallback(
    (details) => {
      return checkEditorChanged() || isNewObjectData(buildObjectData(details));
    },
    [checkEditorChanged]
  );

  const handleEditorChanged: HandleEditorChanged = (details) => {
    return new Promise((resolve) => {
      if (checkEditorChangedOrNew(details)) {
        modal.confirmSave({
          onOk: async () => {
            let result: ObjectData | boolean = true;

            if (saveObject) result = (await saveObject().catch(() => false)) || false;
            else result = await validateFields();

            resolve(result);
          },
          onCancel: () => resolve(false),
        });
      } else {
        resolve(buildObjectData(details));
      }
    });
  };

  return {
    updateEditorChanged,
    checkEditorChanged,
    checkEditorChangedOrNew,
    handleEditorChanged,
  };
};

export default useEditorChanged;
