import React from 'react'

function ZNKEDIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="#D4F0FF"/>
      <path d="M11.7918 13.9249H13.1356V34.0957H11.5927C10.7466 34.0957 10 33.3001 10 32.2705V13.3633C10.4977 13.7377 11.095 13.9249 11.7918 13.9249Z" fill="#004D77"/>
      <path d="M30.506 12.9484C24.9814 12.9484 20.6512 12.9484 11.742 12.9484C10.7964 12.9484 10 12.2932 10 11.4508C10.0498 10.6552 10.7964 10 11.7918 10H30.5558C30.2074 10.4212 30.0083 10.936 30.0083 11.4976C29.9586 12.0124 30.1576 12.5272 30.506 12.9484Z" fill="#004D77"/>
      <path d="M14.1348 13.9297V34.1005H22.3471C22.0485 33.8665 21.8494 33.4921 21.8494 33.0709V30.6841C21.8494 29.9353 22.4965 29.3269 23.2928 29.2801L23.8403 29.2333C23.9398 28.9993 24.0394 28.8121 24.1389 28.6249L23.7408 28.2505C23.1933 27.6889 23.243 26.8465 23.7905 26.3317L25.5823 24.6469C26.1298 24.1321 27.0257 24.0853 27.623 24.6001L28.0211 24.9745C28.2202 24.8809 28.4691 24.7873 28.6682 24.7405L28.7179 24.2257C28.7677 23.4769 29.4148 22.8685 30.2111 22.8685H30.5097V13.9297H14.1348ZM22.8946 25.9105H17.1709C16.8722 25.9105 16.6731 25.6765 16.6731 25.4425C16.6731 25.2085 16.922 24.9745 17.1709 24.9745H22.8946C23.1933 24.9745 23.3924 25.2085 23.3924 25.4425C23.3924 25.6765 23.1933 25.9105 22.8946 25.9105ZM27.5234 23.1493H17.1709C16.8722 23.1493 16.6731 22.9153 16.6731 22.6813C16.6731 22.4005 16.922 22.2133 17.1709 22.2133H27.5234C27.8221 22.2133 28.0211 22.4473 28.0211 22.6813C28.0211 22.9621 27.8221 23.1493 27.5234 23.1493ZM27.5234 20.3881H17.1709C16.8722 20.3881 16.6731 20.1541 16.6731 19.9201C16.6731 19.6393 16.922 19.4521 17.1709 19.4521H27.5234C27.8221 19.4521 28.0211 19.6861 28.0211 19.9201C28.0211 20.1541 27.8221 20.3881 27.5234 20.3881ZM27.5234 17.6269H17.1709C16.8722 17.6269 16.6731 17.3929 16.6731 17.1589C16.6731 16.8781 16.922 16.6909 17.1709 16.6909H27.5234C27.8221 16.6909 28.0211 16.9249 28.0211 17.1589C28.0211 17.3929 27.8221 17.6269 27.5234 17.6269Z" fill="#004D77"/>
      <path d="M39.61 30.2631L38.316 30.2163C38.1666 29.6079 37.9178 29.0463 37.5694 28.4847L38.4155 27.5955C38.5648 27.4083 38.5648 27.1743 38.4155 26.9871L36.6237 25.3023C36.4246 25.1151 36.1758 25.1151 35.9767 25.3023L35.031 26.0979C34.4835 25.7703 33.8365 25.5363 33.1894 25.3959L33.1397 24.2259C33.1397 23.9919 32.9406 23.8047 32.6917 23.8047H30.1534C29.9045 23.8047 29.7054 23.9919 29.7054 24.2259L29.6556 25.4427C29.0086 25.5831 28.4113 25.8171 27.8141 26.1447L26.8684 25.3491C26.6693 25.2087 26.4205 25.2087 26.2214 25.3491L24.4296 27.0339C24.2803 27.2211 24.2305 27.4551 24.4296 27.6423L25.2757 28.5315C24.9273 29.0463 24.6784 29.6547 24.5291 30.2631L23.2351 30.3099C22.9862 30.3099 22.7871 30.4971 22.7871 30.7311V33.1179C22.7871 33.3519 22.9862 33.5391 23.2351 33.5391L24.5291 33.5859C24.6784 34.1943 24.9273 34.7559 25.2757 35.3175L24.4296 36.2067C24.2803 36.3939 24.2803 36.6747 24.4794 36.8151L24.5291 36.8619L26.2711 38.4999C26.4702 38.6871 26.7191 38.6871 26.9182 38.4999L27.8638 37.7043C28.4611 38.0319 29.0584 38.2659 29.7054 38.4063L29.7552 39.5763C29.7552 39.8103 29.9543 39.9975 30.2031 39.9975H32.7415C32.9904 39.9975 33.1894 39.8103 33.1894 39.5763L33.2392 38.4063C33.8863 38.2659 34.4835 38.0319 35.0808 37.7043L36.0264 38.4999C36.2255 38.6403 36.4744 38.6403 36.6735 38.4999L38.4653 36.8151C38.6644 36.6279 38.6644 36.3939 38.4653 36.2067L37.5694 35.2707C37.9178 34.7559 38.1666 34.1475 38.316 33.5391L39.5603 33.4923C39.8091 33.4923 40.0082 33.3051 40.0082 33.0711V30.6843C40.0082 30.4503 39.8589 30.2631 39.61 30.2631ZM34.5831 31.9011C34.5831 33.4923 33.1894 34.8027 31.4474 34.8027C29.7552 34.8027 28.3616 33.4923 28.3616 31.9011C28.3616 30.3099 29.7552 28.9995 31.4474 28.9995C33.1397 28.9527 34.5831 30.2631 34.5831 31.9011Z" fill="black"/>
    </svg>
  )
}

export default ZNKEDIcon