import React from 'react';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import MiscUtils from 'utils/MiscUtils';
import stylesInput from '../InputText/InputText.module.css';

export interface DisabledSelectProps {
  suffixIcon: React.ReactNode;
  value?: string | null;
}

export const DisabledSelect: React.FC<DisabledSelectProps> = ({ suffixIcon, value }) => (
  <>
    <Input hidden={true} />
    <FormItem shouldUpdate={true} noStyle={true}>
      <Input
        disabled={true}
        className={stylesInput.input}
        suffix={suffixIcon}
        autoComplete='off'
        value={MiscUtils.notNullString(value)}
      />
    </FormItem>
  </>
);

export default DisabledSelect;
