import ErrorHandler from 'pages/ErrorHandler';
import React from 'react';
/* tslint:disable */
interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean
  error?: any
  errorInfo?: any
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { error, hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    //   logErrorToMyService(error, errorInfo);
    this.setState({ ...this.state, ...{ error }, ...{ errorInfo } })
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <ErrorHandler errors={[this.state.error, this.state.errorInfo]} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
