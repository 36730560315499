import NavUtils from 'utils/NavUtils';

export const useTableViewComponentsProps = ({ componentData, processesData }: any) => {
  // <TableViewToolButton> props
  const tableViewToolsButtonProps = {
    isCardTable: componentData.isCardTable,
    inboxName: componentData.inboxName || componentData.inboxViewName,
    className: componentData.className,
    parentId: componentData.parentId,
    parentClassName: componentData.parentClassName,
    addGuidItemsTable: componentData.toolsSettings.attach,
    tableId: processesData.tableIdRef.current,
    tableType: componentData.tableType,
    refreshID: componentData.refreshID,
    massReplaceTool: componentData.toolsSettings.massReplace,
    filters: processesData.filters,
    filtersConfiguration: processesData.filtersConfiguration,
    loading: processesData.loading,
    sort: processesData.sort,
    filtersForm: processesData.filtersForm,
    conditionId: componentData.conditionId,
    fillCriteriaFrom: componentData.fillCriteriaFrom,
    width: processesData.width,
  };

  // <TableViewTools> props
  const tableViewToolsProps = {
    isShortView: componentData.isShortView,
    initTools: componentData.initTools,
    tableViewToolsButtonProps,
  };

  // <TableViewCustomerFilters> props
  const tableViewHeaderProps = {
    factory: processesData.factory,
    filtersForm: processesData.filtersForm,
    className: componentData.className,
    doSearch: processesData.doSearch,
    filtersConfiguration: processesData.filtersConfiguration,
    tableId: processesData.tableIdRef.current,
    showFiltersToolsState: processesData.showFiltersToolsState,
    isShortView: componentData.isShortView,
    count: processesData.count,
    selectedRows: componentData.selectedRows,
  };

  // <EntityListFilters> props
  const entityListFiltersProps = {
    filtersForm: processesData.filtersForm,
    factory: processesData.factory,
    config: processesData.filtersConfiguration,
    showFilters: true,
    showSorting: false,
    className: componentData.className,
    inboxName: componentData.inboxName,
    action: componentData.isShortView
      ? processesData.doSearch
      : (config: any) => {
          processesData.doSearch(config, false);
        },
    groupID: componentData.groupID,
    tableId: processesData.tableIdRef.current,
    filtersConfigLoadingRef: processesData.filtersConfigLoadingRef,
  };

  // <TableGrid> props
  const tableProps = {
    setIsScrollEnd: processesData.setIsScrollEnd,
    isShortView: componentData.isShortView,
    onChange: processesData.handleTableChange,
    dataSource: processesData.items,
    loading: processesData.loading,
    isVisible: true,
    useKB: componentData.useKB,
    isCardTable: componentData.isCardTable,
    factory: processesData.factory,
    tableConfiguration: processesData.tableConfiguration,
    addGuidItemsTable: componentData.toolsSettings.attach,
    withRowsSelect: componentData.withRowsSelect,
    fillCriteriaFrom: componentData.fillCriteriaFrom,
    massReplaceTool: componentData.toolsSettings.massReplace,
    tableId: processesData.tableIdRef.current,
    columns: processesData.columns,
    screenResolution: processesData.screenResolution,
  };

  // <TwoColumnGrid> props
  const twoColumnGridProps = {
    className: 'main-content__wrap',
    buttonState: true,
    simpleTable: componentData.simpleTable,
  };

  // <Title> props
  const titleProps = {
    goTo: `/inboxGroup/${processesData.openedInbox}`,
    title: NavUtils.buildTitle(componentData.inboxName, componentData.inboxLabel),
    goBack: false,
  };

  // <FileModalPreview> props
  const fileModalPreviewProps = {
    isOpen: processesData.result || processesData.pdf,
    onClose: processesData.closeFilePreviewModal,
    result: processesData.result,
    pdf: processesData.pdf,
  };

  const tableViewCounterProps = {
    simpleTable: componentData.simpleTable,
    isCardTable: componentData.isCardTable,
    count: processesData.count,
    selectedRows: componentData.selectedRows,
    tableId: processesData.tableIdRef.current,
  };

  const tableViewTopPanelProps = {
    simpleTable: componentData.simpleTable,
    isCardTable: componentData.isCardTable,
    titleProps,
    tableViewCounterProps,
    tableViewToolsProps,
    tableViewHeaderProps,
  };

  return {
    tableViewToolsButtonProps,
    entityListFiltersProps,
    tableProps,
    twoColumnGridProps,
    fileModalPreviewProps,
    tableViewTopPanelProps,
  };
};
