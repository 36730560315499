import React, { useContext, useEffect, useState } from 'react';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import styles from './InputTextArea.module.css';
import TextArea from 'antd/lib/input/TextArea';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import stylesTooltip from '../SelectControl/SelectControl.module.css';
import stylesOverflowText from '../../content/OverflowText/OvertflowText.module.css';
import FormHandler from 'controllers/FormHandler';
import { tryDateStringify } from 'utils/DateUtils';
import HintIcon from '../SelectControl/HintIcon';
import notification from 'components/messages/notification';
import { useDispatch } from 'react-redux';
import { useEditorChanged, useObjectDetails } from 'hooks';
import axios from 'axios';
import { ActionType } from 'store/actionTypes';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import EntityEditorContext, {
  EntityEditorContextData,
} from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import { useWindowWidth } from '@react-hook/window-size';
import { convertNewFileToUploadedFiles, addFile } from '../FilesList/FileNew'
import DisabledIcon from 'components/icons/DisabledIcon';

const InputTextArea: React.FC<BaseComponentProps> = (props) => {
  const { ownProps } = props;
  const readOnly = !!ownProps.component?.readonly;
  const hint = ownProps.component?.hint;
  const { updateEditorChanged } = useEditorChanged();
  const { details } = useObjectDetails();
  const context = useContext<EntityEditorContextData>(EntityEditorContext);
  const dispatch = useDispatch()
  const width = useWindowWidth()
  const [isPaste, setIsPaste] = useState(false)

  const suffix = readOnly ? <DisabledIcon /> : <></>;

  const textAreaProps = {
    className: styles.input,
    readOnly,
    disabled: readOnly,
    maxLength: 32767,
    ...FormHandler.getFormProps(props),
  };
  // на всякий случай если залетела дата
  textAreaProps.value = tryDateStringify(textAreaProps.value);

  const saveParams: any = {
    className: details?.classType,
    VersionSeriesId: details?.params?.VersionSeriesId,
  };

  const onChange = (e: any) => {
    if (!isPaste) {
      FormHandler.getFormProps(props).onChange(e)
    }
  }

  const deactivatePaste = () => {
    setIsPaste(false)
  }

  const getClipboardData = (e: any) => {
    const newAcceptedFiles: any = Array.from(e.clipboardData.files)
    if (newAcceptedFiles.length > 0) {
      setIsPaste(true)
      addFile(dispatch, saveParams, newAcceptedFiles, context, ownProps, width, updateEditorChanged, deactivatePaste)
    }
  }

  return (
    <div className={styles.wrapInput}>
      <TextArea {...textAreaProps} onPaste={getClipboardData} onChange={onChange} />
      <div className={styles.icon}>{suffix}</div>
      {hint ? (
        <div className={stylesTooltip.info}>
          <HintIcon hint={hint} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(InputTextArea);
