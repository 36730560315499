import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
<svg width="1em" height="1em" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 4.93237C10 4.93237 3.75 9.47101 0 15.1443C3.75 20.8176 10 25.3562 17.5 25.3562C25 25.3562 31.25 20.8176 35 15.1443C31.25 9.47101 25 4.93237 17.5 4.93237ZM17.5 22.5196C13.0125 22.5196 9.375 19.2177 9.375 15.1443C9.375 11.0709 13.0125 7.76902 17.5 7.76902C21.9875 7.76902 25.625 11.0709 25.625 15.1443C25.625 19.2177 21.9875 22.5196 17.5 22.5196Z" fill="#C4C4C4"/>
<path d="M17.5 10.6052C16.7375 10.6052 16.0225 10.772 15.375 11.05C16.1013 11.2849 16.625 11.9078 16.625 12.6476C16.625 13.5882 15.7863 14.3496 14.75 14.3496C13.935 14.3496 13.2488 13.8753 12.99 13.2149C12.6838 13.8027 12.5 14.4517 12.5 15.1439C12.5 17.6503 14.7387 19.6825 17.5 19.6825C20.2613 19.6825 22.5 17.6503 22.5 15.1439C22.5 12.6374 20.2613 10.6052 17.5 10.6052Z" fill="#C4C4C4"/>
<rect width="38.3545" height="2.87659" transform="matrix(0.685994 -0.727607 0.685994 0.727607 3.7207 27.907)" fill="#C4C4C4"/>
</svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
