import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { isSimpleObject } from 'utils/MiscUtils';
import { ActionType } from 'store/actionTypes';
import { ModalPayload, ModalState, ModalType } from 'store/reducers/modal';
import { FormObjectData } from 'interfaces/Form';

type AttachParams = Pick<ModalPayload, 'attachParams' | 'refreshIDs' | 'cardForm'>;

type CreateParams = Pick<ModalState, 'objectClassName'> &
  Pick<
    ModalPayload,
    | 'toolName'
    | 'parentDetails'
    | 'parentId'
    | 'layoutParentId'
    | 'parentClassName'
    | 'refreshIDs'
    | 'customLayout'
    | 'layoutParams'
    | 'cardForm'
    | 'cardFormValues'
  > & { formValues?: FormObjectData };

type EditParams = Pick<ModalState, 'objectId' | 'objectClassName'> &
  Pick<
    ModalPayload,
    | 'toolName'
    | 'parentDetails'
    | 'layoutParentId'
    | 'parentClassName'
    | 'modalDetails'
    | 'refreshIDs'
    | 'customLayout'
    | 'layoutParams'
    | 'cardForm'
    | 'cardFormValues'
  >;

type MassReplaceParams = Pick<ModalState, 'massReplaceParams' | 'objectClassName'> &
  Pick<ModalPayload, 'refreshIDs'>;

type ImportFilesParams = Pick<ModalState, 'objectClassName'> &
  Pick<ModalPayload, 'refreshIDs' | 'cardForm' | 'cardFormValues'>;

export type UpdateModalForm = (params?: ModalPayload) => void;
export type ShowModalForm = (params?: ModalPayload) => void;
export type ShowModalAttach = (params?: AttachParams) => void;
export type ShowModalCreate = (params: CreateParams) => void;
export type ShowModalEdit = (params: EditParams) => void;
export type ShowModalCopy = (params: EditParams) => void;
export type ShowModalReadOnly = (params: EditParams) => void;
export type ShowModalMassReplace = (params: MassReplaceParams) => void;
export type ShowModalImportFiles = (params: ImportFilesParams) => void;

type UseModalForm = () => {
  closeModalForm: () => void;
  updateModalForm: UpdateModalForm;
  showModalForm: ShowModalForm;
  showModalAttach: ShowModalAttach;
  showModalCreate: ShowModalCreate;
  showModalEdit: ShowModalEdit;
  showModalCopy: ShowModalCopy;
  showModalReadOnly: ShowModalReadOnly;
  showModalMassReplace: ShowModalMassReplace;
  showModalImportFiles: ShowModalImportFiles;
};

export const useModalForm: UseModalForm = () => {
  const dispatch = useDispatch();

  const closeModalForm = useCallback(() => {
    dispatch({
      type: ActionType.RESET_MODAL_DATA,
    });
  }, [dispatch]);

  const updateModalForm: UpdateModalForm = useCallback(
    (params) => {
      if (params && Object.keys(params).length) {
        dispatch({
          type: ActionType.UPDATE_MODAL_DATA,
          payload: params,
        });
      }
    },
    [dispatch]
  );

  const showModalForm: ShowModalForm = useCallback(
    (params) => updateModalForm({ ...params, visible: true }),
    [updateModalForm]
  );

  const showModalAttach: ShowModalAttach = useCallback(
    ({ attachParams, refreshIDs, cardForm } = {}) =>
      showModalForm({
        attachParams,
        objectClassName: attachParams?.className,
        refreshIDs,
        cardForm,
        type: ModalType.ATTACH,
      }),
    [showModalForm]
  );

  const showModalCreate: ShowModalCreate = useCallback(
    (params) =>
      showModalForm({
        ...params,
        modalDetails: isSimpleObject(params.formValues) ? { params: params.formValues } : undefined,
        type: ModalType.CREATE,
      }),
    [showModalForm]
  );

  const showModalEdit: ShowModalEdit = useCallback(
    (params) =>
      showModalForm({
        ...params,
        type: ModalType.EDIT,
      }),
    [showModalForm]
  );

  const showModalCopy: ShowModalEdit = useCallback(
    (params) =>
      showModalForm({
        ...params,
        type: ModalType.COPY,
      }),
    [showModalForm]
  );

  const showModalReadOnly: ShowModalReadOnly = useCallback(
    (params) =>
      showModalForm({
        ...params,
        type: ModalType.READONLY,
      }),
    [showModalForm]
  );

  const showModalMassReplace: ShowModalMassReplace = useCallback(
    (params) =>
      showModalForm({
        ...params,
        type: ModalType.MASS_REPLACE,
      }),
    [showModalForm]
  );

  const showModalImportFiles: ShowModalImportFiles = useCallback(
    (params) =>
      showModalForm({
        ...params,
        type: ModalType.IMPORT_FILES,
      }),
    [showModalForm]
  );

  return {
    closeModalForm,
    updateModalForm,
    showModalForm,
    showModalAttach,
    showModalCreate,
    showModalEdit,
    showModalCopy,
    showModalReadOnly,
    showModalMassReplace,
    showModalImportFiles,
  };
};

export default useModalForm;
