import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1.2em' height='1em' viewBox='0 0 36 30' xmlns='http://www.w3.org/2000/svg'>
    <path d='M26.9379 5.33594H2.34097C1.05019 5.33594 0 6.38613 0 7.67691V23.442C0 24.7328 1.05019 25.783 2.34097 25.783H4.84C5.06479 25.783 5.27202 25.9042 5.38266 26.0991L7.1037 29.118C7.41103 29.6571 7.96423 29.9873 8.58591 29.9996C8.5982 29.9996 8.6105 29.9996 8.62455 29.9996C9.23042 29.9996 9.78186 29.6958 10.1032 29.1794L12.0508 26.0745C12.1667 25.8919 12.3652 25.7813 12.5812 25.7813H26.9396C28.2304 25.7813 29.2806 24.7311 29.2806 23.4403V7.67691C29.2788 6.38613 28.2287 5.33594 26.9379 5.33594ZM28.1567 23.442C28.1567 24.1147 27.6105 24.6608 26.9379 24.6608H12.5812C11.9753 24.6608 11.4221 24.9664 11.1007 25.4792L9.15315 28.5841C8.98456 28.8563 8.71938 28.8791 8.6105 28.8774C8.50337 28.8756 8.23819 28.8387 8.08013 28.5613L6.35909 25.5424C6.04825 24.998 5.46695 24.6591 4.84 24.6591H2.34097C1.66836 24.6591 1.12219 24.1129 1.12219 23.4403V7.67691C1.12219 7.0043 1.66836 6.45813 2.34097 6.45813H26.9379C27.6105 6.45813 28.1567 7.0043 28.1567 7.67691V23.442Z' />
    <path d='M33.0219 0H8.42496C7.13417 0 6.08398 1.05019 6.08398 2.34097V3.10139C6.08398 3.41224 6.33512 3.66337 6.64596 3.66337C6.9568 3.66337 7.20793 3.41224 7.20793 3.10139V2.34097C7.20793 1.66836 7.7541 1.12219 8.42671 1.12219H33.0236C33.6962 1.12219 34.2424 1.66836 34.2424 2.34097V18.1061C34.2424 18.7787 33.6962 19.3249 33.0236 19.3249H31.9892C31.6784 19.3249 31.4273 19.576 31.4273 19.8869C31.4273 20.1977 31.6784 20.4488 31.9892 20.4488H33.0236C34.3144 20.4488 35.3646 19.3986 35.3646 18.1079V2.34097C35.3628 1.05019 34.3126 0 33.0219 0Z' />
    <path d='M23.1973 10.2852H6.08346C5.77262 10.2852 5.52148 10.5363 5.52148 10.8471C5.52148 11.158 5.77262 11.4091 6.08346 11.4091H23.1991C23.5099 11.4091 23.7611 11.158 23.7611 10.8471C23.7593 10.5363 23.5082 10.2852 23.1973 10.2852Z' />
    <path d='M23.1973 15.0039H6.08346C5.77262 15.0039 5.52148 15.255 5.52148 15.5659C5.52148 15.8767 5.77262 16.1279 6.08346 16.1279H23.1991C23.5099 16.1279 23.7611 15.8767 23.7611 15.5659C23.7593 15.255 23.5082 15.0039 23.1973 15.0039Z' />
    <path d='M23.1973 19.7148H6.08346C5.77262 19.7148 5.52148 19.966 5.52148 20.2768C5.52148 20.5877 5.77262 20.8388 6.08346 20.8388H23.1991C23.5099 20.8388 23.7611 20.5877 23.7611 20.2768C23.7593 19.966 23.5082 19.7148 23.1973 19.7148Z' />
  </svg>
);

const InactiveIconSvg = () => (
  <svg
    width='1.2em'
    height='1em'
    viewBox='0 0 36 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.9379 5.33594H2.34097C1.05019 5.33594 0 6.38613 0 7.67691V23.442C0 24.7328 1.05019 25.783 2.34097 25.783H4.84C5.06479 25.783 5.27202 25.9042 5.38266 26.0991L7.1037 29.118C7.41103 29.6571 7.96423 29.9873 8.58591 29.9996C8.5982 29.9996 8.6105 29.9996 8.62455 29.9996C9.23042 29.9996 9.78186 29.6958 10.1032 29.1794L12.0508 26.0745C12.1667 25.8919 12.3652 25.7813 12.5812 25.7813H26.9396C28.2304 25.7813 29.2806 24.7311 29.2806 23.4403V7.67691C29.2788 6.38613 28.2287 5.33594 26.9379 5.33594ZM28.1567 23.442C28.1567 24.1147 27.6105 24.6608 26.9379 24.6608H12.5812C11.9753 24.6608 11.4221 24.9664 11.1007 25.4792L9.15315 28.5841C8.98456 28.8563 8.71938 28.8791 8.6105 28.8774C8.50337 28.8756 8.23819 28.8387 8.08013 28.5613L6.35909 25.5424C6.04825 24.998 5.46695 24.6591 4.84 24.6591H2.34097C1.66836 24.6591 1.12219 24.1129 1.12219 23.4403V7.67691C1.12219 7.0043 1.66836 6.45813 2.34097 6.45813H26.9379C27.6105 6.45813 28.1567 7.0043 28.1567 7.67691V23.442Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M33.0219 0H8.42496C7.13417 0 6.08398 1.05019 6.08398 2.34097V3.10139C6.08398 3.41224 6.33512 3.66337 6.64596 3.66337C6.9568 3.66337 7.20793 3.41224 7.20793 3.10139V2.34097C7.20793 1.66836 7.7541 1.12219 8.42671 1.12219H33.0236C33.6962 1.12219 34.2424 1.66836 34.2424 2.34097V18.1061C34.2424 18.7787 33.6962 19.3249 33.0236 19.3249H31.9892C31.6784 19.3249 31.4273 19.576 31.4273 19.8869C31.4273 20.1977 31.6784 20.4488 31.9892 20.4488H33.0236C34.3144 20.4488 35.3646 19.3986 35.3646 18.1079V2.34097C35.3628 1.05019 34.3126 0 33.0219 0Z'
      fill='var(--color-dark-2)'
    />
    <path
      d='M23.1973 10.2852H6.08346C5.77262 10.2852 5.52148 10.5363 5.52148 10.8471C5.52148 11.158 5.77262 11.4091 6.08346 11.4091H23.1991C23.5099 11.4091 23.7611 11.158 23.7611 10.8471C23.7593 10.5363 23.5082 10.2852 23.1973 10.2852Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M23.1973 15.0039H6.08346C5.77262 15.0039 5.52148 15.255 5.52148 15.5659C5.52148 15.8767 5.77262 16.1279 6.08346 16.1279H23.1991C23.5099 16.1279 23.7611 15.8767 23.7611 15.5659C23.7593 15.255 23.5082 15.0039 23.1973 15.0039Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M23.1973 19.7148H6.08346C5.77262 19.7148 5.52148 19.966 5.52148 20.2768C5.52148 20.5877 5.77262 20.8388 6.08346 20.8388H23.1991C23.5099 20.8388 23.7611 20.5877 23.7611 20.2768C23.7593 19.966 23.5082 19.7148 23.1973 19.7148Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={props.inactive ? InactiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
