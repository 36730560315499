import { Result } from 'antd';
import React from 'react';

const ErrorHandler: React.FC<any> = (props) => {
  return (
    <Result
      status='error'
      title='Ошибка при выполнении сценария'
      subTitle={JSON.stringify(props.errors)}
    />
  );
};

export default ErrorHandler;
