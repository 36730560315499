import { JsObject } from 'interfaces/Object';

class WrappedError {
  readonly error: Error;
  name: string;
  message?: string;
  stack?: string;
  options?: JsObject;

  constructor(error: any, options?: JsObject) {
    if (error instanceof WrappedError) {
      this.error = error.error;
      this.name = error.name;
      this.message = error.message;
      this.stack = error.stack;
      this.options = { ...error.options, ...options };
    } else {
      this.error = error instanceof Object ? error : new Error(String(error));
      this.name = 'WrappedError';
      this.message = this.error.message;
      this.stack = this.error.stack;
      this.options = options;
    }
  }
}

export default WrappedError;
