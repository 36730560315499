import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='25' cy='25' r='25' fill='#EF3F5C' />
    <path
      d='M24.9991 11.3633C23.7447 11.3633 22.7266 12.3814 22.7266 13.6358V29.5444C22.7266 30.7988 23.7447 31.817 24.9991 31.817C26.2536 31.817 27.2717 30.7988 27.2717 29.5444V13.6358C27.2717 12.3814 26.2536 11.3633 24.9991 11.3633V11.3633Z'
      fill='white'
    />
    <path
      d='M27.2717 36.3633C27.2717 37.6185 26.2543 38.6359 24.9991 38.6359C23.7439 38.6359 22.7266 37.6185 22.7266 36.3633C22.7266 35.1081 23.7439 34.0903 24.9991 34.0903C26.2543 34.0903 27.2717 35.1081 27.2717 36.3633Z'
      fill='white'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
