import cn from 'classnames';
import styles from 'components/TableGrid/index.module.css';
import React, { FC } from 'react';
import { Spin } from 'antd';

type TablePreLoaderPropsType = {
  small?: boolean;
};

export const TablePreLoader: FC<TablePreLoaderPropsType> = ({ small = true }) => (
  <div className={cn(styles.empty, { [styles.empty__small]: small })}>
    <h2 className={cn(styles.emptyTitle, { [styles.emptyTitleForCardTable]: small })}>Загрузка</h2>
    <div className={styles.spinBox}>
      <Spin spinning={true} />
    </div>
    {!small && (
      <>
        <div className={styles.row} />
        <div className={styles.row} />
      </>
    )}
  </div>
);
