import axios from 'axios';
import ServerError from 'integration/ServerError';
import { IEmailNotifications } from 'interfaces/IEmailNotifications';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers';
import styles from './EmailNotifications.module.css';
import { Checkbox } from 'antd';
import { ActionType } from 'store/actionTypes';

function EmailNotifications() {

  const {typeUser} = useSelector((state: AppState) => state);

  const [emailNotifications, setEmailNotifications] = useState<IEmailNotifications>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!typeUser) {
      axios.get<IEmailNotifications>('/UserEmailSubscriptions', {
        params: {
          className: 'Users',
        }
      }).then((res) => {
        setEmailNotifications({
          subSystems: res.data.subSystems.filter(system => system.events.length > 0)
        })
      }).catch((err:ServerError) => {
        err.notify()
      })
    }
  }, [typeUser]);

  const onCheckboxClick = (choiceCode: number, value: boolean) => {
    dispatch({
      type: ActionType.UPDATE_EDITOR_NOTIFICATIONS,
      payload: {
        choiceCode,
        value
      }
    })
  }

  return (
    <div>
      {
        !!emailNotifications ?
          <div>
            {
              emailNotifications.subSystems.map((system, i) => (
                <div key={i} className={styles.systemWrapper}>
                  <div className={styles.systemTitle}>
                    {system.name}
                  </div>
                  <div className={styles.systemBlock}>
                    {
                      system.events.map((event, j) => (
                        <div key={j} className={styles.event}>
                          <div>
                            {event.name}
                          </div>
                          <div className={styles.wrap}>
                            <Checkbox
                              defaultChecked={!event.unsubscribed}
                              onChange={(e) => onCheckboxClick(event.choiceCode, !e.target.checked)}
                            />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div> :
          <div>Отсутствуют доступные события</div>
      }
    </div>
  )
}

export default EmailNotifications