import { ActionType, Action } from '../actionTypes';

const initialState = {
  tableData: {},
  tableState: {},
  tableModalData: {},
};

const tableData = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_TABLE_DATA: {
      const newState = { ...state };
      newState.tableData = { ...newState.tableData, ...action.payload };
      return newState;
    }
    case ActionType.UPDATE_TABLE_STATE: {
      const newState = { ...state };
      newState.tableState = { ...newState.tableState, ...action.payload };
      return newState;
    }
    case ActionType.UPDATE_TABLE_MODAL_DATA: {
      const newState = { ...state };
      newState.tableModalData = {
        ...newState.tableModalData,
        ...action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default tableData;
