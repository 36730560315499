import { useWindowWidth } from '@react-hook/window-size';
import { FormInstance } from 'antd';
import FormHandler from 'controllers/FormHandler';
import { ObjectData } from 'interfaces/Object';

type ValidateFields = () => Promise<boolean>;
type SetFormData = (objectData?: ObjectData) => void;
type BuildObjectData = (details?: ObjectData) => ObjectData;

type UseFormHandler = (form?: FormInstance) => {
  validateFields: ValidateFields;
  setFormData: SetFormData;
  buildObjectData: BuildObjectData;
};

export const useFormHandler: UseFormHandler = (form) => {
  const width = useWindowWidth();

  const validateFields: ValidateFields = () =>
    FormHandler.validateFields(form, { notify: true, width });

  const setFormData: SetFormData = (objectData) => FormHandler.setFormData(form, objectData);

  const buildObjectData: BuildObjectData = (details) => FormHandler.buildObjectData(form, details);

  return {
    validateFields,
    setFormData,
    buildObjectData,
  };
};

export default useFormHandler;
