import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 20 21' fill='var(--color-additional-elements)' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='16.3809'
      y='0.00195312'
      width='5.11902'
      height='23.1647'
      rx='2.55951'
      transform='rotate(45 16.3809 0.00195312)'
    />
    <rect
      y='3.61914'
      width='5.11902'
      height='23.1647'
      rx='2.55951'
      transform='rotate(-45 0 3.61914)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
