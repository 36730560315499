export interface LinkParams {
  prefix?: string;
  test?: string;
  inboxGroup?: string;
  groupID?: string;
  inboxGroupID?: string;
  inbox?: string;
  inboxName?: string;
  className?: string;
  id?: string;
  parentClassName?: string;
  parentId?: string;
  tabId?: string;
  controllerName?: string;
  useKB?: string;
  backUrl?: string;
  preFillResponse?: string;
  message?: string;
  anchor?: string;
  suffix?: string;
  search?: string;
  page?: string;
  cascadeFilterBranch?: string;
}

export type ComposeLinkFunction = (params: LinkParams) => string;

export const nullLink = '#';

export const linkParamFilled = (val: any): boolean => val !== undefined;

export const composeLink: ComposeLinkFunction = ({
  prefix,
  test,
  inboxGroup,
  groupID,
  inboxGroupID,
  inbox,
  inboxName,
  className,
  id,
  parentClassName,
  parentId,
  tabId,
  controllerName,
  useKB,
  backUrl,
  preFillResponse,
  message,
  anchor,
  suffix,
  search,
  page,
  cascadeFilterBranch
}) => {
  const filled = linkParamFilled;

  let link = '';

  if (filled(prefix)) link += prefix;
  if (filled(test)) link += `/test/${test}`;
  if (filled(inboxGroup || groupID || inboxGroupID))
    link += `/inboxGroup/${inboxGroup || groupID || inboxGroupID}`;
  if (filled(inbox || inboxName)) link += `/inbox/${inbox || inboxName}`;
  if (filled(className)) link += `/className/${className}`;
  if (filled(id)) link += `/id/${id}`;
  if (filled(parentClassName)) link += `/parentClassName/${parentClassName}`;
  if (filled(parentId)) link += `/parentId/${parentId}`;
  if (filled(tabId)) link += `/${tabId}`;
  if (filled(controllerName)) link += `/controllerName/${controllerName}`;
  if (filled(useKB)) link += `/useKB/${useKB}`;
  if (filled(backUrl)) link += `/backUrl/${backUrl}`;
  if (filled(preFillResponse)) link += `/preFillResponse/${preFillResponse}`;
  if (filled(message)) link += `/message/${message}`;
  if (filled(search)) link += `/cascadeFilterBranch/${cascadeFilterBranch}/searchQuery/${search}/page/${page ? page : 1}`;
  if (filled(anchor)) link += anchor?.startsWith('#') ? anchor : `#${anchor}`;
  if (filled(suffix)) link += suffix;

  return link;
};

export default composeLink;
