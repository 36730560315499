import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => process.env.REACT_APP_TYPE === 'irk' ? (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='15' fill='#FFC1CB' />
    <path
      d='M14.0163 9.98778H15.9868V15.9922H14.0163V9.98778ZM14.0163 20.0285H15.9868V17.9937H14.0163V20.0285ZM19.0674 18.0269L21.1613 20.1218C21.0013 20.3119 20.8278 20.4955 20.6512 20.6788C19.1761 22.2143 17.132 23.0718 15.0033 23.0474C12.8709 23.0681 10.8252 22.2046 9.35187 20.6623C7.81341 19.1808 6.95642 17.1278 6.98476 14.9915C6.98755 14.6737 7.00755 14.3566 7.04477 14.0408H5.04418C5.01743 14.3544 5.00092 14.6679 5.00092 14.9915C4.96417 17.6505 6.03003 20.2058 7.94476 22.0503C9.78924 23.9667 12.3443 25.0343 15.003 24.9992C17.6573 25.0313 20.2072 23.9642 22.0481 22.0503C22.2281 21.8702 22.3816 21.6866 22.5616 21.5L22.6048 21.5433L24.7352 23.6649L24.7152 17.9939L19.0674 18.0269ZM10.9388 12.0558L8.81492 9.93422C8.98376 9.73411 9.16401 9.53726 9.35494 9.3439C10.8322 7.81235 12.8758 6.9591 15.0031 6.98561C17.1318 6.96118 19.1759 7.81837 20.651 9.3539C22.1909 10.8347 23.0482 12.8882 23.0182 15.0247C23.0158 15.3593 22.9935 15.6935 22.9516 16.0255H24.9522C24.9822 15.6918 24.999 15.3584 24.999 15.0247C25.0422 12.3534 23.9731 9.78434 22.0481 7.93315C20.202 6.02306 17.6486 4.96174 14.9929 5.00105C12.3398 4.96429 9.78939 6.02532 7.9442 7.93315C7.75419 8.12326 7.57418 8.32011 7.40069 8.52022L5.26709 6.38532L5.30035 12.0561L10.9388 12.0558Z'
      fill='#EF3F5C'
    />
  </svg>
) : (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2C7.25144 2 4.82508 3.38626 3.38443 5.5H6V7.5H0V1.5H2V3.99936C3.82381 1.57166 6.72764 0 10 0C15.5228 0 20 4.47715 20 10H18C18 5.58172 14.4183 2 10 2ZM2 10C2 14.4183 5.58172 18 10 18C12.7486 18 15.1749 16.6137 16.6156 14.5H14V12.5H20V18.5H18V16.0006C16.1762 18.4283 13.2724 20 10 20C4.47715 20 0 15.5228 0 10H2Z" fill="#BD2A17"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
