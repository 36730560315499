export const useTableViewAddComponents = (meta: any, setShowFiltersTools?: any) => {
  const addFilterComponent = (condition: any, factory: any) => {
    const options = {
      additionProps: {},
      component: {
        ...condition,
        propName: condition.field,
        label: condition.label || condition.title,
        hint: meta[condition.field]?.hint,
      },
    };
    factory.addComponent(options);
  };

  const addTableComponent = (field: any, factory: any) => {
    const options = {
      additionalProps: {},
      component: {
        ...field,
        propName: field.fieldName,
        hint: meta[field.fieldName]?.hint,
      },
    };
    factory.addComponent(options);
  };

  const bypassNestedFilters = (conditions: any, tableData: any, factory: any, nested?: boolean) => {
    const fields = tableData.fields;

    conditions.forEach((condition: any) => {
      if (!condition.visible) return;

      if (condition.conditions) {
        bypassNestedFilters(condition.conditions, tableData, factory, true);
      }

      fields.forEach((field: any) => {
        if (field.fieldName === condition.field) {
          field.isFiltered = true;
          if (nested) addFilterComponent(condition, factory);
        }
      });
    });

    if (!nested) setShowFiltersTools(Boolean(fields.find((i: any) => i.isFiltered)));
  };

  const addFilterComponents = (filtersConfig: any, factory: any) => {
    const conditions = filtersConfig.config.criteria.conditions;

    if (!conditions) return;

    conditions.forEach((condition: any) => addFilterComponent(condition, factory));
  };

  const addTableComponents = (tableData: any, factory: any) => {
    const fields = tableData.fields;

    fields.forEach((field: any) => {
      if (!field.isFiltered) addTableComponent(field, factory);
    });
  };

  return {
    addFilterComponent,
    addTableComponent,
    bypassNestedFilters,
    addFilterComponents,
    addTableComponents,
  };
};
