import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 49 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M46.8202 35.19C46.9177 34.9986 46.9861 34.7919 47.024 34.5773C48.1949 31.6181 48.7896 28.4347 48.7712 25.221C48.6012 16.1503 43.9834 7.84526 36.6174 3.36257C29.2514 -1.12086 20.2248 -1.12086 12.8593 3.36257C5.49334 7.846 0.875386 16.1511 0.705487 25.221C0.687202 28.4347 1.28179 31.6181 2.45267 34.5773C2.48992 34.7919 2.55899 34.9986 2.65651 35.19L3.07367 35.9804C3.30664 36.4692 3.55923 36.947 3.83079 37.4147L23.0536 73.9457H23.0543C23.399 74.5968 24.0444 75 24.7439 75C25.4428 75 26.0882 74.5968 26.4329 73.9457L45.6557 37.4147C45.9178 36.947 46.1799 36.4684 46.4128 35.9804L46.8202 35.19ZM24.7334 3.65083C30.0772 3.65663 35.201 5.93208 38.9792 9.9776C42.758 14.0239 44.8832 19.5103 44.8885 25.2323C44.9055 28.3459 44.2052 31.4168 42.8494 34.1724H42.9076L24.7337 68.6855L6.57014 34.1724H6.62838C5.2726 31.4168 4.57243 28.3459 4.58924 25.2323C4.59466 19.5118 6.71835 14.0276 10.4944 9.98131C14.2712 5.93579 19.3923 3.65881 24.7342 3.65083H24.7334Z'
      fill='var(--color-additional-elements)'
    />
    <path
      d='M31.1701 34.1714C34.3021 31.9468 36.2397 28.2406 36.3731 24.2183C36.5066 20.196 34.8196 16.352 31.8426 13.8953C28.8655 11.4387 24.9641 10.67 21.3654 11.8324C17.7666 12.9948 14.9136 15.9446 13.7021 19.7545C12.4905 23.5643 13.0696 27.7665 15.2583 31.0383C17.4464 34.3108 20.9747 36.2512 24.7331 36.2505C27.0228 36.2498 29.2616 35.5268 31.17 34.1716L31.1701 34.1714ZM24.7332 15.376C26.7933 15.376 28.7686 16.2519 30.2254 17.8117C31.6821 19.3714 32.5002 21.4867 32.5002 23.6926C32.5002 25.8984 31.6821 28.0136 30.2254 29.5727C28.7687 31.1324 26.7933 32.0091 24.7332 32.0091C22.6731 32.0091 20.6977 31.1324 19.2416 29.5727C17.785 28.0137 16.9662 25.8984 16.9662 23.6926C16.9662 21.4867 17.785 19.3716 19.2416 17.8117C20.6976 16.2519 22.6731 15.376 24.7332 15.376V15.376Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
