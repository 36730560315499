import { LeftMenuData } from 'components/navigation/leftmenu/LeftMenu';
import { ActionType, Action } from '../actionTypes';

const initialState = {
  inboxGroups: [],
  inboxes: {},
};

const menu = (state: LeftMenuData = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.UPDATE_MENU_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

export default menu;
