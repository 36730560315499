import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';
import { VerticalLeftOutlined } from '@ant-design/icons';

import styles from './TwoColumnGrid.module.css';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { useWindowWidth } from '@react-hook/window-size';
import { DataModal } from 'utils/types';
import ModalControl from 'components/controls/ModalControl/ModalControl';
import { isArray } from 'lodash';
import CacheStorage from 'utils/CacheStorage';
import { useSelector } from 'react-redux';
import useModalWithText from 'hooks/useModalWithText';

interface TwoColumnContentProps {
  children: any;
  sider?: any;
  name?: string;
  className?: string;
  inboxGroup?: any;
  buttonState?: any;
  simpleTable?: boolean;
}

const TwoColumnGrid: React.FC<TwoColumnContentProps> = (props) => {
  const [showSidebar, setShowSideBar] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { className, simpleTable, children, buttonState, sider } = props;
  const params: any = useRouteMatch();
  const width = useWindowWidth();
  const isExternal: boolean = useSelector((state: any) => {
    return state?.typeUser?.userData?.external;
  });
  const wrapClassName = ['app-content', styles.content];
  if (className) wrapClassName.push(className);
  const { dataModal, getDataModal, resetDataModal } = useModalWithText()

  const buttonText: string = showSidebar ? 'Свернуть' : 'Развернуть';
  const buttonIcon: ReactElement = <VerticalLeftOutlined rotate={showSidebar ? 90 : 270} />;

  useEffect(() => {
    setShowSideBar(false);
  }, [pathname]);

  const siderStyle = { display: showSidebar ? 'flex' : 'none' };

  const onOk = () => {
    resetDataModal()
    CacheStorage.set('disclaimer', true);
  }

  useEffect(() => {
    if (params.params?.className !== undefined && isExternal) {
      CacheStorage.get('disclaimer').then((res) => {
        if (!res) {
          getDataModal(params.params?.className, width)
        } else {
          onOk()
        }
      });
    }
  }, [params.params?.className, isExternal])

  return (
    <div className={styles.container}>
      <div className={wrapClassName.join(' ')} style={{ width: '100%' }}>
        {children}
      </div>
      <div className={styles.wrapper}>
        {!simpleTable ? (
          !buttonState && (
            <div className={styles.buttonWrapper}>
              <Button
                disabled={buttonState}
                type='default'
                className={styles.buttonOpen}
                onClick={() => setShowSideBar(!showSidebar)}
              >
                {buttonText}
                {buttonIcon}
              </Button>
            </div>
          )
        ) : (
          <></>
        )}
        <div className={styles.sider} style={siderStyle}>
          {sider}
        </div>
      </div>
      <ModalControl
        title={dataModal?.header}
        okText={dataModal?.ok}
        cancelText={dataModal?.cancel ? dataModal.cancel : ''}
        isVisible={params.params?.className !== null && dataModal !== undefined && dataModal.header !== ''}
        onOk={onOk}
      >
        {isArray(dataModal?.text) ? (
          <div>
            {dataModal?.text.map((item: string, i: number) => {
              return (
                <div key={i} className={styles.modalText}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          <span>{dataModal?.text}</span>
        )}
      </ModalControl>
    </div>
  );
};

export default TwoColumnGrid;
