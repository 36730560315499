import { createSelector } from 'reselect';
import { EntityEditorRouteParams } from 'router/Routes';
import { AppState } from '../reducers';

type MetaSelectorProps = Partial<EntityEditorRouteParams>;

const currentSelector = (state: AppState, props: MetaSelectorProps) => {
  return state.meta[props.className || ''] || {};
};

const parentSelector = (state: AppState, props: MetaSelectorProps) => {
  const current: any = state.meta[props.className || ''];
  return (
    state.meta[current != null && current.params != null ? current.params.parentName : ''] || {}
  );
};

export const getMetaByClassType = createSelector(
  [currentSelector, parentSelector],
  (current, parent) => {
    let componentProps: any = {};
    // extract parent class props
    if (parent.property_params != null) {
      componentProps = parent.property_params.reduce((result: any, current: any) => {
        result[parent.params.symbolicName] = parent.params;
        return result;
      }, {});
    }
    // override parent class props
    if (current.property_params != null) {
      current.property_params.reduce((result: any, current: any) => {
        componentProps[current.params.symbolicName] = current.params;
        return result;
      }, {});
    }
    return componentProps;
  }
);
