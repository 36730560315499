import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.42084 6.61378L14.4573 0.45929C14.4655 0.442589 14.4818 0.434239 14.49 0.425888C14.7112 0.200418 14.9978 0.0584561 15.2845 0.0167025C15.3091 0.0167025 15.3337 0.00835124 15.3582 0.00835124C15.4074 5.13289e-07 15.4565 3.72529e-09 15.5057 3.72529e-09L15.6449 0.00835124C15.6695 0.00835124 15.694 0.0167025 15.7186 0.0167025C16.0135 0.0584561 16.2919 0.200418 16.5131 0.425888C16.5213 0.434239 16.5377 0.450939 16.5459 0.45929L22.5823 6.61378C22.8608 6.8977 23 7.27349 23 7.64927C23 8.02505 22.8608 8.40084 22.5823 8.68476C22.0253 9.25261 21.1162 9.25261 20.551 8.68476L16.9226 4.98539V18.5386C16.9226 19.3403 16.2756 20 15.4893 20C15.0961 20 14.7357 19.833 14.4736 19.5741C14.2116 19.3069 14.0559 18.9478 14.0559 18.5386L14.0559 4.98539L10.4275 8.68476C9.87057 9.25261 8.96142 9.25261 8.39627 8.68476C7.86388 8.10856 7.86388 7.18163 8.42084 6.61378Z'
      fill='var(--color-additional-elements)'
    />
    <rect y='27' width='30' height='3' fill='var(--color-dark-2)' />
    <rect y='30' width='10' height='3' transform='rotate(-90 0 30)' fill='var(--color-dark-2)' />
    <rect x='27' y='30' width='10' height='3' transform='rotate(-90 27 30)' fill='var(--color-dark-2)' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
