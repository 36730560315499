import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.0283 6.40932C20.6477 6.40932 21.1493 5.86673 21.1493 5.19745V1.21187C21.1493 0.542593 20.6477 0 20.0283 0C19.4095 0 18.9073 0.542593 18.9073 1.21187V5.19745C18.9073 5.86673 19.4095 6.40932 20.0283 6.40932ZM7.97169 6.40932C8.59048 6.40932 9.09269 5.86673 9.09269 5.19745V1.21187C9.09269 0.542593 8.59048 0 7.97169 0C7.35229 0 6.85068 0.542593 6.85068 1.21187V5.19745C6.85068 5.86673 7.35229 6.40932 7.97169 6.40932ZM26.5053 2.9618H22.4945V5.19745C22.4945 6.66753 21.3882 7.86356 20.0283 7.86356C18.6685 7.86356 17.5621 6.66753 17.5621 5.19745V2.9618H10.4379V5.19745C10.4379 6.66753 9.33154 7.86356 7.97169 7.86356C6.61183 7.86356 5.50548 6.66753 5.50548 5.19745V2.9618H1.49467C0.669015 2.9618 0 3.68504 0 4.57762V11.2025H28V4.57762C28 3.68504 27.331 2.9618 26.5053 2.9618ZM0 28.3842C0 29.2768 0.669015 30 1.49467 30H26.5053C27.331 30 28 29.2768 28 28.3842V12.4952H0V28.3842ZM20.6922 16.3327H24.5162V20.4667H20.6922V16.3327ZM20.6922 22.2305H24.5162V26.3644H20.6922V22.2305ZM14.956 16.3327H18.78V20.4667H14.956V16.3327ZM14.956 22.2305H18.78V26.3644H14.956V22.2305ZM9.22004 16.3327H13.044V20.4667H9.22004V16.3327ZM9.22004 22.2305H13.044V26.3644H9.22004V22.2305ZM3.48378 16.3327H7.30775V20.4667H3.48378V16.3327ZM3.48378 22.2305H7.30775V26.3644H3.48378V22.2305Z'
      fill='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
