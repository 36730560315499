import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg
    width='0.8em'
    height='0.8em'
    viewBox='0 0 15 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.125 11.5789V11.4417L6.05493 11.3237L0.5 1.96801V0.5H14.5V1.96801L8.94507 11.3237L8.875 11.4417V11.5789V17.602L6.125 19.1459V11.5789Z'
      fill='var(--color-dark-2)'
      stroke='var(--color-dark-2)'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg
    width='0.8em'
    height='0.8em'
    viewBox='0 0 15 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.125 11.5789V11.4417L6.05493 11.3237L0.5 1.96801V0.5H14.5V1.96801L8.94507 11.3237L8.875 11.4417V11.5789V17.602L6.125 19.1459V11.5789Z'
      fill='var(--color-additional-elements)'
      stroke='var(--color-additional-elements)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={active ? ActiveIconSvg : iconSvg} {...iconProps} />;
};

export default CustomIcon;
