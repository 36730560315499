import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 27 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.0649 30C5.99973 30 3.93423 29.2139 2.36234 27.6413C0.838651 26.118 0 24.0926 0 21.9384C0 19.7843 0.838651 17.7589 2.36234 16.2352L16.9049 1.69303C17.9962 0.601379 19.4479 0 20.9914 0C22.5348 0 23.9865 0.601379 25.0782 1.69303C27.3321 3.94661 27.3321 7.61345 25.0782 9.86703L10.774 24.1719C9.38546 25.5608 7.12485 25.5608 5.73636 24.1719C5.06336 23.4993 4.6929 22.6047 4.6929 21.6533C4.6929 20.7019 5.06336 19.8074 5.73636 19.1347L16.9521 7.91899C17.4246 7.44646 18.1903 7.44646 18.6628 7.91899C19.1354 8.39153 19.1354 9.15722 18.6628 9.62976L7.44679 20.8451C7.23094 21.061 7.11213 21.3481 7.11213 21.6533C7.11213 21.9585 7.23094 22.2453 7.44679 22.4612C7.89289 22.9073 8.61742 22.9069 9.06285 22.4615L23.3678 8.15626C24.6779 6.84575 24.6779 4.71431 23.3678 3.4038C22.7329 2.76929 21.8889 2.41957 20.9914 2.41957C20.0938 2.41957 19.2498 2.76929 18.615 3.4038L4.07278 17.9463C3.00622 19.0125 2.41924 20.4305 2.41924 21.9384C2.41924 23.4464 3.00656 24.8644 4.07278 25.9306C6.27415 28.1319 9.85532 28.1316 12.0567 25.9309L23.5103 14.4773C23.9829 14.0047 24.7486 14.0047 25.2211 14.4773C25.6936 14.9498 25.6936 15.7155 25.2211 16.188L13.7675 27.6413C12.1956 29.2136 10.1301 30 8.0649 30Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
