import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 54 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='27' cy='26' r='25' fill='white' />
    <path
      d='M52.6129 26C52.6129 39.8068 41.0586 51 26.8065 51C12.5543 51 1 39.8068 1 26C1 12.1932 12.5543 1 26.8065 1C41.0586 1 52.6129 12.1932 52.6129 26Z'
      stroke='var(--color-additional-elements)'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M36.2972 35.1906L17.3242 16.8105'
      stroke='var(--color-additional-elements)'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M36.2972 16.8105L17.3242 35.1906'
      stroke='var(--color-additional-elements)'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
