import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1.4rem" height="1.4rem" viewBox="0 0 59 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.7 0.5C0.312207 0.5 0 0.812207 0 1.2V1.9L29.4 23.95L58.8 1.9V1.2C58.8 0.812207 58.4878 0.5 58.1 0.5H0.7ZM0 5.4V33.5969L16.8219 18.0218L0 5.4ZM58.8 5.4L41.9781 18.0218L58.8 33.5969V5.4ZM19.1187 19.7281L2.05625 35.5H56.7437L39.6812 19.7281L30.2312 26.8156C29.9905 26.9932 29.6992 27.0891 29.4 27.0891C29.1008 27.0891 28.8095 26.9932 28.5687 26.8156L19.1187 19.7281Z"
      fill="var(--color-additional-elements)"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
