import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 51 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    transform='rotate(180)'
  >
    <rect
      x='10.5'
      y='20'
      width='5'
      height='30'
      rx='2.5'
      transform='rotate(-90 10.5 20)'
      fill='var(--color-dark-2)'
    />
    <path
      d='M34.63 38.9993L25.4714 29.7203L16.3129 38.9993L13.4995 36.1286L25.4714 23.9992L37.4434 36.1286L34.63 38.9993Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const ActiveIconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='10.5'
      y='20'
      width='5'
      height='30'
      rx='2.5'
      transform='rotate(-90 10.5 20)'
      fill='var(--color-dark-2)'
    />
    <path
      d='M34.63 38.9993L25.4714 29.7203L16.3129 38.9993L13.4995 36.1286L25.4714 23.9992L37.4434 36.1286L34.63 38.9993Z'
      fill='var(--color-dark-2)'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={active ? ActiveIconSvg : IconSvg} {...iconProps} />;
};

export default CustomIcon;
